import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Container, Button, Form, Accordion } from "react-bootstrap";
import CustomLazyLoad from "../../../Helper/CustomLazyLoad";
import Skeleton from "react-loading-skeleton";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-multi-lang";
import { useDispatch, useSelector } from "react-redux";
import {
  swpCalculatorStart,
  swpCalculatorSuccess,
} from "../../../../store/slices/CommonSlice";
import { ButtonLoader } from "../../../Helper/Loader";
import PieChart from "../PieChart";
import FAQIndex from "../FAQIndex";
import { Link } from "react-router-dom";
import formatNumberWithCommas from "../../../FormatNumber";

const CalculatorIndex = (props) => {
  const t = useTranslation("tax_calculator");
  const investmentRef = useRef(null);
  const interestRef = useRef(null);
  const withdrawalRef = useRef(null);
  const timePeriodRef = useRef(null);
  const dispatch = useDispatch();
  const formRef = useRef();
  const swpCalculator = useSelector((state) => state.common.swpCalculator);

  useEffect(() => {
    const onChange = (evt, field) => {
      const customEvent = evt;
      switch (field) {
        case "investment":
          formRef.current.setFieldValue(
            "total_investment",
            customEvent.detail.value
          );
          break;
        case "withdrawal":
          formRef.current.setFieldValue(
            "withdrawl_per_month",
            customEvent.detail.value
          );
          break;
        case "timePeriod":
          formRef.current.setFieldValue(
            "time_period",
            customEvent.detail.value
          );
          break;
        case "interest":
          formRef.current.setFieldValue(
            "rate_of_interest",
            customEvent.detail.value
          );
          break;
      }
      setTimeout(() => {
        formRef.current.submitForm();
      }, 1);
    };
    investmentRef.current?.addEventListener("change", (evt) =>
      onChange(evt, "investment")
    );
    interestRef.current?.addEventListener("change", (evt) =>
      onChange(evt, "interest")
    );
    timePeriodRef.current?.addEventListener("change", (evt) =>
      onChange(evt, "timePeriod")
    );
    withdrawalRef.current?.addEventListener("change", (evt) =>
      onChange(evt, "withdrawal")
    );
    return () => {
      investmentRef.current?.removeEventListener("change", onChange);
      interestRef.current?.removeEventListener("change", onChange);
      timePeriodRef.current?.removeEventListener("change", onChange);
      withdrawalRef.current?.removeEventListener("change", onChange);
    };
  }, []);

  useEffect(() => {
    //dispatch(swpCalculatorStart(formRef.current.values));
    formRef.current.isValid && calculate(formRef.current.values);
  }, []);

  const onChangeInput = (event, field) => {
    const value = !isNaN(parseFloat(event.target.value))
      ? parseFloat(event.target.value)
      : "";
    switch (field) {
      case "investment":
        formRef.current.setFieldValue("total_investment", value);
        investmentRef.current.value = value;
        break;
      case "interest":
        formRef.current.setFieldValue("rate_of_interest", value);
        interestRef.current.value = value;
        break;
      case "timePeriod":
        formRef.current.setFieldValue("time_period", value);
        timePeriodRef.current.value = value;
        break;
      case "withdrawal":
        formRef.current.setFieldValue("withdrawl_per_month", value);
        withdrawalRef.current.value = value;
        break;
    }
    setTimeout(() => {
      formRef.current.submitForm();
    }, 1);
  };

  const handleSubmit = (values) => {
    formRef.current.isValid && calculate(values);
    // dispatch(swpCalculatorStart(values));
  };

  const swpSchema = Yup.object().shape({
    total_investment: Yup.string()
      .test(
        "min",
        t("swp_calculator.invalid_min", { value: 10000 }),
        (value) => {
          return value >= 10000;
        }
      )
      .test(
        "max",
        t("swp_calculator.invalid_max", {
          value: 5000000,
          name: "Total investment",
        }),
        (value) => {
          return value <= 5000000;
        }
      )
      .required(t("swp_calculator.required")),
    rate_of_interest: Yup.string()
      .test("min", t("swp_calculator.invalid_min", { value: 1 }), (value) => {
        return value >= 1;
      })
      .test(
        "max",
        t("swp_calculator.invalid_max", {
          value: 30,
          name: "Expected return rate",
        }),
        (value) => {
          return value <= 30;
        }
      )
      .required(t("swp_calculator.required")),
    time_period: Yup.string()
      .test("min", t("swp_calculator.invalid_min", { value: 1 }), (value) => {
        return value >= 1;
      })
      .test(
        "max",
        t("swp_calculator.invalid_max", { value: 30, name: "Time period" }),
        (value) => {
          return value <= 30;
        }
      )
      .required(t("swp_calculator.required")),
    withdrawl_per_month: Yup.string()
      .test("min", t("swp_calculator.invalid_min", { value: 500 }), (value) => {
        return value >= 500;
      })
      .test(
        "max",
        t("swp_calculator.invalid_max", {
          value: 50000,
          name: "Withdrawal per month",
        }),
        (value) => {
          return value <= 50000;
        }
      )
      .required(t("swp_calculator.required")),
  });

  const lumCalculator = (principal, rateOfInterest, timePeriod) => {
    const rateOfInterestDecimal = rateOfInterest / 100;
    const monthlyInterestRate = rateOfInterestDecimal / 12;
    const months = timePeriod * 12;
    const maturityAmount =
      principal *
      ((1 + monthlyInterestRate) ** months - 1) *
      ((1 + monthlyInterestRate) / monthlyInterestRate);
    const investedAmount = principal * months;
    const estimatedReturn = maturityAmount - investedAmount;

    return {
      maturityAmount: maturityAmount,
      investedAmount: investedAmount,
      estimatedReturn: estimatedReturn,
    };
  };

  const calculate = (values) => {
    const {
      total_investment,
      withdrawl_per_month,
      rate_of_interest,
      time_period,
      compounding_frequency,
    } = values;
    const totalWithdrawal = withdrawl_per_month * time_period * 12;
    const totalMonths = time_period * 12;
    let closingBalancePrev = total_investment;

    for (let month = 1; month <= totalMonths; month++) {
      const startingBalanceCurr = closingBalancePrev - withdrawl_per_month;
      const { maturityAmount: currentMaturityAmount } = lumCalculator(
        startingBalanceCurr,
        rate_of_interest,
        1 / 12
      );
      closingBalancePrev = currentMaturityAmount;
    }

    const finalValue = closingBalancePrev;

    return dispatch(
      swpCalculatorSuccess({
        total_investment: Math.round(total_investment),
        total_withdrawl: Math.round(totalWithdrawal),
        final_value: Math.round(finalValue),
      })
    );
  };
  return (
    <>
      <div className="single-tax-calculator">
        <div className="sm-padding single-tax-calculator-sec">
          <Container>
            <div className="single-tax-calculator-box">
              <div className="single-tax-calculator-header">
                <Row>
                  <Col md={6}>
                    <div className="d-flex other-calculator-btn-sec">
                      <Link
                        to="/tax-calculator"
                        className="other-calculator-btn"
                      >
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            enableBackground="new 0 0 512 512"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="#fff"
                              d="M10.957 12.354a.5.5 0 010-.708l4.586-4.585a1.5 1.5 0 00-2.121-2.122L8.836 9.525a3.505 3.505 0 000 4.95l4.586 4.586a1.5 1.5 0 002.121-2.122z"
                              data-original="#000000"
                            ></path>
                          </svg>
                        </span>
                        <span>Other Calculators</span>
                      </Link>
                    </div>
                    <div className="single-tax-calculator-info-sec">
                      <h1>{t("swp_calculator.heading")}</h1>
                      <p>{t("swp_calculator.content")}</p>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="single-tax-calculator-header-img-sec">
                      <CustomLazyLoad
                        className="single-tax-calculator-header-img"
                        src={
                          window.location.origin +
                          "/img/single-tax-calculator-header-img.png"
                        }
                        type="image/svg"
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="single-tax-calculator-form-box  line-stright calculator-space-md">
              <Row className="align-items-center g-5">
                <Col md={6} lg={6}>
                  <Formik
                    initialValues={{
                      total_investment: 500000,
                      withdrawl_per_month: 10000,
                      rate_of_interest: 12,
                      time_period: 5,
                      compounding_frequency: 1, // send as static value
                    }}
                    innerRef={formRef}
                    validationSchema={swpSchema}
                    onSubmit={handleSubmit}
                  >
                    {({
                      setFieldValue,
                      values,
                      resetForm,
                      isValid,
                      submitForm,
                    }) => (
                      <FORM className="single-tax-calculator-form">
                        <div className="single-tax-calculator-form-card">
                          <div className="single-tax-calculator-form-header-sec">
                            <h4>{t("swp_calculator.investment.text")}</h4>
                            <Form.Group
                              className="mb-0"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Field
                                className="form-control"
                                name="total_investment"
                                placeholder={t(
                                  "swp_calculator.investment.placeholder"
                                )}
                                type="number"
                                onKeyDown={(e) => {
                                  if (
                                    !/[0-9]|Backspace|ArrowLeft|ArrowRight|Delete|ArrowUp|ArrowDown|Tab/.test(
                                      e.key
                                    ) &&
                                    !(
                                      e.ctrlKey &&
                                      (e.key === "v" ||
                                        e.key === "c" ||
                                        e.key === "x" ||
                                        e.key === "a")
                                    )
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                                onInput={(e) => {
                                  if (e.target.value < 0) {
                                    e.target.value = 0;
                                  }
                                  onChangeInput(e, "investment");
                                }}
                              />
                            </Form.Group>
                          </div>
                          <ErrorMessage
                            component={"div"}
                            name="total_investment"
                            className="errorMsg"
                          />
                          <div className="tax-calculator-input-slider-sec">
                            <tc-range-slider
                              ref={investmentRef}
                              value={investmentRef}
                              mousewheel-disabled="true"
                              min={10000}
                              max={5000000}
                              slider-width="100%"
                              slider-bg-fill="#2F5CFF"
                              generate-labels="true"
                              round="0"
                            ></tc-range-slider>
                          </div>
                        </div>
                        <div className="single-tax-calculator-form-card">
                          <div className="single-tax-calculator-form-header-sec">
                            <h4>{t("swp_calculator.withdrawal.text")}</h4>
                            <Form.Group
                              className="mb-0"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Field
                                className="form-control"
                                name="withdrawl_per_month"
                                placeholder={t(
                                  "swp_calculator.withdrawal.placeholder"
                                )}
                                type="number"
                                onKeyDown={(e) => {
                                  if (
                                    !/[0-9]|Backspace|ArrowLeft|ArrowRight|Delete|ArrowUp|ArrowDown|Tab/.test(
                                      e.key
                                    ) &&
                                    !(
                                      e.ctrlKey &&
                                      (e.key === "v" ||
                                        e.key === "c" ||
                                        e.key === "x" ||
                                        e.key === "a")
                                    )
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                                onInput={(e) => {
                                  if (e.target.value < 0) {
                                    e.target.value = 0;
                                  }
                                  onChangeInput(e, "withdrawal");
                                }}
                              />
                            </Form.Group>
                          </div>
                          <ErrorMessage
                            component={"div"}
                            name="withdrawl_per_month"
                            className="errorMsg"
                          />
                          <div className="tax-calculator-input-slider-sec">
                            <tc-range-slider
                              ref={withdrawalRef}
                              value={withdrawalRef}
                              mousewheel-disabled="true"
                              min={500}
                              max={50000}
                              slider-width="100%"
                              slider-bg-fill="#2F5CFF"
                              generate-labels="true"
                              round="0"
                            ></tc-range-slider>
                          </div>
                        </div>
                        <div className="single-tax-calculator-form-card">
                          <div className="single-tax-calculator-form-header-sec">
                            <h4>{t("swp_calculator.return_rate.text")}</h4>
                            <Form.Group
                              className="mb-0"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Field
                                className="form-control"
                                name="rate_of_interest"
                                placeholder={t(
                                  "swp_calculator.return_rate.placeholder"
                                )}
                                type="number"
                                onKeyDown={(e) => {
                                  if (
                                    !/[0-9.]|Backspace|ArrowLeft|ArrowRight|Delete|ArrowUp|ArrowDown|Tab/.test(
                                      e.key
                                    ) ||
                                    (e.key === "-" &&
                                      e.target.selectionStart !== 0)
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                                onInput={(e) => {
                                  if (e.target.value < 0) {
                                    e.target.value = 0;
                                  }
                                  onChangeInput(e, "interest");
                                }}
                              />
                            </Form.Group>
                          </div>
                          <ErrorMessage
                            component={"div"}
                            name="rate_of_interest"
                            className="errorMsg"
                          />
                          <div className="tax-calculator-input-slider-sec">
                            <tc-range-slider
                              ref={interestRef}
                              value={interestRef}
                              mousewheel-disabled="true"
                              min={1}
                              max={30}
                              slider-width="100%"
                              slider-bg-fill="#2F5CFF"
                              generate-labels="true"
                              round="1"
                            ></tc-range-slider>
                          </div>
                        </div>
                        <div className="single-tax-calculator-form-card">
                          <div className="single-tax-calculator-form-header-sec">
                            <h4>{t("swp_calculator.time_period.text")}</h4>
                            <Form.Group
                              className="mb-0"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Field
                                className="form-control"
                                name="time_period"
                                placeholder={t(
                                  "swp_calculator.time_period.placeholder"
                                )}
                                type="number"
                                onKeyDown={(e) => {
                                  if (
                                    !/[0-9]|Backspace|ArrowLeft|ArrowRight|Delete|ArrowUp|ArrowDown|Tab/.test(
                                      e.key
                                    ) &&
                                    !(
                                      e.ctrlKey &&
                                      (e.key === "v" ||
                                        e.key === "c" ||
                                        e.key === "x" ||
                                        e.key === "a")
                                    )
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                                onInput={(e) => {
                                  if (e.target.value < 0) {
                                    e.target.value = 0;
                                  }
                                  onChangeInput(e, "timePeriod");
                                }}
                              />
                            </Form.Group>
                          </div>
                          <ErrorMessage
                            component={"div"}
                            name="time_period"
                            className="errorMsg"
                          />
                          <div className="tax-calculator-input-slider-sec">
                            <tc-range-slider
                              ref={timePeriodRef}
                              value={timePeriodRef}
                              mousewheel-disabled="true"
                              min={1}
                              max={30}
                              slider-width="100%"
                              slider-bg-fill="#2F5CFF"
                              generate-labels="true"
                              round="0"
                            ></tc-range-slider>
                          </div>
                        </div>
                        {/* <div className="d-flex justify-content-end">
                          <Button
                            type="submit"
                            className="default-primary-btn"
                            disabled={swpCalculator.buttonDisable}
                          >
                            {swpCalculator.buttonDisable ? (
                              <ButtonLoader />
                            ) : (
                              t("swp_calculator.calculate")
                            )}
                          </Button>
                        </div> */}
                      </FORM>
                    )}
                  </Formik>
                </Col>
                <Col md={6} lg={6}>
                  {swpCalculator.loading ? (
                    <div className="single-calculator-info-frame">
                      <div className="pie-chart-skelton">
                        <div className="skeleton-wrapper">
                          <Skeleton
                            borderRadius={130}
                            width={230}
                            height={230}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                        </div>
                        <div className="mt-4">
                          <Skeleton
                            width={250}
                            height={15}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                        </div>
                      </div>

                      <div className="calculator-info-details-card">
                        <div className="calculator-details-frame">
                          <Skeleton
                            width={200}
                            height={20}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                          <Skeleton
                            width={130}
                            height={20}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                        </div>
                        <div className="calculator-details-frame">
                          <Skeleton
                            width={200}
                            height={20}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                          <Skeleton
                            width={130}
                            height={20}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                        </div>
                        <hr />
                        <div className="calculator-details-frame top-bottom-line">
                          <Skeleton
                            width={200}
                            height={20}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                          <Skeleton
                            width={130}
                            height={20}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                        </div>
                        <hr />
                      </div>
                    </div>
                  ) : (
                    Object.keys(swpCalculator.data).length > 0 && (
                      <div className="single-calculator-info-frame">
                        <div className="pie-chart-sec">
                          <PieChart
                            name={[
                              t("swp_calculator.invested_amount"),
                              t("swp_calculator.total_withdrawal"),
                            ]}
                            data={[
                              swpCalculator.data.total_investment,
                              swpCalculator.data.total_withdrawl,
                            ]}
                          />
                        </div>
                        <div className="calculator-info-details-card">
                          <div className="calculator-details-frame">
                            <p>
                              {" "}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                x="0"
                                y="0"
                                enableBackground="new 0 0 29.107 29.107"
                                version="1.1"
                                viewBox="0 0 29.107 29.107"
                                xmlSpace="preserve"
                                width="20"
                                height="20"
                                fill="#2A334F"
                              >
                                <path d="M14.554 0C6.561 0 0 6.562 0 14.552c0 7.996 6.561 14.555 14.554 14.555 7.996 0 14.553-6.559 14.553-14.555C29.106 6.562 22.55 0 14.554 0z"></path>
                              </svg>
                              <span>{t("swp_calculator.invested_amount")}</span>
                            </p>
                            <h3>
                              {formatNumberWithCommas(
                                swpCalculator.data.total_investment
                              )}
                            </h3>
                          </div>
                          <div className="calculator-details-frame">
                            <p>
                              {" "}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                x="0"
                                y="0"
                                enableBackground="new 0 0 29.107 29.107"
                                version="1.1"
                                viewBox="0 0 29.107 29.107"
                                xmlSpace="preserve"
                                width="20"
                                height="20"
                                fill="#2F5CFF"
                              >
                                <path d="M14.554 0C6.561 0 0 6.562 0 14.552c0 7.996 6.561 14.555 14.554 14.555 7.996 0 14.553-6.559 14.553-14.555C29.106 6.562 22.55 0 14.554 0z"></path>
                              </svg>
                              <span>
                                {t("swp_calculator.total_withdrawal")}
                              </span>
                            </p>
                            <h3>
                              {formatNumberWithCommas(
                                swpCalculator.data.total_withdrawl
                              )}
                            </h3>
                          </div>
                          <hr />
                          <div className="calculator-details-frame top-bottom-line">
                            <p className="d-inline">
                              {t("swp_calculator.final_value")}
                            </p>
                            <h3>
                              {formatNumberWithCommas(
                                swpCalculator.data.final_value
                              )}
                            </h3>
                          </div>
                          <hr />
                        </div>
                      </div>
                    )
                  )}
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <Container>
          <Row>
            <Col lg={12}>
              <div className="static-page-card">
                <div className="pricacy-policy-info">
                  <h4>Description</h4>
                  <p>
                    SWP, or Systematic Withdrawal Plan, is a strategy where you
                    invest a lump sum in a mutual fund and then periodically
                    withdraw a predetermined amount at set intervals.
                  </p>
                  <p>
                    Take, for instance, an investment of ₹1 lakh in the HDFC Top
                    200 Fund for a year. If you choose to withdraw ₹10,000
                    monthly, your investment in the fund decreases by this
                    amount each month. The remaining balance continues to be
                    invested and potentially earn returns.
                  </p>
                  <p>
                    You can use the SWP calculator provided to estimate your
                    withdrawals from lump sum investments.
                  </p>
                </div>
                <div className="pricacy-policy-info">
                  <h4>How does a SWP calculator help?</h4>
                  <p>
                    With a Systematic Withdrawal Plan, you commit a lump sum and
                    then regularly withdraw a portion of this investment each
                    month. Post-withdrawal, the deducted sum is removed from
                    your investment, which still accrues interest on the
                    remaining balance.
                  </p>
                  <p>
                    Calculating monthly withdrawals and the eventual total can
                    be complex. The SWP calculator from TaxTrax simplifies these
                    intricate calculations, allowing for precise computation of
                    your matured sum based on your monthly withdrawals. This
                    tool is revolutionizing mutual fund investment by making it
                    more accessible and straightforward.
                  </p>
                  <p className="mt-2">Using the SWP Calculator:</p>
                  <ul>
                    <li>
                      This calculator is designed for straightforward and
                      intuitive use, even for first-time users. It's crucial to
                      input all the values accurately.
                    </li>
                    <li>
                      There are three main fields to complete: tenure, expected
                      return, and the amount invested. Input these according to
                      your specific investment strategy.
                    </li>
                    <li>
                      Simply enter the required information and hit calculate to
                      generate an SWP returns chart similar to the one described
                      previously.
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <FAQIndex
          data={[
            {
              question: "Is the Withdrawal Amount Flexible or Fixed?",
              answer:
                "You have the liberty to select the withdrawal amount, making it adaptable to your financial needs. It's not confined to a fixed sum.",
            },
            {
              question: "When Should One Opt for an SWP?",
              answer:
                "SWPs are ideal for those seeking regular income from their investments, such as during retirement or for managing periodic expenses, making it a strategic choice for income planning.",
            },
            {
              question: "Can Non-Retirees Invest in SWPs?",
              answer:
                "Absolutely, SWPs are not exclusive to retirees. Anyone looking to generate a steady stream of income from their lump sum investments can consider investing in an SWP.",
            },
          ]}
        />
      </div>
    </>
  );
};

export default CalculatorIndex;
