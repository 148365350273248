import React from "react";
import { Bars } from 'react-loader-spinner'
 
const PageLoader = ({ main = false }) => {
  return (
    <div
      className="main-wrapper page-loader-svg-sec"
      style={{
        width: "100%",
        display: "flex",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Bars
        height="200"
        width="200"
        color="#2F5CFF"
        ariaLabel="bars-loading"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
        />
    </div>
  );
};

export default PageLoader;