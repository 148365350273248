import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Container,
  Button,
  Form,
  Accordion,
  Tab,
  Nav,
} from "react-bootstrap";
import CustomLazyLoad from "../../../Helper/CustomLazyLoad";
import PieChart from "../PieChart";
import {
  nscCalculatorStart,
  nscCalculatorSuccess,
} from "../../../../store/slices/CommonSlice";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-multi-lang";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { ButtonLoader } from "../../../Helper/Loader";
import Skeleton from "react-loading-skeleton";
import FAQIndex from "../FAQIndex";
import { Link } from "react-router-dom";
import formatNumberWithCommas from "../../../FormatNumber";

const CalculatorIndex = (props) => {
  const t = useTranslation("tax_calculator");
  const principleRef = useRef(null);
  const timePeriodRef = useRef(null);
  const interestPeriodRef = useRef(null);
  const formRef = useRef();
  const dispatch = useDispatch();
  const nscCalculator = useSelector((state) => state.common.nscCalculator);

  useEffect(() => {
    const onChange = (event, field) => {
      const customEvent = event;
      switch (field) {
        case "principal":
          formRef.current.setFieldValue("principal", customEvent.detail.value);
          break;
        case "timePeriod":
          formRef.current.setFieldValue(
            "time_period",
            customEvent.detail.value
          );
          break;
        case "interest":
          formRef.current.setFieldValue(
            "rate_of_interest",
            customEvent.detail.value
          );
          break;
      }
      setTimeout(() => {
        formRef.current.submitForm();
      }, 1);
    };
    principleRef.current?.addEventListener("change", (event) =>
      onChange(event, "principal")
    );
    timePeriodRef.current?.addEventListener("change", (event) =>
      onChange(event, "timePeriod")
    );
    interestPeriodRef.current?.addEventListener("change", (event) =>
      onChange(event, "interest")
    );
    return () => {
      principleRef.current?.removeEventListener("change", onChange);
      timePeriodRef.current?.removeEventListener("change", onChange);
      interestPeriodRef.current?.removeEventListener("change", onChange);
    };
  }, []);

  useEffect(() => {
    // dispatch(nscCalculatorStart(formRef.current.values));
    calculate(formRef.current.values);
  }, []);

  const onChangeInput = (event, field) => {
    const value =
      !isNaN(parseInt(event.target.value)) == true
        ? parseFloat(event.target.value)
        : "";
    switch (field) {
      case "principal":
        formRef.current.setFieldValue("principal", value);
        principleRef.current.value = value;
        break;
      case "timePeriod":
        formRef.current.setFieldValue("time_period", value);
        timePeriodRef.current.value = value;
        break;
      case "interest":
        formRef.current.setFieldValue("rate_of_interest", value);
        interestPeriodRef.current.value = value;
        break;
    }
    setTimeout(() => {
      formRef.current.submitForm();
    }, 1);
  };

  const handleSubmit = (values) => {
    // dispatch(nscCalculatorStart(values));
    formRef.current.isValid && calculate(values);
  };

  const ppfSchema = Yup.object().shape({
    principal: Yup.string()
      .test("min", t("nsc_calculator.invalid_min", { value: 500 }), (value) => {
        return value >= 500;
      })
      .test(
        "max",
        t("nsc_calculator.invalid_max", {
          value: 10000000,
          name: "Principle Amount",
        }),
        (value) => {
          return value <= 10000000;
        }
      )
      .required(t("nsc_calculator.required")),
    rate_of_interest: Yup.string()
      .test("min", t("nsc_calculator.invalid_min", { value: 1 }), (value) => {
        return value >= 1;
      })
      .test(
        "max",
        t("nsc_calculator.invalid_max", {
          value: 50,
          name: "Rate of interest",
        }),
        (value) => {
          return value <= 50;
        }
      )
      .required(t("nsc_calculator.required")),
    time_period: Yup.string()
      .test("min", t("nsc_calculator.invalid_min", { value: 1 }), (value) => {
        return value >= 1;
      })
      .test(
        "max",
        t("nsc_calculator.invalid_max", { value: 30, name: "Time period" }),
        (value) => {
          return value <= 30;
        }
      )
      .required(t("nsc_calculator.required")),
  });

  const calculate = (values) => {
    const { principal, rate_of_interest, time_period, compounding_frequency } =
      values;

    if (
      !principal ||
      !rate_of_interest ||
      !time_period ||
      !compounding_frequency
    ) {
      return;
    }

    const rate = rate_of_interest / 100;
    const maturity_amount =
      principal *
      Math.pow(
        1 + rate / compounding_frequency,
        time_period * compounding_frequency
      );
    const invested_amount = principal;
    const estimated_return = maturity_amount - invested_amount;

    const rounded_maturity_amount = Math.round(maturity_amount);
    const rounded_invested_amount = Math.round(invested_amount);
    const rounded_estimated_return = Math.round(estimated_return);

    const result = {
      maturity_amount: rounded_maturity_amount,
      invested_amount: rounded_invested_amount,
      estimated_return: rounded_estimated_return,
    };

    dispatch(nscCalculatorSuccess(result));
  };

  return (
    <>
      <div className="single-tax-calculator">
        <div className="sm-padding single-tax-calculator-sec">
          <Container>
            <div className="single-tax-calculator-box">
              <div className="single-tax-calculator-header">
                <Row>
                  <Col md={6}>
                    <div className="d-flex other-calculator-btn-sec">
                      <Link
                        to="/tax-calculator"
                        className="other-calculator-btn"
                      >
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            enableBackground="new 0 0 512 512"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="#fff"
                              d="M10.957 12.354a.5.5 0 010-.708l4.586-4.585a1.5 1.5 0 00-2.121-2.122L8.836 9.525a3.505 3.505 0 000 4.95l4.586 4.586a1.5 1.5 0 002.121-2.122z"
                              data-original="#000000"
                            ></path>
                          </svg>
                        </span>
                        <span>Other Calculators</span>
                      </Link>
                    </div>
                    <div className="single-tax-calculator-info-sec">
                      <h1>{t("nsc_calculator.heading")}</h1>
                      <p>{t("nsc_calculator.content")}</p>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="single-tax-calculator-header-img-sec">
                      <CustomLazyLoad
                        className="single-tax-calculator-header-img"
                        src={
                          window.location.origin +
                          "/img/single-tax-calculator-header-img.png"
                        }
                        type="image/svg"
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="single-tax-calculator-form-box  line-stright">
              <Row className="align-items-center g-5">
                <Col md={6} lg={6}>
                  <Formik
                    initialValues={{
                      principal: 1000,
                      rate_of_interest: 15,
                      time_period: 2,
                      compounding_frequency: 1,
                    }}
                    innerRef={formRef}
                    validationSchema={ppfSchema}
                    onSubmit={handleSubmit}
                  >
                    {({ setFieldValue, values, resetForm, submitForm }) => (
                      <FORM className="single-tax-calculator-form">
                        <div className="single-tax-calculator-form-card">
                          <div className="single-tax-calculator-form-header-sec">
                            <h4>{t("nsc_calculator.principal.text")}</h4>
                            <Form.Group
                              className="mb-0"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Field
                                className="form-control"
                                name="principal"
                                placeholder={t(
                                  "nsc_calculator.principal.placeholder"
                                )}
                                type="number"
                                onKeyDown={(e) => {
                                  if (
                                    !/[0-9]|Backspace|ArrowLeft|ArrowRight|Delete|ArrowUp|ArrowDown|Tab/.test(
                                      e.key
                                    ) &&
                                    !(
                                      e.ctrlKey &&
                                      (e.key === "v" ||
                                        e.key === "c" ||
                                        e.key === "x" ||
                                        e.key === "a")
                                    )
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                                onInput={(e) => {
                                  if (e.target.value < 0) {
                                    e.target.value = 0;
                                  }
                                  onChangeInput(e, "principal");
                                  submitForm();
                                }}
                              />
                            </Form.Group>
                          </div>
                          <ErrorMessage
                            component={"div"}
                            name="principal"
                            className="errorMsg"
                          />
                          <div className="tax-calculator-input-slider-sec">
                            <tc-range-slider
                              ref={principleRef}
                              value={principleRef}
                              mousewheel-disabled="true"
                              min={1000}
                              max={10000000}
                              slider-width="100%"
                              slider-bg-fill="#2F5CFF"
                              generate-labels="true"
                              round="0"
                            ></tc-range-slider>
                          </div>
                        </div>
                        <div className="single-tax-calculator-form-card">
                          <div className="single-tax-calculator-form-header-sec">
                            <h4>{t("nsc_calculator.time_period.text")}</h4>
                            <Form.Group
                              className="mb-0"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Field
                                className="form-control"
                                name="time_period"
                                placeholder={t(
                                  "nsc_calculator.time_period.placeholder"
                                )}
                                type="number"
                                onKeyDown={(e) => {
                                  if (
                                    !/[0-9]|Backspace|ArrowLeft|ArrowRight|Delete|ArrowUp|ArrowDown|Tab/.test(
                                      e.key
                                    ) &&
                                    !(
                                      e.ctrlKey &&
                                      (e.key === "v" ||
                                        e.key === "c" ||
                                        e.key === "x" ||
                                        e.key === "a")
                                    )
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                                onInput={(e) => {
                                  if (e.target.value < 0) {
                                    e.target.value = 0;
                                  }
                                  onChangeInput(e, "timePeriod");
                                  submitForm();
                                }}
                              />
                            </Form.Group>
                          </div>
                          <ErrorMessage
                            component={"div"}
                            name="time_period"
                            className="errorMsg"
                          />
                          <div className="tax-calculator-input-slider-sec">
                            <tc-range-slider
                              ref={timePeriodRef}
                              value={timePeriodRef}
                              mousewheel-disabled="true"
                              min={1}
                              max={30}
                              slider-width="100%"
                              slider-bg-fill="#2F5CFF"
                              generate-labels="true"
                              round="0"
                            ></tc-range-slider>
                          </div>
                        </div>
                        <div className="single-tax-calculator-form-card">
                          <div className="single-tax-calculator-form-header-sec">
                            <h4>{t("nsc_calculator.rate_of_interest.text")}</h4>
                            <Form.Group
                              className="mb-0"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Field
                                className="form-control"
                                name="rate_of_interest"
                                placeholder={t(
                                  "nsc_calculator.rate_of_interest.placeholder"
                                )}
                                type="number"
                                onKeyDown={(e) => {
                                  if (
                                    !/[0-9.]|Backspace|ArrowLeft|ArrowRight|Delete|ArrowUp|ArrowDown|Tab/.test(
                                      e.key
                                    ) ||
                                    (e.key === "-" &&
                                      e.target.selectionStart !== 0)
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                                onInput={(e) => {
                                  if (e.target.value < 0) {
                                    e.target.value = 0;
                                  }
                                  onChangeInput(e, "interest");
                                  submitForm();
                                }}
                              />
                            </Form.Group>
                          </div>
                          <ErrorMessage
                            component={"div"}
                            name="rate_of_interest"
                            className="errorMsg"
                          />
                          <div className="tax-calculator-input-slider-sec">
                            <tc-range-slider
                              ref={interestPeriodRef}
                              value={interestPeriodRef}
                              mousewheel-disabled="true"
                              min={1}
                              max={50}
                              slider-width="100%"
                              slider-bg-fill="#2F5CFF"
                              generate-labels="true"
                              round="1"
                            ></tc-range-slider>
                          </div>
                        </div>
                        <div className="single-tax-calculator-form-card">
                          <div className="single-tax-calculator-form-header-sec">
                            <h4>{t("nsc_calculator.compound_frequency")}</h4>
                          </div>
                          <div className="yes-or-no-radio-btn-sec mt-4">
                            <div class="pretty p-default p-round p-smooth">
                              <input
                                type="radio"
                                value={1}
                                onClick={(e) => {
                                  formRef.current.setFieldValue(
                                    "compounding_frequency",
                                    parseInt(e.target.value)
                                  );
                                  submitForm();
                                }}
                                checked={
                                  formRef.current?.values
                                    .compounding_frequency == 1
                                }
                              />
                              <div class="state p-primary-o">
                                <label>{t("nsc_calculator.yearly")}</label>
                              </div>
                            </div>
                            <div class="pretty p-default p-round p-smooth">
                              <input
                                type="radio"
                                value={2}
                                onClick={(e) => {
                                  formRef.current.setFieldValue(
                                    "compounding_frequency",
                                    parseInt(e.target.value)
                                  );
                                  submitForm();
                                }}
                                checked={
                                  formRef.current?.values
                                    .compounding_frequency == 2
                                }
                              />
                              <div class="state p-primary-o">
                                <label>{t("nsc_calculator.half_yearly")}</label>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <div className="d-flex justify-content-end mt-4">
                          <Button
                            type="submit"
                            className="default-primary-btn"
                            disabled={nscCalculator.buttonDisable}
                          >
                            {nscCalculator.buttonDisable ? (
                              <ButtonLoader />
                            ) : (
                              t("nsc_calculator.calculate")
                            )}
                          </Button>
                        </div> */}
                      </FORM>
                    )}
                  </Formik>
                </Col>
                <Col md={6} lg={6}>
                  {nscCalculator.loading ? (
                    <div className="single-calculator-info-frame">
                      <div className="pie-chart-skelton">
                        <div className="skeleton-wrapper">
                          <Skeleton
                            borderRadius={130}
                            width={230}
                            height={230}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                        </div>
                        <div className="mt-4">
                          <Skeleton
                            width={250}
                            height={15}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                        </div>
                      </div>

                      <div className="calculator-info-details-card">
                        <div className="calculator-details-frame">
                          <Skeleton
                            width={200}
                            height={20}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                          <Skeleton
                            width={130}
                            height={20}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                        </div>
                        <div className="calculator-details-frame">
                          <Skeleton
                            width={200}
                            height={20}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                          <Skeleton
                            width={130}
                            height={20}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                        </div>
                        <hr />
                        <div className="calculator-details-frame top-bottom-line">
                          <Skeleton
                            width={200}
                            height={20}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                          <Skeleton
                            width={130}
                            height={20}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                        </div>
                        <hr />
                      </div>
                    </div>
                  ) : (
                    Object.keys(nscCalculator.data).length > 0 && (
                      <div className="single-calculator-info-frame">
                        <div className="pie-chart-sec">
                          <PieChart
                            name={[
                              t("nsc_calculator.principal_amount"),
                              t("nsc_calculator.total_interest"),
                            ]}
                            data={[
                              nscCalculator.data.invested_amount,
                              nscCalculator.data.estimated_return,
                            ]}
                          />
                        </div>
                        <div className="calculator-info-details-card">
                          <div className="calculator-details-frame">
                            <p>
                              {" "}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                x="0"
                                y="0"
                                enableBackground="new 0 0 29.107 29.107"
                                version="1.1"
                                viewBox="0 0 29.107 29.107"
                                xmlSpace="preserve"
                                width="20"
                                height="20"
                                fill="#2A334F"
                              >
                                <path d="M14.554 0C6.561 0 0 6.562 0 14.552c0 7.996 6.561 14.555 14.554 14.555 7.996 0 14.553-6.559 14.553-14.555C29.106 6.562 22.55 0 14.554 0z"></path>
                              </svg>
                              <span>
                                {t("nsc_calculator.principal_amount")}
                              </span>
                            </p>
                            <h3>
                              {formatNumberWithCommas(
                                nscCalculator.data.invested_amount
                              )}
                            </h3>
                          </div>
                          <div className="calculator-details-frame">
                            <p>
                              {" "}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                x="0"
                                y="0"
                                enableBackground="new 0 0 29.107 29.107"
                                version="1.1"
                                viewBox="0 0 29.107 29.107"
                                xmlSpace="preserve"
                                width="20"
                                height="20"
                                fill="#2F5CFF"
                              >
                                <path d="M14.554 0C6.561 0 0 6.562 0 14.552c0 7.996 6.561 14.555 14.554 14.555 7.996 0 14.553-6.559 14.553-14.555C29.106 6.562 22.55 0 14.554 0z"></path>
                              </svg>
                              <span>{t("nsc_calculator.total_interest")}</span>
                            </p>
                            <h3>
                              {formatNumberWithCommas(
                                nscCalculator.data.estimated_return
                              )}
                            </h3>
                          </div>
                          <hr />
                          <div className="calculator-details-frame top-bottom-line">
                            <p className="d-inline">
                              {t("nsc_calculator.total_value")}
                            </p>
                            <h3>
                              {formatNumberWithCommas(
                                nscCalculator.data.maturity_amount
                              )}
                            </h3>
                          </div>
                          <hr />
                        </div>
                      </div>
                    )
                  )}
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <Container>
          <Row>
            <Col lg={12}>
              <div className="static-page-card">
                <div className="pricacy-policy-info">
                  <h4>Description</h4>
                  <p>
                    The National Savings Certificate (NSC) is a fixed-income
                    investment scheme that is widely favored in India. Available
                    for setup at any Post Office, this government-backed program
                    guarantees returns, making it a secure choice for investors,
                    particularly those with low to moderate incomes who are
                    looking to save. Additionally, NSC investments qualify for a
                    tax deduction under Section 80C of the Income Tax Act, with
                    an exemption limit up to Rs 1.5 lakhs.
                  </p>
                  <p>
                    NSCs have a five-year maturity period and offer a fixed
                    interest rate, which currently stands at 7.7%. As a low-risk
                    investment, NSCs are comparable to other safe vehicles like
                    the Public Provident Fund (PPF) and Post Office Fixed
                    Deposits (FDs). The minimum investment required is Rs 100,
                    and there is no upper limit on the amount one can invest in
                    NSCs. Importantly, there is no Tax Deducted at Source (TDS)
                    on NSC investments.
                  </p>
                </div>
                <div className="pricacy-policy-info">
                  <h4>What is an NSC Calculator, and How Can It Assist You?</h4>
                  <p>
                    The NSC (National Savings Certificate) Calculator is a tool
                    designed to simplify the calculation of returns from an NSC
                    investment, where interest is compounded annually and not
                    paid out until the end of the five-year term. Each year, the
                    interest earned is reinvested into the principal. By
                    inputting your investment amount into the NSC Calculator
                    2024, the tool automatically uses the fixed term of 5 years
                    and the current interest rates to compute the total earnings
                    at maturity.
                  </p>
                  <ul>
                    <li>
                      This calculator provides a quick way to determine the
                      amount you will receive upon the investment's maturity.
                    </li>
                    <li>
                      It allows you to promptly assess whether the NSC fits your
                      financial objectives, helping you make informed decisions
                      efficiently.
                    </li>
                  </ul>
                </div>
                <div className="pricacy-policy-info">
                  <h4>How Does the NSC Calculator Function?</h4>
                  <p>
                    The NSC interest rate calculator is an online tool that
                    quickly determines the total interest and principal amount
                    that an investor will receive at maturity, once the
                    investment period is complete. Using this calculator, you
                    can input the investment amount and the interest rate, and
                    it will calculate the interest earned based on the
                    parameters set by the Government of India's scheme.
                  </p>
                </div>
                <div className="pricacy-policy-info">
                  <h4>Benefits of Using an NSC Calculator</h4>
                  <p>
                    Calculating the interest and the maturity amount for a
                    National Savings Certificate (NSC) investment can be
                    daunting due to the annual compounding of interest and its
                    reinvestment. At the end of the five-year term, investors
                    receive both the accumulated interest and the principal
                    amount. Understanding the growth of your NSC investment can
                    be crucial, and here are some advantages of using an NSC
                    calculator:
                  </p>
                  <ul>
                    <li>
                      User-Friendly: The calculator is straightforward and easy
                      to use.
                    </li>
                    <li>
                      Time-Saving: It eliminates the need for manual
                      calculations, saving you considerable time.
                    </li>
                    <li>
                      Accurate: The calculations are precise, eliminating the
                      need to double-check the results.
                    </li>
                    <li>
                      Cost-Effective: It is available at no cost and can be
                      accessed from anywhere, providing convenience and
                      accessibility.
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <FAQIndex
          data={[
            {
              question: "What is the current interest rate for NSC?",
              answer:
                "The interest rate for National Savings Certificates (NSC) is periodically revised by the government. As of the latest update, it stands at 7.7%, but it's advisable to check the current rate from official or reliable financial sources as it may change.",
            },
            {
              question: "How do I access my funds after NSC maturity?",
              answer:
                "Upon maturity of your NSC, you can claim your funds by submitting the necessary documents at the post office where the NSC was issued. You'll receive the principal along with the accrued interest.",
            },
            {
              question: "Can I withdraw from my NSC before it matures",
              answer:
                "NSC does not typically allow withdrawals before maturity. It is designed as a fixed-term investment to encourage long-term savings",
            },
            {
              question: "Is it possible to break an NSC?",
              answer:
                "Breaking an NSC before its maturity is generally not allowed except under specific circumstances such as the holder’s death.",
            },
            {
              question: "Is the interest rate on NSC constant?",
              answer:
                "Yes, once you purchase an NSC, the interest rate at the time of purchase remains fixed throughout the term of the certificate.",
            },
            {
              question:
                "Are there provisions for premature withdrawals from an NSC",
              answer:
                "Premature withdrawals from NSC are generally not permitted, except under exceptional conditions like the account holder’s demise.",
            },
          ]}
        />
      </div>
    </>
  );
};

export default CalculatorIndex;
