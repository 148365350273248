import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import CustomLazyLoad from "../Helper/CustomLazyLoad";
import { Link } from "react-router-dom";

const BotExperienceIndex = (props) => {
  return (
    <>
      <div className="bot-experience-sec">
        <Container>
          <Row className="justify-content-center">
            <Col
              md={6}
              xl={4}
              lg={6}
              sm={12}
              className="resp-mrg-btm-md resp-margin-btm-xs"
            >
              <div className="bot-experience-card bot-1">
                <div className="bot-experience-icon-sec">
                  <CustomLazyLoad
                    className="bot-experience-icon"
                    src={
                      window.location.origin +
                      "/img/bot-experience/active-user.png"
                    }
                    type="image/svg"
                  />
                </div>
                <div className="bot-experience-info-sec">
                  <h3>9,829+</h3>
                  <p>Active Users</p>
                </div>
              </div>
            </Col>
            <Col
              md={6}
              xl={4}
              lg={6}
              sm={12}
              className="resp-mrg-btm-md resp-margin-btm-xs"
            >
              <div className="bot-experience-card bot-2">
                <div className="bot-experience-icon-sec">
                  <CustomLazyLoad
                    className="bot-experience-icon"
                    src={
                      window.location.origin +
                      "/img/bot-experience/questions-answered.png"
                    }
                    type="image/svg"
                  />
                </div>
                <div className="bot-experience-info-sec">
                  <h3>2,300+</h3>
                  <p>Questions Answered</p>
                </div>
              </div>
            </Col>
            <Col md={6} xl={4} lg={6} sm={12} className="resp-mrg-btm-md">
              <div className="bot-experience-card bot-3">
                <div className="bot-experience-icon-sec">
                  <CustomLazyLoad
                    className="bot-experience-icon"
                    src={
                      window.location.origin +
                      "/img/bot-experience/users-rating.png"
                    }
                    type="image/svg"
                  />
                </div>
                <div className="bot-experience-info-sec">
                  <h3>4.5 / 5</h3>
                  <p>User ratings</p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default BotExperienceIndex;
