import { createSlice } from "@reduxjs/toolkit";
import {
  getCommonInitialState,
  getCommonStart,
  getCommonSuccess,
  getCommonFailure,
} from "../utils";

const initialState = {
  chatMessages: getCommonInitialState(),
  chatDetails: [],
  taxCalculator: getCommonInitialState(),
  sipCalculator: getCommonInitialState(),
  lumpSumCalculator: getCommonInitialState(),
  mfCalculator: getCommonInitialState(),
  fdCalculator: getCommonInitialState(),
  rdCalculator: getCommonInitialState(),
  npsCalculator: getCommonInitialState(),
  hraCalculator: getCommonInitialState(),
  retirementPlanCalculator: getCommonInitialState(),
  ppfCalculator: getCommonInitialState(),
  swpCalculator: getCommonInitialState(),
  ssyCalculator: getCommonInitialState(),
  epfCalculator: getCommonInitialState(),
  emiCalculator: getCommonInitialState(),
  siCalculator: getCommonInitialState(),
  ciCalculator: getCommonInitialState(),
  nscCalculator: getCommonInitialState(),
  stepUpSipCalculator: getCommonInitialState(),
  gratuityCalculator: getCommonInitialState(),
  apyCalculator: getCommonInitialState(),
  cagrCalculator: getCommonInitialState(),
  gstCalculator: getCommonInitialState(),
  flatVsReducingRateCalculator: getCommonInitialState(),
  inflationCalculator: getCommonInitialState(),
  winningAmountTaxCalculator: getCommonInitialState(),
  bitCoinCalculator: getCommonInitialState(),
  takeHomeSalaryCalculator: getCommonInitialState(),
  tdsCalculator: getCommonInitialState(),
  brokerageCalculator: getCommonInitialState(),
};

const CommonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    chatMessagesStart: (state) => {
      state.chatMessages = getCommonStart();
    },
    chatMessagesSuccess: (state, action) => {
      state.chatMessages = getCommonSuccess(action.payload);
    },
    chatMessagesFailure: (state, action) => {
      state.chatMessages = getCommonFailure(action.payload);
    },

    chatDetailsStart: (state, action) => {
      state.chatDetails = [...state.chatDetails, action.payload];
    },
    chatDetailsEmptyStart: (state) => {
      state.chatDetails = [];
    },

    taxCalculatorStart: (state) => {
      state.taxCalculator = getCommonStart();
    },
    taxCalculatorSuccess: (state, action) => {
      state.taxCalculator = getCommonSuccess(action.payload);
    },
    taxCalculatorFailure: (state, action) => {
      state.taxCalculator = getCommonFailure(action.payload);
    },

    sipCalculatorStart: (state) => {
      state.sipCalculator = getCommonStart();
    },
    sipCalculatorSuccess: (state, action) => {
      state.sipCalculator = getCommonSuccess(action.payload);
    },
    sipCalculatorFailure: (state, action) => {
      state.sipCalculator = getCommonFailure(action.payload);
    },

    lumpSumCalculatorStart: (state) => {
      state.lumpSumCalculator = getCommonStart();
    },
    lumpSumCalculatorSuccess: (state, action) => {
      state.lumpSumCalculator = getCommonSuccess(action.payload);
    },
    lumpSumCalculatorFailure: (state, action) => {
      state.lumpSumCalculator = getCommonFailure(action.payload);
    },

    mfCalculatorStart: (state) => {
      state.mfCalculator = getCommonStart();
    },
    mfCalculatorSuccess: (state, action) => {
      state.mfCalculator = getCommonSuccess(action.payload);
    },
    mfCalculatorFailure: (state, action) => {
      state.mfCalculator = getCommonFailure(action.payload);
    },

    fdCalculatorStart: (state) => {
      state.fdCalculator = getCommonStart();
    },
    fdCalculatorSuccess: (state, action) => {
      state.fdCalculator = getCommonSuccess(action.payload);
    },
    fdCalculatorFailure: (state, action) => {
      state.fdCalculator = getCommonFailure(action.payload);
    },

    rdCalculatorStart: (state) => {
      state.rdCalculator = getCommonStart();
    },
    rdCalculatorSuccess: (state, action) => {
      state.rdCalculator = getCommonSuccess(action.payload);
    },
    rdCalculatorFailure: (state, action) => {
      state.rdCalculator = getCommonFailure(action.payload);
    },

    npsCalculatorStart: (state) => {
      state.npsCalculator = getCommonStart();
    },
    npsCalculatorSuccess: (state, action) => {
      state.npsCalculator = getCommonSuccess(action.payload);
    },
    npsCalculatorFailure: (state, action) => {
      state.npsCalculator = getCommonFailure(action.payload);
    },

    hraCalculatorStart: (state) => {
      state.hraCalculator = getCommonStart();
    },
    hraCalculatorSuccess: (state, action) => {
      state.hraCalculator = getCommonSuccess(action.payload);
    },
    hraCalculatorFailure: (state, action) => {
      state.hraCalculator = getCommonFailure(action.payload);
    },

    retirementPlanCalculatorStart: (state) => {
      state.retirementPlanCalculator = getCommonStart();
    },
    retirementPlanCalculatorSuccess: (state, action) => {
      state.retirementPlanCalculator = getCommonSuccess(action.payload);
    },
    retirementPlanCalculatorFailure: (state, action) => {
      state.retirementPlanCalculator = getCommonFailure(action.payload);
    },

    ppfCalculatorStart: (state) => {
      state.ppfCalculator = getCommonStart();
    },
    ppfCalculatorSuccess: (state, action) => {
      state.ppfCalculator = getCommonSuccess(action.payload);
    },
    ppfCalculatorFailure: (state, action) => {
      state.ppfCalculator = getCommonFailure(action.payload);
    },

    swpCalculatorStart: (state) => {
      state.swpCalculator = getCommonStart();
    },
    swpCalculatorSuccess: (state, action) => {
      state.swpCalculator = getCommonSuccess(action.payload);
    },
    swpCalculatorFailure: (state, action) => {
      state.swpCalculator = getCommonFailure(action.payload);
    },

    ssyCalculatorStart: (state) => {
      state.ssyCalculator = getCommonStart();
    },
    ssyCalculatorSuccess: (state, action) => {
      state.ssyCalculator = getCommonSuccess(action.payload);
    },
    ssyCalculatorFailure: (state, action) => {
      state.ssyCalculator = getCommonFailure(action.payload);
    },

    epfCalculatorStart: (state) => {
      state.epfCalculator = getCommonStart();
    },
    epfCalculatorSuccess: (state, action) => {
      state.epfCalculator = getCommonSuccess(action.payload);
    },
    epfCalculatorFailure: (state, action) => {
      state.epfCalculator = getCommonFailure(action.payload);
    },

    emiCalculatorStart: (state) => {
      state.emiCalculator = getCommonStart();
    },
    emiCalculatorSuccess: (state, action) => {
      state.emiCalculator = getCommonSuccess(action.payload);
    },
    emiCalculatorFailure: (state, action) => {
      state.emiCalculator = getCommonFailure(action.payload);
    },

    siCalculatorStart: (state) => {
      state.siCalculator = getCommonStart();
    },
    siCalculatorSuccess: (state, action) => {
      state.siCalculator = getCommonSuccess(action.payload);
    },
    siCalculatorFailure: (state, action) => {
      state.siCalculator = getCommonFailure(action.payload);
    },

    ciCalculatorStart: (state) => {
      state.ciCalculator = getCommonStart();
    },
    ciCalculatorSuccess: (state, action) => {
      state.ciCalculator = getCommonSuccess(action.payload);
    },
    ciCalculatorFailure: (state, action) => {
      state.ciCalculator = getCommonFailure(action.payload);
    },

    nscCalculatorStart: (state) => {
      state.nscCalculator = getCommonStart();
    },
    nscCalculatorSuccess: (state, action) => {
      state.nscCalculator = getCommonSuccess(action.payload);
    },
    nscCalculatorFailure: (state, action) => {
      state.nscCalculator = getCommonFailure(action.payload);
    },

    stepUpSipCalculatorStart: (state) => {
      state.stepUpSipCalculator = getCommonStart();
    },
    stepUpSipCalculatorSuccess: (state, action) => {
      state.stepUpSipCalculator = getCommonSuccess(action.payload);
    },
    stepUpSipCalculatorFailure: (state, action) => {
      state.stepUpSipCalculator = getCommonFailure(action.payload);
    },

    gratuityCalculatorStart: (state) => {
      state.gratuityCalculator = getCommonStart();
    },
    gratuityCalculatorSuccess: (state, action) => {
      state.gratuityCalculator = getCommonSuccess(action.payload);
    },
    gratuityCalculatorFailure: (state, action) => {
      state.gratuityCalculator = getCommonFailure(action.payload);
    },

    apyCalculatorStart: (state) => {
      state.apyCalculator = getCommonStart();
    },
    apyCalculatorSuccess: (state, action) => {
      state.apyCalculator = getCommonSuccess(action.payload);
    },
    apyCalculatorFailure: (state, action) => {
      state.apyCalculator = getCommonFailure(action.payload);
    },

    cagrCalculatorStart: (state) => {
      state.cagrCalculator = getCommonStart();
    },
    cagrCalculatorSuccess: (state, action) => {
      state.cagrCalculator = getCommonSuccess(action.payload);
    },
    cagrCalculatorFailure: (state, action) => {
      state.cagrCalculator = getCommonFailure(action.payload);
    },

    gstCalculatorStart: (state) => {
      state.gstCalculator = getCommonStart();
    },
    gstCalculatorSuccess: (state, action) => {
      state.gstCalculator = getCommonSuccess(action.payload);
    },
    gstCalculatorFailure: (state, action) => {
      state.gstCalculator = getCommonFailure(action.payload);
    },

    flatVsReducingRateCalculatorStart: (state) => {
      state.flatVsReducingRateCalculator = getCommonStart();
    },
    flatVsReducingRateCalculatorSuccess: (state, action) => {
      state.flatVsReducingRateCalculator = getCommonSuccess(action.payload);
    },
    flatVsReducingRateCalculatorFailure: (state, action) => {
      state.flatVsReducingRateCalculator = getCommonFailure(action.payload);
    },

    inflationCalculatorStart: (state) => {
      state.inflationCalculator = getCommonStart();
    },
    inflationCalculatorSuccess: (state, action) => {
      state.inflationCalculator = getCommonSuccess(action.payload);
    },
    inflationCalculatorFailure: (state, action) => {
      state.inflationCalculator = getCommonFailure(action.payload);
    },

    winningAmountTaxCalculatorStart: (state) => {
      state.winningAmountTaxCalculator = getCommonStart();
    },
    winningAmountTaxCalculatorSuccess: (state, action) => {
      state.winningAmountTaxCalculator = getCommonSuccess(action.payload);
    },
    winningAmountTaxCalculatorFailure: (state, action) => {
      state.winningAmountTaxCalculator = getCommonFailure(action.payload);
    },

    bitCoinCalculatorStart: (state) => {
      state.bitCoinCalculator = getCommonStart();
    },
    bitCoinCalculatorSuccess: (state, action) => {
      state.bitCoinCalculator = getCommonSuccess(action.payload);
    },
    bitCoinCalculatorFailure: (state, action) => {
      state.bitCoinCalculator = getCommonFailure(action.payload);
    },

    takeHomeSalaryCalculatorStart: (state) => {
      state.takeHomeSalaryCalculator = getCommonStart();
    },
    takeHomeSalaryCalculatorSuccess: (state, action) => {
      state.takeHomeSalaryCalculator = getCommonSuccess(action.payload);
    },
    takeHomeSalaryCalculatorFailure: (state, action) => {
      state.takeHomeSalaryCalculator = getCommonFailure(action.payload);
    },

    tdsCalculatorStart: (state) => {
      state.tdsCalculator = getCommonStart();
    },
    tdsCalculatorSuccess: (state, action) => {
      state.tdsCalculator = getCommonSuccess(action.payload);
    },
    tdsCalculatorFailure: (state, action) => {
      state.tdsCalculator = getCommonFailure(action.payload);
    },

    brokerageCalculatorStart: (state) => {
      state.brokerageCalculator = getCommonStart();
    },
    brokerageCalculatorSuccess: (state, action) => {
      state.brokerageCalculator = getCommonSuccess(action.payload);
    },
    brokerageCalculatorFailure: (state, action) => {
      state.brokerageCalculator = getCommonFailure(action.payload);
    },
  },
});

export const {
  chatMessagesStart,
  chatMessagesSuccess,
  chatMessagesFailure,
  chatDetailsStart,
  chatDetailsEmptyStart,
  taxCalculatorStart,
  taxCalculatorSuccess,
  taxCalculatorFailure,
  sipCalculatorStart,
  sipCalculatorSuccess,
  sipCalculatorFailure,
  lumpSumCalculatorStart,
  lumpSumCalculatorSuccess,
  lumpSumCalculatorFailure,
  mfCalculatorStart,
  mfCalculatorSuccess,
  mfCalculatorFailure,
  fdCalculatorStart,
  fdCalculatorSuccess,
  fdCalculatorFailure,
  rdCalculatorStart,
  rdCalculatorSuccess,
  rdCalculatorFailure,
  npsCalculatorStart,
  npsCalculatorSuccess,
  npsCalculatorFailure,
  hraCalculatorStart,
  hraCalculatorSuccess,
  hraCalculatorFailure,
  retirementPlanCalculatorStart,
  retirementPlanCalculatorSuccess,
  retirementPlanCalculatorFailure,
  ppfCalculatorStart,
  ppfCalculatorSuccess,
  ppfCalculatorFailure,
  swpCalculatorStart,
  swpCalculatorSuccess,
  swpCalculatorFailure,
  ssyCalculatorStart,
  ssyCalculatorSuccess,
  ssyCalculatorFailure,
  epfCalculatorStart,
  epfCalculatorSuccess,
  epfCalculatorFailure,
  emiCalculatorStart,
  emiCalculatorSuccess,
  emiCalculatorFailure,
  siCalculatorStart,
  siCalculatorSuccess,
  siCalculatorFailure,
  ciCalculatorStart,
  ciCalculatorSuccess,
  ciCalculatorFailure,
  nscCalculatorStart,
  nscCalculatorSuccess,
  nscCalculatorFailure,
  stepUpSipCalculatorStart,
  stepUpSipCalculatorSuccess,
  stepUpSipCalculatorFailure,
  gratuityCalculatorStart,
  gratuityCalculatorSuccess,
  gratuityCalculatorFailure,
  apyCalculatorStart,
  apyCalculatorSuccess,
  apyCalculatorFailure,
  cagrCalculatorStart,
  cagrCalculatorSuccess,
  cagrCalculatorFailure,
  gstCalculatorStart,
  gstCalculatorSuccess,
  gstCalculatorFailure,
  flatVsReducingRateCalculatorStart,
  flatVsReducingRateCalculatorSuccess,
  flatVsReducingRateCalculatorFailure,
  inflationCalculatorStart,
  inflationCalculatorSuccess,
  inflationCalculatorFailure,
  winningAmountTaxCalculatorStart,
  winningAmountTaxCalculatorSuccess,
  winningAmountTaxCalculatorFailure,
  bitCoinCalculatorStart,
  bitCoinCalculatorSuccess,
  bitCoinCalculatorFailure,
  takeHomeSalaryCalculatorStart,
  takeHomeSalaryCalculatorSuccess,
  takeHomeSalaryCalculatorFailure,
  tdsCalculatorStart,
  tdsCalculatorSuccess,
  tdsCalculatorFailure,
  brokerageCalculatorStart,
  brokerageCalculatorSuccess,
  brokerageCalculatorFailure,
} = CommonSlice.actions;

export default CommonSlice.reducer;
