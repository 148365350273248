import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Container, Button, Form, Accordion } from "react-bootstrap";
import CustomLazyLoad from "../../../Helper/CustomLazyLoad";
import Skeleton from "react-loading-skeleton";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-multi-lang";
import { useDispatch, useSelector } from "react-redux";
import {
  rdCalculatorStart,
  rdCalculatorSuccess,
} from "../../../../store/slices/CommonSlice";
import { ButtonLoader } from "../../../Helper/Loader";
import PieChart from "../PieChart";
import FAQIndex from "../FAQIndex";
import { Link } from "react-router-dom";
import formatNumberWithCommas from "../../../FormatNumber";

const RDCalculatorIndex = (props) => {
  const t = useTranslation("tax_calculator");
  const principleRef = useRef(null);
  const interestRef = useRef(null);
  const timePeriodRef = useRef(null);
  const dispatch = useDispatch();
  const formRef = useRef();
  const rdCalculator = useSelector((state) => state.common.rdCalculator);
  const [period, setPeriod] = useState("yearly");

  useEffect(() => {
    const onChange = (event, field) => {
      const customEvent = event;
      switch (field) {
        case "investment":
          formRef.current.setFieldValue(
            "monthly_investment",
            customEvent.detail.value
          );
          formRef.current.submitForm();
          break;
        case "interest":
          formRef.current.setFieldValue(
            "rate_of_interest",
            customEvent.detail.value
          );
          formRef.current.submitForm();
          break;
        case "timePeriod":
          formRef.current.setFieldValue(
            "time_period",
            customEvent.detail.value
          );
          formRef.current.submitForm();
          break;
      }
      setTimeout(() => {
        formRef.current.submitForm();
      }, 1);
    };
    principleRef.current?.addEventListener("change", (event) =>
      onChange(event, "investment")
    );
    interestRef.current?.addEventListener("change", (event) =>
      onChange(event, "interest")
    );
    timePeriodRef.current?.addEventListener("change", (event) =>
      onChange(event, "timePeriod")
    );
    return () => {
      principleRef.current?.removeEventListener("change", onChange);
      interestRef.current?.removeEventListener("change", onChange);
      timePeriodRef.current?.removeEventListener("change", onChange);
    };
  }, []);

  useEffect(() => {
    // dispatch(rdCalculatorStart(formRef.current.values));
    calculate(formRef.current.values);
  }, []);

  const onChangeInput = (event, field) => {
    const value = !isNaN(parseFloat(event.target.value))
      ? parseFloat(event.target.value)
      : "";
    switch (field) {
      case "investment":
        formRef.current.setFieldValue("monthly_investment", value);
        principleRef.current.value = value;
        break;
      case "interest":
        formRef.current.setFieldValue("rate_of_interest", value);
        interestRef.current.value = value;
        break;
      case "timePeriod":
        formRef.current.setFieldValue("time_period", value);
        timePeriodRef.current.value = value;
        break;
    }
    setTimeout(() => {
      formRef.current.submitForm();
    }, 1);
  };

  const handleSubmit = (values) => {
    let newValues = values;
    if (period == "monthly") {
      const data = parseFloat(
        (formRef.current.values.time_period / 12).toFixed(2)
      );
      newValues = { ...newValues, time_period: data };
    }
    formRef.current.isValid && calculate(newValues);
  };

  const sipSchema = Yup.object().shape({
    monthly_investment: Yup.string()
      .test("min", t("rd_calculator.invalid_min", { value: 1000 }), (value) => {
        return value >= 1000;
      })
      .test(
        "max",
        t("rd_calculator.invalid_max", {
          value: 1000000,
          name: "Monthly investment",
        }),
        (value) => {
          return value <= 1000000;
        }
      )
      .required(t("rd_calculator.required")),
    rate_of_interest: Yup.string()
      .test("min", t("rd_calculator.invalid_min", { value: 1 }), (value) => {
        return value >= 1;
      })
      .test(
        "max",
        t("rd_calculator.invalid_max", {
          value: 15,
          name: "Rate of interest",
        }),
        (value) => {
          return value <= 15;
        }
      )
      .required(t("rd_calculator.required")),
    time_period: Yup.string()
      .test(
        "min",
        t("rd_calculator.invalid_min", { value: period == "yearly" ? 1 : 3 }),
        (value) => {
          return period == "yearly" ? value >= 1 : value >= 3;
        }
      )
      .test(
        "max",
        t("rd_calculator.invalid_max", {
          value: period == "yearly" ? 10 : 9,
          name: period == "yearly" ? "year" : "months",
        }),
        (value) => {
          return period == "yearly" ? value <= 10 : value <= 9;
        }
      )
      .required(t("rd_calculator.required")),
  });

  const calculate = (values) => {
    const { monthly_investment, rate_of_interest, time_period } = values;

    if (!monthly_investment || !rate_of_interest || !time_period) {
      return;
    }

    const n = 4 * time_period; // Number of quarters
    const i = rate_of_interest / 400; // Quarterly interest rate

    const maturity_amount =
      monthly_investment *
      ((Math.pow(1 + i, n) - 1) / (1 - Math.pow(1 + i, -1 / 3)));

    const invested_amount = monthly_investment * time_period * 12; // Total invested amount
    const estimated_return = maturity_amount - invested_amount; // Interest earned

    const rounded_maturity_amount = Math.round(maturity_amount);
    const rounded_invested_amount = Math.round(invested_amount);
    const rounded_estimated_return = Math.round(estimated_return);

    const result = {
      maturity_amount: rounded_maturity_amount,
      invested_amount: rounded_invested_amount,
      estimated_return: rounded_estimated_return,
    };

    dispatch(rdCalculatorSuccess(result));
  };

  return (
    <>
      <div className="single-tax-calculator">
        <div className="sm-padding single-tax-calculator-sec">
          <Container>
            <div className="single-tax-calculator-box">
              <div className="single-tax-calculator-header">
                <Row>
                  <Col md={6}>
                    <div className="d-flex other-calculator-btn-sec">
                      <Link
                        to="/tax-calculator"
                        className="other-calculator-btn"
                      >
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            enableBackground="new 0 0 512 512"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="#fff"
                              d="M10.957 12.354a.5.5 0 010-.708l4.586-4.585a1.5 1.5 0 00-2.121-2.122L8.836 9.525a3.505 3.505 0 000 4.95l4.586 4.586a1.5 1.5 0 002.121-2.122z"
                              data-original="#000000"
                            ></path>
                          </svg>
                        </span>
                        <span>Other Calculators</span>
                      </Link>
                    </div>
                    <div className="single-tax-calculator-info-sec">
                      <h1>{t("rd_calculator.heading")}</h1>
                      <p>{t("rd_calculator.content")}</p>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="single-tax-calculator-header-img-sec">
                      <CustomLazyLoad
                        className="single-tax-calculator-header-img"
                        src={
                          window.location.origin +
                          "/img/single-tax-calculator-header-img.png"
                        }
                        type="image/svg"
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="single-tax-calculator-form-box  line-stright calculator-space-lg">
              <Row className="align-items-center g-5">
                <Col md={6} lg={6}>
                  <Formik
                    initialValues={{
                      monthly_investment: 1000,
                      rate_of_interest: 2,
                      time_period: 2,
                    }}
                    innerRef={formRef}
                    validationSchema={sipSchema}
                    onSubmit={handleSubmit}
                  >
                    {({ setFieldValue, values, resetForm, submitForm }) => (
                      <FORM className="single-tax-calculator-form">
                        <div className="single-tax-calculator-form-card">
                          <div className="single-tax-calculator-form-header-sec">
                            <h4>{t("rd_calculator.investment.text")}</h4>
                            <Form.Group
                              className="mb-0"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Field
                                className="form-control"
                                name="monthly_investment"
                                placeholder={t(
                                  "rd_calculator.investment.placeholder"
                                )}
                                type="number"
                                onKeyDown={(e) => {
                                  if (
                                    !/[0-9]|Backspace|ArrowLeft|ArrowRight|Delete|ArrowUp|ArrowDown|Tab/.test(
                                      e.key
                                    ) &&
                                    !(
                                      e.ctrlKey &&
                                      (e.key === "v" ||
                                        e.key === "c" ||
                                        e.key === "x" ||
                                        e.key === "a")
                                    )
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                                onInput={(e) => {
                                  if (e.target.value < 0) {
                                    e.target.value = 0;
                                  }
                                  onChangeInput(e, "investment");
                                  submitForm();
                                }}
                              />
                            </Form.Group>
                          </div>
                          <ErrorMessage
                            component={"div"}
                            name="monthly_investment"
                            className="errorMsg"
                          />
                          <div className="tax-calculator-input-slider-sec">
                            <tc-range-slider
                              ref={principleRef}
                              value={principleRef}
                              mousewheel-disabled="true"
                              min={1000}
                              max={1000000}
                              slider-width="100%"
                              slider-bg-fill="#2F5CFF"
                              generate-labels="true"
                              round="0"
                            ></tc-range-slider>
                          </div>
                        </div>
                        <div className="single-tax-calculator-form-card">
                          <div className="single-tax-calculator-form-header-sec">
                            <h4>{t("rd_calculator.return_rate.text")}</h4>
                            <Form.Group
                              className="mb-0"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Field
                                className="form-control"
                                name="rate_of_interest"
                                placeholder={t(
                                  "rd_calculator.return_rate.placeholder"
                                )}
                                type="number"
                                onKeyDown={(e) => {
                                  if (
                                    !/[0-9.]|Backspace|ArrowLeft|ArrowRight|Delete|ArrowUp|ArrowDown|Tab/.test(
                                      e.key
                                    ) ||
                                    (e.key === "-" &&
                                      e.target.selectionStart !== 0)
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                                onInput={(e) => {
                                  if (e.target.value < 0) {
                                    e.target.value = 0;
                                  }
                                  onChangeInput(e, "interest");
                                  submitForm();
                                }}
                              />
                            </Form.Group>
                          </div>
                          <ErrorMessage
                            component={"div"}
                            name="rate_of_interest"
                            className="errorMsg"
                          />
                          <div className="tax-calculator-input-slider-sec">
                            <tc-range-slider
                              ref={interestRef}
                              value={interestRef}
                              mousewheel-disabled="true"
                              min={1}
                              max={15}
                              slider-width="100%"
                              slider-bg-fill="#2F5CFF"
                              generate-labels="true"
                              round="1"
                            ></tc-range-slider>
                          </div>
                        </div>
                        <div className="single-tax-calculator-form-card">
                          <div className="single-tax-calculator-form-header-sec">
                            <h4 className="d-flex align-items-center">
                              {t("rd_calculator.time_period.text", {
                                value:
                                  period == "yearly" ? "in years" : "in months",
                              })}
                              <div className="yes-or-no-radio-btn-sec mx-2 d-flex">
                                <div class="pretty p-default p-round p-smooth">
                                  <input
                                    type="radio"
                                    value="yearly"
                                    checked={period == "yearly"}
                                    onChange={(e) => {
                                      setPeriod(e.target.value);
                                      submitForm();
                                    }}
                                  />
                                  <div class="state p-primary-o">
                                    <label>
                                      {t("rd_calculator.time_period.yearly")}
                                    </label>
                                  </div>
                                </div>
                                <div class="pretty p-default p-round p-smooth">
                                  <input
                                    type="radio"
                                    value="monthly"
                                    checked={period == "monthly"}
                                    onChange={(e) => {
                                      setPeriod(e.target.value);
                                      submitForm();
                                    }}
                                  />
                                  <div class="state p-primary-o">
                                    <label>
                                      {t("rd_calculator.time_period.monthly")}
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </h4>
                            <Form.Group
                              className="mb-0"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Field
                                className="form-control"
                                name="time_period"
                                placeholder={t(
                                  "sip_calculator.time_period.placeholder"
                                )}
                                type="number"
                                onKeyDown={(e) => {
                                  if (
                                    !/[0-9]|Backspace|ArrowLeft|ArrowRight|Delete|ArrowUp|ArrowDown|Tab/.test(
                                      e.key
                                    ) &&
                                    !(
                                      e.ctrlKey &&
                                      (e.key === "v" ||
                                        e.key === "c" ||
                                        e.key === "x" ||
                                        e.key === "a")
                                    )
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                                onInput={(e) => {
                                  if (e.target.value < 0) {
                                    e.target.value = 0;
                                  }
                                  onChangeInput(e, "timePeriod");
                                  submitForm();
                                }}
                              />
                            </Form.Group>
                          </div>
                          <ErrorMessage
                            component={"div"}
                            name="time_period"
                            className="errorMsg"
                          />
                          <div className="tax-calculator-input-slider-sec">
                            <tc-range-slider
                              ref={timePeriodRef}
                              value={timePeriodRef}
                              mousewheel-disabled="true"
                              min={period == "yearly" ? 1 : 3}
                              max={period == "yearly" ? 10 : 9}
                              slider-width="100%"
                              slider-bg-fill="#2F5CFF"
                              generate-labels="true"
                              round="0"
                            ></tc-range-slider>
                          </div>
                        </div>
                        {/* <div className="d-flex justify-content-end mt-3">
                          <Button
                            type="submit"
                            className="default-primary-btn"
                            disabled={rdCalculator.buttonDisable}
                          >
                            {rdCalculator.buttonDisable ? (
                              <ButtonLoader />
                            ) : (
                              t("rd_calculator.calculate")
                            )}
                          </Button>
                        </div> */}
                      </FORM>
                    )}
                  </Formik>
                </Col>
                <Col md={6} lg={6}>
                  {rdCalculator.loading ? (
                    <div className="single-calculator-info-frame">
                      <div className="pie-chart-skelton">
                        <div className="skeleton-wrapper">
                          <Skeleton
                            borderRadius={130}
                            width={230}
                            height={230}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                        </div>
                        <div className="mt-4">
                          <Skeleton
                            width={250}
                            height={15}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                        </div>
                      </div>

                      <div className="calculator-info-details-card">
                        <div className="calculator-details-frame">
                          <Skeleton
                            width={200}
                            height={20}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                          <Skeleton
                            width={130}
                            height={20}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                        </div>
                        <div className="calculator-details-frame">
                          <Skeleton
                            width={200}
                            height={20}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                          <Skeleton
                            width={130}
                            height={20}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                        </div>
                        <hr />
                        <div className="calculator-details-frame top-bottom-line">
                          <Skeleton
                            width={200}
                            height={20}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                          <Skeleton
                            width={130}
                            height={20}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                        </div>
                        <hr />
                      </div>
                    </div>
                  ) : (
                    Object.keys(rdCalculator.data).length > 0 && (
                      <div className="single-calculator-info-frame">
                        <div className="pie-chart-sec">
                          <PieChart
                            name={[
                              t("rd_calculator.invested_amount"),
                              t("rd_calculator.estimated_returns"),
                            ]}
                            data={[
                              rdCalculator.data.invested_amount,
                              rdCalculator.data.estimated_return,
                            ]}
                          />
                        </div>
                        <div className="calculator-info-details-card">
                          <div className="calculator-details-frame">
                            <p>
                              {" "}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                x="0"
                                y="0"
                                enableBackground="new 0 0 29.107 29.107"
                                version="1.1"
                                viewBox="0 0 29.107 29.107"
                                xmlSpace="preserve"
                                width="20"
                                height="20"
                                fill="#2A334F"
                              >
                                <path d="M14.554 0C6.561 0 0 6.562 0 14.552c0 7.996 6.561 14.555 14.554 14.555 7.996 0 14.553-6.559 14.553-14.555C29.106 6.562 22.55 0 14.554 0z"></path>
                              </svg>
                              <span>{t("rd_calculator.invested_amount")}</span>
                            </p>
                            <h3>
                              {formatNumberWithCommas(
                                rdCalculator.data.invested_amount
                              )}
                            </h3>
                          </div>
                          <div className="calculator-details-frame">
                            <p>
                              {" "}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                x="0"
                                y="0"
                                enableBackground="new 0 0 29.107 29.107"
                                version="1.1"
                                viewBox="0 0 29.107 29.107"
                                xmlSpace="preserve"
                                width="20"
                                height="20"
                                fill="#2F5CFF"
                              >
                                <path d="M14.554 0C6.561 0 0 6.562 0 14.552c0 7.996 6.561 14.555 14.554 14.555 7.996 0 14.553-6.559 14.553-14.555C29.106 6.562 22.55 0 14.554 0z"></path>
                              </svg>
                              <span>
                                {t("rd_calculator.estimated_returns")}
                              </span>
                            </p>
                            <h3>
                              {formatNumberWithCommas(
                                rdCalculator.data.estimated_return
                              )}
                            </h3>
                          </div>
                          <hr />
                          <div className="calculator-details-frame top-bottom-line">
                            <p className="d-inline">
                              {t("rd_calculator.total_value")}
                            </p>
                            <h3>
                              {formatNumberWithCommas(
                                rdCalculator.data.maturity_amount
                              )}
                            </h3>
                          </div>
                          <hr />
                        </div>
                      </div>
                    )
                  )}
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <Container>
          <Row>
            <Col lg={12}>
              <div className="static-page-card">
                <div className="pricacy-policy-info">
                  <h4>Description</h4>
                  <p>
                    Recurring deposits (RDs) resemble fixed deposits (FDs) as a
                    form of investment, with the primary difference being the
                    investment structure: RDs require fixed monthly
                    contributions, unlike the single, lump-sum investment
                    characteristic of FDs. RDs promote a consistent investment
                    habit and encourage a disciplined approach to saving among
                    income earners. Most banks and financial institutions
                    provide recurring deposit options.
                  </p>
                  <p>
                    Calculating the returns on an RD can be complex and
                    challenging for the average investor to determine precisely
                    on each occasion. An RD calculator can be extremely helpful
                    in this context, simplifying the process of estimating
                    returns.
                  </p>
                </div>
                <div className="pricacy-policy-info">
                  <h4>Benefits of Using an RD Calculator</h4>
                  <p>
                    A recurring deposit represents a continuous form of
                    investment, with its returns sometimes being tricky to
                    monitor due to quarterly compounding interest and multiple
                    influencing factors, making manual calculations complex.
                  </p>

                  <p>
                    An RD calculator simplifies this by automating the
                    computation of returns, giving investors a clear picture of
                    the amount their investments will yield over the specified
                    period.
                  </p>
                  <p>
                    Investors need to manually consider the impact of TDS
                    deductions, as recent RBI guidelines have made RDs subject
                    to TDS, but its application varies across different
                    financial institutions, which is why RD calculators
                    typically don't include it.
                  </p>
                  <p>
                    Nonetheless, using an RD calculator brings several key
                    benefits to investors:
                  </p>
                  <ul>
                    <li>
                      It provides precise figures on the future value of
                      investments, aiding in more effective financial planning.
                    </li>
                    <li>
                      The calculator is user-friendly and time-efficient,
                      allowing investors to allocate their time to other
                      productive activities.
                    </li>
                    <li>
                      The reliability of these calculators ensures accurate
                      calculations, which is essential for sound financial
                      planning.
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <FAQIndex
          data={[
            {
              question: "Does RD attract TDS?",
              answer:
                "Yes, TDS is applicable to RDs in accordance with recent RBI guidelines. However, the implementation may vary across different banks and financial institutions.",
            },
            {
              question:
                "What's the least amount required to open an RD account?",
              answer:
                "The minimum amount required to initiate an RD account varies by bank but can start from as low as Rs. 100 in some cases.",
            },
            {
              question:
                "Is it possible to terminate my RD before its maturity date?",
              answer:
                "Premature withdrawal of RD is allowed by most banks, but it may incur a penalty or result in lower interest earnings.",
            },
            {
              question: "What durations are available for RD?",
              answer:
                "RD accounts can have tenures ranging from 6 months to 10 years, offering flexibility in terms of investment duration.",
            },
          ]}
        />
      </div>
    </>
  );
};

export default RDCalculatorIndex;
