import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Container, Button, Form, Accordion } from "react-bootstrap";
import CustomLazyLoad from "../../../Helper/CustomLazyLoad";
import Skeleton from "react-loading-skeleton";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-multi-lang";
import { useDispatch, useSelector } from "react-redux";
import { emiCalculatorStart, emiCalculatorSuccess } from "../../../../store/slices/CommonSlice";
import PieChart from "../PieChart";
import { ButtonLoader } from "../../../Helper/Loader";
import FAQIndex from "../FAQIndex";
import { Link } from "react-router-dom";
import formatNumberWithCommas from "../../../FormatNumber";

const CalculatorIndex = (props) => {
  const t = useTranslation("tax_calculator");
  const dispatch = useDispatch();
  const formRef = useRef();
  const principleRef = useRef(null);
  const interestRef = useRef(null);
  const timePeriodRef = useRef(null);
  const emiCalculator = useSelector((state) => state.common.emiCalculator);

  useEffect(() => {
    const onChange = (evt, field) => {
      const customEvent = evt;
      switch (field) {
        case "principal":
          formRef.current.setFieldValue("principal", customEvent.detail.value);
          break;
        case "interest":
          formRef.current.setFieldValue(
            "rate_of_interest",
            customEvent.detail.value
          );
          break;
        case "timePeriod":
          formRef.current.setFieldValue(
            "time_period",
            customEvent.detail.value
          );
          break;
      }
      setTimeout(() => { formRef.current.submitForm() }, 1)
    };
    principleRef.current?.addEventListener("change", (evt) =>
      onChange(evt, "principal")
    );
    interestRef.current?.addEventListener("change", (evt) =>
      onChange(evt, "interest")
    );
    timePeriodRef.current?.addEventListener("change", (evt) =>
      onChange(evt, "timePeriod")
    );
    return () => {
      principleRef.current?.removeEventListener("change", onChange);
      interestRef.current?.removeEventListener("change", onChange);
      timePeriodRef.current?.removeEventListener("change", onChange);
    };
  }, []);

  useEffect(() => {
    calculate(formRef.current.values)
    //dispatch(emiCalculatorStart(formRef.current.values));
  }, []);

  const onChangeInput = (event, field) => {
    const value = !isNaN(parseFloat(event.target.value))
      ? parseFloat(event.target.value)
      : "";
    switch (field) {
      case "principal":
        formRef.current.setFieldValue("principal", value);
        principleRef.current.value = value;
        break;
      case "interest":
        formRef.current.setFieldValue("rate_of_interest", value);
        interestRef.current.value = value;
        break;
      case "timePeriod":
        formRef.current.setFieldValue("time_period", value);
        timePeriodRef.current.value = value;
        break;
    }
    setTimeout(() => { formRef.current.submitForm() }, 1)
  };

  const homeLoanEmiSchema = Yup.object().shape({
    principal: Yup.string()
      .test(
        "min",
        t("home_loan_calculator.invalid_min", { value: 100000 }),
        (value) => {
          return value >= 100000;
        }
      )
      .test(
        "max",
        t("home_loan_calculator.invalid_max", {
          value: 10000000,
          name: "Loan Amount",
        }),
        (value) => {
          return value <= 10000000;
        }
      )
      .required(t("home_loan_calculator.required")),
    rate_of_interest: Yup.string()
      .test(
        "min",
        t("home_loan_calculator.invalid_min", { value: 1 }),
        (value) => {
          return value >= 1;
        }
      )
      .test(
        "max",
        t("home_loan_calculator.invalid_max", {
          value: 30,
          name: "Rate of interest",
        }),
        (value) => {
          return value <= 30;
        }
      )
      .required(t("home_loan_calculator.required")),
    time_period: Yup.string()
      .test(
        "min",
        t("home_loan_calculator.invalid_min", { value: 1 }),
        (value) => {
          return value >= 1;
        }
      )
      .test(
        "max",
        t("home_loan_calculator.invalid_max", {
          value: 30,
          name: "Time period",
        }),
        (value) => {
          return value <= 30;
        }
      )
      .required(t("home_loan_calculator.required")),
  });

  const handleSubmit = (values) => {
    formRef.current.isValid && calculate(values)
    // dispatch(emiCalculatorStart(values));
  };

  const calculate = (values) => {
    const { rate_of_interest, time_period, principal } = values
    let rateOfInterest = rate_of_interest / 100;
    let monthlyRateOfInterest = rateOfInterest / 12;
    let totalMonths = time_period * 12;
    let monthlyEMI = (principal * monthlyRateOfInterest * Math.pow(1 + monthlyRateOfInterest, totalMonths)) / (Math.pow(1 + monthlyRateOfInterest, totalMonths) - 1);
    let totalPayment = monthlyEMI * totalMonths;
    let totalInterest = totalPayment - principal;
    let principalAmount = principal;
    const newResult = {
      monthly_emi: Math.round(monthlyEMI),
      principal_amount: Math.round(principalAmount),
      total_interest: Math.round(totalInterest),
      total_payment: Math.round(totalPayment),
    };
    dispatch(emiCalculatorSuccess(newResult))
  }

  return (
    <>
      <div className="single-tax-calculator">
        <div className="sm-padding single-tax-calculator-sec">
          <Container>
            <div className="single-tax-calculator-box">
              <div className="single-tax-calculator-header">
                <Row>
                  <Col md={6}>
                    <div className="d-flex other-calculator-btn-sec">
                      <Link
                        to="/tax-calculator"
                        className="other-calculator-btn"
                      >
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            enableBackground="new 0 0 512 512"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="#fff"
                              d="M10.957 12.354a.5.5 0 010-.708l4.586-4.585a1.5 1.5 0 00-2.121-2.122L8.836 9.525a3.505 3.505 0 000 4.95l4.586 4.586a1.5 1.5 0 002.121-2.122z"
                              data-original="#000000"
                            ></path>
                          </svg>
                        </span>
                        <span>Other Calculators</span>
                      </Link>
                    </div>
                    <div className="single-tax-calculator-info-sec">
                      <h1>{t("home_loan_calculator.heading")}</h1>
                      <p>{t("home_loan_calculator.content")}</p>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="single-tax-calculator-header-img-sec">
                      <CustomLazyLoad
                        className="single-tax-calculator-header-img"
                        src={
                          window.location.origin +
                          "/img/single-tax-calculator-header-img.png"
                        }
                        type="image/svg"
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="single-tax-calculator-form-box  line-stright calculator-space-xl">
              <Row className="align-items-center g-5">
                <Col md={6} lg={6}>
                  <Formik
                    initialValues={{
                      principal: 1000000,
                      rate_of_interest: 6.5,
                      time_period: 5,
                      loan_type: "Home Loan",
                    }}
                    innerRef={formRef}
                    validationSchema={homeLoanEmiSchema}
                    onSubmit={handleSubmit}
                  >
                    {({ setFieldValue, values, resetForm, submitForm, isValid }) => (
                      <FORM className="single-tax-calculator-form">
                        <div className="single-tax-calculator-form-card">
                          <div className="single-tax-calculator-form-header-sec">
                            <h4>
                              {t("home_loan_calculator.loan_amount.text")}
                            </h4>
                            <Form.Group
                              className="mb-0"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Field
                                className="form-control"
                                name="principal"
                                type="number"
                                placeholder={t(
                                  "home_loan_calculator.loan_amount.placeholder"
                                )}
                                onKeyDown={(e) => {
                                  if (
                                    !/[0-9]|Backspace|ArrowLeft|ArrowRight|Delete|ArrowUp|ArrowDown|Tab/.test(
                                      e.key
                                    ) &&
                                    !(
                                      e.ctrlKey &&
                                      (e.key === "v" ||
                                        e.key === "c" ||
                                        e.key === "x" ||
                                        e.key === "a")
                                    )
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                                onInput={(e) => {
                                  if (e.target.value < 0) {
                                    e.target.value = 0;
                                  }
                                  onChangeInput(e, "principal");
                                }}
                              />
                            </Form.Group>
                          </div>
                          <ErrorMessage
                            component={"div"}
                            name="principal"
                            className="errorMsg"
                          />
                          <div className="tax-calculator-input-slider-sec">
                            <tc-range-slider
                              ref={principleRef}
                              value={principleRef}
                              mousewheel-disabled="true"
                              min={100000}
                              max={10000000}
                              slider-width="100%"
                              slider-bg-fill="#2F5CFF"
                              generate-labels="true"
                              round="0"
                            ></tc-range-slider>
                          </div>
                        </div>
                        <div className="single-tax-calculator-form-card">
                          <div className="single-tax-calculator-form-header-sec">
                            <h4>
                              {t("home_loan_calculator.rate_of_interest.text")}{" "}
                              ({t("home_loan_calculator.rate_of_interest.p_a")})
                            </h4>
                            <Form.Group
                              className="mb-0"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Field
                                className="form-control"
                                name="rate_of_interest"
                                type="number"
                                placeholder={t(
                                  "home_loan_calculator.rate_of_interest.placeholder"
                                )}
                                onKeyDown={(e) => {
                                  if (
                                    !/[0-9.]|Backspace|ArrowLeft|ArrowRight|Delete|ArrowUp|ArrowDown|Tab/.test(
                                      e.key
                                    ) ||
                                    (e.key === "-" &&
                                      e.target.selectionStart !== 0)
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                                onInput={(e) => {
                                  if (e.target.value < 0) {
                                    e.target.value = 0;
                                  }
                                  onChangeInput(e, "interest");
                                }}
                              />
                            </Form.Group>
                          </div>
                          <ErrorMessage
                            component={"div"}
                            name="rate_of_interest"
                            className="errorMsg"
                          />
                          <div className="tax-calculator-input-slider-sec">
                            <tc-range-slider
                              ref={interestRef}
                              value={interestRef}
                              mousewheel-disabled="true"
                              min={1}
                              max={30}
                              slider-width="100%"
                              slider-bg-fill="#2F5CFF"
                              generate-labels="true"
                              round="1"
                            ></tc-range-slider>
                          </div>
                        </div>
                        <div className="single-tax-calculator-form-card">
                          <div className="single-tax-calculator-form-header-sec">
                            <h4>
                              {t("home_loan_calculator.loan_tenure.text")}{" "}
                            </h4>
                            <Form.Group
                              className="mb-0"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Field
                                className="form-control"
                                name="time_period"
                                type="number"
                                placeholder={t(
                                  "home_loan_calculator.loan_tenure.placeholder"
                                )}
                                onKeyDown={(e) => {
                                  if (
                                    !/[0-9]|Backspace|ArrowLeft|ArrowRight|Delete|ArrowUp|ArrowDown|Tab/.test(
                                      e.key
                                    ) &&
                                    !(
                                      e.ctrlKey &&
                                      (e.key === "v" ||
                                        e.key === "c" ||
                                        e.key === "x" ||
                                        e.key === "a")
                                    )
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                                onInput={(e) => {
                                  if (e.target.value < 0) {
                                    e.target.value = 0;
                                  }
                                  onChangeInput(e, "timePeriod");
                                }}
                              />
                            </Form.Group>
                          </div>
                          <ErrorMessage
                            component={"div"}
                            name="time_period"
                            className="errorMsg"
                          />
                          <div className="tax-calculator-input-slider-sec">
                            <tc-range-slider
                              ref={timePeriodRef}
                              value={timePeriodRef}
                              mousewheel-disabled="true"
                              min={1}
                              max={30}
                              slider-width="100%"
                              slider-bg-fill="#2F5CFF"
                              generate-labels="true"
                              round="0"
                            ></tc-range-slider>
                          </div>
                        </div>
                        {/* <div className="d-flex justify-content-end">
                          <Button
                            type="submit"
                            className="default-primary-btn"
                            disabled={emiCalculator.buttonDisable}
                          >
                            {emiCalculator.buttonDisable ? (
                              <ButtonLoader />
                            ) : (
                              t("car_loan_calculator.calculate")
                            )}
                          </Button>
                        </div> */}
                      </FORM>
                    )}
                  </Formik>
                </Col>
                <Col md={6} lg={6}>
                  {emiCalculator.loading ? (
                    <div className="single-calculator-info-frame">
                      <div className="pie-chart-skelton">
                        <div className="skeleton-wrapper">
                          <Skeleton
                            borderRadius={130}
                            width={230}
                            height={230}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                        </div>
                        <div className="mt-4">
                          <Skeleton
                            width={250}
                            height={15}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                        </div>
                      </div>

                      <div className="calculator-info-details-card">
                        <div className="calculator-details-frame">
                          <Skeleton
                            width={200}
                            height={20}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                          <Skeleton
                            width={130}
                            height={20}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                        </div>
                        <div className="calculator-details-frame">
                          <Skeleton
                            width={200}
                            height={20}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                          <Skeleton
                            width={130}
                            height={20}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                        </div>
                        <hr />
                        <div className="calculator-details-frame top-bottom-line">
                          <Skeleton
                            width={200}
                            height={20}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                          <Skeleton
                            width={130}
                            height={20}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                        </div>
                        <hr />
                      </div>
                    </div>
                  ) : (
                    Object.keys(emiCalculator.data).length > 0 && (
                      <div className="single-calculator-info-frame">
                        <div className="pie-chart-sec">
                          <PieChart
                            name={[
                              t("home_loan_calculator.principal_amount"),
                              t("home_loan_calculator.total_interest"),
                            ]}
                            data={[
                              emiCalculator.data.principal_amount,
                              emiCalculator.data.total_interest,
                            ]}
                          />
                        </div>
                        <div className="calculator-info-details-card">
                          <div className="calculator-details-frame">
                            <p>
                              {" "}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                x="0"
                                y="0"
                                enableBackground="new 0 0 29.107 29.107"
                                version="1.1"
                                viewBox="0 0 29.107 29.107"
                                xmlSpace="preserve"
                                width="20"
                                height="20"
                                fill="#2A334F"
                              >
                                <path d="M14.554 0C6.561 0 0 6.562 0 14.552c0 7.996 6.561 14.555 14.554 14.555 7.996 0 14.553-6.559 14.553-14.555C29.106 6.562 22.55 0 14.554 0z"></path>
                              </svg>
                              <span>
                                {t("home_loan_calculator.monthly_emi")}
                              </span>
                            </p>
                            <h3>
                              {formatNumberWithCommas(
                                emiCalculator.data.monthly_emi
                              )}
                            </h3>
                          </div>
                          <div className="calculator-details-frame">
                            <p>
                              {" "}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                x="0"
                                y="0"
                                enableBackground="new 0 0 29.107 29.107"
                                version="1.1"
                                viewBox="0 0 29.107 29.107"
                                xmlSpace="preserve"
                                width="20"
                                height="20"
                                fill="#2F5CFF"
                              >
                                <path d="M14.554 0C6.561 0 0 6.562 0 14.552c0 7.996 6.561 14.555 14.554 14.555 7.996 0 14.553-6.559 14.553-14.555C29.106 6.562 22.55 0 14.554 0z"></path>
                              </svg>
                              <span>
                                {t("home_loan_calculator.principal_amount")}
                              </span>
                            </p>
                            <h3>
                              {formatNumberWithCommas(
                                emiCalculator.data.principal_amount
                              )}
                            </h3>
                          </div>
                          <div className="calculator-details-frame">
                            <p className="d-inline">
                              <span>
                                {t("home_loan_calculator.total_interest")}
                              </span>
                            </p>
                            <h3>₹{emiCalculator.data.total_interest}</h3>
                          </div>
                          <hr />
                          <div className="calculator-details-frame top-bottom-line">
                            <p className="d-inline">
                              {t("home_loan_calculator.total_amount")}
                            </p>
                            <h3>
                              {formatNumberWithCommas(
                                emiCalculator.data.total_payment
                              )}
                            </h3>
                          </div>
                          <hr />
                        </div>
                      </div>
                    )
                  )}
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <Container>
          <Row>
            <Col lg={12}>
              <div className="static-page-card">
                <div className="pricacy-policy-info">
                  <h4>Description</h4>
                  <p>
                    With the escalating real estate prices in India, buying a
                    home often requires external financing. More and more
                    prospective homeowners are turning to home loans to finance
                    their purchases, evidenced by a 16% growth in housing credit
                    in FY18.
                  </p>
                  <p>
                    It's crucial for potential borrowers to know exactly how
                    much their monthly installment (EMI) will be. Using a home
                    loan EMI calculator can help you accurately determine this
                    amount, ensuring you are well-prepared for your financial
                    commitments.
                  </p>
                </div>
                <div className="pricacy-policy-info">
                  <h4>How a Home Loan EMI Calculator Can Assist You</h4>
                  <p>
                    An Equated Monthly Installment (EMI) is a fixed payment
                    amount made by a borrower to a lender at a specified date
                    each calendar month. Calculating the EMI and its components
                    can be complex, especially for those financing for the first
                    time. A home loan EMI calculator simplifies these
                    calculations, offering a quick and error-free alternative to
                    manual computations.
                  </p>
                  <p>
                    Performing these calculations manually not only takes a lot
                    of time but also leaves room for errors. Using a home loan
                    EMI calculator can save prospective home buyers valuable
                    time. It provides a precise estimate crucial for effective
                    financial planning, eliminating any possibility of
                    inaccuracies or ambiguities.
                  </p>
                  <p>
                    The process of calculating EMI varies depending on the type
                    of loan. For instance, the method for calculating a home
                    loan EMI differs from that for a personal loan. The EMI
                    Calculator for Home Loans is specifically designed for
                    housing loans.
                  </p>
                  <p>
                    Additionally, the online calculator is available for
                    unlimited use at no cost. It allows you to explore different
                    loan amounts and tenures to find the option that best fits
                    your financial needs.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <FAQIndex
          data={[
            {
              question: "What does EMI stand for?",
              answer:
                "EMI stands for Equated Monthly Installment. It is the fixed payment amount that a borrower must pay to the lender on a monthly basis until the loan is fully repaid.",
            },
            {
              question:
                "What are the benefits of using an online EMI calculator?",
              answer:
                "An online EMI calculator provides a quick, accurate, and hassle-free way to calculate your monthly loan payments. It helps in financial planning by allowing you to assess your repayment capacity before taking a loan.",
            },
            {
              question: "How frequently can the EMI calculator be used?",
              answer:
                "There is no limit to the number of times you can use an online EMI calculator. You can use it as often as needed to plan various loan options and repayment schedules.",
            },
            {
              question:
                "Is the EMI payment amount constant throughout the loan tenure?",
              answer:
                "Yes, the EMI amount is typically fixed for the duration of the loan, making it easier for borrowers to budget their monthly expenses. However, it may change if there are alterations in the interest rate or if partial prepayments are made during the loan tenure.",
            },
          ]}
        />
      </div>
    </>
  );
};

export default CalculatorIndex;
