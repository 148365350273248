import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Container, Button, Form } from "react-bootstrap";
import CustomLazyLoad from "../../../Helper/CustomLazyLoad";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import IncomeDetails from "./IncomeDetails";
import Deductions from "./Deductions";
import Exemptions from "./ Exemptions";
import TaxCalculated from "./TaxCalculated";
import * as Yup from "yup";
import { taxCalculatorStart, taxCalculatorSuccess } from "../../../../store/slices/CommonSlice";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-multi-lang";
import { Link } from "react-router-dom";
import FAQIndex from "../FAQIndex";

const CalculatorIndex = (props) => {
  const t = useTranslation("tax_calculator");
  const taxCalculator = useSelector((state) => state.common.taxCalculator);
  const dispatch = useDispatch();
  const formRef = useRef();
  const [step, setStep] = useState(0);
  const [skipRender, setSkiprender] = useState(false);
  const [sliderValue, setSliderValue] = useState(null);

  const handleSubmit = (values) => {
    step != 3 && setStep((prevStep) => prevStep + 1);
    if (step == 3) {
      calculate(values)
      // dispatch(
      //   taxCalculatorStart({ ...values, uuid: localStorage.getItem("uuid") })
      // );
    }
  };

  const handleSliderChange = (value) => {
    setSliderValue(value);
    formRef.current.setFieldValue("basic_salary", value);
  };

  useEffect(() => {
    if (skipRender && Object.keys(taxCalculator.data).length > 0) {
      setStep(step + 1);

      formRef.current.resetForm();
    }
    setSkiprender(true);
  }, [taxCalculator]);

  function taxCalcOldRegime(income) {
    let tax;

    if (income <= 500000) {
      tax = 0;
    } else if (income <= 1000000) {
      tax = (income - 500000) * 0.2 + 12500;
    } else if (income <= 5000000) {
      tax = (income - 1000000) * 0.3 + 112500;
    } else if (income <= 10000000) {
      tax = ((income - 1000000) * 0.3 + 112500) * 1.10;
    } else if (income <= 20000000) {
      tax = ((income - 1000000) * 0.3 + 112500) * 1.15;
    } else if (income <= 50000000) {
      tax = ((income - 1000000) * 0.3 + 112500) * 1.25;
    } else {
      tax = ((income - 1000000) * 0.3 + 112500) * 1.37;
    }

    return tax;
  }

  const taxCalcNewRegime = (income) => {
    let tax;

    if (income <= 300000) {
      tax = 0;
    } else if (income <= 600000) {
      tax = (income - 300000) * 0.05;
    } else if (income <= 900000) {
      tax = (income - 600000) * 0.1 + 15000;
    } else if (income <= 1200000) {
      tax = (income - 900000) * 0.15 + 45000;
    } else if (income <= 1500000) {
      tax = (income - 1200000) * 0.2 + 90000;
    } else {
      tax = (income - 1500000) * 0.3 + 150000;
    }

    if (income <= 700000) {
      tax = 0;
    }

    let surchargeRate;
    if (income <= 5000000) {
      surchargeRate = 0;
    } else if (income <= 10000000) {
      surchargeRate = 0.1;
    } else if (income <= 20000000) {
      surchargeRate = 0.15;
    } else {
      surchargeRate = 0.25;
    }

    const surcharge = tax * surchargeRate;
    const totalTax = tax + surcharge;

    return totalTax;
  }

  const deductionOldRegime = (values) => {
    const { location,
      country_code,
      basic_salary,
      dearness_allownces,
      hra,
      hra_actual,
      special_allowance,
      pf_deduction,
      deduction_80c,
      pt_deduction,
      deduction_80d,
      deduction_NPS,
      deduction_80G,
      deduction_interest_on_loan, } = values
    const standardDeduction = 50000;
    let hraFinal;

    if (location === "Metro") {
      hraFinal = Math.max(0, Math.ceil(Math.min(0.5 * (basic_salary + dearness_allownces), hra, (hra_actual - 0.1 * (basic_salary + dearness_allownces)))));
    } else {
      hraFinal = Math.max(0, Math.ceil(Math.min(0.4 * (basic_salary + dearness_allownces), hra, (hra_actual - 0.1 * (basic_salary + dearness_allownces)))));
    }

    const taxableIncome = basic_salary + hra + dearness_allownces + special_allowance - pf_deduction - pt_deduction - deduction_80d - deduction_NPS - deduction_80G - deduction_interest_on_loan - standardDeduction - hraFinal - deduction_80c;
    const totalTax = taxCalcOldRegime(taxableIncome);
    const finalTax = Math.ceil(totalTax) * 1.04;

    return finalTax;
  }

  const deductionNewRegime = (values) => {
    const {
      basic_salary,
      dearness_allownces,
      hra,
      special_allowance,
    } = values
    const standardDeduction = 50000;
    const taxableIncome = basic_salary + hra + dearness_allownces + special_allowance - standardDeduction;
    const totalTax = taxCalcNewRegime(taxableIncome);
    const finalTax = Math.ceil(totalTax) * 1.04;

    return finalTax;
  }

  const calculate = (values) => {
    const newRegime = deductionNewRegime(values)
    const oldRegime = deductionOldRegime(values)

    return dispatch(taxCalculatorSuccess({ tax_new_regime: newRegime, tax_old_regime: oldRegime }))
  }


  const taxSchema = () => {
    switch (step) {
      case 1:
        return Yup.object().shape({
          basic_salary: Yup.string()
            .required(t("tax_calculator_list.required"))
            .test("min", t("tax_calculator_list.invalid_min"), (value) => {
              return value >= 1;
            })
            .test(
              "max",
              t("tax_calculator_list.invalid_max", {
                value: 10000000,
                name: "Salary",
              }),
              (value) => {
                return value <= 10000000;
              }
            ),
          dearness_allownces: Yup.string()
            .test("min", t("tax_calculator_list.invalid_min"), (value) => {
              return value >= 0;
            })
            .required(t("tax_calculator_list.required")),
          hra: Yup.string()
            .test("min", t("tax_calculator_list.invalid_min"), (value) => {
              return value >= 0;
            })
            .required(t("tax_calculator_list.required")),
          hra_actual: Yup.string()
            .test("min", t("tax_calculator_list.invalid_min"), (value) => {
              return value >= 0;
            })
            .required(t("tax_calculator_list.required")),
          special_allowance: Yup.string()
            .test("min", t("tax_calculator_list.invalid_min"), (value) => {
              return value >= 0;
            })
            .required(t("tax_calculator_list.required")),
        });
      case 2:
        return Yup.object().shape({
          pf_deduction: Yup.string()
            .test("min", t("tax_calculator_list.invalid_min"), (value) => {
              return value >= 0;
            })
            .required(t("tax_calculator_list.required")),
          deduction_80c: Yup.string()
            .test("min", t("tax_calculator_list.invalid_min"), (value) => {
              return value >= 0;
            })
            .test(
              "max",
              t("tax_calculator_list.invalid_max", {
                value: 150000,
                name: "80c",
              }),
              (value) => {
                return value <= 150000;
              }
            )
            .required(t("tax_calculator_list.required")),
          pt_deduction: Yup.string()
            .test("min", t("tax_calculator_list.invalid_min"), (value) => {
              return value >= 0;
            })
            .test(
              "max",
              t("tax_calculator_list.invalid_max", { value: 2400, name: "PT" }),
              (value) => {
                return value <= 2400;
              }
            )
            .required(t("tax_calculator_list.required")),
          deduction_80d: Yup.string()
            .test("min", t("tax_calculator_list.invalid_min"), (value) => {
              return value >= 0;
            })
            .test(
              "max",
              t("tax_calculator_list.invalid_max", {
                value: 25000,
                name: "80d",
              }),
              (value) => {
                return value <= 25000;
              }
            )
            .required(t("tax_calculator_list.required")),
        });
      case 3:
        return Yup.object().shape({
          deduction_NPS: Yup.string()
            .test("min", t("tax_calculator_list.invalid_min"), (value) => {
              return value >= 0;
            })
            .test(
              "max",
              t("tax_calculator_list.invalid_max", {
                value: 50000,
                name: "NPS",
              }),
              (value) => {
                return value <= 50000;
              }
            )
            .required(t("tax_calculator_list.required")),
          deduction_80G: Yup.string()
            .test("min", t("tax_calculator_list.invalid_min"), (value) => {
              return value >= 0;
            })
            .required(t("tax_calculator_list.required")),
          deduction_interest_on_loan: Yup.string()
            .test("min", t("tax_calculator_list.invalid_min"), (value) => {
              return value >= 0;
            })
            .test(
              "max",
              t("tax_calculator_list.invalid_max", {
                value: 200000,
                name: "Interest on loan",
              }),
              (value) => {
                return value <= 200000;
              }
            )
            .required(t("tax_calculator_list.required")),
        });
      default:
        return;
    }
  };

  useEffect(() => {
    setStep(1);
  }, []);

  return (
    <>
      <div className="landing-page-sec">
        <div className="tax-calculator-sec">
          <Container>
            <div className="tax-calculator-box">
              <div className="tax-calculator-left-sec">
                <div className="d-flex other-calculator-btn-sec">
                  <Link to="/tax-calculator" className="other-calculator-btn">
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        enableBackground="new 0 0 512 512"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#fff"
                          d="M10.957 12.354a.5.5 0 010-.708l4.586-4.585a1.5 1.5 0 00-2.121-2.122L8.836 9.525a3.505 3.505 0 000 4.95l4.586 4.586a1.5 1.5 0 002.121-2.122z"
                          data-original="#000000"
                        ></path>
                      </svg>
                    </span>
                    <span>Other Calculators</span>
                  </Link>
                </div>
                <div className="tax-calculator-left-card">
                  <h2>
                    {t("tax_calculator_list.income_tax")} <br></br>
                    {t("tax_calculator_list.calculator")}
                  </h2>
                  <p>{t("tax_calculator_list.message")}</p>
                  <div className="tax-calculator-img-sec">
                    <CustomLazyLoad
                      className="tax-calculator-img"
                      src={
                        window.location.origin +
                        "/img/tax-calculator/tax-calculator-img.png"
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="tax-calculator-right-sec">
                <div className=" tax-calculator-right-box">
                  <h3>{t("tax_calculator_list.income_tax_content")}</h3>
                  <p>
                    {t("tax_calculator_list.income_tax_calculator_content")}
                  </p>
                  <div className="tax-calculator-steps-counter-sec">
                    <div className="stepper-wrapper">
                      <div
                        className={`stepper-item ${step > 1 ? "completed" : ""
                          } ${step == 1 ? "current" : ""}`}
                      >
                        <div className="step-counter">
                          {step > 1 ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="13"
                              height="11"
                              fill="none"
                              viewBox="0 0 13 11"
                            >
                              <path
                                fill="#fff"
                                d="M11.146 1.17a.934.934 0 011.332 1.307L5.214 9.79a.934.934 0 01-1.345.024L.573 6.52a.934.934 0 111.32-1.32L4.5 7.806l6.623-6.61.023-.026z"
                              ></path>
                            </svg>
                          ) : (
                            1
                          )}
                        </div>
                        <div className="step-text">
                          {t("tax_calculator_list.income_details")}
                        </div>
                      </div>
                      <div
                        className={`stepper-item ${step > 2 ? "completed" : ""
                          } ${step == 2 ? "current" : ""}`}
                      >
                        <div className="step-counter">
                          {" "}
                          {step > 2 ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="13"
                              height="11"
                              fill="none"
                              viewBox="0 0 13 11"
                            >
                              <path
                                fill="#fff"
                                d="M11.146 1.17a.934.934 0 011.332 1.307L5.214 9.79a.934.934 0 01-1.345.024L.573 6.52a.934.934 0 111.32-1.32L4.5 7.806l6.623-6.61.023-.026z"
                              ></path>
                            </svg>
                          ) : (
                            2
                          )}
                        </div>
                        <div className="step-text">
                          {t("tax_calculator_list.deductions")}
                        </div>
                      </div>
                      <div
                        className={`stepper-item ${step > 3 ? "completed" : ""
                          } ${step == 3 ? "current" : ""}`}
                      >
                        <div className="step-counter">
                          {" "}
                          {step > 3 ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="13"
                              height="11"
                              fill="none"
                              viewBox="0 0 13 11"
                            >
                              <path
                                fill="#fff"
                                d="M11.146 1.17a.934.934 0 011.332 1.307L5.214 9.79a.934.934 0 01-1.345.024L.573 6.52a.934.934 0 111.32-1.32L4.5 7.806l6.623-6.61.023-.026z"
                              ></path>
                            </svg>
                          ) : (
                            3
                          )}
                        </div>
                        <div className="step-text">
                          {t("tax_calculator_list.exemptions")}
                        </div>
                      </div>
                      <div
                        className={`stepper-item ${step == 4 ? "completed" : ""
                          } ${step == 4 ? "current" : ""}`}
                      >
                        <div className="step-counter">
                          {" "}
                          {step == 4 ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="13"
                              height="11"
                              fill="none"
                              viewBox="0 0 13 11"
                            >
                              <path
                                fill="#fff"
                                d="M11.146 1.17a.934.934 0 011.332 1.307L5.214 9.79a.934.934 0 01-1.345.024L.573 6.52a.934.934 0 111.32-1.32L4.5 7.806l6.623-6.61.023-.026z"
                              ></path>
                            </svg>
                          ) : (
                            4
                          )}
                        </div>
                        <div className="step-text">
                          {t("tax_calculator_list.tax_calculated")}
                        </div>
                      </div>
                    </div>
                  </div>
                  <Formik
                    initialValues={{
                      location: "Metro",
                      country_code: "IN",
                      basic_salary: null,
                      dearness_allownces: null,
                      hra: null,
                      hra_actual: null,
                      special_allowance: null,
                      pf_deduction: null,
                      deduction_80c: null,
                      pt_deduction: null,
                      deduction_80d: null,
                      deduction_NPS: null,
                      deduction_80G: null,
                      deduction_interest_on_loan: null,
                    }}
                    innerRef={formRef}
                    validationSchema={taxSchema()}
                    onSubmit={handleSubmit}
                  >
                    {({ setFieldValue, values, resetForm, isValid, submitForm }) => (
                      <FORM className="tax-calculator-form">
                        {step == 1 && (
                          <div className="step-1">
                            <IncomeDetails
                              setStep={setStep}
                              onSliderChange={handleSliderChange}
                              sliderValue={sliderValue}
                            />
                          </div>
                        )}
                        {step == 2 && (
                          <div className="step-2">
                            <Deductions setStep={setStep} />
                          </div>
                        )}
                        {step == 3 && (
                          <div className="step-3">
                            <Exemptions setStep={setStep} />
                          </div>
                        )}
                        {step == 4 && (
                          <div className="step-4">
                            <TaxCalculated
                              setStep={setStep}
                              setSliderValue={setSliderValue}
                            />
                          </div>
                        )}
                      </FORM>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </Container>
        </div>
        <Container>
          <Row>
            <Col lg={12}>
              <div className="static-page-card">
                <div className="pricacy-policy-info">
                  <h4>Description</h4>
                  <p>
                    An Income Tax Calculator is a digital tool designed to
                    estimate the amount of tax payable based on an individual's
                    income after the Union Budget for the year is declared. This
                    tool is particularly useful for individuals within the
                    taxable income brackets, as it helps determine the specific
                    portion of their net annual income that should be paid as
                    tax. Taxes can be paid either through Tax Deducted at Source
                    (TDS) during the monthly salary disbursement or directly via
                    the income tax returns portal managed by the Central Board
                    of Direct Taxes (CBDT). Online tax payment facilities are
                    also available to ensure that individuals fulfill their tax
                    obligations on additional earnings from other sources.
                  </p>
                  <p>
                    The Income Tax Calculator provided here is updated to
                    reflect the latest changes from the Union Budget for the
                    Financial Year 2023-24 and Assessment Year 2024-25.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <FAQIndex
          data={[
            {
              question: "What amount of tax will be deducted from my earnings?",
              answer:
                "The amount of tax deducted from your salary depends on your total income, applicable tax slabs, and any deductions or exemptions you're eligible for. You can use an income tax calculator to estimate the tax deducted based on these factors.",
            },
            {
              question: "How do I determine the amount of income tax I owe?",
              answer:
                "You can calculate your income tax liability by using an online income tax calculator. Simply input your total annual income, deductions, and other relevant details to receive an estimate of your tax obligation",
            },
            {
              question:
                "Is it mandatory for everyone to file income tax returns?",
              answer:
                "Not everyone is required to file income tax returns. It depends on several factors including the level of your income, age, and the type of income you receive. Generally, if your income exceeds the basic exemption limit, you are required to file an income tax return.",
            },
            {
              question:
                "What information is needed to e-file income tax returns?",
              answer:
                "When e-filing income tax returns, you'll need several pieces of information including your PAN (Permanent Account Number), Aadhaar number, bank account details, proof of income documents, details of deductions claimed, and investment proofs if applicable. These details help in accurately computing your tax liability and ensuring proper credit for taxes paid.",
            },
          ]}
        />
      </div>
    </>
  );
};

export default CalculatorIndex;
