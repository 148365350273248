import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Container,
  Button,
  Form,
  Accordion,
  Tab,
  Nav,
} from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import CustomLazyLoad from "../../../Helper/CustomLazyLoad";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-multi-lang";
import { useDispatch, useSelector } from "react-redux";
import { flatVsReducingRateCalculatorStart, flatVsReducingRateCalculatorSuccess } from "../../../../store/slices/CommonSlice";
import { ButtonLoader } from "../../../Helper/Loader";
import PieChart from "../PieChart";
import FAQIndex from "../FAQIndex";
import { Link } from "react-router-dom";
import formatNumberWithCommas from "../../../FormatNumber";

const CalculatorIndex = () => {
  const t = useTranslation("tax_calculator");
  const principleRef = useRef(null);
  const timePeriodRef = useRef(null);
  const interestPeriodRef = useRef(null);
  const formRef = useRef();
  const dispatch = useDispatch();
  const flatVsReducingRateCalculator = useSelector(
    (state) => state.common.flatVsReducingRateCalculator
  );
  useEffect(() => {
    const onChange = (event, field) => {
      const customEvent = event;
      switch (field) {
        case "principal":
          formRef.current.setFieldValue("principal", customEvent.detail.value);
          break;
        case "timePeriod":
          formRef.current.setFieldValue(
            "tenure_years",
            customEvent.detail.value
          );
          break;
        case "interest":
          formRef.current.setFieldValue(
            "annual_interest_rate",
            customEvent.detail.value
          );
          break;
      }
      setTimeout(() => { formRef.current.submitForm() }, 1)
    };
    principleRef.current?.addEventListener("change", (event) =>
      onChange(event, "principal")
    );
    timePeriodRef.current?.addEventListener("change", (event) =>
      onChange(event, "timePeriod")
    );
    interestPeriodRef.current?.addEventListener("change", (event) =>
      onChange(event, "interest")
    );
    return () => {
      principleRef.current?.removeEventListener("change", onChange);
      timePeriodRef.current?.removeEventListener("change", onChange);
      interestPeriodRef.current?.removeEventListener("change", onChange);
    };
  }, []);

  useEffect(() => {
    calculate(formRef.current?.values);
  }, []);

  const onChangeInput = (event, field) => {
    const value = !isNaN(parseFloat(event.target.value))
      ? parseFloat(event.target.value)
      : "";
    switch (field) {
      case "principal":
        formRef.current.setFieldValue("principal", value);
        principleRef.current.value = value;
        break;
      case "timePeriod":
        formRef.current.setFieldValue("tenure_years", value);
        timePeriodRef.current.value = value;
        break;
      case "interest":
        formRef.current.setFieldValue("annual_interest_rate", value);
        interestPeriodRef.current.value = value;
        break;
    }
    setTimeout(() => { formRef.current.submitForm() }, 1)
  };

  const handleSubmit = (values) => {
    formRef.current.isValid && calculate(values)
    // dispatch(flatVsReducingRateCalculatorStart(values));
  };

  const flatVsReducingSchema = Yup.object().shape({
    principal: Yup.string()
      .test(
        "min",
        t("flat_vs_reduce_calculator.invalid_min", { value: 1000 }),
        (value) => {
          return value >= 1000;
        }
      )
      .test(
        "max",
        t("flat_vs_reduce_calculator.invalid_max", {
          value: 10000000,
          name: "Loan amount",
        }),
        (value) => {
          return value <= 10000000;
        }
      )
      .required(t("flat_vs_reduce_calculator.required")),
    annual_interest_rate: Yup.string()
      .test(
        "min",
        t("flat_vs_reduce_calculator.invalid_min", { value: 1 }),
        (value) => {
          return value >= 1;
        }
      )
      .test(
        "max",
        t("flat_vs_reduce_calculator.invalid_max", {
          value: 30,
          name: "Loan Tenure",
        }),
        (value) => {
          return value <= 50;
        }
      )
      .required(t("flat_vs_reduce_calculator.required")),
    tenure_years: Yup.string()
      .test(
        "min",
        t("flat_vs_reduce_calculator.invalid_min", { value: 1 }),
        (value) => {
          return value >= 1;
        }
      )
      .test(
        "max",
        t("flat_vs_reduce_calculator.invalid_max", {
          value: 50,
          name: "Loan Tenure",
        }),
        (value) => {
          return value <= 50;
        }
      )
      .required(t("flat_vs_reduce_calculator.required")),
  });


  const calculateFlatRateInterest = (principal, annual_interest_rate, tenure_years) => {
    let totalInterest = (principal * annual_interest_rate * tenure_years) / 100;
    let totalAmount = principal + totalInterest;
    let monthlyEmi = totalAmount / (tenure_years * 12);
    return {
      monthlyEmi: Math.round(monthlyEmi),
      totalInterest: Math.round(totalInterest),
      totalAmount: Math.round(totalAmount)
    };
  }

  const calculateReducingRateEmi = (principal, annual_interest_rate, tenure_years) => {
    let monthlyInterestRate = annual_interest_rate / (100 * 12);
    let totalPayments = tenure_years * 12;
    let emi = (principal * monthlyInterestRate * Math.pow(1 + monthlyInterestRate, totalPayments)) / (Math.pow(1 + monthlyInterestRate, totalPayments) - 1);
    let totalAmount = emi * totalPayments;
    let totalInterest = totalAmount - principal;
    return {
      emi: Math.round(emi),
      totalInterest: Math.round(totalInterest),
      totalAmount: Math.round(totalAmount)
    };
  }

  const calculate = async (values) => {
    const { principal, annual_interest_rate, tenure_years } = values
    let flatResults = calculateFlatRateInterest(principal, annual_interest_rate, tenure_years);
    let reducingResults = calculateReducingRateEmi(principal, annual_interest_rate, tenure_years);

    return dispatch(flatVsReducingRateCalculatorSuccess({
      flat_emi: flatResults.monthlyEmi,
      flat_total_interest: flatResults.totalInterest,
      flat_total_amount: flatResults.totalAmount,
      reducing_emi: reducingResults.emi,
      reducing_total_interest: reducingResults.totalInterest,
      reducing_total_amount: reducingResults.totalAmount,
      saved_amount: flatResults.totalAmount - reducingResults.totalAmount
    }));
  }

  return (
    <>
      <div className="single-tax-calculator">
        <div className="sm-padding single-tax-calculator-sec">
          <Container>
            <div className="single-tax-calculator-box">
              <div className="single-tax-calculator-header">
                <Row>
                  <Col md={6}>
                    <div className="d-flex other-calculator-btn-sec">
                      <Link
                        to="/tax-calculator"
                        className="other-calculator-btn"
                      >
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            enableBackground="new 0 0 512 512"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="#fff"
                              d="M10.957 12.354a.5.5 0 010-.708l4.586-4.585a1.5 1.5 0 00-2.121-2.122L8.836 9.525a3.505 3.505 0 000 4.95l4.586 4.586a1.5 1.5 0 002.121-2.122z"
                              data-original="#000000"
                            ></path>
                          </svg>
                        </span>
                        <span>Other Calculators</span>
                      </Link>
                    </div>
                    <div className="single-tax-calculator-info-sec">
                      <h1>{t("flat_vs_reduce_calculator.heading")}</h1>
                      <p>{t("flat_vs_reduce_calculator.content")}</p>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="single-tax-calculator-header-img-sec">
                      <CustomLazyLoad
                        className="single-tax-calculator-header-img"
                        src={
                          window.location.origin +
                          "/img/single-tax-calculator-header-img.png"
                        }
                        type="image/svg"
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="single-tax-calculator-form-box  line-stright calculator-space-sm">
              <Row className="align-items-center g-5">
                <Col md={6} lg={6}>
                  <Formik
                    initialValues={{
                      principal: 1000,
                      annual_interest_rate: 15,
                      tenure_years: 2,
                    }}
                    innerRef={formRef}
                    validationSchema={flatVsReducingSchema}
                    onSubmit={handleSubmit}
                  >
                    {({ setFieldValue, values, resetForm, submitForm, isValid }) => (
                      <FORM className="single-tax-calculator-form">
                        <div className="single-tax-calculator-form-card">
                          <div className="single-tax-calculator-form-header-sec">
                            <h4>
                              {t("flat_vs_reduce_calculator.investment.text")}
                            </h4>
                            <Form.Group
                              className="mb-0"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Field
                                className="form-control"
                                name="principal"
                                placeholder={t(
                                  "flat_vs_reduce_calculator.investment.placeholder"
                                )}
                                type="number"
                                onKeyDown={(e) => {
                                  if (
                                    !/[0-9]|Backspace|ArrowLeft|ArrowRight|Delete|ArrowUp|ArrowDown|Tab/.test(
                                      e.key
                                    ) &&
                                    !(
                                      e.ctrlKey &&
                                      (e.key === "v" ||
                                        e.key === "c" ||
                                        e.key === "x" ||
                                        e.key === "a")
                                    )
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                                onInput={(e) => {
                                  if (e.target.value < 0) {
                                    e.target.value = 0;
                                  }
                                  onChangeInput(e, "principal");
                                }}
                              />
                            </Form.Group>
                          </div>
                          <ErrorMessage
                            component={"div"}
                            name="principal"
                            className="errorMsg"
                          />
                          <div className="tax-calculator-input-slider-sec">
                            <tc-range-slider
                              ref={principleRef}
                              value={principleRef}
                              mousewheel-disabled="true"
                              min={1000}
                              max={10000000}
                              slider-width="100%"
                              slider-bg-fill="#2F5CFF"
                              generate-labels="true"
                              round="0"
                            ></tc-range-slider>
                          </div>
                        </div>
                        <div className="single-tax-calculator-form-card">
                          <div className="single-tax-calculator-form-header-sec">
                            <h4>
                              {t("flat_vs_reduce_calculator.time_period.text")}
                            </h4>
                            <Form.Group
                              className="mb-0"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Field
                                className="form-control"
                                name="tenure_years"
                                placeholder={t(
                                  "flat_vs_reduce_calculator.time_period.placeholder"
                                )}
                                type="number"
                                onKeyDown={(e) => {
                                  if (
                                    !/[0-9]|Backspace|ArrowLeft|ArrowRight|Delete|ArrowUp|ArrowDown|Tab/.test(
                                      e.key
                                    ) &&
                                    !(
                                      e.ctrlKey &&
                                      (e.key === "v" ||
                                        e.key === "c" ||
                                        e.key === "x" ||
                                        e.key === "a")
                                    )
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                                onInput={(e) => {
                                  if (e.target.value < 0) {
                                    e.target.value = 0;
                                  }
                                  onChangeInput(e, "timePeriod");
                                }}
                              />
                            </Form.Group>
                          </div>
                          <ErrorMessage
                            component={"div"}
                            name="tenure_years"
                            className="errorMsg"
                          />
                          <div className="tax-calculator-input-slider-sec">
                            <tc-range-slider
                              ref={timePeriodRef}
                              value={timePeriodRef}
                              mousewheel-disabled="true"
                              min={1}
                              max={50}
                              slider-width="100%"
                              slider-bg-fill="#2F5CFF"
                              generate-labels="true"
                              round="0"
                            ></tc-range-slider>
                          </div>
                        </div>
                        <div className="single-tax-calculator-form-card">
                          <div className="single-tax-calculator-form-header-sec">
                            <h4>
                              {t(
                                "flat_vs_reduce_calculator.rate_of_interest.text"
                              )}
                            </h4>
                            <Form.Group
                              className="mb-0"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Field
                                className="form-control"
                                name="annual_interest_rate"
                                placeholder={t(
                                  "flat_vs_reduce_calculator.rate_of_interest.placeholder"
                                )}
                                type="number"
                                onKeyDown={(e) => {
                                  if (
                                    !/[0-9.]|Backspace|ArrowLeft|ArrowRight|Delete|ArrowUp|ArrowDown|Tab/.test(
                                      e.key
                                    ) ||
                                    (e.key === "-" &&
                                      e.target.selectionStart !== 0)
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                                onInput={(e) => {
                                  if (e.target.value < 0) {
                                    e.target.value = 0;
                                  }
                                  onChangeInput(e, "interest");
                                }}
                              />
                            </Form.Group>
                          </div>
                          <ErrorMessage
                            component={"div"}
                            name="annual_interest_rate"
                            className="errorMsg"
                          />
                          <div className="tax-calculator-input-slider-sec">
                            <tc-range-slider
                              ref={interestPeriodRef}
                              value={interestPeriodRef}
                              mousewheel-disabled="true"
                              min={1}
                              max={50}
                              slider-width="100%"
                              slider-bg-fill="#2F5CFF"
                              generate-labels="true"
                              round="1"
                            ></tc-range-slider>
                          </div>
                        </div>
                        {/* <div className="d-flex justify-content-end mt-3">
                          <Button
                            type="submit"
                            className="default-primary-btn"
                            disabled={
                              flatVsReducingRateCalculator.buttonDisable
                            }
                          >
                            {flatVsReducingRateCalculator.buttonDisable ? (
                              <ButtonLoader />
                            ) : (
                              t("flat_vs_reduce_calculator.calculate")
                            )}
                          </Button>
                        </div> */}
                      </FORM>
                    )}
                  </Formik>
                </Col>
                <Col md={6} lg={6}>
                  <div className="single-calculator-info-frame">
                    <div className="calculator-info-tabs-area">
                      <Tab.Container
                        id="left-tabs-example"
                        defaultActiveKey="flat"
                      >
                        <Nav variant="pills">
                          <Nav.Item>
                            <Nav.Link eventKey="flat">
                              {t("flat_vs_reduce_calculator.flat")}
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="reducing">
                              {t("flat_vs_reduce_calculator.reducing_balance")}
                            </Nav.Link>
                          </Nav.Item>
                        </Nav>
                        <Tab.Content>
                          <Tab.Pane eventKey="flat">
                            {flatVsReducingRateCalculator.loading ? (
                              <div className="calculator-tabs-body">
                                <div className="pie-chart-skelton">
                                  <div className="skeleton-wrapper">
                                    <Skeleton
                                      borderRadius={130}
                                      width={230}
                                      height={230}
                                      baseColor="#202020"
                                      highlightColor="#444"
                                    />
                                  </div>
                                  <div className="mt-4">
                                    <Skeleton
                                      width={250}
                                      height={15}
                                      baseColor="#202020"
                                      highlightColor="#444"
                                    />
                                  </div>
                                </div>
                                <div className="calculator-info-details-card">
                                  <div className="calculator-info-details-card-title">
                                    <Skeleton
                                      width={175}
                                      height={20}
                                      baseColor="#202020"
                                      highlightColor="#444"
                                    />
                                  </div>
                                  <div className="calculator-details-frame">
                                    <Skeleton
                                      width={200}
                                      height={20}
                                      baseColor="#202020"
                                      highlightColor="#444"
                                    />
                                    <Skeleton
                                      width={130}
                                      height={20}
                                      baseColor="#202020"
                                      highlightColor="#444"
                                    />
                                  </div>
                                  <div className="calculator-details-frame">
                                    <Skeleton
                                      width={200}
                                      height={20}
                                      baseColor="#202020"
                                      highlightColor="#444"
                                    />
                                    <Skeleton
                                      width={130}
                                      height={20}
                                      baseColor="#202020"
                                      highlightColor="#444"
                                    />
                                  </div>
                                  <hr />
                                  <div className="calculator-details-frame top-bottom-line">
                                    <Skeleton
                                      width={200}
                                      height={20}
                                      baseColor="#202020"
                                      highlightColor="#444"
                                    />
                                    <Skeleton
                                      width={130}
                                      height={20}
                                      baseColor="#202020"
                                      highlightColor="#444"
                                    />
                                  </div>
                                  <hr />
                                  <div className="calculator-details-frame top-bottom-line">
                                    <Skeleton
                                      width={200}
                                      height={20}
                                      baseColor="#202020"
                                      highlightColor="#444"
                                    />
                                    <Skeleton
                                      width={130}
                                      height={20}
                                      baseColor="#202020"
                                      highlightColor="#444"
                                    />
                                  </div>
                                  <div className="calculator-details-batch">
                                    <Skeleton
                                      width={130}
                                      height={50}
                                      borderRadius={30}
                                      baseColor="#202020"
                                      highlightColor="#444"
                                    />
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div className="calculator-tabs-body">
                                <div className="pie-chart-sec">
                                  <PieChart
                                    name={[
                                      t(
                                        "flat_vs_reduce_calculator.principle_amount"
                                      ),
                                      t(
                                        "flat_vs_reduce_calculator.total_interest"
                                      ),
                                    ]}
                                    data={[
                                      formRef.current?.values.principal,
                                      flatVsReducingRateCalculator.data?.flat_total_interest,
                                    ]}
                                  />
                                </div>
                                <div className="calculator-info-details-card">
                                  <div className="calculator-info-details-card-title">
                                    <h3>
                                      {t("flat_vs_reduce_calculator.flat_rate")}
                                    </h3>
                                  </div>
                                  <div className="calculator-details-frame">
                                    <p>
                                      {" "}
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        x="0"
                                        y="0"
                                        enableBackground="new 0 0 29.107 29.107"
                                        version="1.1"
                                        viewBox="0 0 29.107 29.107"
                                        xmlSpace="preserve"
                                        width="20"
                                        height="20"
                                        fill="#2A334F"
                                      >
                                        <path d="M14.554 0C6.561 0 0 6.562 0 14.552c0 7.996 6.561 14.555 14.554 14.555 7.996 0 14.553-6.559 14.553-14.555C29.106 6.562 22.55 0 14.554 0z"></path>
                                      </svg>
                                      <span>
                                        {" "}
                                        {t(
                                          "flat_vs_reduce_calculator.principle_amount"
                                        )}
                                      </span>
                                    </p>
                                    <h3>
                                      {formRef.current?.values?.principal && formatNumberWithCommas(
                                        formRef.current?.values?.principal
                                      )}
                                    </h3>
                                  </div>
                                  <div className="calculator-details-frame">
                                    <p>
                                      {" "}
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        x="0"
                                        y="0"
                                        enableBackground="new 0 0 29.107 29.107"
                                        version="1.1"
                                        viewBox="0 0 29.107 29.107"
                                        xmlSpace="preserve"
                                        width="20"
                                        height="20"
                                        fill="#2F5CFF"
                                      >
                                        <path d="M14.554 0C6.561 0 0 6.562 0 14.552c0 7.996 6.561 14.555 14.554 14.555 7.996 0 14.553-6.559 14.553-14.555C29.106 6.562 22.55 0 14.554 0z"></path>
                                      </svg>
                                      <span>
                                        {t(
                                          "flat_vs_reduce_calculator.total_interest"
                                        )}
                                      </span>
                                    </p>
                                    <h3>
                                      {flatVsReducingRateCalculator.data?.flat_total_interest && formatNumberWithCommas(
                                        flatVsReducingRateCalculator.data?.flat_total_interest
                                      )}
                                    </h3>
                                  </div>
                                  <hr />
                                  <div className="calculator-details-frame top-bottom-line">
                                    <p className="d-inline">
                                      {t(
                                        "flat_vs_reduce_calculator.total_amount"
                                      )}
                                    </p>
                                    <h3>
                                      {flatVsReducingRateCalculator.data?.flat_emi && formatNumberWithCommas(
                                        flatVsReducingRateCalculator.data?.flat_total_amount
                                      )}
                                    </h3>
                                  </div>
                                  <hr />
                                  <div className="calculator-details-frame top-bottom-line">
                                    <p className="d-inline">
                                      {" "}
                                      {t(
                                        "flat_vs_reduce_calculator.monthly_emi"
                                      )}
                                    </p>
                                    <h3>
                                      {flatVsReducingRateCalculator.data?.flat_emi && formatNumberWithCommas(
                                        flatVsReducingRateCalculator.data?.flat_emi
                                      )}
                                    </h3>
                                  </div>
                                  <div className="calculator-details-batch">
                                    <div className="primary-badges">
                                      {t("flat_vs_reduce_calculator.save")}
                                      {flatVsReducingRateCalculator.data?.saved_amount && formatNumberWithCommas(
                                        flatVsReducingRateCalculator.data?.saved_amount
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </Tab.Pane>
                          <Tab.Pane eventKey="reducing">
                            {flatVsReducingRateCalculator.loading ? (
                              <div className="calculator-tabs-body">
                                <div className="pie-chart-skelton">
                                  <div className="skeleton-wrapper">
                                    <Skeleton
                                      borderRadius={130}
                                      width={230}
                                      height={230}
                                      baseColor="#202020"
                                      highlightColor="#444"
                                    />
                                  </div>
                                  <div className="mt-4">
                                    <Skeleton
                                      width={250}
                                      height={15}
                                      baseColor="#202020"
                                      highlightColor="#444"
                                    />
                                  </div>
                                </div>
                                <div className="calculator-info-details-card">
                                  <div className="calculator-info-details-card-title">
                                    <Skeleton
                                      width={175}
                                      height={20}
                                      baseColor="#202020"
                                      highlightColor="#444"
                                    />
                                  </div>
                                  <div className="calculator-details-frame">
                                    <Skeleton
                                      width={200}
                                      height={20}
                                      baseColor="#202020"
                                      highlightColor="#444"
                                    />
                                    <Skeleton
                                      width={130}
                                      height={20}
                                      baseColor="#202020"
                                      highlightColor="#444"
                                    />
                                  </div>
                                  <div className="calculator-details-frame">
                                    <Skeleton
                                      width={200}
                                      height={20}
                                      baseColor="#202020"
                                      highlightColor="#444"
                                    />
                                    <Skeleton
                                      width={130}
                                      height={20}
                                      baseColor="#202020"
                                      highlightColor="#444"
                                    />
                                  </div>
                                  <hr />
                                  <div className="calculator-details-frame top-bottom-line">
                                    <Skeleton
                                      width={200}
                                      height={20}
                                      baseColor="#202020"
                                      highlightColor="#444"
                                    />
                                    <Skeleton
                                      width={130}
                                      height={20}
                                      baseColor="#202020"
                                      highlightColor="#444"
                                    />
                                  </div>
                                  <hr />
                                  <div className="calculator-details-frame top-bottom-line">
                                    <Skeleton
                                      width={200}
                                      height={20}
                                      baseColor="#202020"
                                      highlightColor="#444"
                                    />
                                    <Skeleton
                                      width={130}
                                      height={20}
                                      baseColor="#202020"
                                      highlightColor="#444"
                                    />
                                  </div>
                                  <div className="calculator-details-batch">
                                    <Skeleton
                                      width={130}
                                      height={50}
                                      borderRadius={30}
                                      baseColor="#202020"
                                      highlightColor="#444"
                                    />
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div className="calculator-tabs-body">
                                <div className="pie-chart-sec">
                                  <PieChart
                                    name={[
                                      t(
                                        "flat_vs_reduce_calculator.principle_amount"
                                      ),
                                      t(
                                        "flat_vs_reduce_calculator.total_interest"
                                      ),
                                    ]}
                                    data={[
                                      formRef.current?.values.principal,
                                      flatVsReducingRateCalculator.data?.reducing_total_interest,
                                    ]}
                                  />
                                </div>
                                <div className="calculator-info-details-card">
                                  <div className="calculator-info-details-card-title">
                                    <h3>
                                      {t(
                                        "flat_vs_reduce_calculator.reducing_balance_interest"
                                      )}{" "}
                                    </h3>
                                  </div>
                                  <div className="calculator-details-frame">
                                    <p>
                                      {" "}
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        x="0"
                                        y="0"
                                        enableBackground="new 0 0 29.107 29.107"
                                        version="1.1"
                                        viewBox="0 0 29.107 29.107"
                                        xmlSpace="preserve"
                                        width="20"
                                        height="20"
                                        fill="#2F5CFF"
                                      >
                                        <path d="M14.554 0C6.561 0 0 6.562 0 14.552c0 7.996 6.561 14.555 14.554 14.555 7.996 0 14.553-6.559 14.553-14.555C29.106 6.562 22.55 0 14.554 0z"></path>
                                      </svg>
                                      <span>
                                        {" "}
                                        {t(
                                          "flat_vs_reduce_calculator.principle_amount"
                                        )}
                                      </span>
                                    </p>
                                    <h3>
                                      ₹{formRef.current?.values.principal}
                                    </h3>
                                  </div>
                                  <div className="calculator-details-frame">
                                    <p>
                                      {" "}
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        x="0"
                                        y="0"
                                        enableBackground="new 0 0 29.107 29.107"
                                        version="1.1"
                                        viewBox="0 0 29.107 29.107"
                                        xmlSpace="preserve"
                                        width="20"
                                        height="20"
                                        fill="#2A334F"
                                      >
                                        <path d="M14.554 0C6.561 0 0 6.562 0 14.552c0 7.996 6.561 14.555 14.554 14.555 7.996 0 14.553-6.559 14.553-14.555C29.106 6.562 22.55 0 14.554 0z"></path>
                                      </svg>
                                      <span>
                                        {t(
                                          "flat_vs_reduce_calculator.total_interest"
                                        )}
                                      </span>
                                    </p>
                                    <h3>
                                      ₹{" "}
                                      {
                                        flatVsReducingRateCalculator.data?.reducing_total_interest
                                      }
                                    </h3>
                                  </div>
                                  <hr />
                                  <div className="calculator-details-frame top-bottom-line">
                                    <p className="d-inline">
                                      {" "}
                                      {t(
                                        "flat_vs_reduce_calculator.total_amount"
                                      )}
                                    </p>
                                    <h3>
                                      ₹
                                      {
                                        flatVsReducingRateCalculator.data?.reducing_total_amount
                                      }
                                    </h3>
                                  </div>
                                  <hr />
                                  <div className="calculator-details-frame top-bottom-line">
                                    <p className="d-inline">
                                      {t(
                                        "flat_vs_reduce_calculator.monthly_emi"
                                      )}{" "}
                                    </p>
                                    <h3>
                                      ₹
                                      {
                                        flatVsReducingRateCalculator.data?.reducing_emi
                                      }
                                    </h3>
                                  </div>
                                </div>
                              </div>
                            )}
                          </Tab.Pane>
                        </Tab.Content>
                      </Tab.Container>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <Container>
          <Row>
            <Col lg={12}>
              <div className="static-page-card">
                <div className="pricacy-policy-info">
                  <h4>Description</h4>
                  <p>
                    Interest is the additional amount paid to the lender, on top
                    of the principal amount of the loan. Banks generally
                    calculate loan interest rates in a similar fashion, often
                    expressed as an Annual Percentage Rate (APR), which is a
                    percentage of the loan amount calculated annually. Each
                    installment of an Equated Monthly Installment (EMI)
                    typically includes a portion that goes toward repaying the
                    principal and another portion that goes toward paying the
                    interest. In most loan repayment schedules, the interest
                    component is higher in the initial EMIs and decreases over
                    time.
                  </p>
                  <p>
                    Conversely, the principal component of the EMI is smaller at
                    the beginning of the loan repayment period and increases as
                    the loan term progresses. There are various methods for
                    calculating interest rates, and the choice of method can
                    affect the interest rate applicable to a personal loan.
                  </p>
                  <p>
                    The primary difference in interest calculation methods lies
                    in whether the interest is calculated on the initial
                    principal amount or the outstanding balance. This leads to
                    two different types of interest calculations: the reducing
                    balance interest rate and the flat interest rate.
                  </p>
                </div>
                <div className="pricacy-policy-info">
                  <h4>Flat Interest Rate</h4>
                  <p>
                    A flat interest rate refers to a rate that remains constant
                    throughout the duration of the loan. Here, the interest is
                    calculated on the entire loan amount initially when the loan
                    tenure begins. The financial institution sets the repayment
                    schedule and determines the fixed monthly installments
                    (EMIs) that the borrower needs to pay. This method keeps the
                    total repayment obligation consistent, aiding borrowers in
                    financial planning since flat interest rates are typically
                    higher compared to reducing rates.
                  </p>

                  <h4>Reducing Interest Rate</h4>
                  <p>
                    With a reduced interest rate, the rate applied to a personal
                    loan is calculated on the remaining principal amount at the
                    end of each period. As mentioned earlier, each EMI payment
                    reduces the principal amount, and a portion goes towards
                    interest. For subsequent interest calculations, the reduced
                    outstanding principal is used, not the original loan amount.
                    This approach generally results in lower total interest
                    payments over the life of the loan compared to flat rates.
                  </p>
                  <h4>Flat vs Reducing Rate Calculator</h4>
                  <p>
                    The Flat vs Reducing Rate Calculator is a tool designed to
                    help you compare the two methods of interest calculation. It
                    enables you to determine which approach is more beneficial
                    for your financial situation. Additionally, this calculator
                    shows the difference in the total interest paid on your EMIs
                    between the two options, assisting you in making an informed
                    decision about your loan repayment strategy.
                  </p>
                  <h4>Advantages of the Flat vs Reducing Rate Calculator</h4>
                  <ul>
                    <li>
                      <h4>Accessibility: </h4>Available online, the TaxTrax Flat
                      Rate vs Reducing Rate Calculator can be used from anywhere
                      at any time.
                    </li>
                    <li>
                      <h4>Speed: </h4>Quickly calculates and displays the
                      interest you will pay on your loans.
                    </li>
                    <li>
                      <h4>Efficiency: </h4>Saves time and energy that would
                      otherwise be spent on manual calculations.
                    </li>
                    <li>
                      <h4>Informative: </h4>Helps assess the total cost of your
                      loan, providing clarity on financial commitments.
                    </li>
                    <li>
                      <h4>Simplicity:</h4>Easy to use for planning and managing
                      loans; requires only basic loan details to operate.
                    </li>
                    <li>
                      <h4>Comparative Analysis:</h4>Enables comparison between
                      the two most common interest calculation methods, showing
                      differences in interest costs to help you choose the best
                      option.
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <FAQIndex
          data={[
            {
              question: "What is the advantage of a flat rate?",
              answer:
                "The main advantage of a flat rate is its simplicity and predictability. The interest rate remains constant throughout the loan term, making it easier for borrowers to budget and plan their finances since the repayment amount does not change.",
            },
            {
              question: "What does 'principal' mean in the context of a loan?",
              answer:
                "The principal is the original sum of money borrowed in a loan. It is the base amount on which interest is calculated during the loan repayment period.",
            },
            {
              question: "How is interest calculated at a flat rate?",
              answer:
                "In a flat rate method, interest is calculated on the entire principal amount of the loan throughout its duration. It does not decrease as you pay down the principal, so the total interest amount is divided equally across all the EMIs.",
            },
            {
              question: "Which interest calculation method is easier?",
              answer:
                "The flat rate method is generally easier to calculate because the interest is consistently based on the original loan amount rather than on a decreasing balance, as is the case with the reducing balance method.",
            },
            {
              question: "How can I determine my EMI before taking out a loan?",
              answer:
                "You can determine your EMI before taking a loan by using an EMI calculator. These tools require you to input the loan amount, the interest rate, and the loan term, and they will calculate the monthly payments you would need to make. This helps you assess the affordability of the loan in advance.",
            },
          ]}
        />
      </div>
    </>
  );
};

export default CalculatorIndex;
