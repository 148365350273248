import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Container, Button, Form, Accordion } from "react-bootstrap";
import CustomLazyLoad from "../../../Helper/CustomLazyLoad";
import Skeleton from "react-loading-skeleton";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-multi-lang";
import { useDispatch, useSelector } from "react-redux";
import {
  ssyCalculatorStart,
  ssyCalculatorSuccess,
} from "../../../../store/slices/CommonSlice";
import { ButtonLoader } from "../../../Helper/Loader";
import PieChart from "../PieChart";
import FAQIndex from "../FAQIndex";
import { Link } from "react-router-dom";
import formatNumberWithCommas from "../../../FormatNumber";

const CalculatorIndex = () => {
  const t = useTranslation("tax_calculator");
  const investmentRef = useRef(null);
  const ageRef = useRef(null);
  const startPeriodRef = useRef(null);
  const dispatch = useDispatch();
  const formRef = useRef();
  const ssyCalculator = useSelector((state) => state.common.ssyCalculator);

  useEffect(() => {
    const onChange = (event, field) => {
      const customEvent = event;
      switch (field) {
        case "investment":
          formRef.current.setFieldValue(
            "yearly_investment",
            customEvent.detail.value
          );
          break;
        case "age":
          formRef.current.setFieldValue("girl_age", customEvent.detail.value);
          break;
        case "startPeriod":
          formRef.current.setFieldValue(
            "start_period",
            customEvent.detail.value
          );
          break;
      }
      setTimeout(() => {
        formRef.current.submitForm();
      }, 1);
    };
    investmentRef.current?.addEventListener("change", (event) =>
      onChange(event, "investment")
    );
    ageRef.current?.addEventListener("change", (event) =>
      onChange(event, "age")
    );
    startPeriodRef.current?.addEventListener("change", (event) =>
      onChange(event, "startPeriod")
    );
    return () => {
      investmentRef.current?.removeEventListener("change", onChange);
      ageRef.current?.removeEventListener("change", onChange);
      startPeriodRef.current?.removeEventListener("change", onChange);
    };
  }, []);

  useEffect(() => {
    calculate(formRef.current.values);
    // dispatch(ssyCalculatorStart(formRef.current.values));
  }, []);

  const onChangeInput = (event, field) => {
    const value = !isNaN(parseInt(event.target.value))
      ? parseInt(event.target.value)
      : "";
    switch (field) {
      case "investment":
        formRef.current.setFieldValue("yearly_investment", value);
        investmentRef.current.value = value;
        break;
      case "age":
        formRef.current.setFieldValue("girl_age", value);
        ageRef.current.value = value;
        break;
      case "startPeriod":
        formRef.current.setFieldValue("start_period", value);
        startPeriodRef.current.value = value;
        break;
    }
    setTimeout(() => {
      formRef.current.submitForm();
    }, 1);
  };

  const handleSubmit = (values) => {
    formRef.current.isValid && calculate(values);
    // dispatch(ssyCalculatorStart(values));
  };

  const ssySchema = Yup.object().shape({
    yearly_investment: Yup.string()
      .test("min", t("ssy_calculator.invalid_min", { value: 250 }), (value) => {
        return value >= 250;
      })
      .test(
        "max",
        t("ssy_calculator.invalid_max", {
          value: 150000,
          name: "Yearly investment",
        }),
        (value) => {
          return value <= 150000;
        }
      )
      .required(t("ssy_calculator.required")),
    girl_age: Yup.string()
      .test("min", t("ssy_calculator.invalid_min", { value: 1 }), (value) => {
        return value >= 1;
      })
      .test(
        "max",
        t("ssy_calculator.invalid_max", {
          value: 10,
          name: "Girl's age",
        }),
        (value) => {
          return value <= 10;
        }
      )
      .required(t("ssy_calculator.required")),
    start_period: Yup.string()
      .test(
        "min",
        t("ssy_calculator.invalid_min", { value: 2018 }),
        (value) => {
          return value >= 2018;
        }
      )
      .test(
        "max",
        t("ssy_calculator.invalid_max", { value: 2030, name: "Time period" }),
        (value) => {
          return value <= 2030;
        }
      )
      .required(t("ssy_calculator.required")),
  });

  const calculate = (values) => {
    const {
      yearly_investment,
      girl_age,
      start_period,
      compounding_frequency,
      ssy_interest_rate,
    } = values;
    const investmentPeriod = 15;
    const totalPeriod = 21;
    const totalInvestment = yearly_investment * investmentPeriod;
    const maturityYear = start_period + totalPeriod;
    const expectedRateOfReturn = ssy_interest_rate;

    let primaryMaturityAmount = 0;
    for (let i = 0; i < investmentPeriod; i++) {
      const totalCorpusForYear =
        (primaryMaturityAmount + yearly_investment) *
        (1 + expectedRateOfReturn / 100);
      primaryMaturityAmount = totalCorpusForYear;
    }
    for (let j = 0; j < 6; j++) {
      primaryMaturityAmount =
        primaryMaturityAmount +
        primaryMaturityAmount * (expectedRateOfReturn / 100);
    }

    const totalInterest = primaryMaturityAmount - totalInvestment;

    const roundedTotalInvestment = Math.round(totalInvestment);
    const roundedPrimaryMaturityAmount = Math.round(primaryMaturityAmount);
    const roundedTotalInterest = Math.round(totalInterest);

    return dispatch(
      ssyCalculatorSuccess({
        total_investment: roundedTotalInvestment,
        maturity_amount: roundedPrimaryMaturityAmount,
        interest_earned: roundedTotalInterest,
        maturity_year: maturityYear,
      })
    );
  };

  return (
    <>
      <div className="single-tax-calculator">
        <div className="sm-padding single-tax-calculator-sec">
          <Container>
            <div className="single-tax-calculator-box">
              <div className="single-tax-calculator-header">
                <Row>
                  <Col md={6}>
                    <div className="d-flex other-calculator-btn-sec">
                      <Link
                        to="/tax-calculator"
                        className="other-calculator-btn"
                      >
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            enableBackground="new 0 0 512 512"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="#fff"
                              d="M10.957 12.354a.5.5 0 010-.708l4.586-4.585a1.5 1.5 0 00-2.121-2.122L8.836 9.525a3.505 3.505 0 000 4.95l4.586 4.586a1.5 1.5 0 002.121-2.122z"
                              data-original="#000000"
                            ></path>
                          </svg>
                        </span>
                        <span>Other Calculators</span>
                      </Link>
                    </div>
                    <div className="single-tax-calculator-info-sec">
                      <h1>{t("ssy_calculator.heading")}</h1>
                      <p>{t("ssy_calculator.content")}</p>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="single-tax-calculator-header-img-sec">
                      <CustomLazyLoad
                        className="single-tax-calculator-header-img"
                        src={
                          window.location.origin +
                          "/img/single-tax-calculator-header-img.png"
                        }
                        type="image/svg"
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="single-tax-calculator-form-box  line-stright calculator-space-md">
              <Row className="align-items-center g-5">
                <Col md={6} lg={6}>
                  <Formik
                    initialValues={{
                      yearly_investment: 25000,
                      girl_age: 5,
                      start_period: 2021,
                      compounding_frequency: 1, //value set as static
                      ssy_interest_rate: 8.2, // value get from api, but now  as static
                    }}
                    innerRef={formRef}
                    validationSchema={ssySchema}
                    onSubmit={handleSubmit}
                  >
                    {({
                      setFieldValue,
                      values,
                      resetForm,
                      submitForm,
                      isValid,
                    }) => (
                      <FORM className="single-tax-calculator-form">
                        <div className="single-tax-calculator-form-card">
                          <div className="single-tax-calculator-form-header-sec">
                            <h4>{t("ssy_calculator.investment.text")}</h4>
                            <Form.Group
                              className="mb-0"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Field
                                className="form-control"
                                name="yearly_investment"
                                placeholder={t(
                                  "ssy_calculator.investment.placeholder"
                                )}
                                type="number"
                                onKeyDown={(e) => {
                                  if (
                                    !/[0-9]|Backspace|ArrowLeft|ArrowRight|Delete|ArrowUp|ArrowDown|Tab/.test(
                                      e.key
                                    ) &&
                                    !(
                                      e.ctrlKey &&
                                      (e.key === "v" ||
                                        e.key === "c" ||
                                        e.key === "x" ||
                                        e.key === "a")
                                    )
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                                onInput={(e) => {
                                  if (e.target.value < 0) {
                                    e.target.value = 0;
                                  }
                                  onChangeInput(e, "investment");
                                }}
                              />
                            </Form.Group>
                          </div>
                          <ErrorMessage
                            component={"div"}
                            name="yearly_investment"
                            className="errorMsg"
                          />
                          <div className="tax-calculator-input-slider-sec">
                            <tc-range-slider
                              ref={investmentRef}
                              value={investmentRef}
                              mousewheel-disabled="true"
                              min={250}
                              max={150000}
                              slider-width="100%"
                              slider-bg-fill="#2F5CFF"
                              generate-labels="true"
                              round="0"
                            ></tc-range-slider>
                          </div>
                        </div>
                        <div className="single-tax-calculator-form-card">
                          <div className="single-tax-calculator-form-header-sec">
                            <h4>{t("ssy_calculator.age.text")}</h4>
                            <Form.Group
                              className="mb-0"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Field
                                className="form-control"
                                name="girl_age"
                                placeholder={t(
                                  "ssy_calculator.age.placeholder"
                                )}
                                type="number"
                                onKeyDown={(e) => {
                                  if (
                                    !/[0-9]|Backspace|ArrowLeft|ArrowRight|Delete|ArrowUp|ArrowDown|Tab/.test(
                                      e.key
                                    ) &&
                                    !(
                                      e.ctrlKey &&
                                      (e.key === "v" ||
                                        e.key === "c" ||
                                        e.key === "x" ||
                                        e.key === "a")
                                    )
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                                onInput={(e) => {
                                  if (e.target.value < 0) {
                                    e.target.value = 0;
                                  }
                                  onChangeInput(e, "age");
                                }}
                              />
                            </Form.Group>
                          </div>
                          <ErrorMessage
                            component={"div"}
                            name="girl_age"
                            className="errorMsg"
                          />
                          <div className="tax-calculator-input-slider-sec">
                            <tc-range-slider
                              ref={ageRef}
                              value={ageRef}
                              mousewheel-disabled="true"
                              min={1}
                              max={10}
                              slider-width="100%"
                              slider-bg-fill="#2F5CFF"
                              generate-labels="true"
                              round="0"
                            ></tc-range-slider>
                          </div>
                        </div>
                        <div className="single-tax-calculator-form-card">
                          <div className="single-tax-calculator-form-header-sec">
                            <h4>{t("ssy_calculator.start_period.text")}</h4>
                            <Form.Group
                              className="mb-0"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Field
                                className="form-control"
                                name="start_period"
                                placeholder={t(
                                  "ssy_calculator.start_period.placeholder"
                                )}
                                type="number"
                                onKeyDown={(e) => {
                                  if (
                                    !/[0-9]|Backspace|ArrowLeft|ArrowRight|Delete|ArrowUp|ArrowDown|Tab/.test(
                                      e.key
                                    ) &&
                                    !(
                                      e.ctrlKey &&
                                      (e.key === "v" ||
                                        e.key === "c" ||
                                        e.key === "x" ||
                                        e.key === "a")
                                    )
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                                onInput={(e) => {
                                  if (e.target.value < 0) {
                                    e.target.value = 0;
                                  }
                                  onChangeInput(e, "startPeriod");
                                }}
                              />
                            </Form.Group>
                          </div>
                          <ErrorMessage
                            component={"div"}
                            name="start_period"
                            className="errorMsg"
                          />
                          <div className="tax-calculator-input-slider-sec">
                            <tc-range-slider
                              ref={startPeriodRef}
                              value={startPeriodRef}
                              mousewheel-disabled="true"
                              min={2018}
                              max={2040}
                              slider-width="100%"
                              slider-bg-fill="#2F5CFF"
                              generate-labels="true"
                              round="0"
                            ></tc-range-slider>
                          </div>
                        </div>
                        <div className="single-tax-calculator-form-card">
                          <div className="single-tax-calculator-form-header-sec">
                            <h4>{t("ssy_calculator.interest_rate.text")}</h4>
                            <h4>
                              {formRef.current?.values.ssy_interest_rate}%
                            </h4>
                          </div>
                        </div>
                        {/* <div className="d-flex justify-content-end mt-3">
                          <Button
                            type="submit"
                            className="default-primary-btn"
                            disabled={ssyCalculator.buttonDisable}
                          >
                            {ssyCalculator.buttonDisable ? (
                              <ButtonLoader />
                            ) : (
                              t("ssy_calculator.calculate")
                            )}
                          </Button>
                        </div> */}
                      </FORM>
                    )}
                  </Formik>
                </Col>
                <Col md={6} lg={6}>
                  {ssyCalculator.loading ? (
                    <div className="single-calculator-info-frame">
                      <div className="pie-chart-skelton">
                        <div className="skeleton-wrapper">
                          <Skeleton
                            borderRadius={130}
                            width={230}
                            height={230}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                        </div>
                        <div className="mt-4">
                          <Skeleton
                            width={250}
                            height={15}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                        </div>
                      </div>

                      <div className="calculator-info-details-card">
                        <div className="calculator-details-frame">
                          <Skeleton
                            width={200}
                            height={20}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                          <Skeleton
                            width={130}
                            height={20}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                        </div>
                        <div className="calculator-details-frame">
                          <Skeleton
                            width={200}
                            height={20}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                          <Skeleton
                            width={130}
                            height={20}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                        </div>
                        <hr />
                        <div className="calculator-details-frame top-bottom-line">
                          <Skeleton
                            width={200}
                            height={20}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                          <Skeleton
                            width={130}
                            height={20}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                        </div>
                        <hr />
                      </div>
                    </div>
                  ) : (
                    Object.keys(ssyCalculator.data).length > 0 && (
                      <div className="single-calculator-info-frame">
                        <div className="pie-chart-sec">
                          <PieChart
                            name={[
                              t("ssy_calculator.total_investment"),
                              t("ssy_calculator.total_interest"),
                            ]}
                            data={[
                              ssyCalculator.data.total_investment,
                              ssyCalculator.data.interest_earned,
                            ]}
                          />
                        </div>
                        <div className="calculator-info-details-card">
                          <div className="calculator-details-frame">
                            <p>
                              {" "}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                x="0"
                                y="0"
                                enableBackground="new 0 0 29.107 29.107"
                                version="1.1"
                                viewBox="0 0 29.107 29.107"
                                xmlSpace="preserve"
                                width="20"
                                height="20"
                                fill="#2A334F"
                              >
                                <path d="M14.554 0C6.561 0 0 6.562 0 14.552c0 7.996 6.561 14.555 14.554 14.555 7.996 0 14.553-6.559 14.553-14.555C29.106 6.562 22.55 0 14.554 0z"></path>
                              </svg>
                              <span>
                                {t("ssy_calculator.total_investment")}
                              </span>
                            </p>
                            <h3>
                              {formatNumberWithCommas(
                                ssyCalculator.data.total_investment
                              )}
                            </h3>
                          </div>
                          <div className="calculator-details-frame">
                            <p>
                              {" "}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                x="0"
                                y="0"
                                enableBackground="new 0 0 29.107 29.107"
                                version="1.1"
                                viewBox="0 0 29.107 29.107"
                                xmlSpace="preserve"
                                width="20"
                                height="20"
                                fill="#2F5CFF"
                              >
                                <path d="M14.554 0C6.561 0 0 6.562 0 14.552c0 7.996 6.561 14.555 14.554 14.555 7.996 0 14.553-6.559 14.553-14.555C29.106 6.562 22.55 0 14.554 0z"></path>
                              </svg>
                              <span>{t("ssy_calculator.total_interest")}</span>
                            </p>
                            <h3>
                              {formatNumberWithCommas(
                                ssyCalculator.data.interest_earned
                              )}
                            </h3>
                          </div>
                          <hr />
                          <div className="calculator-details-frame top-bottom-line">
                            <p className="d-inline">
                              {t("ssy_calculator.maturity_year")}
                            </p>
                            <h3>{ssyCalculator.data.maturity_year}</h3>
                          </div>
                          <hr />
                          <div className="calculator-details-frame top-bottom-line">
                            <p className="d-inline">
                              {t("ssy_calculator.maturity_value")}
                            </p>
                            <h3>
                              {formatNumberWithCommas(
                                ssyCalculator.data.maturity_amount
                              )}
                            </h3>
                          </div>
                        </div>
                      </div>
                    )
                  )}
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <Container>
          <Row>
            <Col lg={12}>
              <div className="static-page-card">
                <div className="pricacy-policy-info">
                  <h4>Description</h4>
                  <p>
                    Introduced in 2015 under the Beti Bachao, Beti Padhao
                    campaign by the Government, the Sukanya Samriddhi Yojana
                    (SSY) is a savings initiative designed for the benefit of
                    girl children. It allows guardians to set up a savings
                    account for their female child at any recognized commercial
                    bank or India Post office.
                  </p>
                  <p>
                    The SSY accounts provide an interest rate of 8.2%. Utilizing
                    a Sukanya Samriddhi Yojana calculator can aid in estimating
                    the returns based on the amount invested and the duration of
                    the investment.
                  </p>
                </div>
                <div className="pricacy-policy-info">
                  <h4>Benefits of Using an SSY Calculator</h4>
                  <ul>
                    <li>
                      Parents seeking to invest in their daughter's future,
                      particularly for educational and marital expenses, often
                      explore various investment options. The Sukanya Samriddhi
                      Yojana stands out as a preferred choice due to its
                      attractive interest rates and tax advantages.
                      Contributions to an SSY account are tax-deductible up to
                      Rs 1.5 Lakh under Section 80C of the Income Tax Act, 1961,
                      with both the interest earned and the maturity amount
                      being tax-free.
                    </li>
                    <li>
                      For parents opting for Sukanya Samriddhi as their
                      investment avenue, manually calculating the maturity
                      amount can be challenging and error-prone. The Sukanya
                      Samriddhi Calculator simplifies this process, offering
                      precise calculations for the maturity amount, which helps
                      in planning and adjusting regular contributions to meet
                      the desired financial goal. The calculator is
                      user-friendly, provides accurate results for various
                      scenarios, and is freely accessible.
                    </li>
                    <li>
                      Sukanya Samriddhi Yojana is a long-term commitment that
                      requires a minimum annual contribution to maintain account
                      activity and achieve a substantial return on investment.
                    </li>
                    <li>
                      Therefore, an online Sukanya Samriddhi Yojana calculator
                      is an invaluable tool for gauging the overall impact of
                      your investments and the potential returns, facilitating
                      informed decision-making and financial planning for your
                      daughter's future.
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <FAQIndex
          data={[
            {
              question:
                "Who qualifies for opening a Sukanya Samriddhi Yojana Account?",
              answer:
                "A Sukanya Samriddhi Yojana Account can be opened by the guardians for a girl child who is below the age of 10 years.",
            },
            {
              question:
                "How many SSY accounts are permitted per eligible child?",
              answer:
                "For each eligible girl child, only one SSY account can be opened. A family can open a maximum of two accounts for two different girl children.",
            },
            {
              question:
                "What is the initial investment needed to start an SSY account?",
              answer:
                "The Sukanya Samriddhi Yojana Account can be initiated with a minimum deposit of Rs 250.",
            },
            {
              question:
                "What are the consequences of not making annual contributions?",
              answer:
                "If no deposits are made in a year, the SSY account becomes inactive but can be reactivated by paying a penalty along with the minimum annual deposit.",
            },
            {
              question: "What's the annual cap on deposits for an SSY account?",
              answer:
                "The maximum annual contribution limit for an SSY account is Rs 1.5 lakh.",
            },
            {
              question: "When does an SSY account reach maturity?",
              answer:
                "An SSY account matures 21 years from the date of opening or upon the girl child's marriage after she turns 18, whichever is earlier.",
            },
            {
              question:
                "Are there tax advantages associated with an SSY account?",
              answer:
                "Yes, investments in a Sukanya Samriddhi Yojana account are eligible for tax deductions under Section 80C of the Income Tax Act, and the interest and maturity amounts are tax-free.",
            },
            {
              question: "Is premature withdrawal allowed from an SSY account?",
              answer:
                "Premature withdrawal is permissible for the account holder's higher education expenses after reaching the age of 18 or completing the 10th grade, subject to certain conditions.",
            },
            {
              question:
                "Can an SSY account be closed before its maturity term?",
              answer:
                "Early closure of an SSY account is permitted under specific circumstances, such as severe illness or the unfortunate demise of the account holder.",
            },
          ]}
        />
      </div>
    </>
  );
};

export default CalculatorIndex;
