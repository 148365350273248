import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import CustomLazyLoad from "../Helper/CustomLazyLoad";
import { Link } from "react-router-dom";

const TaxSolutionIndex = (props) => {
  return (
    <>
      <div className="tax-solution-sec sm-padding">
        <Container>
          <Row className="justify-content-center">
            <Col lg={8} md={8} className="text-center">
              <div className="section-title">
                <h2>Your Definitive Tax Assistant</h2>
                <p>
                  TaxTrax is revolutionizing the way you tackle taxes, providing
                  personalized assistance for individuals, dedicated support for
                  tax professionals, and strategic solutions for businesses.
                  Step into a world of effortless and accurate tax filing with
                  our AI-driven platform.
                </p>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col
              md={6}
              sm={12}
              xl={4}
              lg={6}
              className="resp-mrg-btm-md resp-mrg-btm-xl"
            >
              <div className="tax-solution-card">
                <div className="tax-solution-img-sec">
                  <CustomLazyLoad
                    className="tax-solution-img"
                    src={
                      window.location.origin +
                      "/img/tax-solution/tax-solution-1.png"
                    }
                    type="image/svg"
                  />
                </div>
                <div className="tax-solution-info-sec">
                  <h4>For Individual Taxpayers</h4>
                  <p>
                    Navigate your personal taxes with the confidence of having
                    TaxTrax by your side. Our service isn’t just about
                    answers—it’s about providing peace of mind. From uncovering
                    potential refunds to explaining the latest tax changes,
                    we're here to support your financial health every day of the
                    year.
                  </p>
                  {/* <div className="tax-solution-link-sec">
                    <Link to="#" className="read-more-link">
                      <span>Read More</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="13"
                        height="13"
                        fill="none"
                        viewBox="0 0 13 11"
                      >
                        <path
                          fill="#2F5CFF"
                          d="M.165.914A.507.507 0 01.057.32.544.544 0 01.187.148a.624.624 0 01.825.022L5.97 5.045c.103.1.16.233.16.372a.521.521 0 01-.16.372l-4.958 4.875a.62.62 0 01-.83.027.544.544 0 01-.133-.173.51.51 0 01.115-.597l4.58-4.504L.165.914zM6.692.914a.543.543 0 01.2-.878.542.542 0 01.588.134l4.604 4.875a.542.542 0 010 .744L7.48 10.664a.542.542 0 11-.788-.743l4.253-4.504L6.692.914z"
                        ></path>
                      </svg>
                    </Link>
                  </div> */}
                </div>
              </div>
            </Col>
            <Col
              md={6}
              sm={12}
              xl={4}
              lg={6}
              className="resp-mrg-btm-md resp-mrg-btm-xl"
            >
              <div className="tax-solution-card">
                <div className="tax-solution-img-sec">
                  <CustomLazyLoad
                    className="tax-solution-img"
                    src={
                      window.location.origin +
                      "/img/tax-solution/tax-solution-2.png"
                    }
                    type="image/svg"
                  />
                </div>
                <div className="tax-solution-info-sec">
                  <h4>For Tax Experts</h4>
                  <p>
                    TaxTrax is your partner in professional growth, offering an
                    extensive database of tax knowledge at your fingertips.
                    Augment your expertise with our advanced tools and stay
                    updated with real-time legislative changes, so you can offer
                    your clients the most informed and strategic advice
                    possible.
                  </p>
                  {/* <div className="tax-solution-link-sec">
                    <Link to="#" className="read-more-link">
                      <span>Read More</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="13"
                        height="13"
                        fill="none"
                        viewBox="0 0 13 11"
                      >
                        <path
                          fill="#2F5CFF"
                          d="M.165.914A.507.507 0 01.057.32.544.544 0 01.187.148a.624.624 0 01.825.022L5.97 5.045c.103.1.16.233.16.372a.521.521 0 01-.16.372l-4.958 4.875a.62.62 0 01-.83.027.544.544 0 01-.133-.173.51.51 0 01.115-.597l4.58-4.504L.165.914zM6.692.914a.543.543 0 01.2-.878.542.542 0 01.588.134l4.604 4.875a.542.542 0 010 .744L7.48 10.664a.542.542 0 11-.788-.743l4.253-4.504L6.692.914z"
                        ></path>
                      </svg>
                    </Link>
                  </div> */}
                </div>
              </div>
            </Col>
            <Col
              md={6}
              sm={12}
              xl={4}
              lg={6}
              className="resp-mrg-btm-md resp-mrg-btm-xl"
            >
              <div className="tax-solution-card">
                <div className="tax-solution-img-sec">
                  <CustomLazyLoad
                    className="tax-solution-img"
                    src={
                      window.location.origin +
                      "/img/tax-solution/tax-solution-3.png"
                    }
                    type="image/svg"
                  />
                </div>
                <div className="tax-solution-info-sec">
                  <h4>For Enterprises</h4>
                  <p>
                    TaxTrax equips your business with strategic tax insights
                    around the clock. Experience tailored, comprehensive support
                    that aligns with your company's unique tax requirements Your
                    business deserves a tax service that understands the
                    complexity and scale of corporate finance.
                  </p>
                  {/* <div className="tax-solution-link-sec">
                    <Link to="#" className="read-more-link">
                      <span>Read More</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="13"
                        height="13"
                        fill="none"
                        viewBox="0 0 13 11"
                      >
                        <path
                          fill="#2F5CFF"
                          d="M.165.914A.507.507 0 01.057.32.544.544 0 01.187.148a.624.624 0 01.825.022L5.97 5.045c.103.1.16.233.16.372a.521.521 0 01-.16.372l-4.958 4.875a.62.62 0 01-.83.027.544.544 0 01-.133-.173.51.51 0 01.115-.597l4.58-4.504L.165.914zM6.692.914a.543.543 0 01.2-.878.542.542 0 01.588.134l4.604 4.875a.542.542 0 010 .744L7.48 10.664a.542.542 0 11-.788-.743l4.253-4.504L6.692.914z"
                        ></path>
                      </svg>
                    </Link>
                  </div> */}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default TaxSolutionIndex;
