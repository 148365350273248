import React, { useEffect, useRef, useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import CustomLazyLoad from "../Helper/CustomLazyLoad";
import { Link } from "react-router-dom";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-multi-lang";
import { useDispatch, useSelector } from "react-redux";
import PhoneInput, {
  formatPhoneNumberIntl,
  isValidPhoneNumber,
} from "react-phone-number-input";
import { contactFormStart } from "../../store/slices/ContactSlice";

const ContactIndex = () => {
  const t = useTranslation("home.contact");
  const dispatch = useDispatch();
  const formRef = useRef();
  const contactForm = useSelector((state) => state.contact.contactForm);
  const [formSubmit, setFormSubmit] = useState(false);
  const [skipRender, setSkipRender] = useState(true);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t("name.required")).trim().notOneOf([""]),
    email: Yup.string().email(t("email.invalid")).required(t("email.required")),
    mobile: Yup.string()
      .required(t("mobile.required"))
      .test("valid-mobile", t("mobile.invalid"), (value) => {
        if (!value) {
          return false;
        }
        return isValidPhoneNumber(value);
      })
      .matches(/^(?=.*[0-9])/, t("phone.space_values_not_valid")),
    message: Yup.string().required(t("message.required")).trim().notOneOf([""]),
  });

  const handleSubmit = (values) => {
    dispatch(contactFormStart(values));
  };

  useEffect(() => {
    if (
      !skipRender &&
      !contactForm.loading &&
      Object.keys(contactForm.data).length > 0
    ) {
      setFormSubmit(true);
    }
    setSkipRender(false);
  }, [contactForm]);

  return (
    <>
      <div className="contact-us-sec sm-padding pt-0">
        <Container>
          <div className="contact-us-card">
            <Row className="justify-content-center align-items-center">
              {/* <Col lg={6} md={12} className="resp-margin-btm-xs">
                <div className="contact-us-info">
                  <p>Certainly! Creating content for a landing page
                    about tax GPT (Generative Pre-trained Transformer) requires a mix of clarity, professionalism, and a focus
                    on the benefits of your product or service.</p>
                  <p>Certainly! Creating content for a landing page
                    about tax GPT (Generative Pre-trained Transformer) requires a mix of clarity, professionalism, and a focus
                    on the benefits of your product or service.</p>
                  <p>Certainly! Creating content for a landing page
                    about tax GPT (Generative Pre-trained Transformer) requires a mix of clarity, professionalism, and a focus.</p>
                </div>
                <div className="footer-widget">
                  <h3>Connect Here</h3>
                  <ul className="list-unstyled footer-bio-list">
                  <li>
                    <Link to="mailto:marketing@tax-trax.com">
                      <Image
                        className="footer-bio-icon"
                        src={window.location.origin + "/img/footer/email-icon.png"}
                        type="image/svg"
                      />
                      <span>marketing@tax-trax.com</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="tel:+919380348698">
                      <Image
                        className="footer-bio-icon"
                        src={window.location.origin + "/img/footer/phone-icon.png"}
                        type="image/svg"
                      />
                      <span>+919380348698</span>
                    </Link>
                  </li>
                  <li>
                    <Image
                      className="footer-bio-icon"
                      src={window.location.origin + "/img/footer/map-icon.png"}
                      type="image/svg"
                    />
                    <span>Novel Tech park, 46/4, Hosur Rd, Kudlu Gate, Krishna Reddy Industrial Area, H.S, R Extension, Bengaluru, Karnataka 560068</span>
                  </li>
                </ul>
                </div>
              </Col> */}
              <Col lg={6} md={12} className="resp-margin-btm-xs">
                <div className="contact-us-landing-img-sec">
                  <CustomLazyLoad
                    className="contact-us-landing-img"
                    src={window.location.origin + "/img/contact-us-img.png"}
                    type="image/svg"
                  />
                </div>
              </Col>
              <Col lg={6} md={12}>
                <div className="contact-us-form">
                  <div className="contact-form-info">
                    <h4>{t("get_in_touch")}</h4>
                    <p>{t("any_question")}</p>
                  </div>
                  {!formSubmit ? (
                    <Formik
                      initialValues={{
                        name: "",
                        email: "",
                        message: "",
                        mobile: "",
                      }}
                      onSubmit={handleSubmit}
                      validationSchema={validationSchema}
                      innerRef={formRef}
                    >
                      {({
                        values,
                        setFieldValue,
                        setFieldTouched,
                        errors,
                        touched,
                        isValid,
                      }) => (
                        <FORM className="exchange-form">
                          <Form.Group
                            controlId="formBasicName"
                            className="mb-3"
                          >
                            <Form.Label>
                              {t("name.label")} <span>*</span>
                            </Form.Label>
                            <Field
                              type="text"
                              className="form-control"
                              name="name"
                              placeholder={t("name.placeholder")}
                            />
                            <ErrorMessage
                              component={"div"}
                              name="name"
                              className="errorMsg"
                            />
                          </Form.Group>
                          <Form.Group
                            controlId="formBasicEmail"
                            className="mb-3"
                          >
                            <Form.Label>
                              {t("email.label")} <span>*</span>
                            </Form.Label>
                            <Field
                              type="email"
                              className="form-control"
                              name="email"
                              placeholder={t("email.placeholder")}
                            />
                            <ErrorMessage
                              component={"div"}
                              name="email"
                              className="errorMsg"
                            />
                          </Form.Group>
                          <Form.Group className="mb-3 register-phone-input">
                            <Form.Label htmlFor="mobile" className="form-label">
                              {t("mobile.label")} *
                            </Form.Label>
                            <PhoneInput
                              defaultCountry="IN"
                              placeholder={t("mobile_number.placeholder")}
                              value={values.mobile}
                              onChange={(value) =>
                                setFieldValue("mobile", value)
                              }
                              onBlur={() => formRef.current.submitForm()}
                              international
                              countryCallingCodeEditable={false}
                              className={`${
                                touched.mobile && errors.mobile
                                  ? "is-invalid"
                                  : ""
                              }`}
                            />
                            <ErrorMessage
                              component={"div"}
                              name="mobile"
                              className="errorMsg"
                            />
                          </Form.Group>
                          <Form.Group
                            controlId="formBasicSecondaryAddress"
                            className="mb-3"
                          >
                            <Form.Label>{t("message.label")} *</Form.Label>
                            <Field
                              as="textarea"
                              rows="2"
                              type="text"
                              className="form-control"
                              name="message"
                              placeholder="Enter your message"
                            />
                            <ErrorMessage
                              component={"div"}
                              name="message"
                              className="errorMsg"
                            />
                          </Form.Group>
                          <div className="default-btn-frame">
                            <Button
                              className="default-primary-btn"
                              type="submit"
                              disabled={contactForm.buttonDisable}
                            >
                              {t("submit")}
                            </Button>
                          </div>
                        </FORM>
                      )}
                    </Formik>
                  ) : (
                    <div className="contact-form-info">
                      <h4>{t("thanks_message")}</h4>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default ContactIndex;
