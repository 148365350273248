import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Container, Accordion, Form, Button } from "react-bootstrap";
import CustomLazyLoad from "../../../Helper/CustomLazyLoad";
import Skeleton from "react-loading-skeleton";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-multi-lang";
import { useDispatch, useSelector } from "react-redux";
import { cagrCalculatorSuccess } from "../../../../store/slices/CommonSlice";
import { ButtonLoader } from "../../../Helper/Loader";
import FAQIndex from "../FAQIndex";
import { Link } from "react-router-dom";

const CalculatorIndex = () => {
  const t = useTranslation("tax_calculator");
  const dispatch = useDispatch();
  const formRef = useRef();
  const initialRef = useRef(null);
  const finalRef = useRef(null);
  const timePeriodRef = useRef(null);
  const cagrCalculator = useSelector((state) => state.common.cagrCalculator);

  useEffect(() => {
    const onChange = (evt, field) => {
      const customEvent = evt;
      switch (field) {
        case "initial":
          formRef.current.setFieldValue(
            "present_value",
            customEvent.detail.value
          );
          break;
        case "final":
          formRef.current.setFieldValue(
            "future_value",
            customEvent.detail.value
          );
          break;
        case "timePeriod":
          formRef.current.setFieldValue(
            "time_period",
            customEvent.detail.value
          );
          break;
      }
      setTimeout(() => {
        formRef.current.submitForm();
      }, 1);
    };
    initialRef.current?.addEventListener("change", (evt) =>
      onChange(evt, "initial")
    );
    finalRef.current?.addEventListener("change", (evt) =>
      onChange(evt, "final")
    );
    timePeriodRef.current?.addEventListener("change", (evt) =>
      onChange(evt, "timePeriod")
    );
    return () => {
      initialRef.current?.removeEventListener("change", onChange);
      finalRef.current?.removeEventListener("change", onChange);
      timePeriodRef.current?.removeEventListener("change", onChange);
    };
  }, []);

  useEffect(() => {
    calculate(formRef.current.values);
  }, []);

  const onChangeInput = (event, field) => {
    const value = !isNaN(parseInt(event.target.value))
      ? parseInt(event.target.value)
      : "";
    switch (field) {
      case "initial":
        formRef.current.setFieldValue("present_value", value);
        initialRef.current.value = value;
        break;
      case "final":
        formRef.current.setFieldValue("future_value", value);
        finalRef.current.value = value;
        break;
      case "timePeriod":
        formRef.current.setFieldValue("time_period", value);
        timePeriodRef.current.value = value;
        break;
    }
    setTimeout(() => {
      formRef.current.submitForm();
    }, 1);
  };

  const handleSubmit = (values) => {
    formRef.current.isValid && calculate(values);
    //dispatch(cagrCalculatorStart(values));
  };

  const cagrSchema = Yup.object().shape({
    present_value: Yup.string()
      .test(
        "min",
        t("cagr_calculator.invalid_min", {
          value: 1000,
          name: "Initial Investment",
        }),
        (value) => {
          return value >= 1000;
        }
      )
      .test(
        "max",
        t("cagr_calculator.invalid_max", {
          value: 10000000,
          name: "Initial Investment",
        }),
        (value) => {
          return value <= 10000000;
        }
      )
      .required(t("cagr_calculator.required")),
    future_value: Yup.string()
      .test(
        "min",
        t("cagr_calculator.invalid_min", {
          value: 1000,
          name: "Final Investment",
        }),
        (value) => {
          return value >= 1000;
        }
      )
      .test(
        "max",
        t("cagr_calculator.invalid_max", {
          value: 10000000,
          name: "Final Investment",
        }),
        (value) => {
          return value <= 10000000;
        }
      )
      .required(t("cagr_calculator.required")),
    time_period: Yup.string()
      .test("min", t("cagr_calculator.invalid_min"), (value) => {
        return value >= 1;
      })
      .test(
        "max",
        t("cagr_calculator.invalid_max", {
          value: 40,
          name: "Duration of investment",
        }),
        (value) => {
          return value <= 40;
        }
      )
      .required(t("cagr_calculator.required")),
  });

  const calculate = (values) => {
    const { present_value, future_value, time_period } = values;
    let cagr = Math.pow(future_value / present_value, 1 / time_period) - 1;
    const newResult = {
      cagr: (cagr * 100).toFixed(2),
    };
    dispatch(cagrCalculatorSuccess(newResult));
  };

  return (
    <>
      <div className="single-tax-calculator">
        <div className="sm-padding single-tax-calculator-sec">
          <Container>
            <div className="single-tax-calculator-box">
              <div className="single-tax-calculator-header">
                <Row>
                  <Col md={6}>
                    <div className="d-flex other-calculator-btn-sec">
                      <Link
                        to="/tax-calculator"
                        className="other-calculator-btn"
                      >
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            enableBackground="new 0 0 512 512"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="#fff"
                              d="M10.957 12.354a.5.5 0 010-.708l4.586-4.585a1.5 1.5 0 00-2.121-2.122L8.836 9.525a3.505 3.505 0 000 4.95l4.586 4.586a1.5 1.5 0 002.121-2.122z"
                              data-original="#000000"
                            ></path>
                          </svg>
                        </span>
                        <span>Other Calculators</span>
                      </Link>
                    </div>
                    <div className="single-tax-calculator-info-sec">
                      <h1>CAGR Calculator</h1>
                      <p>The Simplest compound annual growth rate calculator</p>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="single-tax-calculator-header-img-sec">
                      <CustomLazyLoad
                        className="single-tax-calculator-header-img"
                        src={
                          window.location.origin +
                          "/img/single-tax-calculator-header-img.png"
                        }
                        type="image/svg"
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="single-tax-calculator-form-box  line-stright line-bit-left">
              <Row className="align-items-center">
                <Col
                  md={12}
                  xl={6}
                  lg={12}
                  className="resp-margin-btm-small-lap"
                >
                  <Formik
                    initialValues={{
                      present_value: 1000,
                      future_value: 2,
                      time_period: 2,
                    }}
                    innerRef={formRef}
                    validationSchema={cagrSchema}
                    onSubmit={handleSubmit}
                  >
                    {({
                      setFieldValue,
                      values,
                      resetForm,
                      submitForm,
                      isValid,
                    }) => (
                      <FORM className="single-tax-calculator-form ">
                        <div className="single-tax-calculator-form-card">
                          <div className="single-tax-calculator-form-header-sec">
                            <h4>
                              {t("cagr_calculator.intial_investment.text")}
                            </h4>
                            <Form.Group
                              className="mb-0"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Field
                                className="form-control"
                                name="present_value"
                                placeholder={t(
                                  "cagr_calculator.intial_investment.placeholder"
                                )}
                                type="number"
                                onKeyDown={(e) => {
                                  if (
                                    !/[0-9]|Backspace|ArrowLeft|ArrowRight|Delete|ArrowUp|ArrowDown|Tab/.test(
                                      e.key
                                    ) &&
                                    !(
                                      e.ctrlKey &&
                                      (e.key === "v" ||
                                        e.key === "c" ||
                                        e.key === "x" ||
                                        e.key === "a")
                                    )
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                                onInput={(e) => {
                                  if (e.target.value < 0) {
                                    e.target.value = 0;
                                  }
                                  onChangeInput(e, "initial");
                                }}
                              />
                            </Form.Group>
                          </div>
                          <ErrorMessage
                            component={"div"}
                            name="present_value"
                            className="errorMsg"
                          />
                          <div className="tax-calculator-input-slider-sec">
                            <tc-range-slider
                              ref={initialRef}
                              value={initialRef}
                              mousewheel-disabled="true"
                              min={1}
                              max={10000000}
                              slider-width="100%"
                              slider-bg-fill="#2F5CFF"
                              generate-labels="true"
                              round="0"
                            ></tc-range-slider>
                          </div>
                        </div>
                        <div className="single-tax-calculator-form-card">
                          <div className="single-tax-calculator-form-header-sec">
                            <h4>
                              {t("cagr_calculator.final_investment.text")}
                            </h4>
                            <Form.Group
                              className="mb-0"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Field
                                className="form-control"
                                name="future_value"
                                placeholder={t(
                                  "cagr_calculator.final_investment.placeholder"
                                )}
                                type="number"
                                onKeyDown={(e) => {
                                  if (
                                    !/[0-9]|Backspace|ArrowLeft|ArrowRight|Delete|ArrowUp|ArrowDown|Tab/.test(
                                      e.key
                                    ) &&
                                    !(
                                      e.ctrlKey &&
                                      (e.key === "v" ||
                                        e.key === "c" ||
                                        e.key === "x" ||
                                        e.key === "a")
                                    )
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                                onInput={(e) => {
                                  if (e.target.value < 0) {
                                    e.target.value = 0;
                                  }
                                  onChangeInput(e, "final");
                                }}
                              />
                            </Form.Group>
                          </div>
                          <ErrorMessage
                            component={"div"}
                            name="future_value"
                            className="errorMsg"
                          />
                          <div className="tax-calculator-input-slider-sec">
                            <tc-range-slider
                              ref={finalRef}
                              value={finalRef}
                              mousewheel-disabled="true"
                              min={1}
                              max={10000000}
                              slider-width="100%"
                              slider-bg-fill="#2F5CFF"
                              generate-labels="true"
                              round="0"
                            ></tc-range-slider>
                          </div>
                        </div>
                        <div className="single-tax-calculator-form-card">
                          <div className="single-tax-calculator-form-header-sec">
                            <h4>{t("cagr_calculator.duration.text")}</h4>
                            <Form.Group
                              className="mb-0"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Field
                                className="form-control"
                                name="time_period"
                                placeholder={t(
                                  "cagr_calculator.duration.placeholder"
                                )}
                                type="number"
                                onKeyDown={(e) => {
                                  if (
                                    !/[0-9]|Backspace|ArrowLeft|ArrowRight|Delete|ArrowUp|ArrowDown|Tab/.test(
                                      e.key
                                    ) &&
                                    !(
                                      e.ctrlKey &&
                                      (e.key === "v" ||
                                        e.key === "c" ||
                                        e.key === "x" ||
                                        e.key === "a")
                                    )
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                                onInput={(e) => {
                                  if (e.target.value < 0) {
                                    e.target.value = 0;
                                  }
                                  onChangeInput(e, "timePeriod");
                                }}
                              />
                            </Form.Group>
                          </div>
                          <ErrorMessage
                            component={"div"}
                            name="time_period"
                            className="errorMsg"
                          />
                          <div className="tax-calculator-input-slider-sec">
                            <tc-range-slider
                              ref={timePeriodRef}
                              value={timePeriodRef}
                              mousewheel-disabled="true"
                              min={1}
                              max={40}
                              slider-width="100%"
                              slider-bg-fill="#2F5CFF"
                              generate-labels="true"
                              round="0"
                            ></tc-range-slider>
                          </div>
                        </div>
                        {/* <div className="d-flex justify-content-end">
                          <Button
                            type="submit"
                            className="default-primary-btn"
                            disabled={cagrCalculator.buttonDisable}
                          >
                            {cagrCalculator.buttonDisable ? (
                              <ButtonLoader />
                            ) : (
                              t("cagr_calculator.calculate")
                            )}
                          </Button>
                        </div> */}
                      </FORM>
                    )}
                  </Formik>
                </Col>
                <Col md={12} xl={6} lg={12}>
                  {cagrCalculator.loading ? (
                    <div className="single-tax-calculator-form-value-details-sec ">
                      <Skeleton
                        width={50}
                        height={15}
                        baseColor="#202020"
                        highlightColor="#444"
                      />
                      <div>
                        <Skeleton
                          width={115}
                          height={55}
                          baseColor="#202020"
                          highlightColor="#444"
                        />
                      </div>
                    </div>
                  ) : (
                    Object.keys(cagrCalculator.data).length > 0 && (
                      <div className="single-tax-calculator-form-value-details-sec ">
                        <p>{t("cagr_calculator.cagr")}</p>
                        <div className="single-tax-calculator-form-value-amount">
                          {cagrCalculator.data.cagr}%
                        </div>
                      </div>
                    )
                  )}
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <Container>
          <Row>
            <Col lg={12}>
              <div className="static-page-card">
                <div className="pricacy-policy-info">
                  <h4>Description</h4>
                  <p>
                    CAGR, or Compound Annual Growth Rate, is a key metric used
                    to evaluate the performance of a business in a competitive
                    market environment. It reflects an organization's growth
                    rate over time, and this rate can be quickly determined
                    using a CAGR calculator.
                  </p>
                  <p>
                    These calculators are readily accessible online, but ease of
                    use is crucial for accurately calculating CAGR returns.
                    TaxTrax's online CAGR calculator offers a structured and
                    precise method to calculate your business's CAGR,
                    facilitating a straightforward assessment of growth
                    trajectories.
                  </p>
                </div>
                <div className="pricacy-policy-info">
                  <h4>How to Operate TaxTrax’s CAGR Calculator?</h4>
                  <p>
                    TaxTrax’s CAGR calculator is available online for free. To
                    use it, simply follow these steps:
                  </p>
                  <ol>
                    <li className="text-white">
                      Input your initial investment amount.
                    </li>
                    <li className="text-white">
                      Enter the expected value of the investment at the end of
                      the investment period.
                    </li>
                    <li className="text-white">
                      Specify the duration of the investment, either in years or
                      months.
                    </li>
                  </ol>
                  <p>
                    Once you input these values, the CAGR calculator will
                    quickly compute and display the results, allowing you to
                    assess the annual growth rate of your investment
                    effortlessly.
                  </p>
                  <h4>Advantages of Using TaxTrax’s Online CAGR Calculator</h4>
                  <p>
                    TaxTrax’s CAGR calculator offers several significant
                    advantages:
                  </p>
                  <ul>
                    <li>
                      <h4>Detailed Return Insights: </h4>It provides a
                      comprehensive analysis of your Return on Investment (ROI),
                      helping you understand the growth of your funds over time.
                    </li>
                    <li>
                      <h4>Investment Planning: </h4>Ideal for potential
                      investors, this tool allows you to calculate the necessary
                      investment amount needed to maximize returns over a
                      specific period.
                    </li>
                    <li>
                      <h4>Performance Evaluation:</h4>You can compare your
                      business's returns against benchmark options to assess its
                      relative performance.
                    </li>
                    <li>
                      <h4>Strategic Financial Planning: </h4> The calculator
                      aids in planning for long-term capital influx, ensuring
                      you are prepared for future financial needs.
                    </li>
                    <li>
                      <h4>Reliable and Accurate:</h4>
                      As part of a globally recognized financial platform, our
                      calculator guarantees precise results.
                    </li>
                    <li>
                      <h4>Range of Tools: </h4>In addition to the CAGR
                      calculator, a variety of other financial calculators are
                      available on our platform. Feel free to use them as
                      needed. Our CAGR calculator is designed to be safe,
                      efficient, and accurate, supporting effective financial
                      planning.
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <FAQIndex
          data={[
            {
              question: "Is there a charge to use the CAGR calculator?",
              answer:
                "No, the CAGR calculator is available for free use. There is no cost associated with accessing and utilizing this tool.",
            },
            {
              question: "Is it necessary to log in to use the CAGR calculator?",
              answer:
                "No, you do not need to log in to use the CAGR calculator. It is accessible directly online without any login requirement.",
            },
            {
              question: "What measures are in place to ensure data safety?",
              answer:
                "We prioritize data security. Our platform employs advanced security protocols to protect all user data entered into our calculators and tools.",
            },
            {
              question: "What does CAGR return mean?",
              answer:
                "CAGR, or Compound Annual Growth Rate, measures the mean annual growth rate of an investment over a specified time period longer than one year. It represents one of the most accurate ways to calculate and determine returns for anything that can rise or fall in value over time",
            },
            {
              question: "Does CAGR account for investment risks?",
              answer:
                "No, CAGR does not inherently reflect investment risks. It primarily calculates the average annual growth rate based on the initial and final values of the investment, without factoring in volatility or the fluctuation levels that may occur during the investment period.",
            },
            {
              question:
                "Is using CAGR calculations better than using IRR (Internal Rate of Return)?",
              answer:
                "Whether CAGR or IRR is more appropriate depends on the context of the investment analysis. CAGR provides a straightforward and easily understandable measure of growth over time. In contrast, IRR accounts for cash flows and is more complex but provides a more detailed analysis of profitability, especially for varying cash flows. Choose the method based on the specific details and needs of your financial assessment.",
            },
          ]}
        />
      </div>
    </>
  );
};

export default CalculatorIndex;
