import React, { useState } from "react";
import HeaderIndex from "./Header/HeaderIndex";
import FooterIndex from "./Footer/FooterIndex";

const MainLayout = (props) => {


  const [themeState, setThemeState] = useState(
    localStorage.getItem("theme") !== "" &&
      localStorage.getItem("theme") !== null &&
      localStorage.getItem("theme") !== undefined &&
      localStorage.getItem("theme") === "dark" ?
      true
      : false
  );

  const toggleClass = () => {
    localStorage.setItem("theme", themeState ? "light" : "dark");
    setThemeState(!themeState);
  };

  return (
    <>
    <div className={`${themeState ? "dark-theme" : "light-theme"}`} >
      <HeaderIndex toggleTheme={toggleClass} darkTheme={themeState}/>
      <div className="main-wrapper">
        {props.children}
      </div>
      <FooterIndex />
      </div>
    </>
  );
};

export default MainLayout;
