import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Container, Button, Form } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import CustomLazyLoad from "../../Helper/CustomLazyLoad";

const TaxCalculatorIndex = (props) => {
  return (
    <>
      <div className="list-tax-calculator">
        <div className="list-tax-calculator-sec sm-padding">
          <Container>
            <Row className="justify-content-center">
              <Col lg={8} md={8} className="text-center">
                <div className="section-title">
                  <h2>Calculators</h2>
                  <p>Streamlining Your Tax Needs with Advanced AI</p>
                </div>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col
                md={6}
                sm={12}
                xl={3}
                lg={4}
                className="resp-mrg-btm-md resp-mrg-btm-xl"
              >
                <Link to="/sip-calculator">
                  <div className="list-tax-calculator-card">
                    <div className="list-tax-calculator-icon-sec">
                      <CustomLazyLoad
                        className="list-tax-calculator-icon"
                        src={
                          window.location.origin +
                          "/img/list-tax-calculator/sip.png"
                        }
                        type="image/png"
                      />
                    </div>
                    <div className="list-tax-calculator-info-sec">
                      <h4>SIP</h4>
                      <p>
                        Calculate how much you need to save or how much you will
                        accumulate with your SIP
                      </p>
                    </div>
                  </div>
                </Link>
              </Col>
              <Col
                md={6}
                sm={12}
                xl={3}
                lg={4}
                className="resp-mrg-btm-md resp-mrg-btm-xl"
              >
                <Link to="/swp-calculator">
                  <div className="list-tax-calculator-card">
                    <div className="list-tax-calculator-icon-sec">
                      <CustomLazyLoad
                        className="list-tax-calculator-icon"
                        src={
                          window.location.origin +
                          "/img/list-tax-calculator/swp.png"
                        }
                        type="image/png"
                      />
                    </div>
                    <div className="list-tax-calculator-info-sec">
                      <h4>SWP</h4>
                      <p>
                        Calculate your final amount with systematic Withdrawal
                        plans (SWP)
                      </p>
                    </div>
                  </div>
                </Link>
              </Col>
              <Col
                md={6}
                sm={12}
                xl={3}
                lg={4}
                className="resp-mrg-btm-md resp-mrg-btm-xl"
              >
                <Link to="/lumpsum-calculator">
                  <div className="list-tax-calculator-card">
                    <div className="list-tax-calculator-icon-sec">
                      <CustomLazyLoad
                        className="list-tax-calculator-icon"
                        src={
                          window.location.origin +
                          "/img/list-tax-calculator/lumpsum.png"
                        }
                        type="image/png"
                      />
                    </div>
                    <div className="list-tax-calculator-info-sec">
                      <h4>Lumpsum</h4>
                      <p>
                        Calculate returns for lumpsum investment to achieve your
                        financial goals
                      </p>
                    </div>
                  </div>
                </Link>
              </Col>
              <Col
                md={6}
                sm={12}
                xl={3}
                lg={4}
                className="resp-mrg-btm-md resp-mrg-btm-xl"
              >
                <Link to="/mf-calculator">
                  <div className="list-tax-calculator-card">
                    <div className="list-tax-calculator-icon-sec">
                      <CustomLazyLoad
                        className="list-tax-calculator-icon"
                        src={
                          window.location.origin +
                          "/img/list-tax-calculator/mf.png"
                        }
                        type="image/png"
                      />
                    </div>
                    <div className="list-tax-calculator-info-sec">
                      <h4>MF</h4>
                      <p>
                        Calculate the returns on your mutual fund investments
                      </p>
                    </div>
                  </div>
                </Link>
              </Col>
              <Col
                md={6}
                sm={12}
                xl={3}
                lg={4}
                className="resp-mrg-btm-md resp-mrg-btm-xl"
              >
                <Link to="/epf-calculator">
                  <div className="list-tax-calculator-card">
                    <div className="list-tax-calculator-icon-sec">
                      <CustomLazyLoad
                        className="list-tax-calculator-icon"
                        src={
                          window.location.origin +
                          "/img/list-tax-calculator/epf.png"
                        }
                        type="image/png"
                      />
                    </div>
                    <div className="list-tax-calculator-info-sec">
                      <h4>EPF</h4>
                      <p>
                        Calculate returns for your Employee’s Provident Fund
                        (EPF)
                      </p>
                    </div>
                  </div>
                </Link>
              </Col>
              <Col
                md={6}
                sm={12}
                xl={3}
                lg={4}
                className="resp-mrg-btm-md resp-mrg-btm-xl"
              >
                <Link to="/ssy-calculator">
                  <div className="list-tax-calculator-card">
                    <div className="list-tax-calculator-icon-sec">
                      <CustomLazyLoad
                        className="list-tax-calculator-icon"
                        src={
                          window.location.origin +
                          "/img/list-tax-calculator/ssy.png"
                        }
                        type="image/png"
                      />
                    </div>
                    <div className="list-tax-calculator-info-sec">
                      <h4>SSY</h4>
                      <p>
                        Calculate returns for Sukanya Smariddhi Yojana (SSY) as
                        per your investment
                      </p>
                    </div>
                  </div>
                </Link>
              </Col>
              <Col
                md={6}
                sm={12}
                xl={3}
                lg={4}
                className="resp-mrg-btm-md resp-mrg-btm-xl"
              >
                <Link to="/fd-calculator">
                  <div className="list-tax-calculator-card">
                    <div className="list-tax-calculator-icon-sec">
                      <CustomLazyLoad
                        className="list-tax-calculator-icon"
                        src={
                          window.location.origin +
                          "/img/list-tax-calculator/fd.png"
                        }
                        type="image/png"
                      />
                    </div>
                    <div className="list-tax-calculator-info-sec">
                      <h4>FD</h4>
                      <p>
                        Check returns on your fixed deposits (FDs) without any
                        hassle
                      </p>
                    </div>
                  </div>
                </Link>
              </Col>
              <Col
                md={6}
                sm={12}
                xl={3}
                lg={4}
                className="resp-mrg-btm-md resp-mrg-btm-xl"
              >
                <Link to="/ppf-calculator">
                  <div className="list-tax-calculator-card">
                    <div className="list-tax-calculator-icon-sec">
                      <CustomLazyLoad
                        className="list-tax-calculator-icon"
                        src={
                          window.location.origin +
                          "/img/list-tax-calculator/ppf.png"
                        }
                        type="image/png"
                      />
                    </div>
                    <div className="list-tax-calculator-info-sec">
                      <h4>PPF</h4>
                      <p>
                        Calculate your returns on Public Provident Fund (PPF)
                      </p>
                    </div>
                  </div>
                </Link>
              </Col>
              <Col
                md={6}
                sm={12}
                xl={3}
                lg={4}
                className="resp-mrg-btm-md resp-mrg-btm-xl"
              >
                <Link to="/rd-calculator">
                  <div className="list-tax-calculator-card">
                    <div className="list-tax-calculator-icon-sec">
                      <CustomLazyLoad
                        className="list-tax-calculator-icon"
                        src={
                          window.location.origin +
                          "/img/list-tax-calculator/rd.png"
                        }
                        type="image/png"
                      />
                    </div>
                    <div className="list-tax-calculator-info-sec">
                      <h4>RD</h4>
                      <p>
                        Check returns on your Recurring Deposit (RD) in just a
                        few clicks
                      </p>
                    </div>
                  </div>
                </Link>
              </Col>
              <Col
                md={6}
                sm={12}
                xl={3}
                lg={4}
                className="resp-mrg-btm-md resp-mrg-btm-xl"
              >
                <Link to="/nps-calculator">
                  <div className="list-tax-calculator-card">
                    <div className="list-tax-calculator-icon-sec">
                      <CustomLazyLoad
                        className="list-tax-calculator-icon"
                        src={
                          window.location.origin +
                          "/img/list-tax-calculator/nps.png"
                        }
                        type="image/png"
                      />
                    </div>
                    <div className="list-tax-calculator-info-sec">
                      <h4>NPS</h4>
                      <p>
                        Calculate returns for your National Pension Scheme (NPS)
                      </p>
                    </div>
                  </div>
                </Link>
              </Col>
              <Col
                md={6}
                sm={12}
                xl={3}
                lg={4}
                className="resp-mrg-btm-md resp-mrg-btm-xl"
              >
                <Link to="/retirement-calculator">
                  <div className="list-tax-calculator-card">
                    <div className="list-tax-calculator-icon-sec">
                      <CustomLazyLoad
                        className="list-tax-calculator-icon"
                        src={
                          window.location.origin +
                          "/img/list-tax-calculator/retirement.png"
                        }
                        type="image/png"
                      />
                    </div>
                    <div className="list-tax-calculator-info-sec">
                      <h4>Retirement</h4>
                      <p>
                        Calculate how much you need for a relaxed retirement
                      </p>
                    </div>
                  </div>
                </Link>
              </Col>
              <Col
                md={6}
                sm={12}
                xl={3}
                lg={4}
                className="resp-mrg-btm-md resp-mrg-btm-xl"
              >
                <Link to="/hra-calculator">
                  <div className="list-tax-calculator-card">
                    <div className="list-tax-calculator-icon-sec">
                      <CustomLazyLoad
                        className="list-tax-calculator-icon"
                        src={
                          window.location.origin +
                          "/img/list-tax-calculator/hra.png"
                        }
                        type="image/png"
                      />
                    </div>
                    <div className="list-tax-calculator-info-sec">
                      <h4>HRA</h4>
                      <p>Calculate your House Rent Allowance (HRA)</p>
                    </div>
                  </div>
                </Link>
              </Col>
              <Col
                md={6}
                sm={12}
                xl={3}
                lg={4}
                className="resp-mrg-btm-md resp-mrg-btm-xl"
              >
                <Link to="/emi-calculator">
                  <div className="list-tax-calculator-card">
                    <div className="list-tax-calculator-icon-sec">
                      <CustomLazyLoad
                        className="list-tax-calculator-icon"
                        src={
                          window.location.origin +
                          "/img/list-tax-calculator/emi.png"
                        }
                        type="image/png"
                      />
                    </div>
                    <div className="list-tax-calculator-info-sec">
                      <h4>EMI</h4>
                      <p>
                        Calculate EMI on your loans - home loan, car loan or
                        personal loan
                      </p>
                    </div>
                  </div>
                </Link>
              </Col>
              <Col
                md={6}
                sm={12}
                xl={3}
                lg={4}
                className="resp-mrg-btm-md resp-mrg-btm-xl"
              >
                <Link to="/car-loan-calculator">
                  <div className="list-tax-calculator-card">
                    <div className="list-tax-calculator-icon-sec">
                      <CustomLazyLoad
                        className="list-tax-calculator-icon"
                        src={
                          window.location.origin +
                          "/img/list-tax-calculator/car-loan-emi.png"
                        }
                        type="image/png"
                      />
                    </div>
                    <div className="list-tax-calculator-info-sec">
                      <h4>Car Loan EMI</h4>
                      <p>
                        Calculate how much you need to save or how much you will
                        accumulate with your SIP
                      </p>
                    </div>
                  </div>
                </Link>
              </Col>
              <Col
                md={6}
                sm={12}
                xl={3}
                lg={4}
                className="resp-mrg-btm-md resp-mrg-btm-xl"
              >
                <Link to="/home-loan-calculator">
                  <div className="list-tax-calculator-card">
                    <div className="list-tax-calculator-icon-sec">
                      <CustomLazyLoad
                        className="list-tax-calculator-icon"
                        src={
                          window.location.origin +
                          "/img/list-tax-calculator/home-loan-emi.png"
                        }
                        type="image/png"
                      />
                    </div>
                    <div className="list-tax-calculator-info-sec">
                      <h4>Home Loan EMI</h4>
                      <p>Calculate your home loan EMI</p>
                    </div>
                  </div>
                </Link>
              </Col>
              <Col
                md={6}
                sm={12}
                xl={3}
                lg={4}
                className="resp-mrg-btm-md resp-mrg-btm-xl"
              >
                <Link to="/simple-interest-calculator">
                  <div className="list-tax-calculator-card">
                    <div className="list-tax-calculator-icon-sec">
                      <CustomLazyLoad
                        className="list-tax-calculator-icon"
                        src={
                          window.location.origin +
                          "/img/list-tax-calculator/simple-interest.png"
                        }
                        type="image/png"
                      />
                    </div>
                    <div className="list-tax-calculator-info-sec">
                      <h4>Simple Interest</h4>
                      <p>
                        Calculate simple interest on your loans and saving
                        schemes investments
                      </p>
                    </div>
                  </div>
                </Link>
              </Col>
              <Col
                md={6}
                sm={12}
                xl={3}
                lg={4}
                className="resp-mrg-btm-md resp-mrg-btm-xl"
              >
                <Link to="/compound-interest-calculator">
                  <div className="list-tax-calculator-card">
                    <div className="list-tax-calculator-icon-sec">
                      <CustomLazyLoad
                        className="list-tax-calculator-icon"
                        src={
                          window.location.origin +
                          "/img/list-tax-calculator/compound-interest.png"
                        }
                        type="image/png"
                      />
                    </div>
                    <div className="list-tax-calculator-info-sec">
                      <h4>Compound Interest</h4>
                      <p>Calculate compound interest with ease</p>
                    </div>
                  </div>
                </Link>
              </Col>
              <Col
                md={6}
                sm={12}
                xl={3}
                lg={4}
                className="resp-mrg-btm-md resp-mrg-btm-xl"
              >
                <Link to="/nsc-calculator">
                  <div className="list-tax-calculator-card">
                    <div className="list-tax-calculator-icon-sec">
                      <CustomLazyLoad
                        className="list-tax-calculator-icon"
                        src={
                          window.location.origin +
                          "/img/list-tax-calculator/nsc.png"
                        }
                        type="image/png"
                      />
                    </div>
                    <div className="list-tax-calculator-info-sec">
                      <h4>NSC</h4>
                      <p>
                        Calculate your returns under National Savings
                        Certificate scheme
                      </p>
                    </div>
                  </div>
                </Link>
              </Col>
              <Col
                md={6}
                sm={12}
                xl={3}
                lg={4}
                className="resp-mrg-btm-md resp-mrg-btm-xl"
              >
                <Link to="/stepup-sip-calculator">
                  <div className="list-tax-calculator-card">
                    <div className="list-tax-calculator-icon-sec">
                      <CustomLazyLoad
                        className="list-tax-calculator-icon"
                        src={
                          window.location.origin +
                          "/img/list-tax-calculator/set-up-sip.png"
                        }
                        type="image/png"
                      />
                    </div>
                    <div className="list-tax-calculator-info-sec">
                      <h4>Step Up SIP</h4>
                      <p>Calculate SIP Returns with an Yearly Raise</p>
                    </div>
                  </div>
                </Link>
              </Col>
              <Col
                md={6}
                sm={12}
                xl={3}
                lg={4}
                className="resp-mrg-btm-md resp-mrg-btm-xl"
              >
                <Link to="/income-tax-calculator">
                  <div className="list-tax-calculator-card">
                    <div className="list-tax-calculator-icon-sec">
                      <CustomLazyLoad
                        className="list-tax-calculator-icon"
                        src={
                          window.location.origin +
                          "/img/list-tax-calculator/income-tax.png"
                        }
                        type="image/png"
                      />
                    </div>
                    <div className="list-tax-calculator-info-sec">
                      <h4>Income Tax</h4>
                      <p>
                        Calculate your payable income tax with minimal effort
                      </p>
                    </div>
                  </div>
                </Link>
              </Col>
              <Col
                md={6}
                sm={12}
                xl={3}
                lg={4}
                className="resp-mrg-btm-md resp-mrg-btm-xl"
              >
                <Link to="/gratuity-calculator">
                  <div className="list-tax-calculator-card">
                    <div className="list-tax-calculator-icon-sec">
                      <CustomLazyLoad
                        className="list-tax-calculator-icon"
                        src={
                          window.location.origin +
                          "/img/list-tax-calculator/gratuity.png"
                        }
                        type="image/png"
                      />
                    </div>
                    <div className="list-tax-calculator-info-sec">
                      <h4>Gratuity</h4>
                      <p>
                        Calculate how much gratuity you will get when you retire
                      </p>
                    </div>
                  </div>
                </Link>
              </Col>
              <Col
                md={6}
                sm={12}
                xl={3}
                lg={4}
                className="resp-mrg-btm-md resp-mrg-btm-xl"
              >
                <Link to="/apy-calculator">
                  <div className="list-tax-calculator-card">
                    <div className="list-tax-calculator-icon-sec">
                      <CustomLazyLoad
                        className="list-tax-calculator-icon"
                        src={
                          window.location.origin +
                          "/img/list-tax-calculator/apy.png"
                        }
                        type="image/png"
                      />
                    </div>
                    <div className="list-tax-calculator-info-sec">
                      <h4>APY</h4>
                      <p>
                        Calculate your monthly investments under Atal Pension
                        Yojana
                      </p>
                    </div>
                  </div>
                </Link>
              </Col>
              <Col
                md={6}
                sm={12}
                xl={3}
                lg={4}
                className="resp-mrg-btm-md resp-mrg-btm-xl"
              >
                <Link to="/cagr-calculator">
                  <div className="list-tax-calculator-card">
                    <div className="list-tax-calculator-icon-sec">
                      <CustomLazyLoad
                        className="list-tax-calculator-icon"
                        src={
                          window.location.origin +
                          "/img/list-tax-calculator/cagr.png"
                        }
                        type="image/png"
                      />
                    </div>
                    <div className="list-tax-calculator-info-sec">
                      <h4>CAGR</h4>
                      <p>The Simplest compound annual growth rate calculator</p>
                    </div>
                  </div>
                </Link>
              </Col>
              <Col
                md={6}
                sm={12}
                xl={3}
                lg={4}
                className="resp-mrg-btm-md resp-mrg-btm-xl"
              >
                <Link to="/gst-calculator">
                  <div className="list-tax-calculator-card">
                    <div className="list-tax-calculator-icon-sec">
                      <CustomLazyLoad
                        className="list-tax-calculator-icon"
                        src={
                          window.location.origin +
                          "/img/list-tax-calculator/gst.png"
                        }
                        type="image/png"
                      />
                    </div>
                    <div className="list-tax-calculator-info-sec">
                      <h4>GST</h4>
                      <p>Calculate your payable GST amount with a few clicks</p>
                    </div>
                  </div>
                </Link>
              </Col>
              <Col
                md={6}
                sm={12}
                xl={3}
                lg={4}
                className="resp-mrg-btm-md resp-mrg-btm-xl"
              >
                <Link to="/flat-reducing-rate-calculator">
                  <div className="list-tax-calculator-card">
                    <div className="list-tax-calculator-icon-sec">
                      <CustomLazyLoad
                        className="list-tax-calculator-icon"
                        src={
                          window.location.origin +
                          "/img/list-tax-calculator/flat-vs-reducing-rate.png"
                        }
                        type="image/png"
                      />
                    </div>
                    <div className="list-tax-calculator-info-sec">
                      <h4>Flat vs Reducing rate</h4>
                      <p>
                        Compare monthly EMI in Flat and Reducing balance
                        interest rate schemes
                      </p>
                    </div>
                  </div>
                </Link>
              </Col>
              <Col
                md={6}
                sm={12}
                xl={3}
                lg={4}
                className="resp-mrg-btm-md resp-mrg-btm-xl"
              >
                <Link to="/brokerage-calculator">
                  <div className="list-tax-calculator-card">
                    <div className="list-tax-calculator-icon-sec">
                      <CustomLazyLoad
                        className="list-tax-calculator-icon"
                        src={
                          window.location.origin +
                          "/img/list-tax-calculator/brokerage.png"
                        }
                        type="image/png"
                      />
                    </div>
                    <div className="list-tax-calculator-info-sec">
                      <h4>Brokerage</h4>
                      <p>
                        Calculate brokerage and other charges for your stock
                        orders
                      </p>
                    </div>
                  </div>
                </Link>
              </Col>
              {/* <Col
                md={6}
                sm={12}
                xl={3}
                lg={4}
                className="resp-mrg-btm-md resp-mrg-btm-xl"
              >
                <Link to="/equity-margin-calculator">
                  <div className="list-tax-calculator-card">
                    <div className="list-tax-calculator-icon-sec">
                      <CustomLazyLoad
                        className="list-tax-calculator-icon"
                        src={
                          window.location.origin +
                          "/img/list-tax-calculator/margin.png"
                        }
                        type="image/png"
                      />
                    </div>
                    <div className="list-tax-calculator-info-sec">
                      <h4>Margin</h4>
                      <p>
                        Calculate margin for delivery and intraday based on your
                        order details
                      </p>
                    </div>
                  </div>
                </Link>
              </Col> */}
              <Col
                md={6}
                sm={12}
                xl={3}
                lg={4}
                className="resp-mrg-btm-md resp-mrg-btm-xl"
              >
                <Link to="/tds-calculator">
                  <div className="list-tax-calculator-card">
                    <div className="list-tax-calculator-icon-sec">
                      <CustomLazyLoad
                        className="list-tax-calculator-icon"
                        src={
                          window.location.origin +
                          "/img/list-tax-calculator/tds.png"
                        }
                        type="image/png"
                      />
                    </div>
                    <div className="list-tax-calculator-info-sec">
                      <h4>TDS</h4>
                      <p>Calculate your TDS deductions</p>
                    </div>
                  </div>
                </Link>
              </Col>
              <Col
                md={6}
                sm={12}
                xl={3}
                lg={4}
                className="resp-mrg-btm-md resp-mrg-btm-xl"
              >
                <Link to="/salary-calculator">
                  <div className="list-tax-calculator-card">
                    <div className="list-tax-calculator-icon-sec">
                      <CustomLazyLoad
                        className="list-tax-calculator-icon"
                        src={
                          window.location.origin +
                          "/img/list-tax-calculator/salary.png"
                        }
                        type="image/png"
                      />
                    </div>
                    <div className="list-tax-calculator-info-sec">
                      <h4>Salary</h4>
                      <p>Calculate your net take home salary</p>
                    </div>
                  </div>
                </Link>
              </Col>
              <Col
                md={6}
                sm={12}
                xl={3}
                lg={4}
                className="resp-mrg-btm-md resp-mrg-btm-xl"
              >
                <Link to="/inflation-calculator">
                  <div className="list-tax-calculator-card">
                    <div className="list-tax-calculator-icon-sec">
                      <CustomLazyLoad
                        className="list-tax-calculator-icon"
                        src={
                          window.location.origin +
                          "/img/list-tax-calculator/inflation.png"
                        }
                        type="image/png"
                      />
                    </div>
                    <div className="list-tax-calculator-info-sec">
                      <h4>Inflation</h4>
                      <p>Calculate inflation adjusted prices</p>
                    </div>
                  </div>
                </Link>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
};

export default TaxCalculatorIndex;
