import React from "react";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-multi-lang";
import CustomLazyLoad from "./CustomLazyLoad";

const Page404 = ({ buttonText }) => {
  const t = useTranslation("helper");

  return (
    <div className="sec-404">
      <div className="max-w-md p-6 text-center">
        <div className="w-full">
          <CustomLazyLoad
            className="image-404"
            src={window.location.origin + "/img/404_page_not_found.svg"}
            alt="something-2went-wrong"
          />
        </div>
        <Button
          style={{ margin: "auto" }}
          className="default-primary-btn mt-4"
          onClick={() => (window.location.href = "/")}
        >
          {t("page_404_not_found.btn_text")}
        </Button>
      </div>
    </div>
  );
};

export default Page404;
