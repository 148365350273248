import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import CustomLazyLoad from "../Helper/CustomLazyLoad";
import { Link } from "react-router-dom";
import Background1 from "./banner-bg-img.png";

const CTAFirstIndex = (props) => {
  return (
    <>
      <div
        className="cta-sec"
        style={{
          background: `url(${Background1})`,
        }}
      >
        <Container>
          <Row className="align-items-center">
            <Col md={6} className="resp-margin-btm-xs">
              <div className="cta-info-sec">
                <h2>
                  Elevate Your <span>Tax Experience </span>
                </h2>
                <p>
                  Step into the world of effortless tax management with TaxTrax,
                  where a legion of satisfied users have already found answers
                  to their taxing questions. Let TaxTrax be your expert guide to
                  a simplified, precise tax process.
                </p>
              </div>
              <div className="default-btn-frame">
                <a href="#home-banner" className="default-primary-btn">
                  <span>Try Now</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="#fff"
                    x="0"
                    y="0"
                    enableBackground="new 0 0 512 512"
                    version="1.1"
                    viewBox="0 0 512 512"
                    xmlSpace="preserve"
                  >
                    <path d="M506.134 241.843l-.018-.019-104.504-104c-7.829-7.791-20.492-7.762-28.285.068-7.792 7.829-7.762 20.492.067 28.284L443.558 236H20c-11.046 0-20 8.954-20 20s8.954 20 20 20h423.557l-70.162 69.824c-7.829 7.792-7.859 20.455-.067 28.284 7.793 7.831 20.457 7.858 28.285.068l104.504-104 .018-.019c7.833-7.818 7.808-20.522-.001-28.314z"></path>
                  </svg>
                </a>
              </div>
            </Col>
            <Col md={6}>
              <div className="cta-img-sec">
                <CustomLazyLoad
                  className="cta-img-1"
                  src={window.location.origin + "/img/cta/cta-right-img-1.png"}
                  type="image/svg"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default CTAFirstIndex;
