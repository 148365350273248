import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import CustomLazyLoad from "../Helper/CustomLazyLoad";
import { Link } from "react-router-dom";

const CTAIndex = (props) => {
  return (
    <>
      {/* <div className="cta-sec sm-padding">
            <Container>
                <Row className="align-items-center">
                    <Col md={6} className="resp-margin-btm-xs">
                        <div className="cta-info-sec">
                            <h2>Streamline Your Tax Experience</h2>
                            <p>Leverage the power of AI with TaxGPT and transform your tax filing into a smooth, effortless process. Join TaxGPT today for a seamless transition through tax season. Our AI-infused service expertly navigates tax preparation intricacies, saving you valuable time and ensuring pinpoint accuracy in your finances.</p>
                            </div>
                            <div className="default-btn-frame">
                            <Link to="#" className="default-primary-btn">Try Now</Link>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="cta-img-sec">
                            <CustomLazyLoad
                                className="cta-img"
                                src={
                                window.location.origin +
                                "/img/cta/cta-right-img.png"
                                }
                                type="image/svg"
                            />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div> */}
      <div className="why-choose-us sm-padding">
        <Container>
          <Row className="justify-content-center">
            <Col lg={8} md={8} className="text-center">
              <div className="section-title">
                <h2>
                  Key Features of TaxTrax - Your AI-Powered Tax Filing Assistant
                </h2>
                {/* <p>Discover unparalleled tax guidance with TaxTrax, where cutting-edge AI meets personalized, secure, and ever-accessible tax assistance.</p> */}
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col
              md={6}
              sm={12}
              xl={3}
              lg={6}
              className="resp-mrg-btm-md resp-mrg-btm-xl"
            >
              <div className="list-tax-calculator-card">
                <div className="list-tax-calculator-icon-sec">
                  <CustomLazyLoad
                    className="list-tax-calculator-icon"
                    src={
                      window.location.origin +
                      "/img/why-choose-us/smart-guidance.png"
                    }
                    type="image/svg"
                  />
                </div>
                <div className="list-tax-calculator-info-sec">
                  <h4>Accurate AI Tax Assistant</h4>
                  <p>
                    Receive precise insights into tax filing-related
                    information, ensuring accurate data without the need for
                    filing directly on our platform.
                  </p>
                </div>
              </div>
            </Col>
            <Col
              md={6}
              sm={12}
              xl={3}
              lg={6}
              className="resp-mrg-btm-md resp-mrg-btm-xl"
            >
              <div className="list-tax-calculator-card">
                <div className="list-tax-calculator-icon-sec">
                  <CustomLazyLoad
                    className="list-tax-calculator-icon"
                    src={
                      window.location.origin +
                      "/img/why-choose-us/personalized-recommendations.png"
                    }
                    type="image/svg"
                  />
                </div>
                <div className="list-tax-calculator-info-sec">
                  <h4>Efficient Tax consultant chatbot</h4>
                  <p>
                    Save time navigating tax complexities with our efficient Tax
                    Software Bot, delivering quick access to crucial tax details
                    for informed decision-making.
                  </p>
                </div>
              </div>
            </Col>
            <Col
              md={6}
              sm={12}
              xl={3}
              lg={6}
              className="resp-mrg-btm-md resp-mrg-btm-xl"
            >
              <div className="list-tax-calculator-card">
                <div className="list-tax-calculator-icon-sec">
                  <CustomLazyLoad
                    className="list-tax-calculator-icon"
                    src={
                      window.location.origin +
                      "/img/why-choose-us/quick-efficient.png"
                    }
                    type="image/svg"
                  />
                </div>
                <div className="list-tax-calculator-info-sec">
                  <h4>Intuitive AI Taxation</h4>
                  <p>
                    Benefit from our intuitive AI-driven technology, staying
                    up-to-date with the latest tax laws for a solid foundation
                    in your external tax-filing endeavors.
                  </p>
                </div>
              </div>
            </Col>
            <Col
              md={6}
              sm={12}
              xl={3}
              lg={6}
              className="resp-mrg-btm-md resp-mrg-btm-xl"
            >
              <div className="list-tax-calculator-card">
                <div className="list-tax-calculator-icon-sec">
                  <CustomLazyLoad
                    className="list-tax-calculator-icon"
                    src={
                      window.location.origin +
                      "/img/why-choose-us/secure-confidential.png"
                    }
                    type="image/svg"
                  />
                </div>
                <div className="list-tax-calculator-info-sec">
                  <h4>Secure Information Handling</h4>
                  <p>
                    Trust in the security of your data. TaxTrax prioritizes
                    confidentiality, ensuring that your sensitive information is
                    handled with the highest level of security.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default CTAIndex;
