import React, { useEffect, useState, useRef } from "react";
import { Form, InputGroup, Button } from "react-bootstrap";
import CustomLazyLoad from "../Helper/CustomLazyLoad";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import {
  chatMessagesStart,
  chatDetailsStart,
  chatDetailsEmptyStart,
} from "../../store/slices/CommonSlice";
import { Formik, Form as FORM, Field } from "formik";
import { useTranslation } from "react-multi-lang";

const ChatWidgetIndex = () => {
  const t = useTranslation("home");
  const chatEndRef = useRef();
  const formRef = useRef();
  const dispatch = useDispatch();
  const chatMessages = useSelector((state) => state.common.chatMessages);
  const chatDetails = useSelector((state) => state.common.chatDetails);
  const [skipRender, setSkiprender] = useState(false);

  const handleMessageSubmit = (values) => {
    formRef.current.setFieldValue("question", "");
    dispatch(
      chatMessagesStart({
        ...values,
        uuid: localStorage.getItem("uuid"),
        text: values.question,
        time: new Date().toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        }),
        type: "question",
      })
    );
  };

  useEffect(() => {
    if (skipRender && Object.keys(chatMessages.data).length !== 0) {
      dispatch(chatDetailsStart(chatMessages.data));
    }
    setSkiprender(true);
  }, [chatMessages]);

  useEffect(() => {
    scrollDownChat();
  }, [chatDetails]);

  const scrollDownChat = () => {
    chatEndRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
  };

  const customStyles = {
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "8px",
      overflow: "hidden",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      minWidth: 200,
      fontSize: "0.85em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "8px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "auto" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "transparent!important",
      border: "1px solid transparent!important",
      borderRadius: "40px!important",
      boxShadow: "none!important",
      height: "45px",
      minWidth: "100px",
      cursor: "pointer",
      fontSize: "1em",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#111",
      fontSize: "1.1em",
      fontWeight: "600",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#fff",
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
      fontSize: "0.9em",
      fontWeight: "600",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      color: "#fff!important",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      fill: "#fff!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#fff",
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isSelected ? "#F4F6FD" : "#fff",
        color: "#000",
      };
    },
  };

  const options = [
    { value: "IN", label: "India" },
    { value: "US", label: "USA" },
    { value: "CA", label: "Canada" },
  ];

  return (
    <>
      <div className="client-chat-widget-fixed-card">
        <div className="client-chat-resizable-card-left-header-sec">
          <Select
            className="fillert-drop"
            options={options}
            styles={customStyles}
            isSearchable={false}
            defaultValue={options.find((option) => option.value === "IN")}
            onChange={(data) => {
              formRef.current.setFieldValue("country_code", data.value);
            }}
            placeholder={
              <div className="placeholder-flex">
                {t("chat_widget.select_country")}
              </div>
            }
          />
          <Button
            className="default-secondary-btn"
            disabled={chatMessages.buttonDisable}
            onClick={() => {
              dispatch(chatDetailsEmptyStart());
            }}
          >
            {t("chat_widget.clear_chat")}
          </Button>
        </div>
        <div className="client-chat-resizable-card-left-body-sec">
          <div className="client-chat-sticky-date-time">
            {new Date().toLocaleDateString("en-US", {
              weekday: "long",
              year: "numeric",
              month: "long",
            })}
            ,{" "}
            {new Date().toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            })}
          </div>
          <>
            {chatDetails && chatDetails.length > 0 ? (
              chatDetails.map((item) => (
                <>
                  {item.type == "question" ? (
                    <div className="client-chat-user-sender-card">
                      <div className="client-chat-user-sender-icon-sec">
                        <CustomLazyLoad
                          className="client-chat-resizable-ip-icon"
                          src={
                            window.location.origin +
                            "/img/user-receiver-img.png"
                          }
                        />
                      </div>
                      <div className="client-chat-user-sender-message-card">
                        <div className="client-chat-user-sender-message">
                          {item.text}
                        </div>
                        <div className="client-caht-user-sender-time">
                          {item.time}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="client-chat-user-receiver-card">
                      <div className="client-chat-user-receiver-icon-sec">
                        <CustomLazyLoad
                          className="client-chat-resizable-ip-icon"
                          src={window.location.origin + "/img/bot-img.svg"}
                        />
                      </div>
                      <div className="client-chat-user-receiver-message-card">
                        <div className="client-chat-user-receiver-message">
                          {item.text}
                        </div>
                        <div className="client-caht-user-receiver-time">
                          {" "}
                          {item.time}
                        </div>
                      </div>
                    </div>
                  )}
                </>
              ))
            ) : (
              <div className="client-chat-without-conversation-sec">
                <CustomLazyLoad
                  className="client-chat-without-conversation-img"
                  src={window.location.origin + "/img/no-chat-found.png"}
                />
                <p> {t("chat_widget.start_conversation")}</p>
              </div>
            )}
            {chatMessages.buttonDisable ? (
              <div className="client-chat-user-receiver-card">
                <div className="client-chat-user-receiver-icon-sec">
                  <CustomLazyLoad
                    className="client-chat-resizable-ip-icon"
                    src={window.location.origin + "/img/bot-img.svg"}
                  />
                </div>
                <div className="client-chat-user-receiver-message-card">
                  <div className="client-chat-user-receiver-message">
                    <CustomLazyLoad
                      className="typing-img"
                      src={window.location.origin + "/img/typing-img.svg"}
                    />
                  </div>
                </div>
              </div>
            ) : null}
          </>

          <div className="client-chat-resizable-card-left-footer-form">
            <Formik
              initialValues={{
                question: "",
                country_code: "IN",
              }}
              innerRef={formRef}
              onSubmit={handleMessageSubmit}
            >
              {({ values, setFieldValue, setFieldTouched, errors }) => (
                <FORM>
                  <InputGroup className="mb-0">
                    <Field
                      type="text"
                      className="form-control"
                      placeholder={t("chat_widget.write_your_message")}
                      name="question"
                    />
                    <Button
                      type="submit"
                      disabled={
                        chatMessages.buttonDisable || !values.question.trim()
                      }
                      id="basic-addon2"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="#fff"
                        data-name="Layer 1"
                        viewBox="0 0 24 24"
                      >
                        <path d="M.172 3.708A2.745 2.745 0 01.917.713 2.745 2.745 0 013.965.282l18.09 8.444A3.584 3.584 0 0124 10.999H4.048L.229 3.835a1.446 1.446 0 01-.057-.127zM4.062 13L.309 20.175a.944.944 0 00-.054.122 2.744 2.744 0 00.749 2.993 2.756 2.756 0 001.841.708c.409 0 .819-.092 1.201-.279l18.011-8.438a3.582 3.582 0 001.945-2.28H4.062z"></path>
                      </svg>
                    </Button>
                  </InputGroup>
                </FORM>
              )}
            </Formik>
          </div>
          <div ref={chatEndRef}></div>
        </div>
      </div>
    </>
  );
};

export default ChatWidgetIndex;
