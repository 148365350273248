import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Container, Button, Form, Accordion } from "react-bootstrap";
import CustomLazyLoad from "../../../Helper/CustomLazyLoad";
import Skeleton from "react-loading-skeleton";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-multi-lang";
import { useDispatch, useSelector } from "react-redux";
import {
  takeHomeSalaryCalculatorStart,
  takeHomeSalaryCalculatorSuccess,
} from "../../../../store/slices/CommonSlice";
import { ButtonLoader } from "../../../Helper/Loader";
import FAQIndex from "../FAQIndex";
import { Link } from "react-router-dom";
import formatNumberWithCommas from "../../../FormatNumber";

const CalculatorIndex = (props) => {
  const t = useTranslation("tax_calculator");
  const dispatch = useDispatch();
  const formRef = useRef();
  const [bonus, setBonus] = useState("percentage");
  const takeHomeSalaryCalculator = useSelector(
    (state) => state.common.takeHomeSalaryCalculator
  );

  useEffect(() => {
    const data =
      (formRef.current.values.bonus * formRef.current.values.cost_to_company) /
      100;
    dispatch(
      takeHomeSalaryCalculatorStart({ ...formRef.current.values, bonus: data })
    );
  }, []);

  const handleSubmit = (values) => {
    if (bonus == "percentage") {
      const data =
        (formRef.current.values.bonus *
          formRef.current.values.cost_to_company) /
        100;
      //dispatch(takeHomeSalaryCalculatorStart({ ...values, bonus: data }))
      formRef.current.isValid && calculate({ ...values, bonus: data });
    } else formRef.current.isValid && calculate(values);
    // dispatch(takeHomeSalaryCalculatorStart(values))
  };

  const salarySchema = Yup.object().shape({
    cost_to_company: Yup.string()
      .test(
        "min",
        t("salary_calculator.invalid_min", { value: 5000 }),
        (value) => {
          return value >= 5000;
        }
      )
      .test(
        "max",
        t("salary_calculator.invalid_max", {
          value: 10000000,
          name: "Cost to Company",
        }),
        (value) => {
          return value <= 10000000;
        }
      )
      .required(t("salary_calculator.required")),
    bonus: Yup.string()
      .test(
        "max",
        t("salary_calculator.invalid_max", {
          value: bonus == "percentage" ? 95.4 : 1000000,
          name: "Bonus",
        }),
        (value) => {
          return bonus == "percentage" ? value <= 95.4 : value <= 1000000;
        }
      )
      .required(t("salary_calculator.required")),
    professional_tax: Yup.string()
      .test(
        "min",
        t("salary_calculator.invalid_min", { value: 200 }),
        (value) => {
          return value >= 200;
        }
      )
      .test(
        "max",
        t("salary_calculator.invalid_max", {
          value: 10000000,
          name: "Professional Tax",
        }),
        (value) => {
          return value <= 10000000;
        }
      )
      .required(t("salary_calculator.required")),
    employer_pf: Yup.string()
      .test(
        "max",
        t("salary_calculator.invalid_max", {
          value: 10000000,
          name: "Employer Pf",
        }),
        (value) => {
          return value <= 10000000;
        }
      )
      .required(t("salary_calculator.required")),
    employee_pf: Yup.string()
      .test(
        "max",
        t("salary_calculator.invalid_max", {
          value: 10000000,
          name: "Employee Pf",
        }),
        (value) => {
          return value <= 10000000;
        }
      )
      .required(t("salary_calculator.required")),
  });

  const calculate = (values) => {
    const {
      cost_to_company,
      bonus,
      professional_tax,
      employer_pf,
      employee_pf,
      additional_deductions,
    } = values;
    const grossSalary = cost_to_company - bonus;
    const yearlyEmployeePf = employee_pf * 12;
    const yearlyEmployerPf = employer_pf * 12;
    const yearlyProfessionalTax = professional_tax * 12;
    const totalDeductions =
      yearlyEmployeePf +
      yearlyProfessionalTax +
      yearlyEmployerPf +
      additional_deductions;
    const nettSalary = grossSalary - totalDeductions;
    const takeHomeSalary = nettSalary / 12;
    const finalDeductions = totalDeductions + bonus;
    const monthlyDeductedSalary = finalDeductions / 12;
    const roundedTakeHomeSalary = Math.round(takeHomeSalary);
    const roundedMonthlyDeductedSalary = Math.round(monthlyDeductedSalary);
    const roundedFinalDeductions = Math.round(finalDeductions);
    const roundedNettSalary = Math.round(nettSalary);

    return dispatch(
      takeHomeSalaryCalculatorSuccess({
        take_home_salary: roundedTakeHomeSalary,
        monthly_deducted_salary: roundedMonthlyDeductedSalary,
        final_deducations: roundedFinalDeductions,
        nett_salary: roundedNettSalary,
      })
    );
  };

  return (
    <>
      <div className="single-tax-calculator">
        <div className="sm-padding single-tax-calculator-sec">
          <Container>
            <div className="single-tax-calculator-box">
              <div className="single-tax-calculator-header">
                <Row>
                  <Col md={6}>
                    <div className="d-flex other-calculator-btn-sec">
                      <Link
                        to="/tax-calculator"
                        className="other-calculator-btn"
                      >
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            enableBackground="new 0 0 512 512"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="#fff"
                              d="M10.957 12.354a.5.5 0 010-.708l4.586-4.585a1.5 1.5 0 00-2.121-2.122L8.836 9.525a3.505 3.505 0 000 4.95l4.586 4.586a1.5 1.5 0 002.121-2.122z"
                              data-original="#000000"
                            ></path>
                          </svg>
                        </span>
                        <span>Other Calculators</span>
                      </Link>
                    </div>
                    <div className="single-tax-calculator-info-sec">
                      <h1>{t("salary_calculator.heading")}</h1>
                      <p>{t("salary_calculator.content")}</p>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="single-tax-calculator-header-img-sec">
                      <CustomLazyLoad
                        className="single-tax-calculator-header-img"
                        src={
                          window.location.origin +
                          "/img/single-tax-calculator-header-img.png"
                        }
                        type="image/svg"
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
            <Formik
              initialValues={{
                cost_to_company: 60000,
                bonus: 15,
                professional_tax: 200,
                employer_pf: 1800,
                employee_pf: 1800,
                additional_deductions: 0,
              }}
              innerRef={formRef}
              validationSchema={salarySchema}
              onSubmit={handleSubmit}
            >
              {({ setFieldValue, values, resetForm, submitForm, isValid }) => (
                <FORM className="single-tax-calculator-form-box calculator-space-lg">
                  <Row className="align-items-center justify-content-center">
                    <Col md={12} xl={12} lg={12}>
                      <div className="single-calculator-info-frame ms-4 d-space">
                        <div className="calculator-grid-even">
                          <p>
                            {t("salary_calculator.cost_to_company.text")} (
                            {t("salary_calculator.cost_to_company.ctc")})
                          </p>
                          <Form.Group
                            className="mb-0"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Field
                              className="form-control text-end"
                              name="cost_to_company"
                              placeholder={t(
                                "salary_calculator.cost_to_company.placeholder"
                              )}
                              onKeyDown={(e) => {
                                if (
                                  !/[0-9]|Backspace|ArrowLeft|ArrowRight|Delete|ArrowUp|ArrowDown|Tab/.test(
                                    e.key
                                  ) &&
                                  !(
                                    e.ctrlKey &&
                                    (e.key === "v" ||
                                      e.key === "c" ||
                                      e.key === "x" ||
                                      e.key === "a")
                                  )
                                ) {
                                  e.preventDefault();
                                }
                              }}
                              onInput={(e) => {
                                if (e.target.value < 0) {
                                  e.target.value = 0;
                                }
                                submitForm();
                              }}
                              type="number"
                            />
                          </Form.Group>
                        </div>
                        <ErrorMessage
                          component={"div"}
                          name="cost_to_company"
                          className="errorMsg"
                        />
                        <div className="calculator-grid-even">
                          <p className="d-flex align-items-center">
                            {t("salary_calculator.bonus_included_in_ctc.text", {
                              value: bonus == "percentage" ? "%" : "₹",
                            })}
                            <div className="yes-or-no-radio-btn-sec d-flex align-items-center ms-4">
                              <div class="pretty p-default p-round p-smooth">
                                <input
                                  type="radio"
                                  value="percentage"
                                  checked={bonus == "percentage"}
                                  onChange={(e) => {
                                    setBonus(e.target.value);
                                    submitForm();
                                  }}
                                />
                                <div class="state p-primary-o">
                                  <label>
                                    {t(
                                      "salary_calculator.bonus_included_in_ctc.percentage"
                                    )}
                                  </label>
                                </div>
                              </div>
                              <div class="pretty p-default p-round p-smooth">
                                <input
                                  type="radio"
                                  value="amount"
                                  checked={bonus == "amount"}
                                  onChange={(e) => {
                                    setBonus(e.target.value);
                                    submitForm();
                                  }}
                                />
                                <div class="state p-primary-o">
                                  <label>
                                    {t(
                                      "salary_calculator.bonus_included_in_ctc.amount"
                                    )}
                                  </label>
                                </div>
                              </div>
                            </div>
                          </p>
                          <Form.Group
                            className="mb-0"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Field
                              className="form-control text-end"
                              name="bonus"
                              placeholder={t(
                                "salary_calculator.bonus_included_in_ctc.placeholder"
                              )}
                              onKeyDown={(e) => {
                                if (
                                  !/[0-9]|Backspace|ArrowLeft|ArrowRight|Delete|ArrowUp|ArrowDown|Tab/.test(
                                    e.key
                                  ) &&
                                  !(
                                    e.ctrlKey &&
                                    (e.key === "v" ||
                                      e.key === "c" ||
                                      e.key === "x" ||
                                      e.key === "a")
                                  )
                                ) {
                                  e.preventDefault();
                                }
                              }}
                              onInput={(e) => {
                                if (e.target.value < 0) {
                                  e.target.value = 0;
                                }
                                submitForm();
                              }}
                              type="number"
                            />
                          </Form.Group>
                        </div>
                        <ErrorMessage
                          component={"div"}
                          name="bonus"
                          className="errorMsg"
                        />
                        <div className="calculator-grid-even">
                          <p>
                            {t(
                              "salary_calculator.monthly_professional_tax.text"
                            )}
                          </p>
                          <Form.Group
                            className="mb-0"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Field
                              className="form-control text-end"
                              name="professional_tax"
                              placeholder={t(
                                "salary_calculator.monthly_professional_tax.placeholder"
                              )}
                              onKeyDown={(e) => {
                                if (
                                  !/[0-9]|Backspace|ArrowLeft|ArrowRight|Delete|ArrowUp|ArrowDown|Tab/.test(
                                    e.key
                                  ) &&
                                  !(
                                    e.ctrlKey &&
                                    (e.key === "v" ||
                                      e.key === "c" ||
                                      e.key === "x" ||
                                      e.key === "a")
                                  )
                                ) {
                                  e.preventDefault();
                                }
                              }}
                              onInput={(e) => {
                                if (e.target.value < 0) {
                                  e.target.value = 0;
                                }
                                submitForm();
                              }}
                              type="number"
                            />
                          </Form.Group>
                        </div>
                        <ErrorMessage
                          component={"div"}
                          name="professional_tax"
                          className="errorMsg"
                        />
                        <div className="calculator-grid-even">
                          <p>
                            {t("salary_calculator.monthly_employer_pf.text")}
                          </p>
                          <Form.Group
                            className="mb-0"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Field
                              className="form-control text-end"
                              name="employer_pf"
                              placeholder={t(
                                "salary_calculator.monthly_employer_pf.placeholder"
                              )}
                              onKeyDown={(e) => {
                                if (
                                  !/[0-9]|Backspace|ArrowLeft|ArrowRight|Delete|ArrowUp|ArrowDown|Tab/.test(
                                    e.key
                                  ) &&
                                  !(
                                    e.ctrlKey &&
                                    (e.key === "v" ||
                                      e.key === "c" ||
                                      e.key === "x" ||
                                      e.key === "a")
                                  )
                                ) {
                                  e.preventDefault();
                                }
                              }}
                              onInput={(e) => {
                                if (e.target.value < 0) {
                                  e.target.value = 0;
                                }
                                submitForm();
                              }}
                              type="number"
                            />
                          </Form.Group>
                        </div>
                        <ErrorMessage
                          component={"div"}
                          name="employer_pf"
                          className="errorMsg"
                        />
                        <div className="calculator-grid-even">
                          <p>
                            {t("salary_calculator.monthly_employee_pf.text")}
                          </p>
                          <Form.Group
                            className="mb-0"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Field
                              className="form-control text-end"
                              name="employee_pf"
                              placeholder={t(
                                "salary_calculator.monthly_employee_pf.placeholder"
                              )}
                              onKeyDown={(e) => {
                                if (
                                  !/[0-9]|Backspace|ArrowLeft|ArrowRight|Delete|ArrowUp|ArrowDown|Tab/.test(
                                    e.key
                                  ) &&
                                  !(
                                    e.ctrlKey &&
                                    (e.key === "v" ||
                                      e.key === "c" ||
                                      e.key === "x" ||
                                      e.key === "a")
                                  )
                                ) {
                                  e.preventDefault();
                                }
                              }}
                              onInput={(e) => {
                                if (e.target.value < 0) {
                                  e.target.value = 0;
                                }
                                submitForm();
                              }}
                              type="number"
                            />
                          </Form.Group>
                        </div>
                        <ErrorMessage
                          component={"div"}
                          name="employee_pf"
                          className="errorMsg"
                        />
                        <div className="calculator-grid-even">
                          <p>
                            {t(
                              "salary_calculator.monthly_additional_deduction.text"
                            )}
                          </p>
                          <Form.Group
                            className="mb-0"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Field
                              className="form-control text-end"
                              name="additional_deductions"
                              placeholder={t(
                                "salary_calculator.monthly_additional_deduction.placeholder"
                              )}
                              onKeyDown={(e) => {
                                if (
                                  !/[0-9]|Backspace|ArrowLeft|ArrowRight|Delete|ArrowUp|ArrowDown|Tab/.test(
                                    e.key
                                  ) &&
                                  !(
                                    e.ctrlKey &&
                                    (e.key === "v" ||
                                      e.key === "c" ||
                                      e.key === "x" ||
                                      e.key === "a")
                                  )
                                ) {
                                  e.preventDefault();
                                }
                              }}
                              onInput={(e) => {
                                if (e.target.value < 0) {
                                  e.target.value = 0;
                                }
                                submitForm();
                              }}
                              type="number"
                            />
                          </Form.Group>
                        </div>
                        {/* <div className="d-flex justify-content-end mt-3">
                          <Button
                            type="submit"
                            className="default-primary-btn"
                            disabled={takeHomeSalaryCalculator.buttonDisable}
                          >
                            {takeHomeSalaryCalculator.buttonDisable ? (
                              <ButtonLoader />
                            ) : (
                              t("salary_calculator.calculate")
                            )}
                          </Button>
                        </div> */}
                        <hr />
                        {takeHomeSalaryCalculator.loading ? (
                          <div className="calculator-grid-even">
                            <Skeleton count={4} height={40} />
                            <h3 className="text-end">
                              {" "}
                              <Skeleton count={4} height={40} />
                            </h3>
                          </div>
                        ) : (
                          Object.keys(takeHomeSalaryCalculator.data).length >
                            0 && (
                            <>
                              <div className="calculator-grid-even">
                                <p>
                                  {t(
                                    "salary_calculator.total_monthly_deductions"
                                  )}
                                </p>
                                <h3 className="text-end">
                                  {formatNumberWithCommas(
                                    takeHomeSalaryCalculator.data
                                      .monthly_deducted_salary
                                  )}
                                </h3>
                              </div>
                              <div className="calculator-grid-even">
                                <p>
                                  {t(
                                    "salary_calculator.total_annual_deductions"
                                  )}
                                </p>
                                <h3 className="text-end">
                                  {formatNumberWithCommas(
                                    takeHomeSalaryCalculator.data
                                      .final_deducations
                                  )}
                                </h3>
                              </div>
                              <div className="calculator-grid-even">
                                <p>
                                  {t(
                                    "salary_calculator.take_home_monthly_salary"
                                  )}
                                </p>
                                <h3 className="text-end">
                                  {formatNumberWithCommas(
                                    takeHomeSalaryCalculator.data
                                      .take_home_salary
                                  )}
                                </h3>
                              </div>
                              <div className="calculator-grid-even">
                                <p>
                                  {t(
                                    "salary_calculator.take_home_annual_salary"
                                  )}
                                </p>
                                <h3 className="text-end">
                                  {formatNumberWithCommas(
                                    takeHomeSalaryCalculator.data.nett_salary
                                  )}
                                </h3>
                              </div>
                            </>
                          )
                        )}
                      </div>
                    </Col>
                  </Row>
                </FORM>
              )}
            </Formik>
          </Container>
        </div>
        <Container>
          <Row>
            <Col lg={12}>
              <div className="static-page-card">
                <div className="pricacy-policy-info">
                  <h4>Description</h4>
                  <p>
                    A salary calculator is a user-friendly tool designed to help
                    compute various salary-related figures such as take-home
                    annual salary, take-home monthly salary, total annual
                    deductions, and total monthly deductions for an individual.
                  </p>
                  <p>
                    To use the calculator, an employee simply inputs details
                    like their Cost to Company (CTC), any bonus included in the
                    CTC, monthly professional tax, employer and employee
                    contributions to Provident Fund (PF), and any other monthly
                    deductions into the specified fields to calculate the net
                    amount of their earnings.
                  </p>
                  <h4>Advantages of Using a Salary Calculator in India</h4>
                  <ul>
                    <li>
                      <h4>Rapid Results: </h4>
                      TaxTrax's take-home salary calculator delivers quick
                      computations, providing results in just a few seconds.
                    </li>
                    <li>
                      <h4>Detects Variations</h4>
                      The calculator is instrumental in highlighting how
                      additions like bonuses or deductions affect the overall
                      salary structure.
                    </li>
                    <li>
                      <h4>Detailed Breakdown: </h4>It offers a clear breakdown
                      of the various components of an employee's salary,
                      specifying precise amounts and deductions.
                    </li>
                    <li>
                      <h4>Accurate Estimations:</h4>
                      TaxTrax’s CTC calculator provides accurate estimates of
                      monthly and annual deductions, including contributions
                      made by both the employee and employer towards the EPF,
                      helping users gain a comprehensive understanding of their
                      salary structure.
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <FAQIndex
          data={[
            {
              question:
                "What information is required to use the take-home salary calculator in India?",
              answer:
                "To use the take-home salary calculator effectively, you should know and provide your gross salary, any bonuses, professional tax contributions, deductions for employee provident fund (EPF), and any other deductions that might apply.",
            },
            {
              question:
                "Does the CTC salary calculator account for tax deductions?",
              answer:
                "Yes, the CTC salary calculator considers and calculates tax deductions including income tax, professional tax, and other statutory deductions to give you a clear picture of your net take-home pay.",
            },
            {
              question: "What are the main components of a salary structure",
              answer:
                "The main components of a salary structure typically include basic salary, house rent allowance (HRA), special allowances, bonuses, and deductions such as EPF and taxes. Some salary structures may also include components like conveyance allowance, medical allowance, and more.",
            },
            {
              question: "What is the basic salary formula?",
              answer:
                "The basic salary is typically a fixed part of one’s compensation structure and does not include bonuses, allowances, or any other variable components. It is calculated based on the individual's position and agreed upon as a portion of the total pay.",
            },
            {
              question: "What does Cost to Company (CTC) mean?",
              answer:
                "Cost to Company, or CTC, is the total amount an employer spends on an employee in a year. It includes everything from the basic salary, allowances, benefits, bonuses, to any employer contributions like EPF, pension, and insurance, representing the total employment expense from the employer’s perspective.",
            },
          ]}
        />
      </div>
    </>
  );
};

export default CalculatorIndex;
