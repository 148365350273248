import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./staticPage.css";

const PrivacyPolicy = () => {
  return (
    <>
      <div className="landing-page-sec">
        <div className="privacy-policy-sec sm-padding">
          <Container>
            <Row className="justify-content-center">
              <Col lg={8} md={8} className="text-center">
                <div className="section-title">
                  <h2>Privacy Policy for TaxTrax</h2>
                </div>
              </Col>
            </Row>
            <div className="static-page-card">
              <div className="pricacy-policy-info">
                <h4>Introduction</h4>
                <p>
                  Welcome to TaxTrax, your innovative AI-powered personal tax
                  assistant. We are dedicated to simplifying your tax
                  calculations while ensuring the utmost privacy and protection
                  of your personal information. This Privacy Policy provides
                  detailed insights into how we collect, use, safeguard, and
                  handle your personal data in accordance with industry
                  standards and regulatory requirements.
                </p>
              </div>
              <div className="pricacy-policy-info">
                <h4>Information Collection</h4>
                <p>
                  At TaxTrax, we collect specific types of personal information
                  to deliver our services effectively:
                </p>
                <ul>
                  <li>
                    Name and Email ID: For account registration, identification,
                    and communication
                  </li>
                  <li>
                    Financial Details: Including income, deductions, and other
                    tax-related information you provide for the purpose of tax
                    calculation and advice.
                  </li>
                </ul>
              </div>
              <div className="pricacy-policy-info">
                <h4>Methods of Collection</h4>
                <p>We collect your information through the following means:</p>
                <ul>
                  <li>
                    Direct Input: When you sign up for TaxTrax and input your
                    financial details into our system, we collect this
                    information to personalize and enhance your experience.
                  </li>
                  <li>
                    Interaction Data: We may also collect data on how you
                    interact with our services to continuously improve our
                    offerings and your user experience.
                  </li>
                </ul>
              </div>
              <div className="pricacy-policy-info">
                <h4>Purpose of Information Use</h4>
                <p>
                  Your personal information is utilized for the following key
                  purposes:
                </p>
                <ul>
                  <li>
                    Service Provision: To offer personalized tax calculation and
                    assistance services.
                  </li>
                  <li>
                    Customer Support: To address your inquiries, provide
                    assistance, and improve our service quality based on your
                    feedback.
                  </li>
                </ul>
              </div>
              <div className="pricacy-policy-info">
                <h4>Data Security</h4>
                <p>
                  We prioritize the security of your personal data. TaxTrax
                  employs advanced encryption techniques and robust
                  cybersecurity measures to protect your information against
                  unauthorized access, disclosure, or alteration. Our dedicated
                  team continuously monitors and updates our security protocols
                  to ensure your data's integrity and confidentiality.
                </p>
              </div>
              <div className="pricacy-policy-info">
                <h4>Data Sharing and Disclosure</h4>
                <p>
                  TaxTrax is committed to maintaining your trust. We do not
                  sell, rent, or share your personal information with third
                  parties for their marketing purposes. Any data sharing is
                  strictly limited to the provision of our services, compliance
                  with legal obligations, or with your explicit consent.
                </p>
              </div>
              <div className="pricacy-policy-info">
                <h4>User Rights and Control</h4>
                <p>
                  We acknowledge and respect your rights over your personal
                  information. At any time, you may:
                </p>
                <ul>
                  <li>
                    Access your personal data to review what we have stored.
                  </li>
                  <li>
                    Update your information to keep it accurate and current.
                  </li>
                  <li>
                    Delete your information from our records, subject to
                    applicable legal and operational constraints.
                  </li>
                </ul>
                <p>
                  To exercise these rights, please reach out to us using the
                  contact information provided below.
                </p>
              </div>
              <div className="pricacy-policy-info">
                <h4>Updates to Our Privacy Policy</h4>
                <p>
                  TaxTrax may update this Privacy Policy periodically to reflect
                  changes in our practices, technology, legal requirements, or
                  other factors. We encourage you to review our policy regularly
                  to stay informed about how we are protecting your information.
                </p>
              </div>
              <div className="pricacy-policy-info">
                <h4>Contact Us</h4>
                <p>
                  Should you have any questions, concerns, or comments about our
                  Privacy Policy or your personal information, please feel free
                  to contact us at marketing@tax-trax.com. Our dedicated
                  customer support team is here to assist you.
                </p>
              </div>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
