import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import CustomLazyLoad from "../Helper/CustomLazyLoad";
import { Link } from "react-router-dom";

const WhyChooseUsIndex = (props) => {
  return (
    <>
      <div className="why-choose-us sm-padding">
        <Container>
          <Row className="justify-content-center">
            <Col lg={8} md={8} className="text-center">
              <div className="section-title">
                <h2>Why Choose Us?</h2>
                <p>
                  Discover unparalleled tax guidance with TaxTrax, where
                  cutting-edge AI meets personalized, secure, and
                  ever-accessible tax assistance.
                </p>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col
              md={6}
              sm={12}
              xl={4}
              lg={6}
              className="resp-mrg-btm-md resp-mrg-btm-xl"
            >
              <div className="why-choose-us-card">
                <div className="why-choose-us-icon-sec">
                  <CustomLazyLoad
                    className="why-choose-us-icon"
                    src={
                      window.location.origin +
                      "/img/why-choose-us/smart-guidance.png"
                    }
                    type="image/svg"
                  />
                </div>
                <div className="why-choose-us-info-sec">
                  <h4>AI-Powered Insights</h4>
                  <p>
                    TaxTrax employs advanced algorithms to uncover deductions
                    and credits you might miss. Our AI digs deep, ensuring no
                    stone is left unturned in maximizing your returns.
                  </p>
                </div>
              </div>
            </Col>
            <Col
              md={6}
              sm={12}
              xl={4}
              lg={6}
              className="resp-mrg-btm-md resp-mrg-btm-xl"
            >
              <div className="why-choose-us-card">
                <div className="why-choose-us-icon-sec">
                  <CustomLazyLoad
                    className="why-choose-us-icon"
                    src={
                      window.location.origin +
                      "/img/why-choose-us/personalized-recommendations.png"
                    }
                    type="image/svg"
                  />
                </div>
                <div className="why-choose-us-info-sec">
                  <h4>Real-Time Tax Law Updates</h4>
                  <p>
                    Stay ahead of the curve. TaxTrax is continuously updated
                    with the latest tax regulations, providing you with
                    up-to-the-minute advice for informed decision-making.
                  </p>
                </div>
              </div>
            </Col>
            <Col
              md={6}
              sm={12}
              xl={4}
              lg={6}
              className="resp-mrg-btm-md resp-mrg-btm-xl"
            >
              <div className="why-choose-us-card">
                <div className="why-choose-us-icon-sec">
                  <CustomLazyLoad
                    className="why-choose-us-icon"
                    src={
                      window.location.origin +
                      "/img/why-choose-us/quick-efficient.png"
                    }
                    type="image/svg"
                  />
                </div>
                <div className="why-choose-us-info-sec">
                  <h4>User-Friendly Interface</h4>
                  <p>
                    Simplicity is at the core of TaxTrax. Our intuitive platform
                    guides you through every step, making tax preparation
                    accessible, regardless of your financial expertise.
                  </p>
                </div>
              </div>
            </Col>
            <Col
              md={6}
              sm={12}
              xl={4}
              lg={6}
              className="resp-mrg-btm-md resp-mrg-btm-xl"
            >
              <div className="why-choose-us-card">
                <div className="why-choose-us-icon-sec">
                  <CustomLazyLoad
                    className="why-choose-us-icon"
                    src={
                      window.location.origin +
                      "/img/why-choose-us/secure-confidential.png"
                    }
                    type="image/svg"
                  />
                </div>
                <div className="why-choose-us-info-sec">
                  <h4>Secure & Confidential</h4>
                  <p>
                    Your privacy is paramount. TaxTrax safeguards your sensitive
                    information with bank-level encryption, ensuring your data
                    remains secure and confidential at all times.
                  </p>
                </div>
              </div>
            </Col>
            <Col
              md={6}
              sm={12}
              xl={4}
              lg={6}
              className="resp-mrg-btm-md resp-mrg-btm-xl"
            >
              <div className="why-choose-us-card">
                <div className="why-choose-us-icon-sec">
                  <CustomLazyLoad
                    className="why-choose-us-icon"
                    src={
                      window.location.origin +
                      "/img/why-choose-us/accessible-anytime-anywhere.png"
                    }
                    type="image/svg"
                  />
                </div>
                <div className="why-choose-us-info-sec">
                  <h4>24/7 Availability</h4>
                  <p>
                    TaxTrax is your round-the-clock tax expert. Whether you're
                    an early bird or a night owl, our service is available
                    whenever you need it, providing flexibility to suit your
                    lifestyle.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default WhyChooseUsIndex;
