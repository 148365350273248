import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Container, Button, Form, Accordion } from "react-bootstrap";
import CustomLazyLoad from "../../../Helper/CustomLazyLoad";
import Skeleton from "react-loading-skeleton";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-multi-lang";
import { useDispatch, useSelector } from "react-redux";
import { gratuityCalculatorStart, gratuityCalculatorSuccess } from "../../../../store/slices/CommonSlice";
import { ButtonLoader } from "../../../Helper/Loader";
import FAQIndex from "../FAQIndex";
import { Link } from "react-router-dom";
import formatNumberWithCommas from "../../../FormatNumber";

const CalculatorIndex = (props) => {
  const t = useTranslation("tax_calculator");
  const dispatch = useDispatch();
  const formRef = useRef();
  const basciSalaryRef = useRef(null);
  const dearnessAllowncesRef = useRef(null);
  const timePeriodRef = useRef(null);
  const gratuityCalculator = useSelector(
    (state) => state.common.gratuityCalculator
  );

  useEffect(() => {
    const onChange = (event, field) => {
      const customEvent = event;
      switch (field) {
        case "basicSalary":
          formRef.current.setFieldValue(
            "basic_salary",
            customEvent.detail.value
          );
          break;
        case "dearnessAllownces":
          formRef.current.setFieldValue(
            "dearness_allownces",
            customEvent.detail.value
          );
          break;
        case "timePeriod":
          formRef.current.setFieldValue(
            "gratuity_service_period",
            customEvent.detail.value
          );
          break;
      }
      setTimeout(() => { formRef.current.submitForm() }, 1)
    };
    basciSalaryRef.current?.addEventListener("change", (event) =>
      onChange(event, "basicSalary")
    );
    dearnessAllowncesRef.current?.addEventListener("change", (event) =>
      onChange(event, "dearnessAllownces")
    );
    timePeriodRef.current?.addEventListener("change", (event) =>
      onChange(event, "timePeriod")
    );
    return () => {
      basciSalaryRef.current?.removeEventListener("change", onChange);
      dearnessAllowncesRef.current?.removeEventListener("change", onChange);
      timePeriodRef.current?.removeEventListener("change", onChange);
    };
  }, []);

  useEffect(() => {
    calculate(formRef.current.values);
  }, []);

  const onChangeInput = (event, field) => {
    const value = !isNaN(parseFloat(event.target.value))
      ? parseFloat(event.target.value)
      : "";
    switch (field) {
      case "basicSalary":
        formRef.current.setFieldValue("basic_salary", value);
        basciSalaryRef.current.value = value;
        break;
      case "dearnessAllownces":
        formRef.current.setFieldValue("dearness_allownces", value);
        dearnessAllowncesRef.current.value = value;
        break;
      case "timePeriod":
        formRef.current.setFieldValue("gratuity_service_period", value);
        timePeriodRef.current.value = value;
        break;
    }
    setTimeout(() => { formRef.current.submitForm() }, 1)
  };

  const handleSubmit = (values) => {
    formRef.current.isValid && calculate(values)
    //dispatch(gratuityCalculatorStart(values));
  };

  const gratuitySchema = Yup.object().shape({
    basic_salary: Yup.string()
      .test(
        "min",
        t("gratuity_calculator.invalid_min", { value: 5000 }),
        (value) => {
          return value >= 5000;
        }
      )
      .test(
        "max",
        t("gratuity_calculator.invalid_max", {
          value: 1000000,
          name: "Basic salary",
        }),
        (value) => {
          return value <= 10000000;
        }
      )
      .required(t("gratuity_calculator.required")),
    dearness_allownces: Yup.string()
      .test(
        "min",
        t("gratuity_calculator.invalid_min", { value: 500 }),
        (value) => {
          return value >= 500;
        }
      )
      .test(
        "max",
        t("gratuity_calculator.invalid_max", {
          value: 1000000,
          name: "Dearness allownces",
        }),
        (value) => {
          return value <= 10000000;
        }
      )
      .required(t("gratuity_calculator.required")),
    gratuity_service_period: Yup.string()
      .test(
        "min",
        t("gratuity_calculator.invalid_min", { value: 5 }),
        (value) => {
          return value >= 5;
        }
      )
      .test(
        "max",
        t("gratuity_calculator.invalid_max", {
          value: 1000000,
          name: "Service period",
        }),
        (value) => {
          return value <= 50;
        }
      )
      .required(t("gratuity_calculator.required")),
  })

  const calculate = (values) => {
    const { basic_salary, dearness_allownces, gratuity_service_period } = values
    let gratuity_amount = (basic_salary + dearness_allownces) * gratuity_service_period * 15 / 26


    gratuity_amount = Math.round(gratuity_amount)
    return dispatch(gratuityCalculatorSuccess({ gratuity_amount: gratuity_amount }))
  }

  return (
    <>
      <div className="single-tax-calculator">
        <div className="sm-padding single-tax-calculator-sec">
          <Container>
            <div className="single-tax-calculator-box">
              <div className="single-tax-calculator-header">
                <Row>
                  <Col md={6}>
                    <div className="d-flex other-calculator-btn-sec">
                      <Link
                        to="/tax-calculator"
                        className="other-calculator-btn"
                      >
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            enableBackground="new 0 0 512 512"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="#fff"
                              d="M10.957 12.354a.5.5 0 010-.708l4.586-4.585a1.5 1.5 0 00-2.121-2.122L8.836 9.525a3.505 3.505 0 000 4.95l4.586 4.586a1.5 1.5 0 002.121-2.122z"
                              data-original="#000000"
                            ></path>
                          </svg>
                        </span>
                        <span>Other Calculators</span>
                      </Link>
                    </div>
                    <div className="single-tax-calculator-info-sec">
                      <h1>{t("gratuity_calculator.heading")}</h1>
                      <p>{t("gratuity_calculator.content")}</p>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="single-tax-calculator-header-img-sec">
                      <CustomLazyLoad
                        className="single-tax-calculator-header-img"
                        src={
                          window.location.origin +
                          "/img/single-tax-calculator-header-img.png"
                        }
                        type="image/svg"
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="single-tax-calculator-form-box  line-stright line-bit-left">
              <Row className="align-items-center">
                <Col
                  md={12}
                  xl={6}
                  lg={12}
                  className="resp-margin-btm-small-lap"
                >
                  <Formik
                    initialValues={{
                      basic_salary: 5000,
                      dearness_allownces: 500,
                      gratuity_service_period: 5,
                    }}
                    innerRef={formRef}
                    validationSchema={gratuitySchema}
                    onSubmit={handleSubmit}
                  >
                    {({ setFieldValue, values, resetForm, isValid, submitForm }) => (
                      <FORM className="single-tax-calculator-form ">
                        <div className="single-tax-calculator-form-card">
                          <div className="single-tax-calculator-form-header-sec">
                            <h4>
                              {t("gratuity_calculator.basic_salary.text")}
                            </h4>
                            <Form.Group
                              className="mb-0"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Field
                                className="form-control"
                                name="basic_salary"
                                placeholder={t(
                                  "gratuity_calculator.basic_salary.placeholder"
                                )}
                                type="number"
                                onKeyDown={(e) => {
                                  if (
                                    !/[0-9]|Backspace|ArrowLeft|ArrowRight|Delete|ArrowUp|ArrowDown|Tab/.test(
                                      e.key
                                    ) &&
                                    !(
                                      e.ctrlKey &&
                                      (e.key === "v" ||
                                        e.key === "c" ||
                                        e.key === "x" ||
                                        e.key === "a")
                                    )
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                                onInput={(e) => {
                                  if (e.target.value < 0) {
                                    e.target.value = 0;
                                  }
                                  onChangeInput(e, "basicSalary");
                                }}
                              />
                            </Form.Group>
                          </div>
                          <ErrorMessage
                            component={"div"}
                            name="basic_salary"
                            className="errorMsg"
                          />
                          <div className="tax-calculator-input-slider-sec">
                            <tc-range-slider
                              ref={basciSalaryRef}
                              value={basciSalaryRef}
                              mousewheel-disabled="true"
                              min={5000}
                              max={10000000}
                              slider-width="100%"
                              slider-bg-fill="#2F5CFF"
                              generate-labels="true"
                              round="0"
                            ></tc-range-slider>
                          </div>
                        </div>
                        <div className="single-tax-calculator-form-card">
                          <div className="single-tax-calculator-form-header-sec">
                            <h4>
                              {t("gratuity_calculator.dearness_allowance.text")}
                            </h4>
                            <Form.Group
                              className="mb-0"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Field
                                className="form-control"
                                name="dearness_allownces"
                                placeholder={t(
                                  "gratuity_calculator.years_of_service.placeholder"
                                )}
                                type="number"
                                onKeyDown={(e) => {
                                  if (
                                    !/[0-9]|Backspace|ArrowLeft|ArrowRight|Delete|ArrowUp|ArrowDown|Tab/.test(
                                      e.key
                                    ) &&
                                    !(
                                      e.ctrlKey &&
                                      (e.key === "v" ||
                                        e.key === "c" ||
                                        e.key === "x" ||
                                        e.key === "a")
                                    )
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                                onInput={(e) => {
                                  if (e.target.value < 0) {
                                    e.target.value = 0;
                                  }
                                  onChangeInput(e, "dearnessAllownces");
                                }}
                              />
                            </Form.Group>
                          </div>
                          <ErrorMessage
                            component={"div"}
                            name="dearness_allownces"
                            className="errorMsg"
                          />
                          <div className="tax-calculator-input-slider-sec">
                            <tc-range-slider
                              ref={dearnessAllowncesRef}
                              value={dearnessAllowncesRef}
                              mousewheel-disabled="true"
                              min={500}
                              max={10000000}
                              slider-width="100%"
                              slider-bg-fill="#2F5CFF"
                              generate-labels="true"
                              round="0"
                            ></tc-range-slider>
                          </div>
                        </div>
                        <div className="single-tax-calculator-form-card">
                          <div className="single-tax-calculator-form-header-sec">
                            <h4>
                              {t("gratuity_calculator.years_of_service.text")}
                            </h4>
                            <Form.Group
                              className="mb-0"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Field
                                className="form-control"
                                name="gratuity_service_period"
                                placeholder={t(
                                  "gratuity_calculator.dearness_allowance.placeholder"
                                )}
                                type="number"
                                onKeyDown={(e) => {
                                  if (
                                    !/[0-9.]|Backspace|ArrowLeft|ArrowRight|Delete|ArrowUp|ArrowDown|Tab/.test(
                                      e.key
                                    ) ||
                                    (e.key === "-" &&
                                      e.target.selectionStart !== 0)
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                                onInput={(e) => {
                                  if (e.target.value < 0) {
                                    e.target.value = 0;
                                  }
                                  onChangeInput(e, "timePeriod");
                                }}
                              />
                            </Form.Group>
                          </div>
                          <ErrorMessage
                            component={"div"}
                            name="gratuity_service_period"
                            className="errorMsg"
                          />
                          <div className="tax-calculator-input-slider-sec">
                            <tc-range-slider
                              ref={timePeriodRef}
                              value={timePeriodRef}
                              mousewheel-disabled="true"
                              min={5}
                              max={50}
                              slider-width="100%"
                              slider-bg-fill="#2F5CFF"
                              generate-labels="true"
                              round="0"
                            ></tc-range-slider>
                          </div>
                        </div>
                        {/* <div className="d-flex justify-content-end">
                          <Button
                            type="submit"
                            className="default-primary-btn"
                            disabled={gratuityCalculator.buttonDisable}
                          >
                            {gratuityCalculator.buttonDisable ? (
                              <ButtonLoader />
                            ) : (
                              t("gratuity_calculator.calculate")
                            )}
                          </Button>
                        </div> */}
                      </FORM>
                    )}
                  </Formik>
                </Col>
                <Col md={12} xl={6} lg={12}>
                  {gratuityCalculator.loading ? (
                    <div className="single-tax-calculator-form-value-details-sec ">
                      <Skeleton
                        width={200}
                        height={15}
                        baseColor="#202020"
                        highlightColor="#444"
                      />
                      <div>
                        <Skeleton
                          width={175}
                          height={60}
                          baseColor="#202020"
                          highlightColor="#444"
                        />
                      </div>
                    </div>
                  ) : (
                    Object.keys(gratuityCalculator.data).length > 0 && (
                      <div className="single-tax-calculator-form-value-details-sec ">
                        <p>{t("gratuity_calculator.total_gratuity")}</p>
                        <div className="single-tax-calculator-form-value-amount">
                          {formatNumberWithCommas(
                            gratuityCalculator.data.gratuity_amount
                          )}
                        </div>
                      </div>
                    )
                  )}
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <Container>
          <Row>
            <Col lg={12}>
              <div className="static-page-card">
                <div className="pricacy-policy-info">
                  <h4>Description</h4>
                  <p>
                    Any employee of a private company that offers EPF facilities
                    is entitled to receive gratuity after completing 5 years of
                    service. However, employees can also qualify for gratuity
                    before completing five years if they become disabled due to
                    an accident or illness. TaxTrax provides a free gratuity
                    calculator, allowing you to determine the amount your
                    organization owes you. All gratuity payments are governed by
                    the Payment of Gratuity Act of 1972, with the amount based
                    on the last drawn salary and total years of service.
                  </p>
                  <p>
                    If you are eligible for gratuity, it's essential to first
                    determine how much you can claim. Our online gratuity
                    calculator can assist you in this regard, helping you
                    understand what you're entitled to.
                  </p>
                </div>
                <div className="pricacy-policy-info">
                  <p>
                    Any employee of a private company that offers EPF facilities
                    is entitled to receive gratuity after completing 5 years of
                    service. However, employees can also qualify for gratuity
                    before completing five years if they become disabled due to
                    an accident or illness. TaxTrax provides a free gratuity
                    calculator, allowing you to determine the amount your
                    organization owes you. All gratuity payments are governed by
                    the Payment of Gratuity Act of 1972, with the amount based
                    on the last drawn salary and total years of service.
                  </p>
                  <p>
                    TaxTrax, as a frontrunner in financial services, equips you
                    with some of the most advanced tools available. Here’s why
                    our Gratuity Calculator is beneficial for you:
                  </p>
                  <ul>
                    <li>
                      <h4>Data Security: </h4>We prioritize your data security.
                      Our servers are robust and secure, ensuring your
                      information is protected.
                    </li>
                    <li>
                      <h4>User-Friendly: </h4>The calculator is designed for
                      ease of use, requiring no technical skills to operate.
                    </li>
                    <li>
                      <h4>Reliability:</h4>TaxTrax’s gratuity calculator is
                      highly accurate. You can depend on our tool for precise
                      calculations.
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <FAQIndex
          data={[
            {
              question: "Is there a charge for using the gratuity calculator?",
              answer:
                "No, the gratuity calculator provided by TaxTrax is completely free to use. You can access it without any cost.",
            },
            {
              question:
                "How often am I allowed to use the gratuity calculator?",
              answer:
                "You can use the calculator an unlimited number of times. It's available for repeated use to help you calculate your gratuity as needed.",
            },
            {
              question:
                "I have worked for 16 years and 4 months. What will be considered for my gratuity calculation?",
              answer:
                "For gratuity calculation, any fraction of a year greater than six months is rounded off to the next year. Therefore, your employment duration would be considered as 17 years for the purpose of calculating gratuity.",
            },
            {
              question:
                "Can I use this calculator to figure out ex gratia payments?",
              answer:
                "No, the gratuity calculator is specifically designed to calculate gratuity payments based on the terms set out under the Payment of Gratuity Act. For ex gratia payments, which are discretionary bonuses given by employers, you would need to consult different tools or methods based on your company's policies.",
            },
          ]}
        />
      </div>
    </>
  );
};

export default CalculatorIndex;
