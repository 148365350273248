import React, { useState } from "react";
import { Container, Row, Col, Image, Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import "toolcool-range-slider/dist/plugins/tcrs-storage.min.js";
import "toolcool-range-slider";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import { ButtonLoader } from "../../../Helper/Loader";
import { useSelector } from "react-redux";
import { useTranslation } from "react-multi-lang";

const Exemptions = (props) => {
  const t = useTranslation("tax_calculator");
  const taxCalculator = useSelector((state) => state.common.taxCalculator);

  return (
    <>
      <div className="tax-calculator-basic-details-sec">
        <div className="tax-calculator-basic-details-box">
          <div className="tax-calculator-basic-details-body-sec">
            <Row>
              <Col md={6}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>{t("exemptions.deduction_nps")}</Form.Label>
                  <Field
                    type="number"
                    className="form-control"
                    name="deduction_NPS"
                    placeholder="Enter  NPS"
                    onKeyDown={(e) => {
                      if (
                        !/[0-9]|Backspace|ArrowLeft|ArrowRight|Delete|ArrowUp|ArrowDown|Tab/.test(
                          e.key
                        ) &&
                        !(
                          e.ctrlKey &&
                          (e.key === "v" ||
                            e.key === "c" ||
                            e.key === "x" ||
                            e.key === "a")
                        )
                      ) {
                        e.preventDefault();
                      }
                    }}
                    onInput={(e) => {
                      if (e.target.value <= 0 && e.target.value.length > 1) {
                        e.target.value = 0;
                      }
                    }}
                  />
                  <ErrorMessage
                    component={"div"}
                    name="deduction_NPS"
                    className="errorMsg"
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>{t("exemptions.interest_on_loan")}</Form.Label>
                  <Field
                    type="number"
                    className="form-control"
                    name="deduction_interest_on_loan"
                    placeholder="Enter interest on loan "
                    onKeyDown={(e) => {
                      if (
                        !/[0-9]|Backspace|ArrowLeft|ArrowRight|Delete|ArrowUp|ArrowDown|Tab/.test(
                          e.key
                        ) &&
                        !(
                          e.ctrlKey &&
                          (e.key === "v" ||
                            e.key === "c" ||
                            e.key === "x" ||
                            e.key === "a")
                        )
                      ) {
                        e.preventDefault();
                      }
                    }}
                    onInput={(e) => {
                      if (e.target.value <= 0 && e.target.value.length > 1) {
                        e.target.value = 0;
                      }
                    }}
                  />
                  <ErrorMessage
                    component={"div"}
                    name="deduction_interest_on_loan"
                    className="errorMsg"
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>{t("exemptions.deduction_80g")}</Form.Label>
                  <Field
                    type="number"
                    className="form-control"
                    name="deduction_80G"
                    placeholder="Enter deduction 80G"
                    onKeyDown={(e) => {
                      if (
                        !/[0-9]|Backspace|ArrowLeft|ArrowRight|Delete|ArrowUp|ArrowDown|Tab/.test(
                          e.key
                        ) &&
                        !(
                          e.ctrlKey &&
                          (e.key === "v" ||
                            e.key === "c" ||
                            e.key === "x" ||
                            e.key === "a")
                        )
                      ) {
                        e.preventDefault();
                      }
                    }}
                    onInput={(e) => {
                      if (e.target.value <= 0 && e.target.value.length > 1) {
                        e.target.value = 0;
                      }
                    }}
                  />
                  <ErrorMessage
                    component={"div"}
                    name="deduction_80G"
                    className="errorMsg"
                  />
                </Form.Group>
              </Col>
            </Row>
          </div>
          <div className="tax-calculator-basic-detail-footer-sec">
            <Button
              type="submit"
              onClick={() => props.setStep(2)}
              className="default-grey-btn"
              disabled={taxCalculator.buttonDisable}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                fill="none"
                viewBox="0 0 12 11"
              >
                <path
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M11.006 5.492l-9.722.015M4.889 1.613L1.006 5.508 4.901 9.39"
                ></path>
              </svg>
              {t("exemptions.back")}
            </Button>
            <Button
              type="submit"
              className="default-primary-btn"
              disabled={taxCalculator.buttonDisable}
            >
              {taxCalculator.buttonDisable ? (
                <ButtonLoader />
              ) : (
                t("exemptions.calculate")
              )}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Exemptions;
