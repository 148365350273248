import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Container, Button, Form, Accordion } from "react-bootstrap";
import CustomLazyLoad from "../../../Helper/CustomLazyLoad";
import Skeleton from "react-loading-skeleton";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-multi-lang";
import { useDispatch, useSelector } from "react-redux";
import {
  gstCalculatorStart,
  gstCalculatorSuccess,
} from "../../../../store/slices/CommonSlice";
import { ButtonLoader } from "../../../Helper/Loader";
import FAQIndex from "../FAQIndex";
import { Link } from "react-router-dom";
import formatNumberWithCommas from "../../../FormatNumber";

const CalculatorIndex = (props) => {
  const t = useTranslation("tax_calculator");
  const amountRef = useRef(null);
  const gstRateRef = useRef(null);
  const dispatch = useDispatch();
  const formRef = useRef();
  const gstCalculator = useSelector((state) => state.common.gstCalculator);

  useEffect(() => {
    const onChange = (evt, field) => {
      const customEvent = evt;
      switch (field) {
        case "amount":
          formRef.current.setFieldValue("amount", customEvent.detail.value);
          break;
        case "gstRate":
          formRef.current.setFieldValue("gst_rate", customEvent.detail.value);
          break;
      }
      setTimeout(() => {
        formRef.current.submitForm();
      }, 1);
    };
    amountRef.current?.addEventListener("change", (evt) =>
      onChange(evt, "amount")
    );
    gstRateRef.current?.addEventListener("change", (evt) =>
      onChange(evt, "gstRate")
    );
    return () => {
      amountRef.current?.removeEventListener("change", onChange);
      gstRateRef.current?.removeEventListener("change", onChange);
    };
  }, []);

  useEffect(() => {
    calculateFromExcludingGst(formRef.current.values);
    // dispatch(gstCalculatorStart(formRef.current.values));
  }, []);

  const onChangeInput = (event, field) => {
    const value = !isNaN(parseInt(event.target.value))
      ? parseInt(event.target.value)
      : "";
    switch (field) {
      case "amount":
        formRef.current.setFieldValue("amount", value);
        amountRef.current.value = value;
        break;
      case "gstRate":
        formRef.current.setFieldValue("rate_of_interest", value);
        gstRateRef.current.value = value;
        break;
    }
    setTimeout(() => {
      formRef.current.submitForm();
    }, 1);
  };

  const handleSubmit = (values) => {
    if (formRef.current.isValid)
      if (values.is_including_gst == "True") {
        calculateFromIncludingGst(values);
      } else {
        calculateFromExcludingGst(values);
      }
    // dispatch(gstCalculatorStart(values));
  };

  const gstSchema = Yup.object().shape({
    amount: Yup.string()
      .test(
        "min",
        t("gst_calculator.invalid_min", { value: 5000 }),
        (value) => {
          return value >= 5000;
        }
      )
      .test(
        "max",
        t("gst_calculator.invalid_max", {
          value: 500000,
          name: "Amount",
        }),
        (value) => {
          return value <= 500000;
        }
      )
      .required(t("gst_calculator.required")),
    gst_rate: Yup.string()
      .test("min", t("gst_calculator.invalid_min", { value: 1 }), (value) => {
        return value >= 1;
      })
      .test(
        "max",
        t("gst_calculator.invalid_max", {
          value: 30,
          name: "Gst rate",
        }),
        (value) => {
          return value <= 30;
        }
      )
      .required(t("gst_calculator.required")),
  });

  const calculateFromIncludingGst = (values) => {
    const { amount, gst_rate } = values;
    const gstAmount = (amount * gst_rate) / 100;
    const amountIncludingGst = amount + gstAmount;
    return dispatch(
      gstCalculatorSuccess({
        gst_amount: Math.round(gstAmount),
        final_amount: Math.round(amountIncludingGst),
      })
    );
  };

  const calculateFromExcludingGst = (values) => {
    const { amount, gst_rate, is_including_gst } = values;
    const amountExcludingGst = amount / (1 + gst_rate / 100);
    const gstAmount = amount - amountExcludingGst;
    return dispatch(
      gstCalculatorSuccess({
        gst_amount: Math.round(gstAmount),
        final_amount: Math.round(amountExcludingGst),
      })
    );
  };

  return (
    <>
      <div className="single-tax-calculator">
        <div className="sm-padding single-tax-calculator-sec">
          <Container>
            <div className="single-tax-calculator-box">
              <div className="single-tax-calculator-header">
                <Row>
                  <Col md={6}>
                    <div className="d-flex other-calculator-btn-sec">
                      <Link
                        to="/tax-calculator"
                        className="other-calculator-btn"
                      >
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            enableBackground="new 0 0 512 512"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="#fff"
                              d="M10.957 12.354a.5.5 0 010-.708l4.586-4.585a1.5 1.5 0 00-2.121-2.122L8.836 9.525a3.505 3.505 0 000 4.95l4.586 4.586a1.5 1.5 0 002.121-2.122z"
                              data-original="#000000"
                            ></path>
                          </svg>
                        </span>
                        <span>Other Calculators</span>
                      </Link>
                    </div>
                    <div className="single-tax-calculator-info-sec">
                      <h1>{t("gst_calculator.heading")}</h1>
                      <p>{t("gst_calculator.content")}</p>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="single-tax-calculator-header-img-sec">
                      <CustomLazyLoad
                        className="single-tax-calculator-header-img"
                        src={
                          window.location.origin +
                          "/img/single-tax-calculator-header-img.png"
                        }
                        type="image/svg"
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="single-tax-calculator-form-box line-stright line-bit-left">
              <Row className="align-items-center">
                <Col
                  md={12}
                  xl={6}
                  lg={12}
                  className="resp-margin-btm-small-lap"
                >
                  <Formik
                    initialValues={{
                      amount: 5000,
                      gst_rate: 1,
                      is_including_gst: "False",
                    }}
                    innerRef={formRef}
                    validationSchema={gstSchema}
                    onSubmit={handleSubmit}
                  >
                    {({
                      setFieldValue,
                      values,
                      resetForm,
                      submitForm,
                      isValid,
                    }) => (
                      <FORM className="single-tax-calculator-form line-stright-right ">
                        <div className="single-tax-calculator-form-card mb-4">
                          <div className="single-tax-calculator-form-header-sec single-tax-calculator-form-header-yes-or-no-sec">
                            <div className="yes-or-no-radio-btn-sec">
                              <div class="pretty p-default p-round p-smooth">
                                <Field
                                  type="radio"
                                  name="is_including_gst"
                                  className="form-control"
                                  onChange={() => {
                                    submitForm();
                                    setFieldValue("is_including_gst", "True");
                                  }}
                                  checked={values.is_including_gst == "True"}
                                />
                                <div class="state p-primary-o">
                                  <label>
                                    {t("gst_calculator.gst_exclusive")}
                                  </label>
                                </div>
                              </div>
                              <div class="pretty p-default p-round p-smooth">
                                <Field
                                  type="radio"
                                  name="is_including_gst"
                                  onChange={() => {
                                    submitForm();
                                    setFieldValue("is_including_gst", "False");
                                  }}
                                  className="form-control"
                                  checked={values.is_including_gst == "False"}
                                />
                                <div class="state p-primary-o">
                                  <label>
                                    {t("gst_calculator.gst_inclusive")}
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="single-tax-calculator-form-card">
                          <div className="single-tax-calculator-form-header-sec">
                            <h4>{t("gst_calculator.amount.text")}</h4>
                            <Form.Group
                              className="mb-0"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Field
                                className="form-control"
                                name="amount"
                                placeholder={t(
                                  "gst_calculator.amount.placeholder"
                                )}
                                type="number"
                                onKeyDown={(e) => {
                                  if (
                                    !/[0-9]|Backspace|ArrowLeft|ArrowRight|Delete|ArrowUp|ArrowDown|Tab/.test(
                                      e.key
                                    ) &&
                                    !(
                                      e.ctrlKey &&
                                      (e.key === "v" ||
                                        e.key === "c" ||
                                        e.key === "x" ||
                                        e.key === "a")
                                    )
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                                onInput={(e) => {
                                  if (e.target.value < 0) {
                                    e.target.value = 0;
                                  }
                                  onChangeInput(e, "amount");
                                }}
                              />
                            </Form.Group>
                          </div>
                          <ErrorMessage
                            component={"div"}
                            name="amount"
                            className="errorMsg"
                          />
                          <div className="tax-calculator-input-slider-sec">
                            <tc-range-slider
                              ref={amountRef}
                              value={amountRef}
                              mousewheel-disabled="true"
                              min={5000}
                              max={500000}
                              slider-width="100%"
                              slider-bg-fill="#2F5CFF"
                              generate-labels="true"
                              round="0"
                            ></tc-range-slider>
                          </div>
                        </div>
                        <div className="single-tax-calculator-form-card">
                          <div className="single-tax-calculator-form-header-sec">
                            <h4>{t("gst_calculator.tax_slab.text")}</h4>
                            <Form.Group
                              className="mb-0"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Field
                                className="form-control"
                                name="gst_rate"
                                placeholder={t(
                                  "gst_calculator.tax_slab.placeholder"
                                )}
                                type="number"
                                onKeyDown={(e) => {
                                  if (
                                    !/[0-9]|Backspace|ArrowLeft|ArrowRight|Delete|ArrowUp|ArrowDown|Tab/.test(
                                      e.key
                                    ) &&
                                    !(
                                      e.ctrlKey &&
                                      (e.key === "v" ||
                                        e.key === "c" ||
                                        e.key === "x" ||
                                        e.key === "a")
                                    )
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                                onInput={(e) => {
                                  if (e.target.value < 0) {
                                    e.target.value = 0;
                                  }
                                  onChangeInput(e, "gstRate");
                                }}
                              />
                            </Form.Group>
                          </div>
                          <ErrorMessage
                            component={"div"}
                            name="gst_rate"
                            className="errorMsg"
                          />
                          <div className="tax-calculator-input-slider-sec">
                            <tc-range-slider
                              ref={gstRateRef}
                              value={gstRateRef}
                              mousewheel-disabled="true"
                              min={1}
                              max={30}
                              slider-width="100%"
                              slider-bg-fill="#2F5CFF"
                              generate-labels="true"
                              round="0"
                            ></tc-range-slider>
                          </div>
                        </div>
                        {/* <div className="d-flex justify-content-end mt-3">
                          <Button
                            type="submit"
                            className="default-primary-btn"
                            disabled={gstCalculator.buttonDisable}
                          >
                            {gstCalculator.buttonDisable ? (
                              <ButtonLoader />
                            ) : (
                              t("gst_calculator.calculate")
                            )}
                          </Button>
                        </div> */}
                      </FORM>
                    )}
                  </Formik>
                </Col>
                <Col md={12} xl={6} lg={12}>
                  {gstCalculator.loading ? (
                    <>
                      <div className="single-tax-calculator-form-value-details-sec ">
                        <Skeleton
                          width={130}
                          height={20}
                          baseColor="#202020"
                          highlightColor="#444"
                        />
                        <Skeleton
                          width={140}
                          height={55}
                          baseColor="#202020"
                          highlightColor="#444"
                        />
                      </div>
                      <div className="single-tax-calculator-form-value-details-sec margin-top-sm">
                        <Skeleton
                          width={130}
                          height={20}
                          baseColor="#202020"
                          highlightColor="#444"
                        />
                        <Skeleton
                          width={140}
                          height={55}
                          baseColor="#202020"
                          highlightColor="#444"
                        />
                      </div>
                    </>
                  ) : (
                    Object.keys(gstCalculator.data).length > 0 && (
                      <>
                        <div className="single-tax-calculator-form-value-details-sec ">
                          <p>{t("gst_calculator.total_gst")}</p>
                          <div className="single-tax-calculator-form-value-amount">
                            {formatNumberWithCommas(
                              gstCalculator.data.gst_amount
                            )}
                          </div>
                        </div>
                        <div className="single-tax-calculator-form-value-details-sec margin-top-sm">
                          <p>{t("gst_calculator.post_gst_amount")}</p>
                          <div className="single-tax-calculator-form-value-amount">
                            {formatNumberWithCommas(
                              gstCalculator.data.final_amount
                            )}
                          </div>
                        </div>
                      </>
                    )
                  )}
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <Container>
          <Row>
            <Col lg={12}>
              <div className="static-page-card">
                <div className="pricacy-policy-info">
                  <h4>Description</h4>
                  <p>
                    The GST Act was enacted by the Indian Parliament on March
                    29, 2017, and came into effect on July 1 of that year. The
                    Goods and Services Tax (GST) is an indirect tax imposed by
                    the Indian government on the purchase of all goods and
                    services within the country. It consolidates several former
                    indirect taxes like sales tax, VAT, and Excise Duty into a
                    single tax system.
                  </p>
                  <p>
                    All businesses operating in India are required to register
                    for GST and obtain a GST Identification Number (GSTIN).
                    Consumers are responsible for paying this tax on all
                    purchased goods and services. Therefore, accurately
                    calculating GST is crucial. You can use a GST calculator to
                    assist with this calculation.
                  </p>
                </div>
                <div className="pricacy-policy-info">
                  <h4>How Does a GST Calculator Benefit You?</h4>
                  <p>
                    Whenever you purchase a product or service, GST is applied
                    to the price. Understanding exactly how much tax you're
                    paying on your purchases can be very beneficial. An Indian
                    GST calculator is a helpful tool in this regard.
                  </p>
                  <ul>
                    <li>
                      <h4>Accurate Tax Calculations:</h4>The calculator provides
                      a precise estimate of the GST you need to pay.
                    </li>
                    <li>
                      <h4>Time Efficiency: </h4>It simplifies the process,
                      saving you time when calculating GST on various
                      transactions.
                    </li>
                    <li>
                      <h4>Fraud Prevention: </h4>
                      By knowing the correct amount of tax applicable, you can
                      prevent being overcharged, making it a crucial tool for
                      informed consumers.
                    </li>
                  </ul>
                  <h4>Benefits of Using TaxTrax’s GST Calculator</h4>
                  <ul>
                    <li>
                      <h4>Determine Your Tax Burden: </h4>The calculator enables
                      you to easily ascertain the amount of GST you are paying
                      on purchases.
                    </li>
                    <li>
                      <h4>IEfficiency and Accuracy: </h4>It provides quick and
                      precise calculations, saving you significant time.
                    </li>
                    <li>
                      <h4>Protection Against Fraud: </h4>By accurately
                      calculating the correct GST amount, you can avoid falling
                      victim to fraudulent overcharging.
                    </li>
                    <li>
                      <h4>Uniform Tax Framework: </h4> GST has revolutionized
                      the tax framework in India, bringing all businesses, big
                      or small, under a unified tax system. Using the online GST
                      calculator, you can effortlessly find out the tax amount
                      on the goods and services you acquire.
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <FAQIndex
          data={[
            {
              question: "Why was GST introduced?",
              answer:
                "GST was introduced to simplify the complex and multiple indirect tax structures that prevailed in India. By consolidating several taxes into one unified system, it aims to eliminate the cascading effect of taxes, thereby reducing the overall tax burden on goods and services and making the tax regime more efficient and business-friendly.",
            },
            {
              question: "Is obtaining a GSTIN mandatory?",
              answer:
                "Yes, obtaining a GST Identification Number (GSTIN) is mandatory for all businesses that are registered under GST. It is essential for businesses with a turnover exceeding the threshold limit prescribed by the GST law, as it helps in the systemic administration of taxes and compliance with the regulations.",
            },
            {
              question: "What taxes are subsumed under GST",
              answer:
                "GST has absorbed various central and state taxes including, but not limited to, Central Excise Duty, Additional Excise Duties, Service Tax, Additional Customs Duty (commonly known as Countervailing Duty), Special Additional Duty of Customs, State VAT, Central Sales Tax, Luxury Tax, and Entertainment Tax (except those levied by local bodies).",
            },
            {
              question: "What are the tax slabs under GST?",
              answer:
                "GST is levied at multiple rates, primarily four slab rates—5%, 12%, 18%, and 28%. Certain essential goods and services are taxed at lower rates or are zero-rated, while luxury and sin goods attract the highest rates along with additional cesses.",
            },
          ]}
        />
      </div>
    </>
  );
};

export default CalculatorIndex;
