import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Container,
  Accordion,
  Form,
  Table,
  InputGroup,
  Button,
} from "react-bootstrap";
import CustomLazyLoad from "../../../Helper/CustomLazyLoad";
import { Link, useLocation } from "react-router-dom";
import Select from "react-select";
import FAQIndex from "../FAQIndex";

const CalculatorIndex = (props) => {
  const customStyles = {
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "8px",
      overflow: "hidden",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      minWidth: 250,
      fontSize: "0.85em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "3px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "auto" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "#3E3D45!important",
      border: "1px solid #1A203D99!important",
      borderRadius: "8px!important",
      boxShadow: "none!important",
      height: "32px",
      minWidth: "150px",
      cursor: "pointer",
      fontSize: "0.9em",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#fff",
      fontSize: "1.1em",
      fontWeight: "600",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#fff",
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
      fontSize: "0.9em",
      fontWeight: "600",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      color: "#fff!important",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      fill: "#fff!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#fff",
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isSelected ? "#f7f7f7" : "#fff",
        color: "#000",
      };
    },
  };

  const options = [
    { value: "BSE", label: "BSE" },
    { value: "Sell", label: "Sell" },
    { value: "Gain", label: "Gain" },
  ];
  return (
    <>
      <div className="single-tax-calculator">
        <div className="sm-padding single-tax-calculator-sec">
          <Container>
            <div className="single-tax-calculator-box">
              <div className="single-tax-calculator-header">
                <Row>
                  <Col md={6}>
                    <div className="single-tax-calculator-info-sec">
                      <h1>Equity Margin Calculator</h1>
                      <p>
                        Equity margin calculator helps you determine the margin
                        you get for delivery and for intraday based on your
                        details.The margin available is less in case of highly
                        volatile stocks and vice versa.
                      </p>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="single-tax-calculator-header-img-sec">
                      <CustomLazyLoad
                        className="single-tax-calculator-header-img"
                        src={
                          window.location.origin +
                          "/img/single-tax-calculator-header-img.png"
                        }
                        type="image/svg"
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="single-tax-calculator-form-box ">
              <Row className="align-items-center">
                <Col
                  md={12}
                  xl={12}
                  lg={12}
                  className="resp-margin-btm-small-lap"
                >
                  <div className="margin-table-calculator-frame">
                    <Table>
                      <thead>
                        <tr>
                          <th>Company</th>
                          <th>Order Price</th>
                          <th>Shares</th>
                          <th>Buy/Sell</th>
                          <th>Exchange</th>
                          <th>Margin for Delivery</th>
                          <th>Margin for Intraday</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <div className="margin-table-content">
                              <p>Tata Steel Ltd</p>
                            </div>
                          </td>
                          <td>
                            <div className="margin-table-content">
                              <Form.Control type="text" value="23" />
                            </div>
                          </td>
                          <td>
                            <div className="margin-table-content">
                              <Form.Control type="text" value="23" />
                            </div>
                          </td>
                          <td>
                            <div className="margin-table-content">
                              <Select
                                options={options}
                                styles={customStyles}
                                isSearchable={false}
                                isClearable
                                placeholder={
                                  <div className="placeholder-flex">Select</div>
                                }
                                // menuIsOpen={true}
                              />
                            </div>
                          </td>
                          <td>
                            <div className="margin-table-content">
                              <Select
                                options={options}
                                styles={customStyles}
                                isSearchable={false}
                                isClearable
                                placeholder={
                                  <div className="placeholder-flex">Select</div>
                                }
                                // menuIsOpen={true}
                              />
                            </div>
                          </td>
                          <td>
                            <div className="margin-table-content">
                              <div className="margin-count-value">
                                <span> 1x </span> <span> ₹9,00,000 </span>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="margin-table-content">
                              <div className="margin-count-value">
                                <span> 1x </span> <span> ₹9,00,000 </span>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="margin-table-content">
                              <a href="#">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  enableBackground="new 0 0 512 512"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    fill="#ff4a4a"
                                    d="M20 6H4v2h2v11c0 1.103.897 2 2 2h8c1.103 0 2-.897 2-2V8h2zm-3.999 13H8V8h8zM8 3h8v2H8z"
                                    data-original="#000000"
                                  ></path>
                                </svg>
                              </a>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="margin-table-content">
                              <p>Aditya Birla</p>
                            </div>
                          </td>
                          <td>
                            <div className="margin-table-content">
                              <Form.Control type="text" value="23" />
                            </div>
                          </td>
                          <td>
                            <div className="margin-table-content">
                              <Form.Control type="text" value="23" />
                            </div>
                          </td>
                          <td>
                            <div className="margin-table-content">
                              <Select
                                options={options}
                                styles={customStyles}
                                isSearchable={false}
                                isClearable
                                placeholder={
                                  <div className="placeholder-flex">Select</div>
                                }
                                // menuIsOpen={true}
                              />
                            </div>
                          </td>
                          <td>
                            <div className="margin-table-content">
                              <Select
                                options={options}
                                styles={customStyles}
                                isSearchable={false}
                                isClearable
                                placeholder={
                                  <div className="placeholder-flex">Select</div>
                                }
                                // menuIsOpen={true}
                              />
                            </div>
                          </td>
                          <td>
                            <div className="margin-table-content">
                              <div className="margin-count-value">
                                <span> 1x </span> <span> ₹9,00,000 </span>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="margin-table-content">
                              <div className="margin-count-value">
                                <span> 1x </span> <span> ₹9,00,000 </span>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="margin-table-content">
                              <a href="#">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  enableBackground="new 0 0 512 512"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    fill="#ff4a4a"
                                    d="M20 6H4v2h2v11c0 1.103.897 2 2 2h8c1.103 0 2-.897 2-2V8h2zm-3.999 13H8V8h8zM8 3h8v2H8z"
                                    data-original="#000000"
                                  ></path>
                                </svg>
                              </a>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="margin-table-content">
                              <p>Infosys</p>
                            </div>
                          </td>
                          <td>
                            <div className="margin-table-content">
                              <Form.Control type="text" value="23" />
                            </div>
                          </td>
                          <td>
                            <div className="margin-table-content">
                              <Form.Control type="text" value="23" />
                            </div>
                          </td>
                          <td>
                            <div className="margin-table-content">
                              <Select
                                options={options}
                                styles={customStyles}
                                isSearchable={false}
                                isClearable
                                placeholder={
                                  <div className="placeholder-flex">Select</div>
                                }
                                // menuIsOpen={true}
                              />
                            </div>
                          </td>
                          <td>
                            <div className="margin-table-content">
                              <Select
                                options={options}
                                styles={customStyles}
                                isSearchable={false}
                                isClearable
                                placeholder={
                                  <div className="placeholder-flex">Select</div>
                                }
                                // menuIsOpen={true}
                              />
                            </div>
                          </td>
                          <td>
                            <div className="margin-table-content">
                              <div className="margin-count-value">
                                <span> 1x </span> <span> ₹9,00,000 </span>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="margin-table-content">
                              <div className="margin-count-value">
                                <span> 1x </span> <span> ₹9,00,000 </span>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="margin-table-content">
                              <a href="#">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  enableBackground="new 0 0 512 512"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    fill="#ff4a4a"
                                    d="M20 6H4v2h2v11c0 1.103.897 2 2 2h8c1.103 0 2-.897 2-2V8h2zm-3.999 13H8V8h8zM8 3h8v2H8z"
                                    data-original="#000000"
                                  ></path>
                                </svg>
                              </a>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="margin-table-content">
                              <InputGroup>
                                <InputGroup.Text id="basic-addon1">
                                  {" "}
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    enableBackground="new 0 0 512 512"
                                    viewBox="0 0 512 512"
                                  >
                                    <g fill="#fff">
                                      <path
                                        d="M209.552 386.103C112.201 386.103 33 306.902 33 209.552 33 112.201 112.201 33 209.552 33c97.35 0 176.551 79.201 176.551 176.552 0 97.35-79.201 176.551-176.551 176.551zm0-317.793c-77.877 0-141.242 63.365-141.242 141.242s63.365 141.241 141.242 141.241 141.241-63.364 141.241-141.241S287.446 68.31 209.552 68.31z"
                                        data-original="#000000"
                                      ></path>
                                      <path
                                        d="M461.887 479.543a17.606 17.606 0 01-12.482-5.173L313.566 338.531a17.647 17.647 0 01-5.17-12.482 17.651 17.651 0 0124.408-16.309 17.65 17.65 0 015.727 3.827L474.37 449.406a17.636 17.636 0 01-2.661 27.175 17.63 17.63 0 01-9.822 2.962z"
                                        data-original="#000000"
                                      ></path>
                                    </g>
                                  </svg>
                                </InputGroup.Text>
                                <Form.Control
                                  placeholder="Search Company"
                                  aria-label="Username"
                                  aria-describedby="basic-addon1"
                                />
                              </InputGroup>
                            </div>
                          </td>
                          <td>
                            <div className="margin-table-content">
                              <Form.Control type="text" value="23" />
                            </div>
                          </td>
                          <td>
                            <div className="margin-table-content">
                              <Form.Control type="text" value="23" />
                            </div>
                          </td>
                          <td>
                            <div className="margin-table-content">
                              <Select
                                options={options}
                                styles={customStyles}
                                isSearchable={false}
                                isClearable
                                placeholder={
                                  <div className="placeholder-flex">Select</div>
                                }
                                // menuIsOpen={true}
                              />
                            </div>
                          </td>
                          <td>
                            <div className="margin-table-content">
                              <Select
                                options={options}
                                styles={customStyles}
                                isSearchable={false}
                                isClearable
                                placeholder={
                                  <div className="placeholder-flex">Select</div>
                                }
                                // menuIsOpen={true}
                              />
                            </div>
                          </td>
                          <td>
                            <div className="margin-table-content">
                              <div className="margin-count-value">
                                <span> 1x </span> <span> ₹9,00,000 </span>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="margin-table-content">
                              <div className="margin-count-value">
                                <span> 1x </span> <span> ₹9,00,000 </span>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="margin-table-content">
                              <a href="#">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  enableBackground="new 0 0 512 512"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    fill="#ff4a4a"
                                    d="M20 6H4v2h2v11c0 1.103.897 2 2 2h8c1.103 0 2-.897 2-2V8h2zm-3.999 13H8V8h8zM8 3h8v2H8z"
                                    data-original="#000000"
                                  ></path>
                                </svg>
                              </a>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                      <div className="margin-btn-sec">
                        <Button className="margin-btn">
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              enableBackground="new 0 0 512 512"
                              viewBox="0 0 4000 4000"
                            >
                              <path
                                fill="#2f5cff"
                                d="M3100 1890h-990V900c0-60.747-49.253-110-110-110s-110 49.253-110 110v990H900c-60.747 0-110 49.253-110 110s49.253 110 110 110h990v990c0 60.747 49.253 110 110 110s110-49.253 110-110v-990h990c60.747 0 110-49.253 110-110s-49.253-110-110-110z"
                                data-original="#1e2959"
                              ></path>
                            </svg>
                          </span>
                          Add Company
                        </Button>
                      </div>
                    </Table>
                  </div>
                </Col>
                <Col md={12} xl={12} lg={12}>
                  <div className="d-flex align-items-center justify-content-center gap-4">
                    <div className="single-tax-calculator-form-value-details-sec ">
                      <p>Total Margin for Delivery</p>
                      <div className="single-tax-calculator-form-value-amount">
                        ₹9,00,000
                      </div>
                    </div>
                    <div className="single-tax-calculator-form-value-details-sec ">
                      <p>Total Margin for Intraday</p>
                      <div className="single-tax-calculator-form-value-amount">
                        ₹9,00,000
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        {/* <FAQIndex /> */}
      </div>
    </>
  );
};

export default CalculatorIndex;
