import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Container, Button, Form, Accordion } from "react-bootstrap";
import CustomLazyLoad from "../../../Helper/CustomLazyLoad";
import Skeleton from "react-loading-skeleton";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-multi-lang";
import { useDispatch, useSelector } from "react-redux";
import { ppfCalculatorStart, ppfCalculatorSuccess } from "../../../../store/slices/CommonSlice";
import { ButtonLoader } from "../../../Helper/Loader";
import PieChart from "../PieChart";
import FAQIndex from "../FAQIndex";
import { Link } from "react-router-dom";
import formatNumberWithCommas from "../../../FormatNumber";

const CalculatorIndex = (props) => {
  const t = useTranslation("tax_calculator");
  const principleRef = useRef(null);
  const timePeriodRef = useRef(null);
  const formRef = useRef();
  const dispatch = useDispatch();
  const ppfCalculator = useSelector((state) => state.common.ppfCalculator);

  useEffect(() => {
    const onChange = (event, field) => {
      const customEvent = event;
      switch (field) {
        case "principal":
          formRef.current.setFieldValue("principal", customEvent.detail.value);
          formRef.current.submitForm();
          break;
        case "timePeriod":
          formRef.current.setFieldValue("time_period",customEvent.detail.value);
          formRef.current.submitForm();
          break;
      }
    };
    principleRef.current?.addEventListener("change", (event) =>
      onChange(event, "principal")
    );
    timePeriodRef.current?.addEventListener("change", (event) =>
      onChange(event, "timePeriod")
    );
    return () => {
      principleRef.current?.removeEventListener("change", onChange);
      timePeriodRef.current?.removeEventListener("change", onChange);
    };
  }, []);

  useEffect(() => {
    // dispatch(ppfCalculatorStart(formRef.current.values));
    calculate(formRef.current.values);
  }, []);

  const onChangeInput = (event, field) => {
    const value = !isNaN(parseInt(event.target.value))
      ? parseInt(event.target.value)
      : "";
    switch (field) {
      case "principal":
        formRef.current.setFieldValue("principal", value);
        principleRef.current.value = value;
        break;
      case "timePeriod":
        formRef.current.setFieldValue("time_period", value);
        timePeriodRef.current.value = value;
        break;
    }
  };

  const handleSubmit = (values) => {
    // dispatch(ppfCalculatorStart(values));
    formRef.current.isValid && calculate(values);
  };

  const ppfSchema = Yup.object().shape({
    principal: Yup.string()
      .test("min", t("ppf_calculator.invalid_min", { value: 500 }), (value) => {
        return value >= 500;
      })
      .test(
        "max",
        t("ppf_calculator.invalid_max", {
          value: 150000,
          name: "Monthly investment",
        }),
        (value) => {
          return value <= 150000;
        }
      )
      .required(t("ppf_calculator.required")),
    time_period: Yup.string()
      .test("min", t("ppf_calculator.invalid_min", { value: 15 }), (value) => {
        return value >= 15;
      })
      .test(
        "max",
        t("ppf_calculator.invalid_max", { value: 50, name: "Time period" }),
        (value) => {
          return value <= 50;
        }
      )
      .required(t("ppf_calculator.required")),
  });

  const calculate = (values) => {
      const { principal, time_period, rate_of_interest } = values;
  
      if (!principal || !time_period || !rate_of_interest) {
          return;
      }
  
      let rate = rate_of_interest / 100;
  
      const maturity_amount = principal * 
          ((Math.pow((1 + rate), time_period) - 1) * ((1 + rate) / rate));
  
      const invested_amount = principal * time_period;
      const estimated_return = maturity_amount - invested_amount;
  
      const rounded_maturity_amount = Math.round(maturity_amount);
      const rounded_invested_amount = Math.round(invested_amount);
      const rounded_estimated_return = Math.round(estimated_return);
  
      const result = {
          maturity_amount: rounded_maturity_amount,
          invested_amount: rounded_invested_amount,
          estimated_return: rounded_estimated_return
      };
  
      dispatch(ppfCalculatorSuccess(result));
  }

  return (
    <>
      <div className="single-tax-calculator">
        <div className="sm-padding single-tax-calculator-sec">
          <Container>
            <div className="single-tax-calculator-box">
              <div className="single-tax-calculator-header">
                <Row>
                  <Col md={6}>
                    <div className="d-flex other-calculator-btn-sec">
                      <Link
                        to="/tax-calculator"
                        className="other-calculator-btn"
                      >
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            enableBackground="new 0 0 512 512"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="#fff"
                              d="M10.957 12.354a.5.5 0 010-.708l4.586-4.585a1.5 1.5 0 00-2.121-2.122L8.836 9.525a3.505 3.505 0 000 4.95l4.586 4.586a1.5 1.5 0 002.121-2.122z"
                              data-original="#000000"
                            ></path>
                          </svg>
                        </span>
                        <span>Other Calculators</span>
                      </Link>
                    </div>
                    <div className="single-tax-calculator-info-sec">
                      <h1>{t("ppf_calculator.heading")}</h1>
                      <p>{t("ppf_calculator.content")}</p>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="single-tax-calculator-header-img-sec">
                      <CustomLazyLoad
                        className="single-tax-calculator-header-img"
                        src={
                          window.location.origin +
                          "/img/single-tax-calculator-header-img.png"
                        }
                        type="image/svg"
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="single-tax-calculator-form-box  line-stright calculator-space-sm">
              <Row className="align-items-center g-5">
                <Col md={6} lg={6}>
                  <Formik
                    initialValues={{
                      principal: 1000,
                      rate_of_interest: 7.1,
                      time_period: 15,
                    }}
                    innerRef={formRef}
                    validationSchema={ppfSchema}
                    onSubmit={handleSubmit}
                  >
                    {({ setFieldValue, values, resetForm, submitForm }) => (
                      <FORM className="single-tax-calculator-form">
                        <div className="single-tax-calculator-form-card">
                          <div className="single-tax-calculator-form-header-sec">
                            <h4>{t("ppf_calculator.investment.text")}</h4>
                            <Form.Group
                              className="mb-0"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Field
                                className="form-control"
                                name="principal"
                                placeholder={t(
                                  "ppf_calculator.investment.placeholder"
                                )}
                                type="number"
                                onKeyDown={(e) => {
                                  if (
                                    !/[0-9]|Backspace|ArrowLeft|ArrowRight|Delete|ArrowUp|ArrowDown|Tab/.test(
                                      e.key
                                    ) &&
                                    !(
                                      e.ctrlKey &&
                                      (e.key === "v" ||
                                        e.key === "c" ||
                                        e.key === "x" ||
                                        e.key === "a")
                                    )
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                                onInput={(e) => {
                                  if (e.target.value < 0) {
                                    e.target.value = 0;
                                  }
                                  onChangeInput(e, "principal");
                                  submitForm();
                                }}
                              />
                            </Form.Group>
                          </div>
                          <ErrorMessage
                            component={"div"}
                            name="principal"
                            className="errorMsg"
                          />
                          <div className="tax-calculator-input-slider-sec">
                            <tc-range-slider
                              ref={principleRef}
                              value={principleRef}
                              mousewheel-disabled="true"
                              min={500}
                              max={150000}
                              slider-width="100%"
                              slider-bg-fill="#2F5CFF"
                              generate-labels="true"
                              round="0"
                            ></tc-range-slider>
                          </div>
                        </div>
                        <div className="single-tax-calculator-form-card">
                          <div className="single-tax-calculator-form-header-sec">
                            <h4>{t("ppf_calculator.time_period.text")}</h4>
                            <Form.Group
                              className="mb-0"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Field
                                className="form-control"
                                name="time_period"
                                placeholder={t(
                                  "ppf_calculator.time_period.placeholder"
                                )}
                                type="number"
                                onKeyDown={(e) => {
                                  if (
                                    !/[0-9]|Backspace|ArrowLeft|ArrowRight|Delete|ArrowUp|ArrowDown|Tab/.test(
                                      e.key
                                    ) &&
                                    !(
                                      e.ctrlKey &&
                                      (e.key === "v" ||
                                        e.key === "c" ||
                                        e.key === "x" ||
                                        e.key === "a")
                                    )
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                                onInput={(e) => {
                                  if (e.target.value < 0) {
                                    e.target.value = 0;
                                  }
                                  onChangeInput(e, "timePeriod");
                                  submitForm();
                                }}
                              />
                            </Form.Group>
                          </div>
                          <ErrorMessage
                            component={"div"}
                            name="time_period"
                            className="errorMsg"
                          />
                          <div className="tax-calculator-input-slider-sec">
                            <tc-range-slider
                              ref={timePeriodRef}
                              value={timePeriodRef}
                              mousewheel-disabled="true"
                              min={15}
                              max={50}
                              slider-width="100%"
                              slider-bg-fill="#2F5CFF"
                              generate-labels="true"
                              round="0"
                            ></tc-range-slider>
                          </div>
                        </div>
                        <div className="single-tax-calculator-form-card">
                          <div className="single-tax-calculator-form-header-sec">
                            <h4>{t("ppf_calculator.rate_of_interest.text")}</h4>
                            <h4>{formRef.current?.values.rate_of_interest}%</h4>
                          </div>
                        </div>
                        {/* <div className="d-flex justify-content-end mt-3">
                          <Button
                            type="submit"
                            className="default-primary-btn"
                            disabled={ppfCalculator.buttonDisable}
                          >
                            {ppfCalculator.buttonDisable ? (
                              <ButtonLoader />
                            ) : (
                              t("ppf_calculator.calculate")
                            )}
                          </Button>
                        </div> */}
                      </FORM>
                    )}
                  </Formik>
                </Col>
                <Col md={6} lg={6}>
                  {ppfCalculator.loading ? (
                    <div className="single-calculator-info-frame">
                      <div className="pie-chart-skelton">
                        <div className="skeleton-wrapper">
                          <Skeleton
                            borderRadius={130}
                            width={230}
                            height={230}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                        </div>
                        <div className="mt-4">
                          <Skeleton
                            width={250}
                            height={15}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                        </div>
                      </div>

                      <div className="calculator-info-details-card">
                        <div className="calculator-details-frame">
                          <Skeleton
                            width={200}
                            height={20}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                          <Skeleton
                            width={130}
                            height={20}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                        </div>
                        <div className="calculator-details-frame">
                          <Skeleton
                            width={200}
                            height={20}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                          <Skeleton
                            width={130}
                            height={20}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                        </div>
                        <hr />
                        <div className="calculator-details-frame top-bottom-line">
                          <Skeleton
                            width={200}
                            height={20}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                          <Skeleton
                            width={130}
                            height={20}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                        </div>
                        <hr />
                      </div>
                    </div>
                  ) : (
                    Object.keys(ppfCalculator.data).length > 0 && (
                      <div className="single-calculator-info-frame">
                        <div className="pie-chart-sec">
                          <PieChart
                            name={[
                              t("ppf_calculator.invested_amount"),
                              t("ppf_calculator.estimated_returns"),
                            ]}
                            data={[
                              ppfCalculator.data.invested_amount,
                              ppfCalculator.data.estimated_return,
                            ]}
                          />
                        </div>
                        <div className="calculator-info-details-card">
                          <div className="calculator-details-frame">
                            <p>
                              {" "}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                x="0"
                                y="0"
                                enableBackground="new 0 0 29.107 29.107"
                                version="1.1"
                                viewBox="0 0 29.107 29.107"
                                xmlSpace="preserve"
                                width="20"
                                height="20"
                                fill="#2A334F"
                              >
                                <path d="M14.554 0C6.561 0 0 6.562 0 14.552c0 7.996 6.561 14.555 14.554 14.555 7.996 0 14.553-6.559 14.553-14.555C29.106 6.562 22.55 0 14.554 0z"></path>
                              </svg>
                              <span>{t("ppf_calculator.invested_amount")}</span>
                            </p>
                            <h3>
                              {formatNumberWithCommas(
                                ppfCalculator.data.invested_amount
                              )}
                            </h3>
                          </div>
                          <div className="calculator-details-frame">
                            <p>
                              {" "}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                x="0"
                                y="0"
                                enableBackground="new 0 0 29.107 29.107"
                                version="1.1"
                                viewBox="0 0 29.107 29.107"
                                xmlSpace="preserve"
                                width="20"
                                height="20"
                                fill="#2F5CFF"
                              >
                                <path d="M14.554 0C6.561 0 0 6.562 0 14.552c0 7.996 6.561 14.555 14.554 14.555 7.996 0 14.553-6.559 14.553-14.555C29.106 6.562 22.55 0 14.554 0z"></path>
                              </svg>
                              <span>
                                {t("ppf_calculator.estimated_returns")}
                              </span>
                            </p>
                            <h3>
                              {formatNumberWithCommas(
                                ppfCalculator.data.estimated_return
                              )}
                            </h3>
                          </div>
                          <hr />
                          <div className="calculator-details-frame top-bottom-line">
                            <p className="d-inline">
                              {t("ppf_calculator.maturity_value")}
                            </p>
                            <h3>
                              {formatNumberWithCommas(
                                ppfCalculator.data.maturity_amount
                              )}
                            </h3>
                          </div>
                          <hr />
                        </div>
                      </div>
                    )
                  )}
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <Container>
          <Row>
            <Col lg={12}>
              <div className="static-page-card">
                <div className="pricacy-policy-info">
                  <h4>Description</h4>
                  <p>
                    The initial step in wealth management involves building up
                    your savings. While there are numerous savings options
                    available, it's wise to choose those that offer significant,
                    risk-free returns. This is where Public Provident Fund (PPF)
                    accounts often come into play. Designed for the long-term
                    investment of your capital, PPF accounts are a popular
                    choice.
                  </p>
                  <p>
                    Whether you're starting out in your career or a
                    forward-thinking parent planning for the future, PPF could
                    be the right option for you. However, figuring out the
                    interest rates and potential returns from your PPF account
                    can be challenging. For simplifying these complex
                    calculations, a PPF account calculator is a handy tool.
                  </p>
                </div>
                <div className="pricacy-policy-info">
                  <h4>How a PPF Calculator Can Benefit You?</h4>
                  <p>
                    A PPF calculator is designed to address all your questions
                    concerning the Public Provident Fund account, adhering to
                    specific rules for calculating the maturity amount over
                    time. It monitors the progression of your investment, which
                    is particularly useful for PPF account holders, given that
                    interest rates are subject to monthly adjustments.
                  </p>

                  <p>
                    While staying updated with fluctuating rates has become more
                    manageable today, the introduction of the PPF calculator has
                    made it significantly simpler for account holders to track
                    monthly interest changes. The market offers numerous
                    user-friendly PPF calculators, among which TaxTrax stands
                    out as a reliable choice for many.
                  </p>
                </div>
                <div className="pricacy-policy-info">
                  <h4>How a PPF Calculator Can Benefit You?</h4>
                  <p>
                    Maximizing the benefits of this calculator requires
                    familiarity with its functionality. Its user-friendliness
                    and precision make it a valuable tool. Users simply need to
                    input data into designated fields to start. The PPF
                    calculator requires inputs such as the investment period,
                    the total sum invested, the interest accrued, and the
                    monthly or annual investment amounts.
                  </p>
                  <p>
                    Just input the necessary details in the provided spaces, and
                    the calculator will display the total maturity amount almost
                    instantly. For instance, if a deposit is made on the 1st of
                    April, the interest for that year will be computed based on
                    the fiscal year, keeping in mind that inflation may
                    influence the interest rate.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <FAQIndex
          data={[
            {
              question:
                "Is it possible to move my PPF account to a different branch or institution?",
              answer:
                "Yes, you have the option to transfer your PPF account to another branch or financial institution, ensuring flexibility in managing your account.",
            },

            {
              question: "What interest rate will my PPF account earn?",
              answer:
                "The interest rate on PPF accounts is set by the government and subject to periodic revisions. It's advisable to check the current rate for the most accurate information.",
            },
            {
              question: "When will my PPF investment reach maturity?",
              answer:
                "A PPF investment matures after 15 years from the end of the financial year in which the account was opened. However, there is an option to extend the maturity period in blocks of five years.",
            },
          ]}
        />
      </div>
    </>
  );
};

export default CalculatorIndex;
