import React, { useState, useEffect } from "react";
import { Container, Row, Col, Image, Button, Form } from "react-bootstrap";
import PieChart from "./PieChart";
import { useTranslation } from "react-multi-lang";
import { useDispatch, useSelector } from "react-redux";
import formatNumberWithCommas from "../../../FormatNumber";

const TaxCalculated = (props) => {
  const taxCalculator = useSelector((state) => state.common.taxCalculator);
  const t = useTranslation("tax_calculator");
  const [taxData, setTaxData] = useState({
    oldTaxRegime: 0,
    newTaxRegime: 0,
  });

  useEffect(() => {
    if (
      Object.keys(taxCalculator.data).length > 0 &&
      taxCalculator.data.tax_new_regime !== "" &&
      taxCalculator.data.tax_old_regime !== ""
    ) {
      const totalTax =
        taxCalculator.data.tax_old_regime + taxCalculator.data.tax_new_regime;
      const percentage = totalTax / 100;
      const oldTaxRegime = taxCalculator.data.tax_old_regime / percentage;
      const newTaxRegime = taxCalculator.data.tax_new_regime / percentage;
      setTaxData({ oldTaxRegime, newTaxRegime });
    }
  }, [taxCalculator]);

  return (
    <>
      <div className="tax-calculator-basic-details-sec">
        <div className="tax-calculator-basic-details-box">
          <div className="tax-calculator-basic-details-body-sec">
            <Row className="align-items-center justify-content-center">
              <Col md={12} xl={7} lg={12} className="resp-margin-btm-small-lap">
                <PieChart
                  name={[
                    t("tax_calculated.old_tax_regime"),
                    t("tax_calculated.new_tax_regime"),
                  ]}
                  data={[taxData.oldTaxRegime, taxData.newTaxRegime]}
                />
              </Col>
              <Col md={12} xl={5} lg={8}>
                <div className="tax-calculator-deduction-sec">
                  <div className="tax-calculator-deduction-card">
                    <div className="tax-calculator-deduction-icon-sec">
                      <Image
                        className="tax-calculator-deduction-icon"
                        src={
                          window.location.origin +
                          "/img/tax-calculator/old-tax-regime.png"
                        }
                        type="image/svg"
                      />
                    </div>
                    <div className="tax-calculator-deduction-info-sec">
                      <p>
                        {t("tax_calculated.old_tax_regime")}{" "}
                        <strong>({t("tax_calculated.tax_payable")})</strong>
                      </p>
                      <h6>
                        {taxCalculator.data.tax_old_regime !== ""
                          ? formatNumberWithCommas(
                              taxCalculator.data.tax_old_regime.toFixed(2)
                            )
                          : ""}
                      </h6>
                    </div>
                  </div>
                  <div className="tax-calculator-deduction-card">
                    <div className="tax-calculator-deduction-icon-sec">
                      <Image
                        className="tax-calculator-deduction-icon"
                        src={
                          window.location.origin +
                          "/img/tax-calculator/new-tax-regime.png"
                        }
                        type="image/svg"
                      />
                    </div>
                    <div className="tax-calculator-deduction-info-sec">
                      <p>
                        {t("tax_calculated.new_tax_regime")}
                        <strong>({t("tax_calculated.tax_payable")})</strong>
                      </p>
                      <h3>
                        {taxCalculator.data.tax_new_regime !== ""
                          ? formatNumberWithCommas(
                              taxCalculator.data.tax_new_regime.toFixed(2)
                            )
                          : ""}
                      </h3>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="tax-calculator-deduction-footer-sec">
            <Button
              type="submit"
              onClick={() => {
                props.setStep(1);
                props.setSliderValue(null);
              }}
              className="default-grey-btn"
            >
              {t("tax_calculated.re_calculate")}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default TaxCalculated;
