import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Container, Button, Form, Accordion } from "react-bootstrap";
import CustomLazyLoad from "../../../Helper/CustomLazyLoad";
import Skeleton from "react-loading-skeleton";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-multi-lang";
import { useDispatch, useSelector } from "react-redux";
import { emiCalculatorStart, emiCalculatorSuccess } from "../../../../store/slices/CommonSlice";
import PieChart from "../PieChart";
import { ButtonLoader } from "../../../Helper/Loader";
import FAQIndex from "../FAQIndex";
import { Link } from "react-router-dom";
import formatNumberWithCommas from "../../../FormatNumber";

const CalculatorIndex = (props) => {
  const t = useTranslation("tax_calculator");
  const dispatch = useDispatch();
  const formRef = useRef();
  const principleRef = useRef(null);
  const interestRef = useRef(null);
  const timePeriodRef = useRef(null);
  const emiCalculator = useSelector((state) => state.common.emiCalculator);

  useEffect(() => {
    const onChange = (evt, field) => {
      const customEvent = evt;
      switch (field) {
        case "principal":
          formRef.current.setFieldValue("principal", customEvent.detail.value);
          break;
        case "interest":
          formRef.current.setFieldValue(
            "rate_of_interest",
            customEvent.detail.value
          );
          break;
        case "timePeriod":
          formRef.current.setFieldValue(
            "time_period",
            customEvent.detail.value
          );
          break;
      }
      setTimeout(() => { formRef.current.submitForm() }, 1)
    };
    principleRef.current?.addEventListener("change", (evt) =>
      onChange(evt, "principal")
    );
    interestRef.current?.addEventListener("change", (evt) =>
      onChange(evt, "interest")
    );
    timePeriodRef.current?.addEventListener("change", (evt) =>
      onChange(evt, "timePeriod")
    );
    return () => {
      principleRef.current?.removeEventListener("change", onChange);
      interestRef.current?.removeEventListener("change", onChange);
      timePeriodRef.current?.removeEventListener("change", onChange);
    };
  }, []);

  useEffect(() => {
    calculate(formRef.current.values);
  }, []);

  const onChangeInput = (event, field) => {
    const value = !isNaN(parseInt(event.target.value))
      ? parseInt(event.target.value)
      : "";
    switch (field) {
      case "principal":
        formRef.current.setFieldValue("principal", value);
        principleRef.current.value = value;
        break;
      case "interest":
        formRef.current.setFieldValue("rate_of_interest", value);
        interestRef.current.value = value;
        break;
      case "timePeriod":
        formRef.current.setFieldValue("time_period", value);
        timePeriodRef.current.value = value;
        break;
    }
    setTimeout(() => { formRef.current.submitForm() }, 1)
  };

  const carLoanEmiSchema = Yup.object().shape({
    principal: Yup.string()
      .test(
        "min",
        t("car_loan_calculator.invalid_min", { value: 100000 }),
        (value) => {
          return value >= 100000;
        }
      )
      .test(
        "max",
        t("car_loan_calculator.invalid_max", {
          value: 10000000,
          name: "Loan Amount",
        }),
        (value) => {
          return value <= 10000000;
        }
      )
      .required(t("car_loan_calculator.required")),
    rate_of_interest: Yup.string()
      .test(
        "min",
        t("car_loan_calculator.invalid_min", { value: 1 }),
        (value) => {
          return value >= 1;
        }
      )
      .test(
        "max",
        t("car_loan_calculator.invalid_max", {
          value: 30,
          name: "Rate of Interest",
        }),
        (value) => {
          return value <= 30;
        }
      )
      .required(t("car_loan_calculator.required")),
    time_period: Yup.string()
      .test(
        "min",
        t("car_loan_calculator.invalid_min", { value: 1 }),
        (value) => {
          return value >= 1;
        }
      )
      .test(
        "max",
        t("car_loan_calculator.invalid_max", {
          value: 30,
          name: "Time period",
        }),
        (value) => {
          return value <= 30;
        }
      )
      .required(t("car_loan_calculator.required")),
  });

  const handleSubmit = (values) => {
    formRef.current.isValid && calculate(values)
    // dispatch(emiCalculatorStart(values));
  };

  const calculate = (values) => {
    const { rate_of_interest, time_period, principal } = values
    let rateOfInterest = rate_of_interest / 100;
    let monthlyRateOfInterest = rateOfInterest / 12;
    let totalMonths = time_period * 12;
    let monthlyEMI = (principal * monthlyRateOfInterest * Math.pow(1 + monthlyRateOfInterest, totalMonths)) / (Math.pow(1 + monthlyRateOfInterest, totalMonths) - 1);
    let totalPayment = monthlyEMI * totalMonths;
    let totalInterest = totalPayment - principal;
    let principalAmount = principal;
    const newResult = {
      monthly_emi: Math.round(monthlyEMI),
      principal_amount: Math.round(principalAmount),
      total_interest: Math.round(totalInterest),
      total_payment: Math.round(totalPayment),
    };
    dispatch(emiCalculatorSuccess(newResult))
  }

  return (
    <>
      <div className="single-tax-calculator">
        <div className="sm-padding single-tax-calculator-sec">
          <Container>
            <div className="single-tax-calculator-box">
              <div className="single-tax-calculator-header">
                <Row>
                  <Col md={6}>
                    <div className="d-flex other-calculator-btn-sec">
                      <Link
                        to="/tax-calculator"
                        className="other-calculator-btn"
                      >
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            enableBackground="new 0 0 512 512"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="#fff"
                              d="M10.957 12.354a.5.5 0 010-.708l4.586-4.585a1.5 1.5 0 00-2.121-2.122L8.836 9.525a3.505 3.505 0 000 4.95l4.586 4.586a1.5 1.5 0 002.121-2.122z"
                              data-original="#000000"
                            ></path>
                          </svg>
                        </span>
                        <span>Other Calculators</span>
                      </Link>
                    </div>
                    <div className="single-tax-calculator-info-sec">
                      <h1>{t("car_loan_calculator.heading")}</h1>
                      <p>{t("car_loan_calculator.content")}</p>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="single-tax-calculator-header-img-sec">
                      <CustomLazyLoad
                        className="single-tax-calculator-header-img"
                        src={
                          window.location.origin +
                          "/img/single-tax-calculator-header-img.png"
                        }
                        type="image/svg"
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="single-tax-calculator-form-box  line-stright">
              <Row className="align-items-center g-5">
                <Col md={6} lg={6}>
                  <Formik
                    initialValues={{
                      principal: 10000000,
                      rate_of_interest: 30,
                      time_period: 12,
                      loan_type: "Car Loan",
                    }}
                    innerRef={formRef}
                    validationSchema={carLoanEmiSchema}
                    onSubmit={handleSubmit}
                  >
                    {({ setFieldValue, values, resetForm, submitForm, isValid }) => (
                      <FORM className="single-tax-calculator-form">
                        <div className="single-tax-calculator-form-card">
                          <div className="single-tax-calculator-form-header-sec">
                            <h4>{t("car_loan_calculator.loan_amount.text")}</h4>
                            <Form.Group
                              className="mb-0"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Field
                                className="form-control"
                                name="principal"
                                type="number"
                                placeholder={t(
                                  "car_loan_calculator.loan_amount.placeholder"
                                )}
                                onKeyDown={(e) => {
                                  if (
                                    !/[0-9]|Backspace|ArrowLeft|ArrowRight|Delete|ArrowUp|ArrowDown|Tab/.test(
                                      e.key
                                    ) &&
                                    !(
                                      e.ctrlKey &&
                                      (e.key === "v" ||
                                        e.key === "c" ||
                                        e.key === "x" ||
                                        e.key === "a")
                                    )
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                                onInput={(e) => {
                                  if (e.target.value < 0) {
                                    e.target.value = 0;
                                  }
                                  onChangeInput(e, "principal");
                                }}
                              />
                            </Form.Group>
                          </div>
                          <ErrorMessage
                            component={"div"}
                            name="principal"
                            className="errorMsg"
                          />
                          <div className="tax-calculator-input-slider-sec">
                            <tc-range-slider
                              ref={principleRef}
                              value={principleRef}
                              mousewheel-disabled="true"
                              min={100000}
                              max={10000000}
                              slider-width="100%"
                              slider-bg-fill="#2F5CFF"
                              generate-labels="true"
                              round="0"
                            ></tc-range-slider>
                          </div>
                        </div>
                        <div className="single-tax-calculator-form-card">
                          <div className="single-tax-calculator-form-header-sec">
                            <h4>
                              {t("car_loan_calculator.rate_of_interest.text")}{" "}
                            </h4>
                            <Form.Group
                              className="mb-0"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Field
                                className="form-control"
                                name="rate_of_interest"
                                type="number"
                                placeholder={t(
                                  "car_loan_calculator.rate_of_interest.placeholder"
                                )}
                                onKeyDown={(e) => {
                                  if (
                                    !/[0-9]|Backspace|ArrowLeft|ArrowRight|Delete|ArrowUp|ArrowDown|Tab/.test(
                                      e.key
                                    ) &&
                                    !(
                                      e.ctrlKey &&
                                      (e.key === "v" ||
                                        e.key === "c" ||
                                        e.key === "x" ||
                                        e.key === "a")
                                    )
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                                onInput={(e) => {
                                  if (e.target.value < 0) {
                                    e.target.value = 0;
                                  }
                                  onChangeInput(e, "interest");
                                }}
                              />
                            </Form.Group>
                          </div>
                          <ErrorMessage
                            component={"div"}
                            name="rate_of_interest"
                            className="errorMsg"
                          />
                          <div className="tax-calculator-input-slider-sec">
                            <tc-range-slider
                              ref={interestRef}
                              value={interestRef}
                              mousewheel-disabled="true"
                              min={1}
                              max={30}
                              slider-width="100%"
                              slider-bg-fill="#2F5CFF"
                              generate-labels="true"
                              round="1"
                            ></tc-range-slider>
                          </div>
                        </div>
                        <div className="single-tax-calculator-form-card">
                          <div className="single-tax-calculator-form-header-sec">
                            <h4>{t("car_loan_calculator.loan_tenure.text")}</h4>
                            <Form.Group
                              className="mb-0"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Field
                                className="form-control"
                                name="time_period"
                                type="number"
                                placeholder={t(
                                  "car_loan_calculator.loan_tenure.placeholder"
                                )}
                                onKeyDown={(e) => {
                                  if (
                                    !/[0-9]|Backspace|ArrowLeft|ArrowRight|Delete|ArrowUp|ArrowDown|Tab/.test(
                                      e.key
                                    ) &&
                                    !(
                                      e.ctrlKey &&
                                      (e.key === "v" ||
                                        e.key === "c" ||
                                        e.key === "x" ||
                                        e.key === "a")
                                    )
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                                onInput={(e) => {
                                  if (e.target.value < 0) {
                                    e.target.value = 0;
                                  }
                                  onChangeInput(e, "timePeriod");
                                }}
                              />
                            </Form.Group>
                          </div>
                          <ErrorMessage
                            component={"div"}
                            name="time_period"
                            className="errorMsg"
                          />
                          <div className="tax-calculator-input-slider-sec">
                            <tc-range-slider
                              ref={timePeriodRef}
                              value={timePeriodRef}
                              mousewheel-disabled="true"
                              min={1}
                              max={30}
                              slider-width="100%"
                              slider-bg-fill="#2F5CFF"
                              generate-labels="true"
                              round="0"
                            ></tc-range-slider>
                          </div>
                        </div>
                        {/* <div className="d-flex justify-content-end">
                          <Button
                            type="submit"
                            className="default-primary-btn"
                            disabled={emiCalculator.buttonDisable}
                          >
                            {emiCalculator.buttonDisable ? (
                              <ButtonLoader />
                            ) : (
                              t("car_loan_calculator.calculate")
                            )}
                          </Button>
                        </div> */}
                      </FORM>
                    )}
                  </Formik>
                </Col>
                <Col md={6} lg={6}>
                  {emiCalculator.loading ? (
                    <div className="single-calculator-info-frame">
                      <div className="pie-chart-skelton">
                        <div className="skeleton-wrapper">
                          <Skeleton
                            borderRadius={130}
                            width={230}
                            height={230}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                        </div>
                        <div className="mt-4">
                          <Skeleton
                            width={250}
                            height={15}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                        </div>
                      </div>

                      <div className="calculator-info-details-card">
                        <div className="calculator-details-frame">
                          <Skeleton
                            width={200}
                            height={20}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                          <Skeleton
                            width={130}
                            height={20}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                        </div>
                        <div className="calculator-details-frame">
                          <Skeleton
                            width={200}
                            height={20}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                          <Skeleton
                            width={130}
                            height={20}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                        </div>
                        <hr />
                        <div className="calculator-details-frame top-bottom-line">
                          <Skeleton
                            width={200}
                            height={20}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                          <Skeleton
                            width={130}
                            height={20}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                        </div>
                        <hr />
                      </div>
                    </div>
                  ) : (
                    Object.keys(emiCalculator.data).length > 0 && (
                      <div className="single-calculator-info-frame">
                        <div className="pie-chart-sec">
                          <PieChart
                            name={[
                              t("car_loan_calculator.principle_amount"),
                              t("car_loan_calculator.total_interest"),
                            ]}
                            data={[
                              emiCalculator.data.principal_amount,
                              emiCalculator.data.total_interest,
                            ]}
                          />
                        </div>
                        <div className="calculator-info-details-card">
                          <div className="calculator-details-frame">
                            <p>
                              {" "}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                x="0"
                                y="0"
                                enableBackground="new 0 0 29.107 29.107"
                                version="1.1"
                                viewBox="0 0 29.107 29.107"
                                xmlSpace="preserve"
                                width="20"
                                height="20"
                                fill="#2A334F"
                              >
                                <path d="M14.554 0C6.561 0 0 6.562 0 14.552c0 7.996 6.561 14.555 14.554 14.555 7.996 0 14.553-6.559 14.553-14.555C29.106 6.562 22.55 0 14.554 0z"></path>
                              </svg>
                              <span>
                                {t("car_loan_calculator.principle_amount")}
                              </span>
                            </p>
                            <h3>
                              {formatNumberWithCommas(
                                emiCalculator.data.principal_amount
                              )}
                            </h3>
                          </div>
                          <div className="calculator-details-frame">
                            <p>
                              {" "}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                x="0"
                                y="0"
                                enableBackground="new 0 0 29.107 29.107"
                                version="1.1"
                                viewBox="0 0 29.107 29.107"
                                xmlSpace="preserve"
                                width="20"
                                height="20"
                                fill="#2F5CFF"
                              >
                                <path d="M14.554 0C6.561 0 0 6.562 0 14.552c0 7.996 6.561 14.555 14.554 14.555 7.996 0 14.553-6.559 14.553-14.555C29.106 6.562 22.55 0 14.554 0z"></path>
                              </svg>
                              <span>
                                {t("car_loan_calculator.total_interest")}
                              </span>
                            </p>
                            <h3>
                              {formatNumberWithCommas(
                                emiCalculator.data.total_interest
                              )}
                            </h3>
                          </div>
                          <hr />
                          <div className="calculator-details-frame top-bottom-line">
                            <p className="d-inline">
                              {t("car_loan_calculator.total_amount")}
                            </p>
                            <h3>
                              {formatNumberWithCommas(
                                emiCalculator.data.total_payment
                              )}
                            </h3>
                          </div>
                          <hr />
                          <div className="calculator-details-frame top-bottom-line">
                            <p className="d-inline">
                              {t("car_loan_calculator.monthly_emi")}
                            </p>
                            <h3>
                              {formatNumberWithCommas(
                                emiCalculator.data.monthly_emi
                              )}
                            </h3>
                          </div>
                        </div>
                      </div>
                    )
                  )}
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <Container>
          <Row>
            <Col lg={12}>
              <div className="static-page-card">
                <div className="pricacy-policy-info">
                  <h4>Description</h4>
                  <p>
                    India ranks as the fourth largest market globally for
                    4-wheelers, with an annual growth rate of 9.5%. This surge
                    in automobile sales has correspondingly increased the demand
                    for a reliable and user-friendly car loan EMI calculator.
                  </p>
                  <p>
                    Amidst a plethora of online tools, what sets one apart is
                    its simplicity. TaxTrax offers an outstanding and
                    straightforward EMI calculator that assists you in making
                    well-informed decisions about your car financing. It helps
                    you determine the necessary funding to purchase your dream
                    car, estimate your monthly EMI payments, and other essential
                    details.
                  </p>
                </div>
                <div className="pricacy-policy-info">
                  <h4>How to Use TaxTrax’s Car Loan EMI Calculator</h4>
                  <p>
                    Using the car loan EMI calculator on TaxTrax's website is
                    straightforward. Just follow these simple steps:
                  </p>
                  <ul>
                    <li>
                      Input the loan amount (principal), the interest rate, and
                      the loan tenure.
                    </li>
                    <li>
                      Click to calculate and immediately see the EMI you will
                      need to pay.
                    </li>
                  </ul>
                </div>
                <div className="pricacy-policy-info">
                  <h4>
                    Benefits of Using TaxTrax’s Online EMI Calculator for Car
                    Loans
                  </h4>
                  <p>
                    TaxTrax provides comprehensive financial solutions across a
                    broad spectrum of products, including an invaluable online
                    car loan EMI calculator.
                  </p>
                  <ul>
                    <li>Your data is stored securely on our servers.</li>
                    <li>
                      The calculator is free to use indefinitely once you
                      register with us.
                    </li>
                    <li>
                      It is user-friendly and provides precise calculations.
                    </li>
                    <li>
                      Using our calculator, you can save time and identify the
                      most cost-effective loans available, simplifying your
                      application process.
                    </li>
                  </ul>
                  <p>
                    In summary, an EMI calculator is incredibly useful for all
                    prospective borrowers. Armed with detailed financial
                    insights, you can approach the lender fully prepared and
                    maximize the benefits from your car loan.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <FAQIndex
          data={[
            {
              question: "How long does it take to use the loan calculator?",
              answer:
                "Using the loan calculator is a quick process; it typically takes just a few seconds to input your loan details and receive the calculated EMI.",
            },
            {
              question: "Is it possible to get a car loan for a used vehicle?",
              answer:
                "Yes, you can secure a car loan for purchasing a used vehicle. Most financial institutions offer loans specifically for used cars, though the terms and interest rates may differ from new car loans.",
            },
            {
              question:
                "What is the standard loan duration for a car loan in India?",
              answer:
                "The typical tenure for a car loan in India ranges from 1 to 7 years, depending on the lender’s policies and the loan agreement.",
            },
            {
              question:
                "Do I need a co-signer or guarantor to obtain a car loan?",
              answer:
                "Whether you need a co-signer or guarantor for a car loan depends on the lender's requirements, which can vary based on your credit history, income, and other factors. Some lenders may require a co-signer if they deem the risk higher.",
            },
            {
              question: "Can my application for a car loan be denied?",
              answer:
                "Yes, your car loan application can be rejected. Common reasons for rejection include poor credit score, insufficient income, unstable employment history, or the financial institution's assessment that you may not be able to repay the loan.",
            },
          ]}
        />
      </div>
    </>
  );
};

export default CalculatorIndex;
