import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Container, Button, Form, Accordion } from "react-bootstrap";
import CustomLazyLoad from "../../../Helper/CustomLazyLoad";
import Skeleton from "react-loading-skeleton";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-multi-lang";
import { useDispatch, useSelector } from "react-redux";
import {
  sipCalculatorStart,
  sipCalculatorSuccess,
} from "../../../../store/slices/CommonSlice";
import { ButtonLoader } from "../../../Helper/Loader";
import PieChart from "../PieChart";
import FAQIndex from "../FAQIndex";
import { Link } from "react-router-dom";
import formatNumberWithCommas from "../../../FormatNumber";

const CalculatorIndex = (props) => {
  const t = useTranslation("tax_calculator");
  const principleRef = useRef(null);
  const interestRef = useRef(null);
  const timePeriodRef = useRef(null);
  const dispatch = useDispatch();
  const formRef = useRef();
  const sipCalculator = useSelector((state) => state.common.sipCalculator);

  useEffect(() => {
    const onChange = (evt, field) => {
      const customEvent = evt;
      switch (field) {
        case "principal":
          formRef.current.setFieldValue("principal", customEvent.detail.value);
          formRef.current.submitForm();
          break;
        case "interest":
          formRef.current.setFieldValue(
            "rate_of_interest",
            customEvent.detail.value
          );
          formRef.current.submitForm();
          break;
        case "timePeriod":
          formRef.current.setFieldValue(
            "time_period",
            customEvent.detail.value
          );
          formRef.current.submitForm();
          break;
      }
      setTimeout(() => {
        formRef.current.submitForm();
      }, 1);
    };
    principleRef.current?.addEventListener("change", (evt) =>
      onChange(evt, "principal")
    );
    interestRef.current?.addEventListener("change", (evt) =>
      onChange(evt, "interest")
    );
    timePeriodRef.current?.addEventListener("change", (evt) =>
      onChange(evt, "timePeriod")
    );
    return () => {
      principleRef.current?.removeEventListener("change", onChange);
      interestRef.current?.removeEventListener("change", onChange);
      timePeriodRef.current?.removeEventListener("change", onChange);
    };
  }, []);

  useEffect(() => {
    // dispatch(sipCalculatorStart(formRef.current.values));
    calculate(formRef.current.values);
  }, []);

  const onChangeInput = (event, field) => {
    const value = !isNaN(parseFloat(event.target.value))
      ? parseFloat(event.target.value)
      : "";
    switch (field) {
      case "principal":
        formRef.current.setFieldValue("principal", value);
        principleRef.current.value = value;
        break;
      case "interest":
        formRef.current.setFieldValue("rate_of_interest", value);
        interestRef.current.value = value;
        break;
      case "timePeriod":
        formRef.current.setFieldValue("time_period", value);
        timePeriodRef.current.value = value;
        break;
    }
    setTimeout(() => {
      formRef.current.submitForm();
    }, 1);
  };

  const handleSubmit = (values) => {
    //dispatch(sipCalculatorStart(values));
    formRef.current.isValid && calculate(values);
  };

  const sipSchema = Yup.object().shape({
    principal: Yup.string()
      .test(
        "min",
        t("sip_calculator.invalid_min", { value: 1000 }),
        (value) => {
          return value >= 1000;
        }
      )
      .test(
        "max",
        t("sip_calculator.invalid_max", {
          value: 1000000,
          name: "Monthly investment",
        }),
        (value) => {
          return value <= 1000000;
        }
      )
      .required(t("sip_calculator.required")),
    rate_of_interest: Yup.string()
      .test("min", t("sip_calculator.invalid_min", { value: 1 }), (value) => {
        return value >= 1;
      })
      .test(
        "max",
        t("sip_calculator.invalid_max", {
          value: 30,
          name: "Rate of interest",
        }),
        (value) => {
          return value <= 30;
        }
      )
      .required(t("sip_calculator.required")),
    time_period: Yup.string()
      .test("min", t("sip_calculator.invalid_min", { value: 1 }), (value) => {
        return value >= 1;
      })
      .test(
        "max",
        t("sip_calculator.invalid_max", { value: 40, name: "Time period" }),
        (value) => {
          return value <= 40;
        }
      )
      .required(t("sip_calculator.required")),
  });

  const calculate = (values) => {
    const { principal, rate_of_interest, time_period } = values;

    if (!principal || !rate_of_interest || !time_period) {
      return;
    }

    const rate = rate_of_interest / 100;
    const monthly_rate = rate / 12;
    const months = time_period * 12;

    const maturity_amount =
      principal *
      ((1 + monthly_rate) ** months - 1) *
      ((1 + monthly_rate) / monthly_rate);
    const invested_amount = principal * months;
    const estimated_return = maturity_amount - invested_amount;

    const newResult = {
      maturity_amount: Math.round(maturity_amount),
      invested_amount: Math.round(invested_amount),
      estimated_return: Math.round(estimated_return),
    };

    dispatch(sipCalculatorSuccess(newResult));
  };

  return (
    <>
      <div className="single-tax-calculator">
        <div className="sm-padding single-tax-calculator-sec">
          <Container>
            <div className="single-tax-calculator-box">
              <div className="single-tax-calculator-header">
                <Row>
                  <Col md={6}>
                    <div className="d-flex other-calculator-btn-sec">
                      <Link
                        to="/tax-calculator"
                        className="other-calculator-btn"
                      >
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            enableBackground="new 0 0 512 512"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="#fff"
                              d="M10.957 12.354a.5.5 0 010-.708l4.586-4.585a1.5 1.5 0 00-2.121-2.122L8.836 9.525a3.505 3.505 0 000 4.95l4.586 4.586a1.5 1.5 0 002.121-2.122z"
                              data-original="#000000"
                            ></path>
                          </svg>
                        </span>
                        <span>Other Calculators</span>
                      </Link>
                    </div>
                    <div className="single-tax-calculator-info-sec">
                      <h1>{t("sip_calculator.heading")}</h1>
                      <p>{t("sip_calculator.content")}</p>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="single-tax-calculator-header-img-sec">
                      <CustomLazyLoad
                        className="single-tax-calculator-header-img"
                        src={
                          window.location.origin +
                          "/img/single-tax-calculator-header-img.png"
                        }
                        type="image/svg"
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="single-tax-calculator-form-box  line-stright">
              <Row className="align-items-center g-5">
                <Col md={6} lg={6}>
                  <Formik
                    initialValues={{
                      principal: 1000,
                      rate_of_interest: 1,
                      time_period: 1,
                    }}
                    innerRef={formRef}
                    validationSchema={sipSchema}
                    onSubmit={handleSubmit}
                  >
                    {({
                      setFieldValue,
                      values,
                      resetForm,
                      isValid,
                      setFieldError,
                      errors,
                      submitForm,
                    }) => (
                      <FORM className="single-tax-calculator-form">
                        <div className="single-tax-calculator-form-card">
                          <div className="single-tax-calculator-form-header-sec">
                            <h4>{t("sip_calculator.investment.text")}</h4>
                            <Form.Group
                              className="mb-0"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Field
                                className="form-control"
                                name="principal"
                                placeholder={t(
                                  "sip_calculator.investment.placeholder"
                                )}
                                type="number"
                                onKeyDown={(e) => {
                                  if (
                                    !/[0-9]|Backspace|ArrowLeft|ArrowRight|Delete|ArrowUp|ArrowDown|Tab/.test(
                                      e.key
                                    ) &&
                                    !(
                                      e.ctrlKey &&
                                      (e.key === "v" ||
                                        e.key === "c" ||
                                        e.key === "x" ||
                                        e.key === "a")
                                    )
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                                onInput={(e) => {
                                  if (e.target.value < 0) {
                                    e.target.value = 0;
                                  }
                                  onChangeInput(e, "principal");
                                }}
                              />
                            </Form.Group>
                          </div>
                          <ErrorMessage
                            component={"div"}
                            name="principal"
                            className="errorMsg"
                          />
                          <div className="tax-calculator-input-slider-sec">
                            <tc-range-slider
                              ref={principleRef}
                              value={principleRef}
                              mousewheel-disabled="true"
                              min={1000}
                              max={1000000}
                              slider-width="100%"
                              slider-bg-fill="#2F5CFF"
                              generate-labels="true"
                              round="0"
                            ></tc-range-slider>
                          </div>
                        </div>
                        <div className="single-tax-calculator-form-card">
                          <div className="single-tax-calculator-form-header-sec">
                            <h4>{t("sip_calculator.return_rate.text")}</h4>
                            <Form.Group
                              className="mb-0"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Field
                                className="form-control"
                                name="rate_of_interest"
                                placeholder={t(
                                  "sip_calculator.return_rate.placeholder"
                                )}
                                type="number"
                                onKeyDown={(e) => {
                                  if (
                                    !/[0-9.]|Backspace|ArrowLeft|ArrowRight|Delete|ArrowUp|ArrowDown|Tab/.test(
                                      e.key
                                    ) ||
                                    (e.key === "-" &&
                                      e.target.selectionStart !== 0)
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                                onInput={(e) => {
                                  if (e.target.value < 0) {
                                    e.target.value = 0;
                                  }
                                  onChangeInput(e, "interest");
                                }}
                              />
                            </Form.Group>
                          </div>
                          <ErrorMessage
                            component={"div"}
                            name="rate_of_interest"
                            className="errorMsg"
                          />
                          <div className="tax-calculator-input-slider-sec">
                            <tc-range-slider
                              ref={interestRef}
                              value={interestRef}
                              mousewheel-disabled="true"
                              min={1}
                              max={30}
                              slider-width="100%"
                              slider-bg-fill="#2F5CFF"
                              generate-labels="true"
                              round="1"
                            ></tc-range-slider>
                          </div>
                        </div>
                        <div className="single-tax-calculator-form-card">
                          <div className="single-tax-calculator-form-header-sec">
                            <h4>{t("sip_calculator.time_period.text")}</h4>
                            <Form.Group
                              className="mb-0"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Field
                                className="form-control"
                                name="time_period"
                                placeholder={t(
                                  "sip_calculator.time_period.placeholder"
                                )}
                                type="number"
                                onKeyDown={(e) => {
                                  if (
                                    !/[0-9]|Backspace|ArrowLeft|ArrowRight|Delete|ArrowUp|ArrowDown|Tab/.test(
                                      e.key
                                    ) &&
                                    !(
                                      e.ctrlKey &&
                                      (e.key === "v" ||
                                        e.key === "c" ||
                                        e.key === "x" ||
                                        e.key === "a")
                                    )
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                                onInput={(e) => {
                                  if (e.target.value < 0) {
                                    e.target.value = 0;
                                  }
                                  onChangeInput(e, "timePeriod");
                                }}
                              />
                            </Form.Group>
                          </div>
                          <ErrorMessage
                            component={"div"}
                            name="time_period"
                            className="errorMsg"
                          />
                          <div className="tax-calculator-input-slider-sec">
                            <tc-range-slider
                              ref={timePeriodRef}
                              value={timePeriodRef}
                              mousewheel-disabled="true"
                              min={1}
                              max={40}
                              slider-width="100%"
                              slider-bg-fill="#2F5CFF"
                              generate-labels="true"
                              round="0"
                            ></tc-range-slider>
                          </div>
                        </div>
                        {/* <div className="d-flex justify-content-end">
                          <Button
                            type="submit"
                            className="default-primary-btn"
                            disabled={sipCalculator.buttonDisable}
                          >
                            {sipCalculator.buttonDisable ? (
                              <ButtonLoader />
                            ) : (
                              t("sip_calculator.calculate")
                            )}
                          </Button>
                        </div> */}
                      </FORM>
                    )}
                  </Formik>
                </Col>
                <Col md={6} lg={6}>
                  {sipCalculator.loading ? (
                    <div className="single-calculator-info-frame">
                      <div className="pie-chart-skelton">
                        <div className="skeleton-wrapper">
                          <Skeleton
                            borderRadius={130}
                            width={230}
                            height={230}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                        </div>
                        <div className="mt-4">
                          <Skeleton
                            width={250}
                            height={15}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                        </div>
                      </div>

                      <div className="calculator-info-details-card">
                        <div className="calculator-details-frame">
                          <Skeleton
                            width={200}
                            height={20}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                          <Skeleton
                            width={130}
                            height={20}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                        </div>
                        <div className="calculator-details-frame">
                          <Skeleton
                            width={200}
                            height={20}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                          <Skeleton
                            width={130}
                            height={20}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                        </div>
                        <hr />
                        <div className="calculator-details-frame top-bottom-line">
                          <Skeleton
                            width={200}
                            height={20}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                          <Skeleton
                            width={130}
                            height={20}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                        </div>
                        <hr />
                      </div>
                    </div>
                  ) : (
                    Object.keys(sipCalculator.data).length > 0 && (
                      <div className="single-calculator-info-frame">
                        <div className="pie-chart-sec">
                          <PieChart
                            name={[
                              t("sip_calculator.invested_amount"),
                              t("sip_calculator.estimated_returns"),
                            ]}
                            data={[
                              sipCalculator.data.invested_amount,
                              sipCalculator.data.estimated_return,
                            ]}
                          />
                        </div>
                        <div className="calculator-info-details-card">
                          <div className="calculator-details-frame">
                            <p>
                              {" "}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                x="0"
                                y="0"
                                enableBackground="new 0 0 29.107 29.107"
                                version="1.1"
                                viewBox="0 0 29.107 29.107"
                                xmlSpace="preserve"
                                width="20"
                                height="20"
                                fill="#2A334F"
                              >
                                <path d="M14.554 0C6.561 0 0 6.562 0 14.552c0 7.996 6.561 14.555 14.554 14.555 7.996 0 14.553-6.559 14.553-14.555C29.106 6.562 22.55 0 14.554 0z"></path>
                              </svg>
                              <span>{t("sip_calculator.invested_amount")}</span>
                            </p>
                            <h3>
                              {formatNumberWithCommas(
                                sipCalculator.data.invested_amount
                              )}
                            </h3>
                          </div>
                          <div className="calculator-details-frame">
                            <p>
                              {" "}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                x="0"
                                y="0"
                                enableBackground="new 0 0 29.107 29.107"
                                version="1.1"
                                viewBox="0 0 29.107 29.107"
                                xmlSpace="preserve"
                                width="20"
                                height="20"
                                fill="#2F5CFF"
                              >
                                <path d="M14.554 0C6.561 0 0 6.562 0 14.552c0 7.996 6.561 14.555 14.554 14.555 7.996 0 14.553-6.559 14.553-14.555C29.106 6.562 22.55 0 14.554 0z"></path>
                              </svg>
                              <span>
                                {t("sip_calculator.estimated_returns")}
                              </span>
                            </p>
                            <h3>
                              {formatNumberWithCommas(
                                sipCalculator.data.estimated_return
                              )}
                            </h3>
                          </div>
                          <hr />
                          <div className="calculator-details-frame top-bottom-line">
                            <p className="d-inline">
                              {" "}
                              {t("sip_calculator.total_value")}
                            </p>
                            <h3>
                              {formatNumberWithCommas(
                                sipCalculator.data.maturity_amount
                              )}
                            </h3>
                          </div>
                          <hr />
                        </div>
                      </div>
                    )
                  )}
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <Container>
          <Row>
            <Col lg={12}>
              <div className="static-page-card">
                <div className="pricacy-policy-info">
                  <h4>Description</h4>
                  <p>
                    SIP Calculator - An Introduction to Systematic Investment
                    Plan Calculator It's common for new investors to confuse
                    SIPs with mutual funds. However, SIPs are just one way to
                    invest in mutual funds, with the alternative being a
                    one-time, lump sum investment. A SIP calculator is a handy
                    device designed to help you understand the potential returns
                    from investing your money in these vehicles. A Systematic
                    Investment Plan, or SIP, involves making regular fixed
                    investments into mutual funds at set intervals, which could
                    be weekly, quarterly, or monthly.
                  </p>
                </div>
                <div className="pricacy-policy-info">
                  <h4>Understanding the SIP Calculator</h4>
                  <p>
                    A SIP calculator is an uncomplicated tool that offers
                    individuals a glimpse into the potential returns from their
                    SIP-based mutual fund investments. Recently, SIPs have
                    emerged as a favored investment avenue among the younger
                    generation.
                  </p>
                  <p>
                    This tool is designed to project the growth of your wealth
                    and the returns you might expect from your monthly SIP
                    investments, offering a general estimate of the maturity
                    amount for any given monthly SIP based on an assumed annual
                    return rate.
                  </p>
                </div>
                <div className="pricacy-policy-info">
                  <h4>Benefits of Using a SIP Return Calculator</h4>
                  <p>
                    Many mutual fund experts suggest that SIPs offer a more
                    advantageous investment approach than lump sum investments,
                    fostering financial discipline and encouraging a savings
                    habit that pays off long-term.
                  </p>
                  <p>
                    An online SIP calculator proves to be a valuable resource by
                    providing an approximation of the returns you can expect
                    over your investment period.
                  </p>
                </div>
                <div className="pricacy-policy-info">
                  <h4>Advantages of SIP Calculators</h4>

                  <ul>
                    <li>Helps you decide on the investment amount.</li>
                    <li>Indicates the cumulative sum of your investments.</li>
                    <li>Provides a forecast of potential returns.</li>
                  </ul>
                </div>
                <div className="pricacy-policy-info">
                  <h4>Understanding the Functioning of SIP Calculators</h4>
                  <p>
                    The operation of a SIP calculator is based on this formula:
                  </p>
                  <h4>{"M = P × ({[1 + i] ^ (n - 1)} / i) × (1 + i)."}</h4>
                  <p>In this formula:</p>
                  <ul>
                    <li>M represents the final amount you'll receive.</li>
                    <li>P stands for the regular investment amount.</li>
                    <li>n indicates the total number of contributions made.</li>
                    <li>i is the interest rate per period.</li>
                  </ul>
                  <p>
                    For instance, if you plan to invest Rs. 1,000 monthly over a
                    span of 12 months with an annual interest rate of 12%, the
                    monthly interest rate equates to 12%/12 = 1/100 = 0.01.
                  </p>
                  <p>
                    Thus,
                    {" M = 1,000 × ({[1 + 0.01]^{12} - 1} / 0.01) × (1 + 0.01)"}
                  </p>
                  <p>
                    This calculation results in an approximate maturity amount
                    of Rs 12,809 after one year.
                  </p>
                  <p>
                    It's important to note that the interest rate for SIPs is
                    subject to market fluctuations, which can lead to variations
                    in the projected returns.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <FAQIndex
          data={[
            {
              question: "What is the minimum investment amount for a SIP?",
              answer:
                "The minimum investment in a SIP can be as low as Rs. 500, but it varies from one fund house to another.",
            },
            {
              question: "How long can I continue a SIP investment?",
              answer:
                "SIP investments can be continued for any duration chosen by the investor, commonly ranging from 1 year to perpetuity, until the investor decides to stop.",
            },
            {
              question:
                "How do SIPs differ from direct mutual fund investments?",
              answer:
                "SIPs involve investing a fixed amount at regular intervals into a mutual fund, while direct mutual fund investments involve making a one-time, lump-sum investment.",
            },
            {
              question:
                "Is it possible to change the investment amount in my SIP?",
              answer:
                "Yes, most fund houses allow you to modify the SIP amount, subject to their terms and conditions.",
            },
            {
              question:
                "Are investments through SIPs limited to equity mutual funds?",
              answer:
                "No, SIPs can be used to invest in various types of mutual funds, including debt, equity, and hybrid funds.",
            },
            {
              question: "What different kinds of SIPs can I choose from?",
              answer:
                "There are several types of SIPs, including regular SIPs, step-up or top-up SIPs, flexible SIPs, and trigger SIPs, each with its unique features.",
            },
            {
              question:
                "Is it possible to extend the duration of my SIP upon its completion?",
              answer:
                "Yes, you can choose to renew or extend your SIP by submitting a request to the mutual fund house before the end of the current SIP term.",
            },
            {
              question: "Can I temporarily halt my SIP contributions?",
              answer:
                "Some mutual fund houses offer the flexibility to pause SIP contributions for a specified period, typically ranging from 1 to 3 months, but this feature is not universally available.",
            },
          ]}
        />
      </div>
    </>
  );
};

export default CalculatorIndex;
