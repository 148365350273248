import React, { useState } from "react";
import { Container, Nav, Row, Col } from "react-bootstrap";
import CustomLazyLoad from "../Helper/CustomLazyLoad";
import { Link } from "react-router-dom";

const HomeBannerIndex = (props) => {
  return (
    <>
      <div className="home-banner-sec" id="home-banner">
        <div className="hero-banner-wrapped">
          <Container>
            <Row className="align-items-center">
              <Col md={12} lg={6} xl={6}>
                <div className="hero-banner-frame">
                  <div className="hero-banner-info">
                    <h5>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="none"
                        viewBox="0 0 20 20"
                      >
                        <path
                          fill="#2F5CFF"
                          fillRule="evenodd"
                          d="M6.603 1.799A4.49 4.49 0 0110 .25c1.357 0 2.573.6 3.397 1.549a4.49 4.49 0 013.498 1.307 4.491 4.491 0 011.307 3.497A4.49 4.49 0 0119.75 10a4.49 4.49 0 01-1.549 3.397 4.491 4.491 0 01-1.307 3.497 4.491 4.491 0 01-3.497 1.307A4.49 4.49 0 0110 19.75a4.49 4.49 0 01-3.397-1.549 4.49 4.49 0 01-3.498-1.306 4.491 4.491 0 01-1.307-3.498A4.49 4.49 0 01.25 10c0-1.357.6-2.573 1.549-3.397a4.49 4.49 0 011.307-3.497 4.49 4.49 0 013.497-1.307zm7.007 6.387a.75.75 0 10-1.22-.872l-3.236 4.53L7.53 10.22a.75.75 0 10-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                      Harness AI for Stress-Free Tax Filing
                    </h5>
                    <h1>Welcome to TaxTrax - Your AI-Powered Tax Assistant!</h1>
                    <p>
                      Your ultimate AI Tax Assistant! Seamlessly navigate tax
                      complexities with our cutting-edge tax software bot,
                      offering expert guidance through our intuitive tax
                      consultant chatbot. Experience the future of tax filing
                      with precision and efficiency using our AI taxation
                      technology. Transform your tax journey today with
                      TaxTrax—where innovation meets assistance.
                    </p>
                  </div>
                  <div className="default-btn-frame">
                    <Link to="/tax-calculator" className="default-primary-btn">
                      <span>Try Now</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="#fff"
                        x="0"
                        y="0"
                        enableBackground="new 0 0 512 512"
                        version="1.1"
                        viewBox="0 0 512 512"
                        xmlSpace="preserve"
                      >
                        <path d="M506.134 241.843l-.018-.019-104.504-104c-7.829-7.791-20.492-7.762-28.285.068-7.792 7.829-7.762 20.492.067 28.284L443.558 236H20c-11.046 0-20 8.954-20 20s8.954 20 20 20h423.557l-70.162 69.824c-7.829 7.792-7.859 20.455-.067 28.284 7.793 7.831 20.457 7.858 28.285.068l104.504-104 .018-.019c7.833-7.818 7.808-20.522-.001-28.314z"></path>
                      </svg>
                    </Link>
                  </div>
                </div>
              </Col>
              {/* <Col md={12} xl={6}>
                        <div className="banner-img-sec">
                            <CustomLazyLoad
                                className="banner-img"
                                src={
                                    window.location.origin + "/img/banner/banner-right-img.gif"
                                }
                                type="image/svg"
                            />
                        </div>
                    </Col> */}
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
};

export default HomeBannerIndex;
