import React, { useState } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./staticPage.css"

const Terms = () => {
  return (
    <>
      <div className="landing-page-sec">
        <div className="privacy-policy-sec sm-padding">
          <Container>
            <Row className="justify-content-center">
              <Col lg={8} md={8} className="text-center">
                <div className="section-title">
                  <h2>Terms and Conditions for TaxTrax</h2>
                </div>
              </Col>
            </Row>
            <div className="static-page-card">
              <div className="pricacy-policy-info">
                <h4>Introduction</h4>
                <p>Welcome to TaxTrax, your partner in professional growth for tax expertise. By accessing or using the TaxTrax website and services, you acknowledge that you have read, understood, and agree to be bound by these Terms and Conditions. TaxTrax provides an extensive database of tax knowledge, advanced tools, and real-time updates on legislative changes to enhance your tax-related decision-making capabilities.</p>
              </div>
              <div className="pricacy-policy-info">
                <h4>Use of the Service</h4>
                <p>By engaging with TaxTrax services, you affirm your commitment to these Terms and Conditions. The services are designed to empower tax professionals and enthusiasts by providing cutting-edge tools and comprehensive insights into tax laws and regulations. Your access to and use of TaxTrax's services is predicated upon your adherence to these terms, which serve as a legal agreement between you and TaxTrax.</p>
              </div>
              <div className="pricacy-policy-info">
                <h4>User Obligations</h4>
                <p>As a user of TaxTrax, you are obliged to furnish accurate, complete, and current information about yourself and your tax-related inquiries or situations as necessitated by the service's features. You are responsible for safeguarding the confidentiality of your account details, including passwords, and for all activities that transpire under your account. Misrepresentation or the provision of misleading information may result in service limitations or termination of your account.</p>
              </div>
              <div className="pricacy-policy-info">
                <h4>Service Availability</h4>
                <p>TaxTrax endeavors to provide continuous, reliable access to its services, although it does not warrant uninterrupted or error-free operation. Occasional downtime for maintenance, updates, or unforeseen circumstances may impact service availability. TaxTrax is committed to minimizing such disruptions and providing timely communication regarding service status</p>
              </div>
              <div className="pricacy-policy-info">
                <h4>Data Protection and Privacy</h4>
                <p>Your privacy is paramount at TaxTrax. We are committed to protecting your personal and financial information with the utmost care and in compliance with applicable data protection laws. Our Privacy Policy, integral to these Terms, delineates our practices regarding the collection, use, protection, and disclosure of your information. We employ advanced security measures to safeguard your data from unauthorized access, alteration, or dissemination.</p>
              </div>
              <div className="pricacy-policy-info">
                <h4>Limitation of Liability</h4>
                <p>TaxTrax shall not be held liable for any direct, indirect, incidental, special, consequential, or exemplary damages resulting from your use or inability to use the services. This includes, but is not limited to, damages for loss of profits, goodwill, use, data, or other intangible losses, even if TaxTrax has been advised of the possibility of such damages. Our liability is limited to the maximum extent permitted by law, and under no circumstances will it exceed the amount paid by you to TaxTrax in the preceding twelve months.</p>
              </div>
              <div className="pricacy-policy-info">
                <h4>Changes to Terms</h4>
                <p>TaxTrax reserves the exclusive right to revise and update these Terms and Conditions at any time without prior notice. Your continued engagement with the service following any modifications signifies your acceptance of the revised terms. We encourage you to periodically review the Terms and Conditions to stay informed about your rights and obligations.</p>
              </div>
              <div className="pricacy-policy-info">
                <h4>Governing Law</h4>
                <p>These Terms and Conditions, and your use of TaxTrax services, are governed by and construed in accordance with the laws of the jurisdiction in which TaxTrax operates. This agreement is subject to the exclusive jurisdiction of the courts of that jurisdiction, and any disputes arising out of or relating to these Terms or TaxTrax services will be resolved in accordance with these laws.</p>
              </div>
              <div className="pricacy-policy-info">
                <h4>Contact Us</h4>
                <p>If you have any questions, concerns, or feedback regarding these Terms and Conditions, or if you require further assistance with TaxTrax services, please do not hesitate to contact us. Our dedicated support team is available to provide you with the guidance and information you need to make the most of your TaxTrax experience.</p>
              </div>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
};

export default Terms;
