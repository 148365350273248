import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Container, Button, Form, Accordion } from "react-bootstrap";
import CustomLazyLoad from "../../../Helper/CustomLazyLoad";
import Skeleton from "react-loading-skeleton";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-multi-lang";
import { useDispatch, useSelector } from "react-redux";
import {
  stepUpSipCalculatorStart,
  stepUpSipCalculatorSuccess,
} from "../../../../store/slices/CommonSlice";
import { ButtonLoader } from "../../../Helper/Loader";
import PieChart from "../PieChart";
import FAQIndex from "../FAQIndex";
import { Link } from "react-router-dom";
import formatNumberWithCommas from "../../../FormatNumber";

const CalculatorIndex = (props) => {
  const t = useTranslation("tax_calculator");
  const principleRef = useRef(null);
  const interestRef = useRef(null);
  const timePeriodRef = useRef(null);
  const stepUpRateRef = useRef(null);
  const dispatch = useDispatch();
  const formRef = useRef();
  const stepUpSipCalculator = useSelector(
    (state) => state.common.stepUpSipCalculator
  );

  useEffect(() => {
    const onChange = (evt, field) => {
      const customEvent = evt;
      switch (field) {
        case "principal":
          formRef.current.setFieldValue("principal", customEvent.detail.value);
          break;
        case "interest":
          formRef.current.setFieldValue(
            "rate_of_interest",
            customEvent.detail.value
          );
          break;
        case "timePeriod":
          formRef.current.setFieldValue(
            "time_period",
            customEvent.detail.value
          );
          break;
        case "stepUpRate":
          formRef.current.setFieldValue(
            "step_up_rate",
            customEvent.detail.value
          );
          break;
      }
      setTimeout(() => { formRef.current.submitForm() }, 1)
    };
    principleRef.current?.addEventListener("change", (evt) =>
      onChange(evt, "principal")
    );
    interestRef.current?.addEventListener("change", (evt) =>
      onChange(evt, "interest")
    );
    timePeriodRef.current?.addEventListener("change", (evt) =>
      onChange(evt, "timePeriod")
    );
    stepUpRateRef.current?.addEventListener("change", (evt) =>
      onChange(evt, "stepUpRate")
    );
    return () => {
      principleRef.current?.removeEventListener("change", onChange);
      interestRef.current?.removeEventListener("change", onChange);
      timePeriodRef.current?.removeEventListener("change", onChange);
      stepUpRateRef.current?.removeEventListener("change", onChange);
    };
  }, []);

  useEffect(() => {
    calculate(formRef.current.values);
    //dispatch(stepUpSipCalculatorStart(formRef.current.values));
  }, []);

  const onChangeInput = (event, field) => {
    const value = !isNaN(parseInt(event.target.value))
      ? parseInt(event.target.value)
      : "";
    switch (field) {
      case "principal":
        formRef.current.setFieldValue("principal", value);
        principleRef.current.value = value;
        break;
      case "interest":
        formRef.current.setFieldValue("rate_of_interest", value);
        interestRef.current.value = value;
        break;
      case "timePeriod":
        formRef.current.setFieldValue("time_period", value);
        timePeriodRef.current.value = value;
        break;
      case "stepUpRate":
        formRef.current.setFieldValue("step_up_rate", value);
        stepUpRateRef.current.value = value;
        break;
    }
    setTimeout(() => { formRef.current.submitForm() }, 1)
  };

  const handleSubmit = (values) => {
    formRef.current.isValid && calculate(values);
    // dispatch(stepUpSipCalculatorStart(values));
  };

  const stepUpsipSchema = Yup.object().shape({
    principal: Yup.string()
      .test(
        "min",
        t("step_up_sip_calculator.invalid_min", { value: 500 }),
        (value) => {
          return value >= 500;
        }
      )
      .test(
        "max",
        t("step_up_sip_calculator.invalid_max", {
          value: 1000000,
          name: "Monthly investment",
        }),
        (value) => {
          return value <= 1000000;
        }
      )
      .required(t("step_up_sip_calculator.required")),
    step_up_rate: Yup.string()
      .test(
        "min",
        t("step_up_sip_calculator.invalid_min", { value: 1 }),
        (value) => {
          return value >= 1;
        }
      )
      .test(
        "max",
        t("step_up_sip_calculator.invalid_max", {
          value: 50,
          name: "Step up Rate",
        }),
        (value) => {
          return value <= 50;
        }
      )
      .required(t("step_up_sip_calculator.required")),
    rate_of_interest: Yup.string()
      .test(
        "min",
        t("step_up_sip_calculator.invalid_min", { value: 1 }),
        (value) => {
          return value >= 1;
        }
      )
      .test(
        "max",
        t("step_up_sip_calculator.invalid_max", {
          value: 30,
          name: "Rate of interest",
        }),
        (value) => {
          return value <= 30;
        }
      )
      .required(t("step_up_sip_calculator.required")),
    time_period: Yup.string()
      .test(
        "min",
        t("step_up_sip_calculator.invalid_min", { value: 1 }),
        (value) => {
          return value >= 1;
        }
      )
      .test(
        "max",
        t("step_up_sip_calculator.invalid_max", {
          value: 40,
          name: "Time period",
        }),
        (value) => {
          return value <= 40;
        }
      )
      .required(t("step_up_sip_calculator.required")),
  });

  function yearlySipCalculator(monthlyInvestment, rate_of_interest, time_period) {
    const compoundingFrequency = 12;
    let rateOfInterest = rate_of_interest / 100;
    const monthlyInterestRate = rateOfInterest / 12;
    const months = time_period * 12;
    const maturityAmount = monthlyInvestment * ((1 + monthlyInterestRate) ** months - 1) * ((1 + monthlyInterestRate) / monthlyInterestRate);
    const investedAmount = monthlyInvestment * months;
    const estimatedReturn = maturityAmount - investedAmount;

    return { maturityAmount, investedAmount, estimatedReturn };
  }

  function lumpsumCalculator(principal, rate_of_interest, time_period) {
    const compoundingFrequency = 12;
    let rateOfInterest = rate_of_interest / 100;
    const maturityAmount = principal * ((1 + rateOfInterest / compoundingFrequency) ** (time_period * compoundingFrequency));
    const investedAmount = principal;
    const estimatedReturn = maturityAmount - investedAmount;

    return { maturityAmount, investedAmount, estimatedReturn };
  }

  const calculate = (values) => {
    const { principal, rate_of_interest, time_period, step_up_rate, step_up_frequency } = values
    let maturityAmount = 0;
    let investedAmount = 0;
    let estimatedReturn = 0;
    let principalMonthly = principal
    for (let i = 1; i <= time_period; i++) {
      const lumpsum = lumpsumCalculator(maturityAmount, rate_of_interest, 1);
      const yearly = yearlySipCalculator(principalMonthly, rate_of_interest, 1);
      maturityAmount += yearly.maturityAmount;
      maturityAmount += lumpsum.estimatedReturn;
      investedAmount += yearly.investedAmount;
      estimatedReturn += yearly.estimatedReturn;
      estimatedReturn += lumpsum.estimatedReturn;

      principalMonthly *= (1 + step_up_rate / 100);
    }

    maturityAmount = Math.round(maturityAmount);
    investedAmount = Math.round(investedAmount);
    estimatedReturn = Math.round(estimatedReturn);
    return dispatch(stepUpSipCalculatorSuccess({ maturity_amount: maturityAmount, invested_amount: investedAmount, estimated_return: estimatedReturn }));
  }

  return (
    <>
      <div className="single-tax-calculator">
        <div className="sm-padding single-tax-calculator-sec">
          <Container>
            <div className="single-tax-calculator-box">
              <div className="single-tax-calculator-header">
                <Row>
                  <Col md={6}>
                    <div className="d-flex other-calculator-btn-sec">
                      <Link
                        to="/tax-calculator"
                        className="other-calculator-btn"
                      >
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            enableBackground="new 0 0 512 512"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="#fff"
                              d="M10.957 12.354a.5.5 0 010-.708l4.586-4.585a1.5 1.5 0 00-2.121-2.122L8.836 9.525a3.505 3.505 0 000 4.95l4.586 4.586a1.5 1.5 0 002.121-2.122z"
                              data-original="#000000"
                            ></path>
                          </svg>
                        </span>
                        <span>Other Calculators</span>
                      </Link>
                    </div>
                    <div className="single-tax-calculator-info-sec">
                      <h1>{t("step_up_sip_calculator.heading")}</h1>
                      <p>{t("step_up_sip_calculator.content")}</p>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="single-tax-calculator-header-img-sec">
                      <CustomLazyLoad
                        className="single-tax-calculator-header-img"
                        src={
                          window.location.origin +
                          "/img/single-tax-calculator-header-img.png"
                        }
                        type="image/svg"
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="single-tax-calculator-form-box  line-stright calculator-space-lg">
              <Row className="align-items-center g-5">
                <Col md={6} lg={6}>
                  <Formik
                    initialValues={{
                      principal: 100000,
                      rate_of_interest: 2,
                      time_period: 2,
                      step_up_rate: 29,
                      step_up_frequency: 1,
                    }}
                    innerRef={formRef}
                    validationSchema={stepUpsipSchema}
                    onSubmit={handleSubmit}
                  >
                    {({ setFieldValue, values, resetForm, submitForm, isValid }) => (
                      <FORM className="single-tax-calculator-form">
                        <div className="single-tax-calculator-form-card">
                          <div className="single-tax-calculator-form-header-sec">
                            <h4>
                              {t("step_up_sip_calculator.investment.text")}
                            </h4>
                            <Form.Group
                              className="mb-0"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Field
                                className="form-control"
                                name="principal"
                                placeholder={t(
                                  "step_up_sip_calculator.investment.placeholder"
                                )}
                                type="number"
                                onKeyDown={(e) => {
                                  if (
                                    !/[0-9]|Backspace|ArrowLeft|ArrowRight|Delete|ArrowUp|ArrowDown|Tab/.test(
                                      e.key
                                    ) &&
                                    !(
                                      e.ctrlKey &&
                                      (e.key === "v" ||
                                        e.key === "c" ||
                                        e.key === "x" ||
                                        e.key === "a")
                                    )
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                                onInput={(e) => {
                                  if (e.target.value < 0) {
                                    e.target.value = 0;
                                  }
                                  onChangeInput(e, "principal");
                                }}
                              />
                            </Form.Group>
                          </div>
                          <ErrorMessage
                            component={"div"}
                            name="principal"
                            className="errorMsg"
                          />
                          <div className="tax-calculator-input-slider-sec">
                            <tc-range-slider
                              ref={principleRef}
                              value={principleRef}
                              mousewheel-disabled="true"
                              min={500}
                              max={1000000}
                              slider-width="100%"
                              slider-bg-fill="#2F5CFF"
                              generate-labels="true"
                              round="0"
                            ></tc-range-slider>
                          </div>
                        </div>
                        <div className="single-tax-calculator-form-card">
                          <div className="single-tax-calculator-form-header-sec">
                            <h4>
                              {t("step_up_sip_calculator.annual_step_up.text")}
                            </h4>
                            <Form.Group
                              className="mb-0"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Field
                                className="form-control"
                                name="step_up_rate"
                                placeholder={t(
                                  "step_up_sip_calculator.annual_step_up.placeholder"
                                )}
                                type="number"
                                onKeyDown={(e) => {
                                  if (
                                    !/[0-9]|Backspace|ArrowLeft|ArrowRight|Delete|ArrowUp|ArrowDown|Tab/.test(
                                      e.key
                                    ) &&
                                    !(
                                      e.ctrlKey &&
                                      (e.key === "v" ||
                                        e.key === "c" ||
                                        e.key === "x" ||
                                        e.key === "a")
                                    )
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                                onInput={(e) => {
                                  if (e.target.value < 0) {
                                    e.target.value = 0;
                                  }
                                  onChangeInput(e, "stepUpRate");
                                }}
                              />
                            </Form.Group>
                          </div>
                          <ErrorMessage
                            component={"div"}
                            name="step_up_rate"
                            className="errorMsg"
                          />
                          <div className="tax-calculator-input-slider-sec">
                            <tc-range-slider
                              ref={stepUpRateRef}
                              value={stepUpRateRef}
                              mousewheel-disabled="true"
                              min={1}
                              max={50}
                              slider-width="100%"
                              slider-bg-fill="#2F5CFF"
                              generate-labels="true"
                              round="0"
                            ></tc-range-slider>
                          </div>
                        </div>
                        <div className="single-tax-calculator-form-card">
                          <div className="single-tax-calculator-form-header-sec">
                            <h4>
                              {t("step_up_sip_calculator.return_rate.text")}
                            </h4>
                            <Form.Group
                              className="mb-0"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Field
                                className="form-control"
                                name="rate_of_interest"
                                placeholder={t(
                                  "step_up_sip_calculator.return_rate.placeholder"
                                )}
                                type="number"
                                onKeyDown={(e) => {
                                  if (
                                    !/[0-9]|Backspace|ArrowLeft|ArrowRight|Delete|ArrowUp|ArrowDown|Tab/.test(
                                      e.key
                                    ) &&
                                    !(
                                      e.ctrlKey &&
                                      (e.key === "v" ||
                                        e.key === "c" ||
                                        e.key === "x" ||
                                        e.key === "a")
                                    )
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                                onInput={(e) => {
                                  if (e.target.value < 0) {
                                    e.target.value = 0;
                                  }
                                  onChangeInput(e, "interest");
                                }}
                              />
                            </Form.Group>
                          </div>
                          <ErrorMessage
                            component={"div"}
                            name="rate_of_interest"
                            className="errorMsg"
                          />
                          <div className="tax-calculator-input-slider-sec">
                            <tc-range-slider
                              ref={interestRef}
                              value={interestRef}
                              mousewheel-disabled="true"
                              min={1}
                              max={30}
                              slider-width="100%"
                              slider-bg-fill="#2F5CFF"
                              generate-labels="true"
                              round="1"
                            ></tc-range-slider>
                          </div>
                        </div>
                        <div className="single-tax-calculator-form-card">
                          <div className="single-tax-calculator-form-header-sec">
                            <h4>
                              {t("step_up_sip_calculator.time_period.text")}
                            </h4>
                            <Form.Group
                              className="mb-0"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Field
                                className="form-control"
                                name="time_period"
                                placeholder={t(
                                  "step_up_sip_calculator.time_period.placeholder"
                                )}
                                type="number"
                                onKeyDown={(e) => {
                                  if (
                                    !/[0-9]|Backspace|ArrowLeft|ArrowRight|Delete|ArrowUp|ArrowDown|Tab/.test(
                                      e.key
                                    ) &&
                                    !(
                                      e.ctrlKey &&
                                      (e.key === "v" ||
                                        e.key === "c" ||
                                        e.key === "x" ||
                                        e.key === "a")
                                    )
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                                onInput={(e) => {
                                  if (e.target.value < 0) {
                                    e.target.value = 0;
                                  }
                                  onChangeInput(e, "timePeriod");
                                }}
                              />
                            </Form.Group>
                          </div>
                          <ErrorMessage
                            component={"div"}
                            name="time_period"
                            className="errorMsg"
                          />
                          <div className="tax-calculator-input-slider-sec">
                            <tc-range-slider
                              ref={timePeriodRef}
                              value={timePeriodRef}
                              mousewheel-disabled="true"
                              min={1}
                              max={40}
                              slider-width="100%"
                              slider-bg-fill="#2F5CFF"
                              generate-labels="true"
                              round="0"
                            ></tc-range-slider>
                          </div>
                        </div>
                        {/* <div className="d-flex justify-content-end">
                          <Button
                            type="submit"
                            className="default-primary-btn"
                            disabled={stepUpSipCalculator.buttonDisable}
                          >
                            {stepUpSipCalculator.buttonDisable ? (
                              <ButtonLoader />
                            ) : (
                              t("step_up_sip_calculator.calculate")
                            )}
                          </Button>
                        </div> */}
                      </FORM>
                    )}
                  </Formik>
                </Col>
                <Col md={6} lg={6}>
                  {stepUpSipCalculator.loading ? (
                    <div className="single-calculator-info-frame">
                      <div className="pie-chart-skelton">
                        <div className="skeleton-wrapper">
                          <Skeleton
                            borderRadius={130}
                            width={230}
                            height={230}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                        </div>
                        <div className="mt-4">
                          <Skeleton
                            width={250}
                            height={15}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                        </div>
                      </div>
                      <div className="calculator-info-details-card">
                        <div className="calculator-details-frame">
                          <Skeleton
                            width={200}
                            height={20}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                          <Skeleton
                            width={130}
                            height={20}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                        </div>
                        <div className="calculator-details-frame">
                          <Skeleton
                            width={200}
                            height={20}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                          <Skeleton
                            width={130}
                            height={20}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                        </div>
                        <hr />
                        <div className="calculator-details-frame top-bottom-line">
                          <Skeleton
                            width={200}
                            height={20}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                          <Skeleton
                            width={130}
                            height={20}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                        </div>
                        <hr />
                      </div>
                    </div>
                  ) : (
                    Object.keys(stepUpSipCalculator.data).length > 0 && (
                      <div className="single-calculator-info-frame">
                        <div className="pie-chart-sec">
                          <PieChart
                            name={[
                              t("step_up_sip_calculator.invested_amount"),
                              t("step_up_sip_calculator.estimated_returns"),
                            ]}
                            data={[
                              stepUpSipCalculator.data.invested_amount,
                              stepUpSipCalculator.data.estimated_return,
                            ]}
                          />
                        </div>
                        <div className="calculator-info-details-card">
                          <div className="calculator-details-frame">
                            <p>
                              {" "}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                x="0"
                                y="0"
                                enableBackground="new 0 0 29.107 29.107"
                                version="1.1"
                                viewBox="0 0 29.107 29.107"
                                xmlSpace="preserve"
                                width="20"
                                height="20"
                                fill="#2A334F"
                              >
                                <path d="M14.554 0C6.561 0 0 6.562 0 14.552c0 7.996 6.561 14.555 14.554 14.555 7.996 0 14.553-6.559 14.553-14.555C29.106 6.562 22.55 0 14.554 0z"></path>
                              </svg>
                              <span>
                                {t("step_up_sip_calculator.invested_amount")}
                              </span>
                            </p>
                            <h3>
                              {formatNumberWithCommas(
                                stepUpSipCalculator.data.invested_amount
                              )}
                            </h3>
                          </div>
                          <div className="calculator-details-frame">
                            <p>
                              {" "}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                x="0"
                                y="0"
                                enableBackground="new 0 0 29.107 29.107"
                                version="1.1"
                                viewBox="0 0 29.107 29.107"
                                xmlSpace="preserve"
                                width="20"
                                height="20"
                                fill="#2F5CFF"
                              >
                                <path d="M14.554 0C6.561 0 0 6.562 0 14.552c0 7.996 6.561 14.555 14.554 14.555 7.996 0 14.553-6.559 14.553-14.555C29.106 6.562 22.55 0 14.554 0z"></path>
                              </svg>
                              <span>
                                {" "}
                                {t("step_up_sip_calculator.estimated_returns")}
                              </span>
                            </p>
                            <h3>
                              {formatNumberWithCommas(
                                stepUpSipCalculator.data.estimated_return
                              )}
                            </h3>
                          </div>
                          <hr />
                          <div className="calculator-details-frame top-bottom-line">
                            <p className="d-inline">
                              {" "}
                              {t("step_up_sip_calculator.total_value")}
                            </p>
                            <h3>
                              {formatNumberWithCommas(
                                stepUpSipCalculator.data.maturity_amount
                              )}
                            </h3>
                          </div>
                          <hr />
                        </div>
                      </div>
                    )
                  )}
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <Container>
          <Row>
            <Col lg={12}>
              <div className="static-page-card">
                <div className="pricacy-policy-info">
                  <h4>Description</h4>
                  <p>
                    A Step-Up SIP (Systematic Investment Plan) is a mechanism
                    for investing in mutual funds, allowing for regular
                    transfers from a savings account to a mutual fund at set
                    intervals—be it weekly, monthly, or quarterly. The Step-Up
                    SIP calculator is a tool designed to help you project the
                    growth of your SIP investments, especially useful if you
                    plan to increase your investment amount annually.
                  </p>
                  <ul>
                    <li>
                      <h4>Understanding Growth Potential:</h4>
                      The Step-Up SIP calculator enables investors to see how
                      their savings can compound, aiding in long-term wealth
                      creation.
                    </li>
                    <li>
                      <h4>Goal-Oriented Saving:</h4>
                      It serves as a tool to help you build towards specific
                      financial goals and objectives.
                    </li>
                    <li>
                      <h4>Decision-Making Support: </h4>The calculator provides
                      immediate calculations to help investors determine if the
                      investment aligns with their financial strategy.
                    </li>
                  </ul>
                  <p>
                    The Step-Up SIP calculator is designed to estimate the
                    future value of your SIP investments when you periodically
                    increase your SIP amount by a specified percentage. By
                    inputting the necessary information into the calculator, you
                    can easily obtain the final amount using this step-up mutual
                    fund calculator.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <FAQIndex
          data={[
            {
              question: "What frequency options are available for Step-Up SIP?",
              answer:
                "Step-Up SIP can be configured to increase at regular intervals, typically on an annual basis, but the frequency can be adjusted according to specific plan details offered by the fund.",
            },
            {
              question: "Is it possible to apply a Step-Up to an existing SIP?",
              answer:
                "Generally, once a SIP is set up, its terms cannot be changed; however, you can start a new Step-Up SIP or modify your investment strategy during the renewal or with a new investment.",
            },
            {
              question: "Why should I consider a Step-Up in my SIP",
              answer:
                "A Step-Up SIP is beneficial as it aligns with your increasing income, allowing you to invest more as your earning capacity grows. This helps in building a larger corpus over time, maximizing the compounding effect.",
            },
            {
              question: "How does a Step-Up SIP differ from a traditional SIP?",
              answer:
                "Unlike a conventional SIP where the investment amount remains constant throughout the tenure, a Step-Up SIP allows for an increase in the investment amount at specified intervals, enhancing potential returns due to higher contributions over time.",
            },
            {
              question: "When is the right time to initiate a Step-Up SIP",
              answer:
                "You can start a Step-Up SIP at any time, ideally when you foresee a steady increase in your income which would allow you to commit more towards investments progressively.",
            },
            {
              question: "What are the advantages of investing through SIP?",
              answer:
                "SIP investments provide several benefits including disciplined saving, lower impact of market volatility through rupee cost averaging, and the potential for higher returns over the long term due to the compounding effect.",
            },
          ]}
        />
      </div>
    </>
  );
};

export default CalculatorIndex;
