const formatNumberWithCommas = (num) => {
  let numStr = num.toString();
  let lastThree = numStr.slice(-3);
  let otherNumbers = numStr.slice(0, -3);
  if (otherNumbers != "") {
    lastThree = "," + lastThree;
  }
  return (
    "₹" + " " + otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree
  );
};

export default formatNumberWithCommas;
