import React, { useEffect, useState } from "react";
import { Navbar, Container, Nav, Button } from "react-bootstrap";
import CustomLazyLoad from "../../Helper/CustomLazyLoad";
import { Link, useLocation, useNavigate } from "react-router-dom";

const HeaderIndex = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isScrolled, setIsScrolled] = useState(
    location.pathname == "/" ? false : true
  );

  const handleClick = () => {
    if (location.pathname == "/") {
      navigate("/tax-calculator");
      setIsScrolled(!isScrolled);
    } else navigate("/");
    setIsScrolled(!isScrolled);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrolled = window.scrollY > window.innerHeight;
      location.pathname == "/" && setIsScrolled(scrolled);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <header className="header-nav-center">
        <Navbar expand="lg">
          <Container>
            <Navbar.Brand href="/">
              <CustomLazyLoad
                className="logo"
                src={window.location.origin + "/img/tax-trax-logo.png"}
                type="image/png"
              />
            </Navbar.Brand>
            <div
              className={
                !isScrolled && location.pathname == "/"
                  ? "header-nav-link-sec"
                  : "header-nav-link-right-sec"
              }
            >
              <Button onClick={handleClick} className="open-header-btn">
                {location.pathname == "/" ? "Calculators" : "Tax Assistant"}
              </Button>
            </div>
            {/* <Navbar.Toggle aria-controls="basic-navbar-nav">
              <CustomLazyLoad
                className="hamburger-menu"
                src={
                  window.location.origin + "/img/hamburger-menu.png"
                }
                type="image/png"
              />
            </Navbar.Toggle>
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ms-auto">
                <div className="nav-header-btn">
                    <Link to="#" target="_blank" className="open-header-btn">Login / Register</Link>
                </div>
              </Nav>
            </Navbar.Collapse> */}

            <div className="theme-switch-sec">
              <label className="switch">
                <input type="checkbox" id="switch-styler"  onChange={props.toggleTheme} checked={props.darkTheme}/>
                <span className="slider round" id="switch-style"></span>
              </label>
            </div>

          </Container>
        </Navbar>
      </header>
    </>
  );
};

export default HeaderIndex;
