import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Container, Accordion, Form, Button } from "react-bootstrap";
import CustomLazyLoad from "../../../Helper/CustomLazyLoad";
import { Link, useLocation } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-multi-lang";
import { useDispatch, useSelector } from "react-redux";
import {
  epfCalculatorStart,
  epfCalculatorSuccess,
} from "../../../../store/slices/CommonSlice";
import { ButtonLoader } from "../../../Helper/Loader";
import FAQIndex from "../FAQIndex";
import formatNumberWithCommas from "../../../FormatNumber";
const CalculatorIndex = (props) => {
  const t = useTranslation("tax_calculator");
  const monthlyRef = useRef(null);
  const epfRef = useRef(null);
  const ageRef = useRef();
  const annualIncreaseRef = useRef();
  const dispatch = useDispatch();
  const formRef = useRef();
  const epfCalculator = useSelector((state) => state.common.epfCalculator);

  useEffect(() => {
    const onChange = (event, field) => {
      const customEvent = event;
      switch (field) {
        case "monthlySalary":
          formRef.current.setFieldValue(
            "monthly_salary",
            customEvent.detail.value
          );
          break;
        case "age":
          formRef.current.setFieldValue("age", customEvent.detail.value);
          break;
        case "epf":
          formRef.current.setFieldValue(
            "epf_contribution",
            customEvent.detail.value
          );
          break;
        case "annualIncrease":
          formRef.current.setFieldValue(
            "annual_increase",
            customEvent.detail.value
          );
          break;
      }
      setTimeout(() => {
        formRef.current.submitForm();
      }, 1);
    };
    monthlyRef.current?.addEventListener("change", (event) =>
      onChange(event, "monthlySalary")
    );
    epfRef.current?.addEventListener("change", (event) =>
      onChange(event, "epf")
    );
    annualIncreaseRef.current?.addEventListener("change", (event) =>
      onChange(event, "annualIncrease")
    );
    ageRef.current?.addEventListener("change", (event) =>
      onChange(event, "age")
    );
    return () => {
      monthlyRef.current?.removeEventListener("change", onChange);
      epfRef.current?.removeEventListener("change", onChange);
      ageRef.current?.removeEventListener("change", onChange);
      annualIncreaseRef.current?.removeEventListener("change", onChange);
    };
  }, []);

  useEffect(() => {
    calculate(formRef.current.values);
    // dispatch(epfCalculatorStart(formRef.current.values));
  }, []);

  const onChangeInput = (event, field) => {
    const value = !isNaN(parseInt(event.target.value))
      ? parseInt(event.target.value)
      : "";
    switch (field) {
      case "monthlySalary":
        formRef.current.setFieldValue("monthly_salary", value);
        monthlyRef.current.value = value;
        break;
      case "epf":
        formRef.current.setFieldValue("epf_contribution", value);
        epfRef.current.value = value;
        break;
      case "age":
        formRef.current.setFieldValue("age", value);
        ageRef.current.value = value;
        break;
      case "annualIncrease":
        formRef.current.setFieldValue("annual_increase", value);
        annualIncreaseRef.current.value = value;
        break;
    }
    setTimeout(() => {
      formRef.current.submitForm();
    }, 1);
  };

  const handleSubmit = (values) => {
    formRef.current.isValid && calculate(values);
    // formRef.current.isValid && dispatch(epfCalculatorStart(values));
  };

  const epfSchema = Yup.object().shape({
    monthly_salary: Yup.string()
      .test(
        "min",
        t("epf_calculator.invalid_min", { value: 1000 }),
        (value) => {
          return value >= 1000;
        }
      )
      .test(
        "max",
        t("epf_calculator.invalid_max", {
          value: 500000,
          name: "Monthly salary",
        }),
        (value) => {
          return value <= 500000;
        }
      )
      .required(t("epf_calculator.required")),
    epf_contribution: Yup.string()
      .test("min", t("epf_calculator.invalid_min", { value: 12 }), (value) => {
        return value >= 12;
      })
      .test(
        "max",
        t("epf_calculator.invalid_max", { value: 20, name: "Epf" }),
        (value) => {
          return value <= 20;
        }
      )
      .required(t("epf_calculator.required")),
    annual_increase: Yup.string()
      .required(t("epf_calculator.required"))
      .test(
        "max",
        t("epf_calculator.invalid_max", { value: 15, name: "Annual increase" }),
        (value) => {
          return value <= 15;
        }
      ),
    age: Yup.string()
      .test("min", t("epf_calculator.invalid_min", { value: 15 }), (value) => {
        return value >= 15;
      })
      .test(
        "max",
        t("epf_calculator.invalid_max", { value: 58, name: "Age" }),
        (value) => {
          return value <= 58;
        }
      )
      .required(t("epf_calculator.required")),
  });

  const calculate = (values) => {
    const {
      monthly_salary,
      age,
      epf_contribution,
      rate_of_interest,
      annual_increase,
    } = values;
    const employerEpf = 3.67;
    const retirementAge = 58;
    let rateOfInterest = rate_of_interest / 100;
    let annualIncrease = annual_increase / 100;
    let yearlyIncome = monthly_salary * 12;
    let epfClosingBalance = 0;
    for (let i = age; i < retirementAge; i++) {
      if (i !== age) {
        yearlyIncome = yearlyIncome * (1 + annualIncrease);
      }

      let employeeEpfContribution = yearlyIncome * (epf_contribution / 100);
      let employerEpfContribution = yearlyIncome * (employerEpf / 100);
      let yearEpfContribution =
        employeeEpfContribution + employerEpfContribution;

      epfClosingBalance += yearEpfContribution;
      epfClosingBalance = epfClosingBalance * (1 + rateOfInterest);
    }

    epfClosingBalance = Math.round(epfClosingBalance);
    dispatch(epfCalculatorSuccess({ estimated_return: epfClosingBalance }));
  };

  return (
    <>
      <div className="single-tax-calculator">
        <div className="sm-padding single-tax-calculator-sec">
          <Container>
            <div className="single-tax-calculator-box">
              <div className="single-tax-calculator-header">
                <Row>
                  <Col md={6}>
                    <div className="d-flex other-calculator-btn-sec">
                      <Link
                        to="/tax-calculator"
                        className="other-calculator-btn"
                      >
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            enableBackground="new 0 0 512 512"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="#fff"
                              d="M10.957 12.354a.5.5 0 010-.708l4.586-4.585a1.5 1.5 0 00-2.121-2.122L8.836 9.525a3.505 3.505 0 000 4.95l4.586 4.586a1.5 1.5 0 002.121-2.122z"
                              data-original="#000000"
                            ></path>
                          </svg>
                        </span>
                        <span>Other Calculators</span>
                      </Link>
                    </div>
                    <div className="single-tax-calculator-info-sec">
                      <h1>{t("epf_calculator.heading")}</h1>
                      <p>{t("epf_calculator.content")}</p>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="single-tax-calculator-header-img-sec">
                      <CustomLazyLoad
                        className="single-tax-calculator-header-img"
                        src={
                          window.location.origin +
                          "/img/single-tax-calculator-header-img.png"
                        }
                        type="image/svg"
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="single-tax-calculator-form-box  line-stright line-bit-left">
              <Row className="align-items-center">
                <Col
                  md={12}
                  xl={6}
                  lg={12}
                  className="resp-margin-btm-small-lap"
                >
                  <Formik
                    initialValues={{
                      monthly_salary: 5000,
                      age: 30,
                      epf_contribution: 12,
                      rate_of_interest: 8.1,
                      annual_increase: 5,
                    }}
                    innerRef={formRef}
                    validationSchema={epfSchema}
                    onSubmit={handleSubmit}
                  >
                    {({
                      setFieldValue,
                      values,
                      resetForm,
                      submitForm,
                      isValid,
                    }) => (
                      <FORM className="single-tax-calculator-form ">
                        <div className="single-tax-calculator-form-card">
                          <div className="single-tax-calculator-form-header-sec">
                            <h4>{t("epf_calculator.monthly_salary.text")}</h4>
                            <Form.Group
                              className="mb-0"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Field
                                className="form-control"
                                name="monthly_salary"
                                placeholder={t(
                                  "epf_calculator.monthly_salary.placeholder"
                                )}
                                type="number"
                                onKeyDown={(e) => {
                                  if (
                                    !/[0-9]|Backspace|ArrowLeft|ArrowRight|Delete|ArrowUp|ArrowDown|Tab/.test(
                                      e.key
                                    ) &&
                                    !(
                                      e.ctrlKey &&
                                      (e.key === "v" ||
                                        e.key === "c" ||
                                        e.key === "x" ||
                                        e.key === "a")
                                    )
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                                onInput={(e) => {
                                  if (e.target.value < 0) {
                                    e.target.value = 0;
                                  }
                                  onChangeInput(e, "monthlySalary");
                                }}
                              />
                            </Form.Group>
                          </div>
                          <ErrorMessage
                            component={"div"}
                            name="monthly_salary"
                            className="errorMsg"
                          />
                          <div className="tax-calculator-input-slider-sec">
                            <tc-range-slider
                              ref={monthlyRef}
                              value={monthlyRef}
                              mousewheel-disabled="true"
                              min={1000}
                              max={500000}
                              slider-width="100%"
                              slider-bg-fill="#2F5CFF"
                              generate-labels="true"
                              round="0"
                            ></tc-range-slider>
                          </div>
                        </div>
                        <div className="single-tax-calculator-form-card">
                          <div className="single-tax-calculator-form-header-sec">
                            <h4>{t("epf_calculator.age.text")}</h4>
                            <Form.Group
                              className="mb-0"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Field
                                className="form-control"
                                name="age"
                                placeholder={t(
                                  "epf_calculator.age.placeholder"
                                )}
                                type="number"
                                onKeyDown={(e) => {
                                  if (
                                    !/[0-9]|Backspace|ArrowLeft|ArrowRight|Delete|ArrowUp|ArrowDown|Tab/.test(
                                      e.key
                                    ) &&
                                    !(
                                      e.ctrlKey &&
                                      (e.key === "v" ||
                                        e.key === "c" ||
                                        e.key === "x" ||
                                        e.key === "a")
                                    )
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                                onInput={(e) => {
                                  if (e.target.value < 0) {
                                    e.target.value = 0;
                                  }
                                  onChangeInput(e, "age");
                                }}
                              />
                            </Form.Group>
                          </div>
                          <ErrorMessage
                            component={"div"}
                            name="age"
                            className="errorMsg"
                          />
                          <div className="tax-calculator-input-slider-sec">
                            <tc-range-slider
                              ref={ageRef}
                              value={ageRef}
                              mousewheel-disabled="true"
                              min={15}
                              max={58}
                              slider-width="100%"
                              slider-bg-fill="#2F5CFF"
                              generate-labels="true"
                              round="0"
                            ></tc-range-slider>
                          </div>
                          <ErrorMessage
                            component={"div"}
                            name="principal"
                            className="errorMsg"
                          />
                        </div>
                        <div className="single-tax-calculator-form-card">
                          <div className="single-tax-calculator-form-header-sec">
                            <h4>{t("epf_calculator.epf.text")}</h4>
                            <Form.Group
                              className="mb-0"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Field
                                className="form-control"
                                name="epf_contribution"
                                placeholder={t(
                                  "epf_calculator.epf.placeholder"
                                )}
                                type="number"
                                onKeyDown={(e) => {
                                  if (
                                    !/[0-9]|Backspace|ArrowLeft|ArrowRight|Delete|ArrowUp|ArrowDown|Tab/.test(
                                      e.key
                                    ) &&
                                    !(
                                      e.ctrlKey &&
                                      (e.key === "v" ||
                                        e.key === "c" ||
                                        e.key === "x" ||
                                        e.key === "a")
                                    )
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                                onInput={(e) => {
                                  if (e.target.value < 0) {
                                    e.target.value = 0;
                                  }
                                  onChangeInput(e, "epf");
                                }}
                              />
                            </Form.Group>
                          </div>
                          <ErrorMessage
                            component={"div"}
                            name="epf_contribution"
                            className="errorMsg"
                          />
                          <div className="tax-calculator-input-slider-sec">
                            <tc-range-slider
                              ref={epfRef}
                              value={epfRef}
                              mousewheel-disabled="true"
                              min={12}
                              max={20}
                              slider-width="100%"
                              slider-bg-fill="#2F5CFF"
                              generate-labels="true"
                              round="0"
                            ></tc-range-slider>
                          </div>
                        </div>
                        <div className="single-tax-calculator-form-card">
                          <div className="single-tax-calculator-form-header-sec">
                            <h4>{t("epf_calculator.annual_salary.text")}</h4>
                            <Form.Group
                              className="mb-0"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Field
                                className="form-control"
                                name="annual_increase"
                                placeholder={t(
                                  "epf_calculator.annual_salary.placeholder"
                                )}
                                type="number"
                                onKeyDown={(e) => {
                                  if (
                                    !/[0-9]|Backspace|ArrowLeft|ArrowRight|Delete|ArrowUp|ArrowDown|Tab/.test(
                                      e.key
                                    ) &&
                                    !(
                                      e.ctrlKey &&
                                      (e.key === "v" ||
                                        e.key === "c" ||
                                        e.key === "x" ||
                                        e.key === "a")
                                    )
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                                onInput={(e) => {
                                  if (e.target.value < 0) {
                                    e.target.value = 0;
                                  }
                                  onChangeInput(e, "annualIncrease");
                                }}
                              />
                            </Form.Group>
                          </div>
                          <ErrorMessage
                            component={"div"}
                            name="annual_increase"
                            className="errorMsg"
                          />
                          <div className="tax-calculator-input-slider-sec">
                            <tc-range-slider
                              ref={annualIncreaseRef}
                              value={annualIncreaseRef}
                              mousewheel-disabled="true"
                              min={0}
                              max={15}
                              slider-width="100%"
                              slider-bg-fill="#2F5CFF"
                              generate-labels="true"
                              round="0"
                            ></tc-range-slider>
                          </div>
                        </div>
                        <div className="single-tax-calculator-form-card">
                          <div className="single-tax-calculator-form-header-sec">
                            <h4>{t("epf_calculator.rate_of_interest.text")}</h4>
                            <h4>{formRef.current?.values.rate_of_interest}%</h4>
                          </div>
                        </div>
                        {/* <div className="d-flex justify-content-end mt-4">
                          <Button
                            type="submit"
                            className="default-primary-btn"
                            disabled={epfCalculator.buttonDisable}
                          >
                            {epfCalculator.buttonDisable ? (
                              <ButtonLoader />
                            ) : (
                              t("epf_calculator.calculate")
                            )}
                          </Button>
                        </div> */}
                      </FORM>
                    )}
                  </Formik>
                </Col>
                <Col md={12} xl={6} lg={12}>
                  {epfCalculator.loading ? (
                    <div className="single-tax-calculator-form-value-details-sec ">
                      <Skeleton
                        baseColor="#202020"
                        highlightColor="#444"
                        height={15}
                        width={250}
                      />

                      <Skeleton
                        baseColor="#202020"
                        highlightColor="#444"
                        borderRadius={10}
                        height={60}
                        width={150}
                      />
                    </div>
                  ) : (
                    Object.keys(epfCalculator.data).length > 0 && (
                      <div className="single-tax-calculator-form-value-details-sec ">
                        <p>{t("epf_calculator.by_time_retire")}</p>
                        <div className="single-tax-calculator-form-value-amount">
                          {formatNumberWithCommas(
                            epfCalculator.data.estimated_return
                          )}
                        </div>
                      </div>
                    )
                  )}
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <Container>
          <Row>
            <Col lg={12}>
              <div className="static-page-card">
                <div className="pricacy-policy-info">
                  <h4>Description</h4>
                  <p>
                    Most employees in the private sector are eligible for
                    post-retirement benefits if they are part of the formal
                    sector. Unlike their counterparts in the private sector,
                    government employees also receive pension benefits. The
                    Employee Provident Fund (EPF) was established following the
                    enactment of the EPF Act in Parliament. The Employees
                    Provident Fund Organisation of India (EPFO) oversees the
                    contributions made by both employees and employers into a
                    permanent account, each marked by a Unique Account Number
                    (UAN). An EPF calculator can assist in accurately projecting
                    your savings.
                  </p>
                  <p>
                    The EPF calculator employs advanced technology to accurately
                    determine your total savings each time you enter your
                    details. The Provident Fund serves as a safety net for
                    future well-being or in case of job loss, making it crucial
                    for financial planning.
                  </p>
                </div>
                <div className="pricacy-policy-info">
                  <h4>Benefits of Using an EPF Calculator:</h4>
                  <p>
                    Utilizing our EPF calculator in India allows you to
                    effortlessly monitor your investments and the interest
                    they're accruing.
                  </p>
                  <p>
                    Here are several key benefits of using an online EPF
                    calculator:
                  </p>
                  <ul>
                    <li>
                      It eliminates the need for manual calculations of your
                      contributions.
                    </li>
                    <li>
                      Our online PF calculator ensures accurate calculations
                      every time.
                    </li>
                    <li>
                      Changes in interest rates or contribution ratios over time
                      are automatically adjusted by the calculator.
                    </li>
                    <li>
                      Using our calculator keeps you updated on any recent
                      transactions, contributions, and changes, such as updates
                      to the interest rate, which is currently at 8.15%. Should
                      this rate adjust due to new legislation within the fiscal
                      year, you'll be notified upon logging in.
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <FAQIndex
          data={[
            {
              question: "Is there a charge for using the EPF calculator?",
              answer:
                "No, the EPF calculator is available for use at no cost, allowing you to estimate your savings without any fees.",
            },
            {
              question:
                "Are there any limits to how often I can access the online EPF calculator?",
              answer:
                "You can use the online EPF calculator as many times as needed, providing the flexibility to make multiple calculations at any time.",
            },
            {
              question:
                "Can I continue to use the EPF calculator if I switch employers?",
              answer:
                "Yes, the EPF calculator remains usable even if you change jobs. It's designed to work with your EPF details regardless of employer changes.",
            },
            {
              question:
                "What occurs if the contribution rates, from either my side or my employer's, are altered?",
              answer:
                "If there are any changes to the contribution rates by you or your employer, the EPF calculator can adjust to these changes, ensuring your calculations remain accurate.",
            },
            {
              question:
                "When am I allowed to withdraw funds from my EPF account?",
              answer:
                "Withdrawals from your EPF account can be made under specific conditions, such as retirement, unemployment, or for certain permitted expenses like medical emergencies, home purchase, or education. There are specific rules and timings for these withdrawals as per EPF guidelines.",
            },
          ]}
        />
      </div>
    </>
  );
};

export default CalculatorIndex;
