import React, {
  useContext,
  useEffect,
  useRef,
  useState,
  Children,
} from "react";
import { Routes, Route, Navigate, BrowserRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import configuration from "react-global-configuration";
import LandingPageIndex from "../LandingPage/LandingPageIndex";
import EmptyLayout from "../layouts/EmptyLayout";
import ScrollToTop from "../Helper/ScrollTop";
import MainLayout from "../layouts/MainLayout";
import { ToastContainer } from "react-toastify";
import TaxCalculatorIndex from "./TaxCalculator/TaxCalculatorIndex";
import { setTranslations, setLanguage } from "react-multi-lang";
import en from "../translations/en.json";
import PrivacyPolicy from "../StaticPage/PrivacyPolicy";
import Terms from "../StaticPage/Terms";
import ContactUs from "../StaticPage/ContactUs";
import AboutUs from "../StaticPage/AboutUs";
import Page404 from "../Helper/Page404";
import IncomeTaxCalculator from "./TaxCalculator/IncomeTax/CalculatorIndex";
import EPFCalculatorIndex from "./TaxCalculator/EPF/CalculatorIndex";
import SIPCalculatorIndex from "./TaxCalculator/SIP/CalculatorIndex";
import NPSCalculatorIndex from "./TaxCalculator/NPS/CalculatorIndex";
import HRACalculatorIndex from "./TaxCalculator/HRA/CalculatorIndex";
import RetirementIndex from "./TaxCalculator/Retirement/CalculatorIndex";
import CarLoanCalculatorIndex from "./TaxCalculator/CarLoan/CalculatorIndex";
import CompoundInterestCalculatorIndex from "./TaxCalculator/CompoundCalculator/CalculatorIndex";
import CAGRCalculatorIndex from "./TaxCalculator/CAGR/CalculatorIndex";
import GratuityCalculatorIndex from "./TaxCalculator/Gratuity/CalculatorIndex";
import GSTCalculatorIndex from "./TaxCalculator/GST/CalculatorIndex";
import FlatReducingRateCalculatorIndex from "./TaxCalculator/FlatRateCalculator/CalculatorIndex";
import BrokerageCalculatorIndex from "./TaxCalculator/Brokerage/CalculatorIndex";
import EquityMarginCalculatorIndex from "./TaxCalculator/EquityMargin/CalculatorIndex";
import TDSCalculatorIndex from "./TaxCalculator/TDS/CalculatorIndex";
import MFCalculatorIndex from "./TaxCalculator/MF/CalculatorIndex";
import LumpsumCalculatorIndex from "./TaxCalculator/Lumpsum/CalculatorIndex";
import SSYCalculatorIndex from "./TaxCalculator/SSY/CalculatorIndex";
import PPFCalculatorIndex from "./TaxCalculator/PPF/CalculatorIndex";
import RDCalculatorIndex from "./TaxCalculator/RD/CalculatorIndex";
import SWPCalculatorIndex from "./TaxCalculator/SWP/CalculatorIndex";
import FDCalculatorIndex from "./TaxCalculator/FD/CalculatorIndex";
import EMICalculatorIndex from "./TaxCalculator/EMI/CalculatorIndex";
import HomeLoanCalculatorIndex from "./TaxCalculator/HomeLoan/CalculatorIndex";
import SimpleInerestCalculatorIndex from "./TaxCalculator/SimpleInterest/CalculatorIndex";
import NSCcalculatorIndex from "./TaxCalculator/NSC/CalculatorIndex";
import StepUpSIPCalculatorIndex from "./TaxCalculator/StepUpSIP/CalculatorIndex";
import InflationCalculatorIndex from "./TaxCalculator/Inflation/CalculatorIndex";
import SalaryCalculatorIndex from "./TaxCalculator/Salary/CalculatorIndex";
import APYCalculatorIndex from "./TaxCalculator/APY/CalculatorIndex";
import { useDispatch, useSelector } from "react-redux";
import { settingsJsonStart } from "../../store/slices/ContactSlice";
import PageLoader from "../Helper/PageLoader";
import { Button } from "react-bootstrap";

const $ = window.$;
setTranslations({ en });

const AppRoute = ({
  component: Component,
  layout: Layout,
  screenProps: ScreenProps,
  ...rest
}) => (
  <Layout screenProps={ScreenProps} {...rest}>
    <Component />
  </Layout>
);

const PrivateRoute = ({
  component: Component,
  layout: Layout,
  screenProps: ScreenProps,
  ...rest
}) => {
  let userId = localStorage.getItem("userId");
  let accessToken = localStorage.getItem("accessToken");
  let userLoginStatus = localStorage.getItem("userLoginStatus");
  let authentication =
    userId && accessToken && userLoginStatus == "true" ? true : false;
  return authentication === true ? (
    <Layout screenProps={ScreenProps}>
      <Component authRoute={true} />
    </Layout>
  ) : (
    <Navigate to={{ pathname: "/" }} />
  );
};

const App = () => {
  const [loading, setLoading] = useState(true);
  const [configLoading, setConfigLoading] = useState(true);
  const [routes, setRoutes] = useState([]);
  const dispatch = useDispatch();
  const settingsJson = useSelector((state) => state.contact.settingsJson);

  useEffect(() => {
    let userLanguage = localStorage.getItem("lang")
      ? localStorage.getItem("lang")
      : "en";
    localStorage.setItem("lang", userLanguage);
    setLanguage(userLanguage);
  }, []);

  // Sitemap Code

  const AllRoutes = (
    <Routes>
      <Route
        path={"/"}
        element={<AppRoute component={LandingPageIndex} layout={MainLayout} />}
      />
      {/* <Route path={"/sitemap"} element={<Sitemap routes={routes} settingsJson={settingsJson}/>} /> */}
      <Route
        path={"/tax-calculator"}
        element={
          <AppRoute component={TaxCalculatorIndex} layout={MainLayout} />
        }
      />
      <Route
        path={"/income-tax-calculator"}
        element={
          <AppRoute component={IncomeTaxCalculator} layout={MainLayout} />
        }
      />
      <Route
        path={"/epf-calculator"}
        element={
          <AppRoute component={EPFCalculatorIndex} layout={MainLayout} />
        }
      />
      <Route
        path={"/sip-calculator"}
        element={
          <AppRoute component={SIPCalculatorIndex} layout={MainLayout} />
        }
      />
      <Route
        path={"/nps-calculator"}
        element={
          <AppRoute component={NPSCalculatorIndex} layout={MainLayout} />
        }
      />
      <Route
        path={"/hra-calculator"}
        element={
          <AppRoute component={HRACalculatorIndex} layout={MainLayout} />
        }
      />
      <Route
        path={"/retirement-calculator"}
        element={<AppRoute component={RetirementIndex} layout={MainLayout} />}
      />
      <Route
        path={"/car-loan-calculator"}
        element={
          <AppRoute component={CarLoanCalculatorIndex} layout={MainLayout} />
        }
      />
      <Route
        path={"/compound-interest-calculator"}
        element={
          <AppRoute
            component={CompoundInterestCalculatorIndex}
            layout={MainLayout}
          />
        }
      />
      <Route
        path={"/cagr-calculator"}
        element={
          <AppRoute component={CAGRCalculatorIndex} layout={MainLayout} />
        }
      />
      <Route
        path={"/gratuity-calculator"}
        element={
          <AppRoute component={GratuityCalculatorIndex} layout={MainLayout} />
        }
      />
      <Route
        path={"/gst-calculator"}
        element={
          <AppRoute component={GSTCalculatorIndex} layout={MainLayout} />
        }
      />
      <Route
        path={"/flat-reducing-rate-calculator"}
        element={
          <AppRoute
            component={FlatReducingRateCalculatorIndex}
            layout={MainLayout}
          />
        }
      />
      <Route
        path={"/brokerage-calculator"}
        element={
          <AppRoute component={BrokerageCalculatorIndex} layout={MainLayout} />
        }
      />
      <Route
        path={"/equity-margin-calculator"}
        element={
          <AppRoute
            component={EquityMarginCalculatorIndex}
            layout={MainLayout}
          />
        }
      />
      <Route
        path={"/tds-calculator"}
        element={
          <AppRoute component={TDSCalculatorIndex} layout={MainLayout} />
        }
      />
      <Route
        path={"/mf-calculator"}
        element={<AppRoute component={MFCalculatorIndex} layout={MainLayout} />}
      />
      <Route
        path={"/ssy-calculator"}
        element={
          <AppRoute component={SSYCalculatorIndex} layout={MainLayout} />
        }
      />
      <Route
        path={"/ppf-calculator"}
        element={
          <AppRoute component={PPFCalculatorIndex} layout={MainLayout} />
        }
      />
      <Route
        path={"/rd-calculator"}
        element={<AppRoute component={RDCalculatorIndex} layout={MainLayout} />}
      />
      <Route
        path={"/lumpsum-calculator"}
        element={
          <AppRoute component={LumpsumCalculatorIndex} layout={MainLayout} />
        }
      />
      <Route
        path={"/swp-calculator"}
        element={
          <AppRoute component={SWPCalculatorIndex} layout={MainLayout} />
        }
      />
      <Route
        path={"/fd-calculator"}
        element={<AppRoute component={FDCalculatorIndex} layout={MainLayout} />}
      />
      <Route
        path={"/emi-calculator"}
        element={
          <AppRoute component={EMICalculatorIndex} layout={MainLayout} />
        }
      />
      <Route
        path={"/home-loan-calculator"}
        element={
          <AppRoute component={HomeLoanCalculatorIndex} layout={MainLayout} />
        }
      />
      <Route
        path={"/simple-interest-calculator"}
        element={
          <AppRoute
            component={SimpleInerestCalculatorIndex}
            layout={MainLayout}
          />
        }
      />
      <Route
        path={"/nsc-calculator"}
        element={
          <AppRoute component={NSCcalculatorIndex} layout={MainLayout} />
        }
      />
      <Route
        path={"/stepup-sip-calculator"}
        element={
          <AppRoute component={StepUpSIPCalculatorIndex} layout={MainLayout} />
        }
      />
      <Route
        path={"/salary-calculator"}
        element={
          <AppRoute component={SalaryCalculatorIndex} layout={MainLayout} />
        }
      />
      <Route
        path={"/inflation-calculator"}
        element={
          <AppRoute component={InflationCalculatorIndex} layout={MainLayout} />
        }
      />
      <Route
        path={"/apy-calculator"}
        element={
          <AppRoute component={APYCalculatorIndex} layout={MainLayout} />
        }
      />
      <Route
        path={"/privacy-policy"}
        element={<AppRoute component={PrivacyPolicy} layout={MainLayout} />}
      />
      <Route
        path={"/terms"}
        element={<AppRoute component={Terms} layout={MainLayout} />}
      />
      <Route
        path={"/contact-us"}
        element={<AppRoute component={ContactUs} layout={MainLayout} />}
      />
      <Route
        path={"/about-us"}
        element={<AppRoute component={AboutUs} layout={MainLayout} />}
      />
      <Route
        path={"*"}
        element={<AppRoute component={Page404} layout={MainLayout} />}
      />
    </Routes>
  );

  const extractRoutes = (element) => {
    let routes = [];

    // If the element is a Route component, extract its path
    if (
      element.type === Route &&
      element.props.path &&
      element.props.path != "/sitemap" &&
      element.props.path != "*"
    ) {
      routes.push(element.props.path);
    }

    // If the element has children, traverse through them recursively
    if (element.props.children) {
      Children.forEach(element.props.children, (child) => {
        routes = routes.concat(extractRoutes(child));
      });
    }

    return routes;
  };

  useEffect(() => {
    dispatch(settingsJsonStart());
    const allRoutes = extractRoutes(AllRoutes);
    setRoutes(allRoutes);
  }, []);

  return settingsJson.loading ? (
    <PageLoader />
  ) : Object.keys(settingsJson.data).length > 0 ? (
    <>
      <ToastContainer />
      {/* <Helmet>
				<title>{configuration.get("configData.site_name")}</title>
				<link
					rel="icon"
					type="image/png"
					href={configuration.get("configData.site_icon")}
				/>
				<script src={configuration.get("configData.head_scripts")}></script>
			</Helmet> */}
      <BrowserRouter>
        <ScrollToTop />
        {AllRoutes}
      </BrowserRouter>
    </>
  ) : null;
};

const Sitemap = ({ routes, settingsJson }) => {
  const [sitemapXML, setSitemapXML] = useState("");

  // Function to generate the XML content for the sitemap
  const generateXML = () => {
    let xml = `<?xml version="1.0" encoding="UTF-8"?>
      <urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">`;

    routes.forEach((route) => {
      xml += `
        <url>
          <loc>${
            settingsJson.data.frontend_url || "https://tax-trax.com"
          }${route}</loc>
          <lastmod>${new Date().toISOString().split("T")[0]}</lastmod>
        </url>`;
    });

    xml += `
      </urlset>`;

    return xml;
  };

  const handleDownload = () => {
    const element = document.createElement("a");
    const file = new Blob([sitemapXML], { type: "text/xml" });
    element.href = URL.createObjectURL(file);
    element.download = "sitemap.xml";
    document.body.appendChild(element);
    element.click();
  };

  useEffect(() => {
    setSitemapXML(generateXML());
  }, [routes, settingsJson]);

  return (
    <div className="p-3" style={{ color: "#fff" }}>
      <h1>Site Map</h1>
      <ul>
        {routes.map((route, index) => (
          <p key={index}>
            {settingsJson.data.frontend_url || "https://tax-trax.com"}
            {route}
          </p>
        ))}
      </ul>
      <Button className="default-btn" onClick={handleDownload}>
        Download Sitemap
      </Button>
    </div>
  );
};

export default App;
