import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Container, Button, Form, Accordion } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import CustomLazyLoad from "../../../Helper/CustomLazyLoad";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-multi-lang";
import { useDispatch, useSelector } from "react-redux";
import { emiCalculatorSuccess } from "../../../../store/slices/CommonSlice";
import PieChart from "../PieChart";
import Select from "react-select";
import { ButtonLoader } from "../../../Helper/Loader";
import FAQIndex from "../FAQIndex";
import { Link } from "react-router-dom";
import formatNumberWithCommas from "../../../FormatNumber";

const CalculatorIndex = (props) => {
  const t = useTranslation("tax_calculator");
  const dispatch = useDispatch();
  const formRef = useRef();
  const principleRef = useRef(null);
  const interestRef = useRef(null);
  const timePeriodRef = useRef(null);
  const emiCalculator = useSelector((state) => state.common.emiCalculator);

  useEffect(() => {
    const onChange = (event, field) => {
      const customEvent = event;
      switch (field) {
        case "principal":
          formRef.current.setFieldValue("principal", customEvent.detail.value);
          break;
        case "interest":
          formRef.current.setFieldValue(
            "rate_of_interest",
            customEvent.detail.value
          );
          break;
        case "timePeriod":
          formRef.current.setFieldValue(
            "time_period",
            customEvent.detail.value
          );
          break;
      }
      setTimeout(() => { formRef.current.submitForm() }, 1)
    };
    principleRef.current?.addEventListener("change", (event) =>
      onChange(event, "principal")
    );
    interestRef.current?.addEventListener("change", (event) =>
      onChange(event, "interest")
    );
    timePeriodRef.current?.addEventListener("change", (event) =>
      onChange(event, "timePeriod")
    );
    return () => {
      principleRef.current?.removeEventListener("change", onChange);
      interestRef.current?.removeEventListener("change", onChange);
      timePeriodRef.current?.removeEventListener("change", onChange);
    };
  }, []);

  useEffect(() => {
    calculate(formRef.current.values);
  }, []);

  const onChangeInput = (event, field) => {
    const value = !isNaN(parseFloat(event.target.value))
      ? parseFloat(event.target.value)
      : "";
    switch (field) {
      case "principal":
        formRef.current.setFieldValue("principal", value);
        principleRef.current.value = value;
        break;
      case "interest":
        formRef.current.setFieldValue("rate_of_interest", value);
        interestRef.current.value = value;
        break;
      case "timePeriod":
        formRef.current.setFieldValue("time_period", value);
        timePeriodRef.current.value = value;
        break;
    }
    setTimeout(() => { formRef.current.submitForm() }, 1)
  };

  const carLoanEmiSchema = Yup.object().shape({
    principal: Yup.string()
      .test(
        "min",
        t("emi_calculator.invalid_min", { value: 100000 }),
        (value) => {
          return value >= 100000;
        }
      )
      .test(
        "max",
        t("emi_calculator.invalid_max", {
          value: 10000000,
          name: "Loan Amount",
        }),
        (value) => {
          return value <= 10000000;
        }
      )
      .required(t("emi_calculator.required")),
    rate_of_interest: Yup.string()
      .test("min", t("emi_calculator.invalid_min", { value: 1 }), (value) => {
        return value >= 1;
      })
      .test(
        "max",
        t("emi_calculator.invalid_max", {
          value: 30,
          name: "Rate of interest",
        }),
        (value) => {
          return value <= 30;
        }
      )
      .required(t("emi_calculator.required")),
    time_period: Yup.string()
      .test("min", t("emi_calculator.invalid_min", { value: 1 }), (value) => {
        return value >= 1;
      })
      .test(
        "max",
        t("emi_calculator.invalid_max", {
          value: 30,
          name: "Time period",
        }),
        (value) => {
          return value <= 30;
        }
      )
      .required(t("emi_calculator.required")),
  });

  const customStyles = {
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "8px",
      overflow: "hidden",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      minWidth: 200,
      fontSize: "0.85em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "8px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "auto" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "transparent!important",
      border: "1px solid #fff!important",
      borderRadius: "40px!important",
      boxShadow: "none!important",
      height: "45px",
      minWidth: "100px",
      cursor: "pointer",
      fontSize: "1em",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#000",
      fontSize: "1.1em",
      fontWeight: "600",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#fff",
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
      fontSize: "0.9em",
      fontWeight: "600",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      color: "#fff!important",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      fill: "#fff!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#fff",
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isSelected ? "#F4F6FD" : "#fff",
        color: "#000",
      };
    },
  };

  const options = [
    { value: "Personal Loan", label: "Personal Loan" },
    { value: "Education Loan", label: "Education Loan" },
    { value: "Gold Loan", label: "Gold Loan" },
    { value: "Car Loan", label: "Car Loan" },
    { value: "Home Laon", label: "Home Loan" },
    { value: "Others", label: "Others" },
  ];

  const handleSubmit = (values) => {
    calculate(values);
  };

  const calculate = (values) => {
    const { rate_of_interest, time_period, principal } = values
    let rateOfInterest = rate_of_interest / 100;
    let monthlyRateOfInterest = rateOfInterest / 12;
    let totalMonths = time_period * 12;
    let monthlyEMI = (principal * monthlyRateOfInterest * Math.pow(1 + monthlyRateOfInterest, totalMonths)) / (Math.pow(1 + monthlyRateOfInterest, totalMonths) - 1);
    let totalPayment = monthlyEMI * totalMonths;
    let totalInterest = totalPayment - principal;
    let principalAmount = principal;
    const newResult = {
      monthly_emi: Math.round(monthlyEMI),
      principal_amount: Math.round(principalAmount),
      total_interest: Math.round(totalInterest),
      total_payment: Math.round(totalPayment),
    };
    dispatch(emiCalculatorSuccess(newResult))
  }

  return (
    <>
      <div className="single-tax-calculator">
        <div className="sm-padding single-tax-calculator-sec">
          <Container>
            <div className="single-tax-calculator-box">
              <div className="single-tax-calculator-header">
                <Row>
                  <Col md={6}>
                    <div className="d-flex other-calculator-btn-sec">
                      <Link
                        to="/tax-calculator"
                        className="other-calculator-btn"
                      >
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            enableBackground="new 0 0 512 512"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="#fff"
                              d="M10.957 12.354a.5.5 0 010-.708l4.586-4.585a1.5 1.5 0 00-2.121-2.122L8.836 9.525a3.505 3.505 0 000 4.95l4.586 4.586a1.5 1.5 0 002.121-2.122z"
                              data-original="#000000"
                            ></path>
                          </svg>
                        </span>
                        <span>Other Calculators</span>
                      </Link>
                    </div>
                    <div className="single-tax-calculator-info-sec">
                      <h1>{t("emi_calculator.heading")}</h1>
                      <p>{t("emi_calculator.content")}</p>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="single-tax-calculator-header-img-sec">
                      <CustomLazyLoad
                        className="single-tax-calculator-header-img"
                        src={
                          window.location.origin +
                          "/img/single-tax-calculator-header-img.png"
                        }
                        type="image/svg"
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="single-tax-calculator-form-box  line-stright calculator-space-md">
              <Row className="align-items-center g-5">
                <Col md={6} lg={6}>
                  <Formik
                    initialValues={{
                      principal: 1000000,
                      rate_of_interest: 6.5,
                      time_period: 5,
                      loan_type: "Personal Loan",
                    }}
                    innerRef={formRef}
                    onSubmit={handleSubmit}
                    validationSchema={carLoanEmiSchema}
                  >
                    {({ setFieldValue, values, resetForm }) => (
                      <FORM className="single-tax-calculator-form">
                        <div className="single-tax-calculator-form-card">
                          <div className="single-tax-calculator-form-header-sec">
                            <h4>{t("emi_calculator.loan_amount.text")}</h4>
                            <Form.Group
                              className="mb-0"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Field
                                className="form-control"
                                name="principal"
                                type="number"
                                placeholder={t(
                                  "emi_calculator.loan_amount.placeholder"
                                )}
                                onKeyDown={(e) => {
                                  if (
                                    !/[0-9]|Backspace|ArrowLeft|ArrowRight|Delete|ArrowUp|ArrowDown|Tab/.test(
                                      e.key
                                    ) &&
                                    !(
                                      e.ctrlKey &&
                                      (e.key === "v" ||
                                        e.key === "c" ||
                                        e.key === "x" ||
                                        e.key === "a")
                                    )
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                                onInput={(e) => {
                                  if (e.target.value < 0) {
                                    e.target.value = 0;
                                  }
                                  onChangeInput(e, "principal");
                                }}
                              />
                            </Form.Group>
                          </div>
                          <ErrorMessage
                            component={"div"}
                            name="principal"
                            className="errorMsg"
                          />
                          <div className="tax-calculator-input-slider-sec">
                            <tc-range-slider
                              ref={principleRef}
                              value={principleRef}
                              mousewheel-disabled="true"
                              min={100000}
                              max={10000000}
                              slider-width="100%"
                              slider-bg-fill="#2F5CFF"
                              generate-labels="true"
                              round="0"
                            ></tc-range-slider>
                          </div>
                        </div>
                        <div className="single-tax-calculator-form-card">
                          <div className="single-tax-calculator-form-header-sec">
                            <h4>
                              {t("emi_calculator.rate_of_interest.text")} (p.a)
                            </h4>
                            <Form.Group
                              className="mb-0"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Field
                                className="form-control"
                                name="rate_of_interest"
                                type="number"
                                placeholder={t(
                                  "emi_calculator.rate_of_interest.placeholder"
                                )}
                                onKeyDown={(e) => {
                                  if (
                                    !/[0-9.]|Backspace|ArrowLeft|ArrowRight|Delete|ArrowUp|ArrowDown|Tab/.test(
                                      e.key
                                    ) ||
                                    (e.key === "-" &&
                                      e.target.selectionStart !== 0)
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                                onInput={(e) => {
                                  if (e.target.value < 0) {
                                    e.target.value = 0;
                                  }
                                  onChangeInput(e, "interest");
                                }}
                              />
                            </Form.Group>
                          </div>
                          <ErrorMessage
                            component={"div"}
                            name="rate_of_interest"
                            className="errorMsg"
                          />
                          <div className="tax-calculator-input-slider-sec">
                            <tc-range-slider
                              ref={interestRef}
                              value={interestRef}
                              mousewheel-disabled="true"
                              min={1}
                              max={30}
                              slider-width="100%"
                              slider-bg-fill="#2F5CFF"
                              generate-labels="true"
                              round="1"
                            ></tc-range-slider>
                          </div>
                        </div>
                        <div className="single-tax-calculator-form-card">
                          <div className="single-tax-calculator-form-header-sec">
                            <h4>{t("emi_calculator.loan_tenure.text")}</h4>
                            <Form.Group
                              className="mb-0"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Field
                                className="form-control"
                                name="time_period"
                                type="number"
                                placeholder={t(
                                  "emi_calculator.loan_tenure.placeholder"
                                )}
                                onKeyDown={(e) => {
                                  if (
                                    !/[0-9]|Backspace|ArrowLeft|ArrowRight|Delete|ArrowUp|ArrowDown|Tab/.test(
                                      e.key
                                    ) &&
                                    !(
                                      e.ctrlKey &&
                                      (e.key === "v" ||
                                        e.key === "c" ||
                                        e.key === "x" ||
                                        e.key === "a")
                                    )
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                                onInput={(e) => {
                                  if (e.target.value < 0) {
                                    e.target.value = 0;
                                  }
                                  onChangeInput(e, "timePeriod");
                                }}
                              />
                            </Form.Group>
                          </div>
                          <ErrorMessage
                            component={"div"}
                            name="time_period"
                            className="errorMsg"
                          />
                          <div className="tax-calculator-input-slider-sec">
                            <tc-range-slider
                              ref={timePeriodRef}
                              value={timePeriodRef}
                              min={1}
                              max={30}
                              slider-width="100%"
                              slider-bg-fill="#2F5CFF"
                              generate-labels="true"
                              round="0"
                            ></tc-range-slider>
                          </div>
                        </div>
                        <div className="single-tax-calculator-form-header-sec">
                          <h4>{t("emi_calculator.loan_type")}</h4>
                          <Select
                            className="fillert-drop"
                            options={options}
                            styles={customStyles}
                            isSearchable={false}
                            defaultValue={options.find(
                              (option) => option.value === "Personal Loan"
                            )}
                            onChange={(data) => {
                              formRef.current.setFieldValue(
                                "loan_type",
                                data.value
                              );
                            }}
                            placeholder={
                              <div className="placeholder-flex">
                                {t("chat_widget.select_country")}
                              </div>
                            }
                          />
                        </div>
                        {/* <div className="d-flex justify-content-end mt-4">
                          <Button
                            type="submit"
                            className="default-primary-btn"
                            disabled={emiCalculator.buttonDisable}
                          >
                            {emiCalculator.buttonDisable ? (
                              <ButtonLoader />
                            ) : (
                              t("emi_calculator.calculate")
                            )}
                          </Button>
                        </div> */}
                      </FORM>
                    )}
                  </Formik>
                </Col>
                <Col md={6} lg={6}>
                  {emiCalculator.loading ? (
                    <div className="single-calculator-info-frame">
                      <div className="pie-chart-skelton">
                        <div className="skeleton-wrapper">
                          <Skeleton
                            borderRadius={130}
                            width={230}
                            height={230}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                        </div>
                        <div className="mt-4">
                          <Skeleton
                            width={250}
                            height={15}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                        </div>
                      </div>

                      <div className="calculator-info-details-card">
                        <div className="calculator-details-frame">
                          <Skeleton
                            width={200}
                            height={20}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                          <Skeleton
                            width={130}
                            height={20}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                        </div>
                        <div className="calculator-details-frame">
                          <Skeleton
                            width={200}
                            height={20}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                          <Skeleton
                            width={130}
                            height={20}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                        </div>
                        <hr />
                        <div className="calculator-details-frame top-bottom-line">
                          <Skeleton
                            width={200}
                            height={20}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                          <Skeleton
                            width={130}
                            height={20}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                        </div>
                        <hr />
                      </div>
                    </div>
                  ) : (
                    Object.keys(emiCalculator.data).length > 0 && (
                      <div className="single-calculator-info-frame">
                        <div className="pie-chart-sec">
                          <PieChart
                            name={[
                              t("emi_calculator.principle_amount"),
                              t("emi_calculator.total_interest"),
                            ]}
                            data={[
                              emiCalculator.data.principal_amount,
                              emiCalculator.data.total_interest,
                            ]}
                          />
                        </div>
                        <div className="calculator-info-details-card">
                          <div className="calculator-details-frame">
                            <p>
                              {" "}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                x="0"
                                y="0"
                                enableBackground="new 0 0 29.107 29.107"
                                version="1.1"
                                viewBox="0 0 29.107 29.107"
                                xmlSpace="preserve"
                                width="20"
                                height="20"
                                fill="#2A334F"
                              >
                                <path d="M14.554 0C6.561 0 0 6.562 0 14.552c0 7.996 6.561 14.555 14.554 14.555 7.996 0 14.553-6.559 14.553-14.555C29.106 6.562 22.55 0 14.554 0z"></path>
                              </svg>
                              <span>
                                {t("emi_calculator.principle_amount")}
                              </span>
                            </p>
                            <h3>
                              {formatNumberWithCommas(
                                emiCalculator.data.principal_amount
                              )}
                            </h3>
                          </div>
                          <div className="calculator-details-frame">
                            <p>
                              {" "}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                x="0"
                                y="0"
                                enableBackground="new 0 0 29.107 29.107"
                                version="1.1"
                                viewBox="0 0 29.107 29.107"
                                xmlSpace="preserve"
                                width="20"
                                height="20"
                                fill="#2F5CFF"
                              >
                                <path d="M14.554 0C6.561 0 0 6.562 0 14.552c0 7.996 6.561 14.555 14.554 14.555 7.996 0 14.553-6.559 14.553-14.555C29.106 6.562 22.55 0 14.554 0z"></path>
                              </svg>
                              <span>{t("emi_calculator.total_interest")}</span>
                            </p>
                            <h3>
                              {formatNumberWithCommas(
                                emiCalculator.data.total_interest
                              )}
                            </h3>
                          </div>
                          <hr />
                          <div className="calculator-details-frame top-bottom-line">
                            <p className="d-inline">
                              {t("emi_calculator.total_amount")}
                            </p>
                            <h3>
                              {formatNumberWithCommas(
                                emiCalculator.data.total_payment
                              )}
                            </h3>
                          </div>
                          <hr />
                          <div className="calculator-details-frame top-bottom-line">
                            <p className="d-inline">
                              {t("emi_calculator.monthly_emi")}
                            </p>
                            <h3>
                              {formatNumberWithCommas(
                                emiCalculator.data.monthly_emi
                              )}
                            </h3>
                          </div>
                        </div>
                      </div>
                    )
                  )}
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <Container>
          <Row>
            <Col lg={12}>
              <div className="static-page-card">
                <div className="pricacy-policy-info">
                  <h4>Description</h4>
                  <p>
                    India's credit sector is experiencing robust growth, now
                    ranking as the world's fourth largest, with an annual
                    compound growth rate surpassing 11%. The lion's share of
                    this lending is attributed to short-term financial solutions
                    like personal loans and credit cards, which together
                    represent 78% of the total credit market in India. It's
                    important for borrowers to be mindful of their Equated
                    Monthly Installments (EMIs) to effectively manage both
                    present and future financial commitments.
                  </p>
                  <p>
                    For accurate EMI calculations, it's crucial to select a
                    reliable online EMI calculator and understand its
                    functionalities to determine the exact amount payable for a
                    loan.
                  </p>
                </div>
                <div className="pricacy-policy-info">
                  <h4>Key Factors Influencing Your EMI</h4>
                  <p>
                    When preparing to apply for a loan, several key factors
                    should be taken into account to calculate your EMI:
                  </p>
                  <ul>
                    <li>
                      Loan Tenure: The duration of your loan can either shorten
                      or lengthen, impacting your EMI amount correspondingly.
                      Hence, the loan term is a crucial consideration that
                      influences your payable amount.
                    </li>
                    <li>
                      nterest Rate: This is a critical element in determining
                      your installment amount. By comparing different options
                      and choosing one with a lower interest rate, you can keep
                      your total repayment amount manageable.
                    </li>
                    <li>
                      Loan Amount: Deciding on the amount of the loan is also
                      vital, as your EMI will be calculated based on this
                      figure.
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <FAQIndex
          data={[
            {
              question:
                "For which types of loans is an EMI calculator applicable?",
              answer:
                "An EMI calculator can be used for a wide range of loans, including personal loans, home loans, car loans, and education loans. It's a versatile tool designed to help you understand your monthly repayment obligations across various loan types.",
            },
            {
              question:
                "How does my debt-to-income ratio influence my loan eligibility?",
              answer:
                "Your debt-to-income ratio, which compares your total monthly debts to your monthly income, plays a significant role in your loan application. Lenders use it to assess your ability to manage and repay new debt. A lower ratio generally improves your chances of loan approval, indicating that you have a manageable level of debt.",
            },
            {
              question: "What components make up an EMI?",
              answer:
                "An Equated Monthly Installment (EMI) comprises two main components: the principal amount and the interest on the loan. Initially, the interest component constitutes a larger portion of the EMI, but as you progressively pay down the loan, the principal component increases.",
            },
            {
              question: "What are the consequences of not paying EMIs on time?",
              answer:
                "Failing to pay your EMIs can lead to several negative consequences, including penalties and additional interest charges. Consistent non-payment can also affect your credit score adversely, making it difficult to secure loans in the future. In extreme cases, lenders may initiate legal action to recover the outstanding loan amount.",
            },
          ]}
        />
      </div>
    </>
  );
};

export default CalculatorIndex;
