import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import CustomLazyLoad from "../Helper/CustomLazyLoad";
import { Link } from "react-router-dom";

const HowItsWorkIndex = (props) => {
  return (
    <>
      <div className="how-its-work-sec sm-padding">
        <Container>
          <Row className="justify-content-center">
            <Col lg={8} md={8} className="text-center">
              <div className="section-title">
                <h2>Effortless Tax Handling in Three Simple Steps</h2>
                <p>
                  Navigate your taxes with TaxTrax: simply ask your questions,
                  receive AI-powered answers, and make informed decisions with
                  confidence.
                </p>
              </div>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col md={5} className="order-change">
              <div className="how-its-work-img-sec">
                <CustomLazyLoad
                  className="how-its-work-img"
                  src={
                    window.location.origin +
                    "/img/how-its-work/ask-your-questions.png"
                  }
                  type="image/svg"
                />
              </div>
            </Col>
            <Col md={7} className="resp-margin-btm-xs">
              <div className="how-its-work-card xl-padding-left">
                <div className="how-its-work-icon-sec">
                  <CustomLazyLoad
                    className="how-its-work-icon"
                    src={
                      window.location.origin +
                      "/img/how-its-work/ask-your-questions-icon.png"
                    }
                    type="image/svg"
                  />
                </div>
                <div className="how-its-work-info-sec">
                  <h3>1. Ask Your Questions</h3>
                  <p>
                    Start by typing in your tax-related queries. Whether it's
                    about deductions, income brackets, or filing deadlines,
                    TaxTrax is ready to listen and understand your specific
                    needs. No question is too big or too small.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="how-its-work-sec sm-padding">
        <Container>
          <Row className="align-items-center">
            <Col md={7} className="resp-margin-btm-xs">
              <div className="how-its-work-card xl-padding-right">
                <div className="how-its-work-icon-sec">
                  <CustomLazyLoad
                    className="how-its-work-icon"
                    src={
                      window.location.origin +
                      "/img/how-its-work/get-ai-powered-answers-icon.png"
                    }
                    type="image/svg"
                  />
                </div>
                <div className="how-its-work-info-sec">
                  <h3>2. Get AI-Powered Answers</h3>
                  <p>
                    Within moments, our advanced AI sifts through the latest tax
                    codes to provide you with accurate, up-to-date answers. It's
                    like having a tax expert in your pocket, always on standby
                    to help you navigate the complexities of tax law.
                  </p>
                </div>
              </div>
            </Col>
            <Col md={5}>
              <div className="how-its-work-img-sec">
                <CustomLazyLoad
                  className="how-its-work-img"
                  src={
                    window.location.origin +
                    "/img/how-its-work/get-ai-powered-answers.png"
                  }
                  type="image/svg"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="how-its-work-sec sm-padding">
        <Container>
          <Row className="align-items-center">
            <Col md={5} className="order-change">
              <div className="how-its-work-img-sec">
                <CustomLazyLoad
                  className="how-its-work-img"
                  src={
                    window.location.origin +
                    "/img/how-its-work/make-informed-decision.png"
                  }
                  type="image/svg"
                />
              </div>
            </Col>
            <Col md={7} className="resp-margin-btm-xs">
              <div className="how-its-work-card xl-padding-left">
                <div className="how-its-work-icon-sec">
                  <CustomLazyLoad
                    className="how-its-work-icon"
                    src={
                      window.location.origin +
                      "/img/how-its-work/make-informed-decision-icon.png"
                    }
                    type="image/svg"
                  />
                </div>
                <div className="how-its-work-info-sec">
                  <h3>3. Make Informed Decisions</h3>
                  <p>
                    Equipped with knowledge, you're now ready to make smart tax
                    decisions with confidence. Whether it's optimizing your
                    return or planning for the future, TaxTrax ensures you're
                    making the most of your finances.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default HowItsWorkIndex;
