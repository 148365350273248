import { all, fork } from "redux-saga/effects";
import CommonSaga from "./CommonSaga";
import ContactSaga from "./ContactSaga";

export default function* rootSaga() {
  yield all([
    fork(CommonSaga),
    fork(ContactSaga),
  ]);

}
