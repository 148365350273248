import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import CustomLazyLoad from "../../Helper/CustomLazyLoad";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-multi-lang";

const FooterIndex = (props) => {
  const t = useTranslation("footer");
  const settingsJson = useSelector((state) => state.contact.settingsJson);

  return (
    <>
      <footer className="footer-sec">
        <Container>
          <Row className="justify-content-center">
            <Col md={6} xl={3} lg={4} className="resp-margin-btm-md">
              <div className="footer-about-sec">
                <div className="footer-logo-sec">
                  <CustomLazyLoad
                    className="footer-logo"
                    src={window.location.origin + "/img/tax-trax-logo.png"}
                    type="image/svg"
                  />
                </div>
                <p>{t("content")}</p>
              </div>
            </Col>
            <Col md={6} xl={3} lg={4} className="resp-margin-btm-md">
              <div className="footer-widget">
                <h3>{t("get_in_touch")}</h3>
                <ul className="list-unstyled footer-bio-list">
                  <li>
                    <Link to="mailto:marketing@tax-trax.com">
                      <CustomLazyLoad
                        className="footer-bio-icon"
                        src={
                          window.location.origin + "/img/footer/email-icon.png"
                        }
                        type="image/svg"
                      />
                      <span>{settingsJson.data.contact_email}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="tel:+919380348698">
                      <CustomLazyLoad
                        className="footer-bio-icon"
                        src={
                          window.location.origin + "/img/footer/phone-icon.png"
                        }
                        type="image/svg"
                      />
                      <span>+91{settingsJson.data.contact_mobile}</span>
                    </Link>
                  </li>
                  <li>
                    <CustomLazyLoad
                      className="footer-bio-icon"
                      src={window.location.origin + "/img/footer/map-icon.png"}
                      type="image/svg"
                    />
                    <span>{settingsJson.data.contact_address}</span>
                  </li>
                </ul>
              </div>
            </Col>
            <Col md={4} xl={2} lg={2} className="resp-margin-btm-xs">
              <div className="footer-widget">
                <h3>{t("footer.quick_links")}</h3>
                <ul className="list-unstyled footer-link-list">
                  <li>
                    <Link to="/">{t("home")}</Link>
                  </li>
                  <li>
                    <Link to="/about-us" target="blank">
                      {t("about_us")}
                    </Link>
                  </li>
                  {/* <li>
                    <Link to="#">Career</Link>
                  </li> */}
                  <li>
                    <Link to="#">{t("blogs")}</Link>
                  </li>
                </ul>
              </div>
            </Col>
            <Col md={4} xl={2} lg={2} className="resp-margin-btm-xs">
              <div className="footer-widget">
                <h3>{t("company")}</h3>
                <ul className="list-unstyled footer-link-list">
                  <li>
                    <Link to="/contact-us" target="blank">
                      {t("contact_us")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy" target="blank">
                      {t("privacy_policy")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/terms" target="blank">
                      {t("terms_condition")}
                    </Link>
                  </li>
                </ul>
              </div>
            </Col>
            <Col md={4} xl={2} lg={2}>
              <div className="footer-widget">
                <h3> {t("connect_us")}</h3>
                <ul className="list-unstyled footer-social-link-list">
                  <li>
                    <Link
                      to={`${settingsJson.data.facebook_link}`}
                      target="_blank"
                    >
                      <svg
                        className="footer-icon-primary"
                        xmlns="http://www.w3.org/2000/svg"
                        width="512"
                        height="512"
                        enableBackground="new 0 0 512 512"
                        viewBox="0 0 155.139 155.139"
                      >
                        <path
                          fill="#2f5cff"
                          d="M89.584 155.139V84.378h23.742l3.562-27.585H89.584V39.184c0-7.984 2.208-13.425 13.67-13.425l14.595-.006V1.08C115.325.752 106.661 0 96.577 0 75.52 0 61.104 12.853 61.104 36.452v20.341H37.29v27.585h23.814v70.761h28.48z"
                          data-original="#010002"
                        ></path>
                      </svg>
                      <svg
                        className="footer-icon-light"
                        xmlns="http://www.w3.org/2000/svg"
                        x="0"
                        y="0"
                        enablebackground="new 0 0 155.139 155.139"
                        version="1.1"
                        viewBox="0 0 155.139 155.139"
                        xmlspace="preserve"
                        width="30"
                        height="30"
                        fill="#fff"
                      >
                        <path d="M89.584 155.139V84.378h23.742l3.562-27.585H89.584V39.184c0-7.984 2.208-13.425 13.67-13.425l14.595-.006V1.08C115.325.752 106.661 0 96.577 0 75.52 0 61.104 12.853 61.104 36.452v20.341H37.29v27.585h23.814v70.761h28.48z"></path>
                      </svg>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`${settingsJson.data.instagram_link}`}
                      target="_blank"
                    >
                      <svg
                        className="footer-icon-primary"
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="30"
                        enableBackground="new 0 0 512 512"
                        viewBox="0 0 24 24"
                      >
                        <g fill="#2f5cff">
                          <path
                            d="M12 2.162c3.204 0 3.584.012 4.849.07 1.308.06 2.655.358 3.608 1.311.962.962 1.251 2.296 1.311 3.608.058 1.265.07 1.645.07 4.849s-.012 3.584-.07 4.849c-.059 1.301-.364 2.661-1.311 3.608-.962.962-2.295 1.251-3.608 1.311-1.265.058-1.645.07-4.849.07s-3.584-.012-4.849-.07c-1.291-.059-2.669-.371-3.608-1.311-.957-.957-1.251-2.304-1.311-3.608-.058-1.265-.07-1.645-.07-4.849s.012-3.584.07-4.849c.059-1.296.367-2.664 1.311-3.608.96-.96 2.299-1.251 3.608-1.311 1.265-.058 1.645-.07 4.849-.07M12 0C8.741 0 8.332.014 7.052.072 5.197.157 3.355.673 2.014 2.014.668 3.36.157 5.198.072 7.052.014 8.332 0 8.741 0 12c0 3.259.014 3.668.072 4.948.085 1.853.603 3.7 1.942 5.038 1.345 1.345 3.186 1.857 5.038 1.942C8.332 23.986 8.741 24 12 24c3.259 0 3.668-.014 4.948-.072 1.854-.085 3.698-.602 5.038-1.942 1.347-1.347 1.857-3.184 1.942-5.038.058-1.28.072-1.689.072-4.948 0-3.259-.014-3.668-.072-4.948-.085-1.855-.602-3.698-1.942-5.038C20.643.671 18.797.156 16.948.072 15.668.014 15.259 0 12 0z"
                            data-original="#000000"
                          ></path>
                          <path
                            d="M12 5.838a6.162 6.162 0 100 12.324 6.162 6.162 0 000-12.324zM12 16a4 4 0 110-8 4 4 0 010 8z"
                            data-original="#000000"
                          ></path>
                          <circle
                            cx="18.406"
                            cy="5.594"
                            r="1.44"
                            data-original="#000000"
                          ></circle>
                        </g>
                      </svg>
                      <svg
                        className="footer-icon-light"
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="30"
                        fill="#fff"
                        viewBox="0 0 511 511.9"
                      >
                        <path d="M510.95 150.5c-1.2-27.2-5.598-45.898-11.9-62.102-6.5-17.199-16.5-32.597-29.6-45.398-12.802-13-28.302-23.102-45.302-29.5-16.296-6.3-34.898-10.7-62.097-11.898C334.648.3 325.949 0 256.449 0s-78.199.3-105.5 1.5c-27.199 1.2-45.898 5.602-62.097 11.898-17.204 6.5-32.602 16.5-45.403 29.602-13 12.8-23.097 28.3-29.5 45.3-6.3 16.302-10.699 34.9-11.898 62.098C.75 177.801.449 186.5.449 256s.301 78.2 1.5 105.5c1.2 27.2 5.602 45.898 11.903 62.102 6.5 17.199 16.597 32.597 29.597 45.398 12.801 13 28.301 23.102 45.301 29.5 16.3 6.3 34.898 10.7 62.102 11.898 27.296 1.204 36 1.5 105.5 1.5s78.199-.296 105.5-1.5c27.199-1.199 45.898-5.597 62.097-11.898a130.934 130.934 0 0074.903-74.898c6.296-16.301 10.699-34.903 11.898-62.102 1.2-27.3 1.5-36 1.5-105.5s-.102-78.2-1.3-105.5zm-46.098 209c-1.102 25-5.301 38.5-8.801 47.5-8.602 22.3-26.301 40-48.602 48.602-9 3.5-22.597 7.699-47.5 8.796-27 1.204-35.097 1.5-103.398 1.5s-76.5-.296-103.403-1.5c-25-1.097-38.5-5.296-47.5-8.796C94.551 451.5 84.45 445 76.25 436.5c-8.5-8.3-15-18.3-19.102-29.398-3.5-9-7.699-22.602-8.796-47.5-1.204-27-1.5-35.102-1.5-103.403s.296-76.5 1.5-103.398c1.097-25 5.296-38.5 8.796-47.5C61.25 94.199 67.75 84.1 76.352 75.898c8.296-8.5 18.296-15 29.398-19.097 9-3.5 22.602-7.7 47.5-8.801 27-1.2 35.102-1.5 103.398-1.5 68.403 0 76.5.3 103.403 1.5 25 1.102 38.5 5.3 47.5 8.8 11.097 4.098 21.199 10.598 29.398 19.098 8.5 8.301 15 18.301 19.102 29.403 3.5 9 7.699 22.597 8.8 47.5 1.2 27 1.5 35.097 1.5 103.398s-.3 76.301-1.5 103.301zm0 0"></path>
                        <path d="M256.45 124.5c-72.598 0-131.5 58.898-131.5 131.5s58.902 131.5 131.5 131.5c72.6 0 131.5-58.898 131.5-131.5s-58.9-131.5-131.5-131.5zm0 216.8c-47.098 0-85.302-38.198-85.302-85.3s38.204-85.3 85.301-85.3c47.102 0 85.301 38.198 85.301 85.3s-38.2 85.3-85.3 85.3zm0 0M423.852 119.3c0 16.954-13.747 30.7-30.704 30.7-16.953 0-30.699-13.746-30.699-30.7 0-16.956 13.746-30.698 30.7-30.698 16.956 0 30.703 13.742 30.703 30.699zm0 0"></path>
                      </svg>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`${settingsJson.data.twitter_link}`}
                      target="_blank"
                    >
                      <svg
                        className="footer-icon-primary"
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="30"
                        enableBackground="new 0 0 512 512"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#2f5cff"
                          d="M18.9 1.153h3.682l-8.042 9.189L24 22.848h-7.405l-5.804-7.583-6.634 7.583H.469l8.6-9.831L0 1.153h7.593l5.241 6.931 6.065-6.931zm-1.293 19.494h2.039L6.482 3.239h-2.19l13.314 17.408z"
                          data-original="#000000"
                        ></path>
                      </svg>
                      <svg
                        className="footer-icon-light"
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="30"
                        fill="#fff"
                        viewBox="0 0 1226.37 1226.37"
                      >
                        <path d="M727.348 519.284L1174.075 0h-105.86L680.322 450.887 370.513 0H13.185l468.492 681.821L13.185 1226.37h105.866l409.625-476.152 327.181 476.152h357.328L727.322 519.284zM582.35 687.828l-47.468-67.894-377.686-540.24H319.8l304.797 435.991 47.468 67.894 396.2 566.721H905.661L582.35 687.854z"></path>
                      </svg>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`${settingsJson.data.linkedin_link}`}
                      target="_blank"
                    >
                      <svg
                        className="footer-icon-primary"
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="30"
                        enableBackground="new 0 0 512 512"
                        viewBox="0 0 512 512"
                      >
                        <path
                          fill="#2f5cff"
                          d="M69.12 4.267c-18.773 0-35.84 6.827-48.64 19.627C6.827 37.547 0 54.613 0 72.533c0 18.773 7.68 35.84 20.48 48.64 12.8 12.8 30.72 20.48 47.787 19.627h1.707c17.067 0 33.28-6.827 46.08-19.627 12.8-12.8 20.48-29.867 20.48-48.64.853-17.92-6.827-34.987-19.627-47.787C104.107 11.093 87.04 4.267 69.12 4.267zm34.987 104.96c-9.387 9.387-22.187 15.36-35.84 14.507-12.8 0-26.453-5.12-35.84-14.507-10.24-9.387-15.36-23.04-15.36-36.693s5.12-26.453 15.36-36.693c9.387-9.387 22.187-14.507 36.693-14.507 12.8 0 25.6 5.12 34.987 14.507 10.24 10.24 15.36 23.04 15.36 36.693s-5.12 27.306-15.36 36.693zm-1.707 48.64H33.28c-13.653 0-24.747 11.093-24.747 25.6v298.667c0 13.653 11.947 25.6 25.6 25.6H102.4c13.653 0 25.6-11.947 25.6-24.747v-299.52c0-13.654-11.947-25.6-25.6-25.6zm8.533 325.12c0 4.267-4.267 7.68-8.533 7.68H34.133c-4.267 0-8.533-4.267-8.533-8.533V183.467c0-4.267 3.413-8.533 7.68-8.533h69.12c4.267 0 8.533 4.267 8.533 8.533v299.52zm281.6-333.654h-17.92c-33.28 0-64.853 14.507-85.333 37.547v-11.947c0-8.533-8.533-17.067-17.067-17.067H186.88c-7.68 0-17.067 6.827-17.067 16.213v318.293c0 9.387 9.387 15.36 17.067 15.36h93.867c7.68 0 17.067-5.973 17.067-15.36v-184.32c0-28.16 20.48-50.347 46.933-50.347 13.653 0 26.453 5.12 35.84 14.507 8.533 7.68 11.947 19.627 11.947 34.987v183.467c0 8.533 8.533 17.067 17.067 17.067h85.333c8.533 0 17.067-8.533 17.067-17.067v-220.16C512 202.24 459.947 149.333 392.533 149.333zm102.4 340.48l-.853.853h-83.627L409.6 307.2c0-20.48-5.12-35.84-16.213-46.933-12.8-12.8-29.867-19.627-47.787-19.627-35.84.853-64 29.867-64 67.413v182.613h-93.867V174.933h84.48l.853.853v53.76l23.04-23.04.853-.853c17.067-23.893 46.933-39.253 78.507-39.253h17.92c57.173 0 101.547 46.08 101.547 104.107v219.306z"
                          data-original="#000000"
                        ></path>
                      </svg>
                      <svg
                        className="footer-icon-light"
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="30"
                        fill="#fff"
                        viewBox="0 0 512 512"
                      >
                        <path d="M7.813 509.935h132.571V161.322H7.813zm30-318.613h72.571v288.612H37.813zM74.099 2.065C33.24 2.065 0 35.306 0 76.164s33.24 74.1 74.099 74.1 74.1-33.241 74.1-74.1-33.242-74.099-74.1-74.099zM30 76.164c0-24.316 19.782-44.099 44.099-44.099s44.1 19.782 44.1 44.099-19.783 44.1-44.1 44.1S30 100.48 30 76.164zM511.679 270.79c-4.718-67.855-61.318-120.144-131.489-120.144-31.387 0-61.016 10.912-84.361 30.274v-19.6h-127.03v348.613h132.572V319.269c0-21.488 17.481-38.97 38.97-38.97 21.487 0 38.969 17.482 38.969 38.979l.128 190.656H512V271.112zM271.371 479.935h-72.572V191.321h67.029v42.847l24.005.138 4.46-6.924c18.85-29.265 50.961-46.735 85.897-46.735 55.836 0 100.543 42.602 101.78 96.985l.03 202.303h-72.582l-.108-160.666c0-38.03-30.94-68.97-68.969-68.97-38.03 0-68.97 30.94-68.97 68.97z"></path>
                      </svg>
                    </Link>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
      <div className="copyright-sec">
        <Container>
          <div className="row justify-content-center">
            <div className="col-md-6">
              <div className="copyright-card">
                <p>© {settingsJson.data.copyright_content}</p>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default FooterIndex;
