import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Container, Button, Form, Accordion } from "react-bootstrap";
import PieChart from "../PieChart";
import CustomLazyLoad from "../../../Helper/CustomLazyLoad";
import Skeleton from "react-loading-skeleton";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-multi-lang";
import { useDispatch, useSelector } from "react-redux";
import { fdCalculatorStart, fdCalculatorSuccess } from "../../../../store/slices/CommonSlice";
import { ButtonLoader } from "../../../Helper/Loader";
import FAQIndex from "../FAQIndex";
import { Link } from "react-router-dom";
import formatNumberWithCommas from "../../../FormatNumber";

const CalculatorIndex = (props) => {
  const t = useTranslation("tax_calculator");
  const principleRef = useRef(null);
  const interestRef = useRef(null);
  const dispatch = useDispatch();
  const formRef = useRef();
  const fdCalculator = useSelector((state) => state.common.fdCalculator);
  const [period, setPeriod] = useState("yearly");

  useEffect(() => {
    const onChange = (event, field) => {
      const customEvent = event;
      switch (field) {
        case "principal":
          formRef.current.setFieldValue("principal", customEvent.detail.value);
          break;
        case "interest":
          formRef.current.setFieldValue(
            "rate_of_interest",
            customEvent.detail.value
          );
          break;
      }
      setTimeout(() => { formRef.current.submitForm() }, 1)
    };
    principleRef.current?.addEventListener("change", (event) =>
      onChange(event, "principal")
    );
    interestRef.current?.addEventListener("change", (event) =>
      onChange(event, "interest")
    );
    return () => {
      principleRef.current?.removeEventListener("change", onChange);
      interestRef.current?.removeEventListener("change", onChange);
    };
  }, []);

  useEffect(() => {
    calculate(formRef.current.values);
  }, []);

  const onChangeInput = (event, field) => {
    const value = !isNaN(parseFloat(event.target.value))
      ? parseFloat(event.target.value)
      : "";
    switch (field) {
      case "principal":
        formRef.current.setFieldValue("principal", value);
        principleRef.current.value = value;
        break;
      case "interest":
        formRef.current.setFieldValue("rate_of_interest", value);
        interestRef.current.value = value;
        break;
      case "timePeriod":
        formRef.current.setFieldValue("time_period", value);
        break;
    }
    setTimeout(() => { formRef.current.submitForm() }, 1)
  };

  const handleSubmit = (values) => {
    if (formRef.current.isValid) {
      if (period == "monthly") {
        const data = parseFloat(
          (formRef.current.values.time_period / 12).toFixed(1)
        );
        calculate({ ...values, time_period: data });
      } else calculate(values);
    }
  };

  const fdSchema = Yup.object().shape({
    principal: Yup.string()
      .test(
        "min",
        t("sip_calculator.invalid_min", { value: 10000 }),
        (value) => {
          return value >= 10000;
        }
      )
      .test(
        "max",
        t("sip_calculator.invalid_max", {
          value: 10000000,
          name: "investment",
        }),
        (value) => {
          return value <= 10000000;
        }
      )
      .required(t("sip_calculator.required")),
    rate_of_interest: Yup.string()
      .test("min", t("sip_calculator.invalid_min", { value: 1 }), (value) => {
        return value >= 1;
      })
      .test(
        "max",
        t("sip_calculator.invalid_max", {
          value: 15,
          name: "Rate of interest",
        }),
        (value) => {
          return value <= 15;
        }
      )
      .required(t("sip_calculator.required")),
    time_period: Yup.string()
      .test("min", t("sip_calculator.invalid_min", { value: 1 }), (value) => {
        return value >= 1;
      })
      .test(
        "max",
        t("sip_calculator.invalid_max", {
          value: period == "yearly" ? 25 : 11,
          name: "Time period",
        }),
        (value) => {
          return period == "yearly" ? value <= 25 : value <= 11;
        }
      )
      .required(t("sip_calculator.required")),
  });

  const calculate = (values) => {
    const { principal, rate_of_interest, time_period, compounding_frequency } =
      values;

    let rateOfInterest = rate_of_interest / 100;
    let maturityAmount =
      principal *
      Math.pow(
        1 + rateOfInterest / compounding_frequency,
        time_period * compounding_frequency
      );
    let investedAmount = principal;
    let estimatedReturn = maturityAmount - investedAmount;
    const newResult = {
      maturity_amount: Math.round(maturityAmount),
      invested_amount: Math.round(investedAmount),
      estimated_return: Math.round(estimatedReturn),
    };
    dispatch(fdCalculatorSuccess(newResult))
  };

  return (
    <>
      <div className="single-tax-calculator">
        <div className="sm-padding single-tax-calculator-sec">
          <Container>
            <div className="single-tax-calculator-box">
              <div className="single-tax-calculator-header">
                <Row>
                  <Col md={6}>
                    <div className="d-flex other-calculator-btn-sec">
                      <Link
                        to="/tax-calculator"
                        className="other-calculator-btn"
                      >
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            enableBackground="new 0 0 512 512"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="#fff"
                              d="M10.957 12.354a.5.5 0 010-.708l4.586-4.585a1.5 1.5 0 00-2.121-2.122L8.836 9.525a3.505 3.505 0 000 4.95l4.586 4.586a1.5 1.5 0 002.121-2.122z"
                              data-original="#000000"
                            ></path>
                          </svg>
                        </span>
                        <span>Other Calculators</span>
                      </Link>
                    </div>
                    <div className="single-tax-calculator-info-sec">
                      <h1>{t("fd_calculator.heading")}</h1>
                      <p>{t("fd_calculator.content")}</p>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="single-tax-calculator-header-img-sec">
                      <CustomLazyLoad
                        className="single-tax-calculator-header-img"
                        src={
                          window.location.origin +
                          "/img/single-tax-calculator-header-img.png"
                        }
                        type="image/svg"
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="single-tax-calculator-form-box  line-stright calculator-space-md">
              <Row className="align-items-center g-5">
                <Col md={6} lg={6}>
                  <Formik
                    initialValues={{
                      principal: 10000,
                      rate_of_interest: 10,
                      time_period: 5,
                      compounding_frequency: 4, // value send as static
                    }}
                    innerRef={formRef}
                    validationSchema={fdSchema}
                    onSubmit={handleSubmit}
                  >
                    {({ setFieldValue, values, resetForm, isValid, submitForm }) => (
                      <FORM className="single-tax-calculator-form">
                        <div className="single-tax-calculator-form-card">
                          <div className="single-tax-calculator-form-header-sec">
                            <h4>{t("fd_calculator.investment.text")}</h4>
                            <Form.Group
                              className="mb-0"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Field
                                className="form-control"
                                name="principal"
                                placeholder={t(
                                  "fd_calculator.investment.placeholder"
                                )}
                                type="number"
                                onKeyDown={(e) => {
                                  if (
                                    !/[0-9.]|Backspace|ArrowLeft|ArrowRight|Delete|ArrowUp|ArrowDown|Tab/.test(
                                      e.key
                                    ) ||
                                    (e.key === "-" &&
                                      e.target.selectionStart !== 0)
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                                onInput={(e) => {
                                  if (e.target.value < 0) {
                                    e.target.value = 0;
                                  }
                                  onChangeInput(e, "principal");
                                }}
                              />
                            </Form.Group>
                          </div>
                          <ErrorMessage
                            component={"div"}
                            name="principal"
                            className="errorMsg"
                          />
                          <div className="tax-calculator-input-slider-sec">
                            <tc-range-slider
                              ref={principleRef}
                              value={principleRef}
                              mousewheel-disabled="true"
                              min={1000}
                              max={10000000}
                              slider-width="100%"
                              slider-bg-fill="#2F5CFF"
                              generate-labels="true"
                              round="0"
                            ></tc-range-slider>
                          </div>
                        </div>
                        <div className="single-tax-calculator-form-card">
                          <div className="single-tax-calculator-form-header-sec">
                            <h4>{t("fd_calculator.return_rate.text")}</h4>
                            <Form.Group
                              className="mb-0"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Field
                                className="form-control"
                                name="rate_of_interest"
                                placeholder={t(
                                  "fd_calculator.return_rate.placeholder"
                                )}
                                type="number"
                                onKeyDown={(e) => {
                                  if (
                                    !/[0-9.]|Backspace|ArrowLeft|ArrowRight|Delete|ArrowUp|ArrowDown|Tab/.test(
                                      e.key
                                    ) ||
                                    (e.key === "-" &&
                                      e.target.selectionStart !== 0)
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                                onInput={(e) => {
                                  if (e.target.value < 0) {
                                    e.target.value = 0;
                                  }
                                  onChangeInput(e, "interest");
                                }}
                              />
                            </Form.Group>
                          </div>
                          <ErrorMessage
                            component={"div"}
                            name="rate_of_interest"
                            className="errorMsg"
                          />
                          <div className="tax-calculator-input-slider-sec">
                            <tc-range-slider
                              ref={interestRef}
                              value={interestRef}
                              mousewheel-disabled="true"
                              min={1}
                              max={15}
                              slider-width="100%"
                              slider-bg-fill="#2F5CFF"
                              generate-labels="true"
                              round="1"
                            ></tc-range-slider>
                          </div>
                        </div>
                        <div className="single-tax-calculator-form-card">
                          <div className="single-tax-calculator-form-header-sec">
                            <h4 className="d-flex align-items-center">
                              {t("fd_calculator.time_period.text")}{" "}</h4>
                              <div className="yes-or-no-radio-btn-sec mx-2 d-flex">
                                <div class="pretty p-default p-round p-smooth">
                                  <input
                                    type="radio"
                                    value="yearly"
                                    checked={period == "yearly"}
                                    onChange={(e) => { setPeriod(e.target.value); submitForm() }}
                                  />
                                  <div class="state p-primary-o">
                                    <label>
                                      {t("fd_calculator.time_period.yearly")}
                                    </label>
                                  </div>
                                </div>
                                <div class="pretty p-default p-round p-smooth">
                                  <input
                                    type="radio"
                                    value="monthly"
                                    checked={period == "monthly"}
                                    onChange={(e) => { setPeriod(e.target.value); submitForm() }}
                                  />
                                  <div class="state p-primary-o">
                                    <label>
                                      {t("fd_calculator.time_period.monthly")}
                                    </label>
                                  </div>
                                </div>
                              </div>
                            
                            <Form.Group
                              className="mb-0 fd-year-input"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Field
                                className="form-control"
                                name="time_period"
                                placeholder={t(
                                  "fd_calculator.time_period.placeholder"
                                )}
                                type="number"
                                onKeyDown={(e) => {
                                  if (
                                    !/[0-9.]|Backspace|ArrowLeft|ArrowRight|Delete|ArrowUp|ArrowDown|Tab/.test(
                                      e.key
                                    ) ||
                                    (e.key === "-" &&
                                      e.target.selectionStart !== 0)
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                                onInput={(e) => {
                                  if (e.target.value < 0) {
                                    e.target.value = 0;
                                  }
                                  onChangeInput(e, "timePeriod");
                                }}
                              />
                            </Form.Group>
                          </div>
                          <ErrorMessage
                            component={"div"}
                            name="time_period"
                            className="errorMsg"
                          />
                        </div>
                        {/* <div className="d-flex justify-content-end mt-3">
                          <Button
                            type="submit"
                            className="default-primary-btn"
                            disabled={fdCalculator.buttonDisable}
                          >
                            {fdCalculator.buttonDisable ? (
                              <ButtonLoader />
                            ) : (
                              t("fd_calculator.calculate")
                            )}
                          </Button>
                        </div> */}
                      </FORM>
                    )}
                  </Formik>
                </Col>
                <Col md={6} lg={6}>
                  {fdCalculator.loading ? (
                    <div className="single-calculator-info-frame">
                      <div className="pie-chart-skelton">
                        <div className="skeleton-wrapper">
                          <Skeleton
                            borderRadius={130}
                            width={230}
                            height={230}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                        </div>
                        <div className="mt-4">
                          <Skeleton
                            width={250}
                            height={15}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                        </div>
                      </div>

                      <div className="calculator-info-details-card">
                        <div className="calculator-details-frame">
                          <Skeleton
                            width={200}
                            height={20}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                          <Skeleton
                            width={130}
                            height={20}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                        </div>
                        <div className="calculator-details-frame">
                          <Skeleton
                            width={200}
                            height={20}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                          <Skeleton
                            width={130}
                            height={20}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                        </div>
                        <hr />
                        <div className="calculator-details-frame top-bottom-line">
                          <Skeleton
                            width={200}
                            height={20}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                          <Skeleton
                            width={130}
                            height={20}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                        </div>
                        <hr />
                      </div>
                    </div>
                  ) : (
                    Object.keys(fdCalculator.data).length > 0 && (
                      <div className="single-calculator-info-frame">
                        <div className="pie-chart-sec">
                          <PieChart
                            name={[
                              t("fd_calculator.invested_amount"),
                              t("fd_calculator.estimated_returns"),
                            ]}
                            data={[
                              fdCalculator.data.invested_amount,
                              fdCalculator.data.estimated_return,
                            ]}
                          />
                        </div>
                        <div className="calculator-info-details-card">
                          <div className="calculator-details-frame">
                            <p>
                              {" "}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                x="0"
                                y="0"
                                enableBackground="new 0 0 29.107 29.107"
                                version="1.1"
                                viewBox="0 0 29.107 29.107"
                                xmlSpace="preserve"
                                width="20"
                                height="20"
                                fill="#2A334F"
                              >
                                <path d="M14.554 0C6.561 0 0 6.562 0 14.552c0 7.996 6.561 14.555 14.554 14.555 7.996 0 14.553-6.559 14.553-14.555C29.106 6.562 22.55 0 14.554 0z"></path>
                              </svg>
                              <span>{t("fd_calculator.invested_amount")}</span>
                            </p>
                            <h3>
                              {formatNumberWithCommas(
                                fdCalculator.data.invested_amount
                              )}
                            </h3>
                          </div>
                          <div className="calculator-details-frame">
                            <p>
                              {" "}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                x="0"
                                y="0"
                                enableBackground="new 0 0 29.107 29.107"
                                version="1.1"
                                viewBox="0 0 29.107 29.107"
                                xmlSpace="preserve"
                                width="20"
                                height="20"
                                fill="#2F5CFF"
                              >
                                <path d="M14.554 0C6.561 0 0 6.562 0 14.552c0 7.996 6.561 14.555 14.554 14.555 7.996 0 14.553-6.559 14.553-14.555C29.106 6.562 22.55 0 14.554 0z"></path>
                              </svg>
                              <span>
                                {t("fd_calculator.estimated_returns")}
                              </span>
                            </p>
                            <h3>
                              {formatNumberWithCommas(
                                fdCalculator.data.estimated_return
                              )}
                            </h3>
                          </div>
                          <hr />
                          <div className="calculator-details-frame top-bottom-line">
                            <p className="d-inline">
                              {t("fd_calculator.total_value")}
                            </p>
                            <h3>
                              {formatNumberWithCommas(
                                fdCalculator.data.maturity_amount
                              )}
                            </h3>
                          </div>
                          <hr />
                        </div>
                      </div>
                    )
                  )}
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <Container>
          <Row>
            <Col lg={12}>
              <div className="static-page-card">
                <div className="pricacy-policy-info">
                  <h4>Description</h4>
                  <p>
                    A fixed deposit is a form of term investment available
                    through various banks and Non-Banking Financial Companies
                    (NBFCs). These investments often come with a higher interest
                    rate, depending on specific terms and conditions. The funds
                    placed in a fixed deposit are committed for a fixed term,
                    ranging from 7 days to 10 years.
                  </p>
                  <p>
                    To calculate the interest and the total amount you will
                    receive upon maturity, you can use an FD calculator.
                  </p>
                </div>
                <div className="pricacy-policy-info">
                  <h4>Benefits of Using an FD Calculator</h4>
                  <p>
                    Determining the maturity value of a fixed deposit can be
                    intricate and demanding. Utilizing an online FD calculator
                    simplifies this task considerably.
                  </p>
                  <ul>
                    <li>
                      The calculations for FD maturity involve numerous factors
                      and can get quite complex. An FD calculator efficiently
                      handles these complexities, providing precise results with
                      just a simple click.
                    </li>
                    <li>
                      It significantly reduces the time spent on these elaborate
                      calculations.
                    </li>
                    <li>
                      An FD calculator also allows for the comparison of
                      maturity values and interest rates across various FDs from
                      different financial institutions. Having access to this
                      data enables you to make well-informed choices regarding
                      where to place your deposits.
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <FAQIndex
          data={[
            {
              question:
                "Does it cost anything to access the FD interest calculator?",
              answer:
                "No, the FD interest calculator is available for free, allowing users to estimate their FD returns without any charges.",
            },
            {
              question:
                "What is the duration required to operate the FD calculator?",
              answer:
                "Using the FD calculator is a quick process, typically taking only a few moments to input your details and receive the calculated results.",
            },
            {
              question:
                "In what ways does the FD calculator contribute to planning financial goals?",
              answer:
                "The FD calculator aids in financial planning by providing accurate forecasts of your fixed deposit's maturity amount, helping you gauge how your savings will grow over time. This insight is valuable for setting and achieving long-term financial objectives.",
            },
          ]}
        />
      </div>
    </>
  );
};

export default CalculatorIndex;
