import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Container, Button, Form, Accordion } from "react-bootstrap";
import CustomLazyLoad from "../../../Helper/CustomLazyLoad";
import Skeleton from "react-loading-skeleton";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-multi-lang";
import { useDispatch, useSelector } from "react-redux";
import {
  lumpSumCalculatorStart,
  lumpSumCalculatorSuccess,
} from "../../../../store/slices/CommonSlice";
import { ButtonLoader } from "../../../Helper/Loader";
import PieChart from "../PieChart";
import FAQIndex from "../FAQIndex";
import { Link } from "react-router-dom";
import formatNumberWithCommas from "../../../FormatNumber";

const CalculatorIndex = (props) => {
  const t = useTranslation("tax_calculator");
  const principleRef = useRef(null);
  const interestRef = useRef(null);
  const timePeriodRef = useRef(null);
  const dispatch = useDispatch();
  const formRef = useRef();
  const lumpSumCalculator = useSelector(
    (state) => state.common.lumpSumCalculator
  );

  useEffect(() => {
    const onChange = (event, field) => {
      const customEvent = event;
      switch (field) {
        case "principal":
          formRef.current.setFieldValue("principal", customEvent.detail.value);
          break;
        case "interest":
          formRef.current.setFieldValue(
            "rate_of_interest",
            customEvent.detail.value
          );
          break;
        case "timePeriod":
          formRef.current.setFieldValue(
            "time_period",
            customEvent.detail.value
          );
          break;
      }
      setTimeout(() => {
        formRef.current.submitForm();
      }, 1);
    };
    principleRef.current?.addEventListener("change", (event) =>
      onChange(event, "principal")
    );
    interestRef.current?.addEventListener("change", (event) =>
      onChange(event, "interest")
    );
    timePeriodRef.current?.addEventListener("change", (event) =>
      onChange(event, "timePeriod")
    );
    return () => {
      principleRef.current?.removeEventListener("change", onChange);
      interestRef.current?.removeEventListener("change", onChange);
      timePeriodRef.current?.removeEventListener("change", onChange);
    };
  }, []);

  useEffect(() => {
    // dispatch(lumpSumCalculatorStart(formRef.current.values));
    calculate(formRef.current.values);
  }, []);

  const onChangeInput = (event, field) => {
    const value = !isNaN(parseFloat(event.target.value))
      ? parseFloat(event.target.value)
      : "";
    switch (field) {
      case "principal":
        formRef.current.setFieldValue("principal", value);
        principleRef.current.value = value;
        break;
      case "interest":
        formRef.current.setFieldValue("rate_of_interest", value);
        interestRef.current.value = value;
        break;
      case "timePeriod":
        formRef.current.setFieldValue("time_period", value);
        timePeriodRef.current.value = value;
        break;
    }
    setTimeout(() => {
      formRef.current.submitForm();
    }, 1);
  };

  const handleSubmit = (values) => {
    // formRef.current.isValid && dispatch(lumpSumCalculatorStart(values));
    formRef.current.isValid && calculate(values);
  };

  const lumpsumSchema = Yup.object().shape({
    principal: Yup.string()
      .test(
        "min",
        t("lumpSum_calculator.invalid_min", { value: 500 }),
        (value) => {
          return value >= 500;
        }
      )
      .test(
        "max",
        t("lumpSum_calculator.invalid_max", {
          value: 10000000,
          name: "Total investment",
        }),
        (value) => {
          return value <= 10000000;
        }
      )
      .required(t("lumpSum_calculator.required")),
    rate_of_interest: Yup.string()
      .test(
        "min",
        t("lumpSum_calculator.invalid_min", { value: 1 }),
        (value) => {
          return value >= 1;
        }
      )
      .test(
        "max",
        t("lumpSum_calculator.invalid_max", {
          value: 30,
          name: "Rate of interest",
        }),
        (value) => {
          return value <= 30;
        }
      )
      .required(t("lumpSum_calculator.required")),
    time_period: Yup.string()
      .test(
        "min",
        t("lumpSum_calculator.invalid_min", { value: 1 }),
        (value) => {
          return value >= 1;
        }
      )
      .test(
        "max",
        t("lumpSum_calculator.invalid_max", { value: 40, name: "Time period" }),
        (value) => {
          return value <= 40;
        }
      )
      .required(t("lumpSum_calculator.required")),
  });

  const calculate = (values) => {
    const { principal, rate_of_interest, time_period } = values;

    if (!principal || !rate_of_interest || !time_period) {
      return;
    }

    const rateOfInterest = rate_of_interest / 100;
    const maturity_amount = principal * (1 + rateOfInterest) ** time_period;
    const invested_amount = principal;
    const estimated_return = maturity_amount - invested_amount;

    const newResult = {
      maturity_amount: Math.round(maturity_amount),
      invested_amount: Math.round(invested_amount),
      estimated_return: Math.round(estimated_return),
    };

    dispatch(lumpSumCalculatorSuccess(newResult));
  };

  return (
    <>
      <div className="single-tax-calculator">
        <div className="sm-padding single-tax-calculator-sec">
          <Container>
            <div className="single-tax-calculator-box">
              <div className="single-tax-calculator-header">
                <Row>
                  <Col md={6}>
                    <div className="d-flex other-calculator-btn-sec">
                      <Link
                        to="/tax-calculator"
                        className="other-calculator-btn"
                      >
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            enableBackground="new 0 0 512 512"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="#fff"
                              d="M10.957 12.354a.5.5 0 010-.708l4.586-4.585a1.5 1.5 0 00-2.121-2.122L8.836 9.525a3.505 3.505 0 000 4.95l4.586 4.586a1.5 1.5 0 002.121-2.122z"
                              data-original="#000000"
                            ></path>
                          </svg>
                        </span>
                        <span>Other Calculators</span>
                      </Link>
                    </div>
                    <div className="single-tax-calculator-info-sec">
                      <h1>{t("lumpSum_calculator.heading")}</h1>
                      <p>{t("lumpSum_calculator.content")}</p>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="single-tax-calculator-header-img-sec">
                      <CustomLazyLoad
                        className="single-tax-calculator-header-img"
                        src={
                          window.location.origin +
                          "/img/single-tax-calculator-header-img.png"
                        }
                        type="image/svg"
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="single-tax-calculator-form-box  line-stright">
              <Row className="align-items-center g-5">
                <Col md={6} lg={6}>
                  <Formik
                    initialValues={{
                      principal: 1000,
                      rate_of_interest: 2,
                      time_period: 2,
                    }}
                    innerRef={formRef}
                    validationSchema={lumpsumSchema}
                    onSubmit={handleSubmit}
                  >
                    {({
                      setFieldValue,
                      values,
                      resetForm,
                      setFieldError,
                      isValid,
                      errors,
                      submitForm,
                    }) => (
                      <FORM className="single-tax-calculator-form">
                        <div className="single-tax-calculator-form-card">
                          <div className="single-tax-calculator-form-header-sec">
                            <h4>{t("lumpSum_calculator.investment.text")}</h4>
                            <Form.Group
                              className="mb-0"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Field
                                className="form-control"
                                name="principal"
                                placeholder={t(
                                  "lumpSum_calculator.investment.placeholder"
                                )}
                                type="number"
                                onKeyDown={(e) => {
                                  if (
                                    !/[0-9]|Backspace|ArrowLeft|ArrowRight|Delete|ArrowUp|ArrowDown|Tab/.test(
                                      e.key
                                    ) &&
                                    !(
                                      e.ctrlKey &&
                                      (e.key === "v" ||
                                        e.key === "c" ||
                                        e.key === "x" ||
                                        e.key === "a")
                                    )
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                                onInput={(e) => {
                                  if (e.target.value < 0) {
                                    e.target.value = 0;
                                  }
                                  onChangeInput(e, "principal");
                                }}
                              />
                            </Form.Group>
                          </div>
                          <ErrorMessage
                            component={"div"}
                            name="principal"
                            className="errorMsg"
                          />
                          <div className="tax-calculator-input-slider-sec">
                            <tc-range-slider
                              ref={principleRef}
                              value={principleRef}
                              mousewheel-disabled="true"
                              min={500}
                              max={10000000}
                              slider-width="100%"
                              slider-bg-fill="#2F5CFF"
                              generate-labels="true"
                              round="0"
                            ></tc-range-slider>
                          </div>
                        </div>
                        <div className="single-tax-calculator-form-card">
                          <div className="single-tax-calculator-form-header-sec">
                            <h4>{t("lumpSum_calculator.return_rate.text")}</h4>
                            <Form.Group
                              className="mb-0"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Field
                                className="form-control"
                                name="rate_of_interest"
                                placeholder={t(
                                  "lumpSum_calculator.return_rate.placeholder"
                                )}
                                type="number"
                                onKeyDown={(e) => {
                                  if (
                                    !/[0-9.]|Backspace|ArrowLeft|ArrowRight|Delete|ArrowUp|ArrowDown|Tab/.test(
                                      e.key
                                    ) ||
                                    (e.key === "-" &&
                                      e.target.selectionStart !== 0)
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                                onInput={(e) => {
                                  if (e.target.value < 0) {
                                    e.target.value = 0;
                                  }
                                  onChangeInput(e, "interest");
                                }}
                              />
                            </Form.Group>
                          </div>
                          <ErrorMessage
                            component={"div"}
                            name="rate_of_interest"
                            className="errorMsg"
                          />
                          <div className="tax-calculator-input-slider-sec">
                            <tc-range-slider
                              ref={interestRef}
                              value={interestRef}
                              mousewheel-disabled="true"
                              min={1}
                              max={30}
                              slider-width="100%"
                              slider-bg-fill="#2F5CFF"
                              generate-labels="true"
                              round="1"
                            ></tc-range-slider>
                          </div>
                        </div>
                        <div className="single-tax-calculator-form-card">
                          <div className="single-tax-calculator-form-header-sec">
                            <h4>{t("lumpSum_calculator.time_period.text")}</h4>
                            <Form.Group
                              className="mb-0"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Field
                                className="form-control"
                                name="time_period"
                                placeholder={t(
                                  "lumpSum_calculator.time_period.placeholder"
                                )}
                                type="number"
                                onKeyDown={(e) => {
                                  if (
                                    !/[0-9]|Backspace|ArrowLeft|ArrowRight|Delete|ArrowUp|ArrowDown|Tab/.test(
                                      e.key
                                    ) &&
                                    !(
                                      e.ctrlKey &&
                                      (e.key === "v" ||
                                        e.key === "c" ||
                                        e.key === "x" ||
                                        e.key === "a")
                                    )
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                                onInput={(e) => {
                                  if (e.target.value < 0) {
                                    e.target.value = 0;
                                  }
                                  onChangeInput(e, "timePeriod");
                                }}
                              />
                            </Form.Group>
                          </div>
                          <ErrorMessage
                            component={"div"}
                            name="time_period"
                            className="errorMsg"
                          />
                          <div className="tax-calculator-input-slider-sec">
                            <tc-range-slider
                              ref={timePeriodRef}
                              value={timePeriodRef}
                              mousewheel-disabled="true"
                              min={1}
                              max={40}
                              slider-width="100%"
                              slider-bg-fill="#2F5CFF"
                              generate-labels="true"
                              round="0"
                            ></tc-range-slider>
                          </div>
                        </div>
                        {/* <div className="d-flex justify-content-end">
                          <Button
                            type="submit"
                            className="default-primary-btn"
                            disabled={lumpSumCalculator.buttonDisable}
                          >
                            {lumpSumCalculator.buttonDisable ? (
                              <ButtonLoader />
                            ) : (
                              t("lumpSum_calculator.calculate")
                            )}
                          </Button>
                        </div> */}
                      </FORM>
                    )}
                  </Formik>
                </Col>
                <Col md={6} lg={6}>
                  {lumpSumCalculator.loading ? (
                    <div className="single-calculator-info-frame">
                      <div className="pie-chart-skelton">
                        <div className="skeleton-wrapper">
                          <Skeleton
                            borderRadius={130}
                            width={230}
                            height={230}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                        </div>
                        <div className="mt-4">
                          <Skeleton
                            width={250}
                            height={15}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                        </div>
                      </div>

                      <div className="calculator-info-details-card">
                        <div className="calculator-details-frame">
                          <Skeleton
                            width={200}
                            height={20}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                          <Skeleton
                            width={130}
                            height={20}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                        </div>
                        <div className="calculator-details-frame">
                          <Skeleton
                            width={200}
                            height={20}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                          <Skeleton
                            width={130}
                            height={20}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                        </div>
                        <hr />
                        <div className="calculator-details-frame top-bottom-line">
                          <Skeleton
                            width={200}
                            height={20}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                          <Skeleton
                            width={130}
                            height={20}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                        </div>
                        <hr />
                      </div>
                    </div>
                  ) : (
                    Object.keys(lumpSumCalculator.data).length > 0 && (
                      <div className="single-calculator-info-frame">
                        <div className="pie-chart-sec">
                          <PieChart
                            name={[
                              t("lumpSum_calculator.invested_amount"),
                              t("lumpSum_calculator.estimated_returns"),
                            ]}
                            data={[
                              lumpSumCalculator.data.invested_amount,
                              lumpSumCalculator.data.estimated_return,
                            ]}
                          />
                        </div>
                        <div className="calculator-info-details-card">
                          <div className="calculator-details-frame">
                            <p>
                              {" "}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                x="0"
                                y="0"
                                enableBackground="new 0 0 29.107 29.107"
                                version="1.1"
                                viewBox="0 0 29.107 29.107"
                                xmlSpace="preserve"
                                width="20"
                                height="20"
                                fill="#2A334F"
                              >
                                <path d="M14.554 0C6.561 0 0 6.562 0 14.552c0 7.996 6.561 14.555 14.554 14.555 7.996 0 14.553-6.559 14.553-14.555C29.106 6.562 22.55 0 14.554 0z"></path>
                              </svg>
                              <span>
                                {t("lumpSum_calculator.invested_amount")}
                              </span>
                            </p>
                            <h3>
                              {formatNumberWithCommas(
                                lumpSumCalculator.data.invested_amount
                              )}
                            </h3>
                          </div>
                          <div className="calculator-details-frame">
                            <p>
                              {" "}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                x="0"
                                y="0"
                                enableBackground="new 0 0 29.107 29.107"
                                version="1.1"
                                viewBox="0 0 29.107 29.107"
                                xmlSpace="preserve"
                                width="20"
                                height="20"
                                fill="#2F5CFF"
                              >
                                <path d="M14.554 0C6.561 0 0 6.562 0 14.552c0 7.996 6.561 14.555 14.554 14.555 7.996 0 14.553-6.559 14.553-14.555C29.106 6.562 22.55 0 14.554 0z"></path>
                              </svg>
                              <span>
                                {t("lumpSum_calculator.estimated_returns")}
                              </span>
                            </p>
                            <h3>
                              {formatNumberWithCommas(
                                lumpSumCalculator.data.estimated_return
                              )}
                            </h3>
                          </div>
                          <hr />
                          <div className="calculator-details-frame top-bottom-line">
                            <p className="d-inline">
                              {" "}
                              {t("lumpSum_calculator.total_value")}
                            </p>
                            <h3>
                              {formatNumberWithCommas(
                                lumpSumCalculator.data.maturity_amount
                              )}
                            </h3>
                          </div>
                          <hr />
                        </div>
                      </div>
                    )
                  )}
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <Container>
          <Row>
            <Col lg={12}>
              <div className="static-page-card">
                <div className="pricacy-policy-info">
                  <h4>Description</h4>
                  <p>
                    Mutual Fund investments are primarily divided into two
                    categories: lump sum and SIP. In a lump sum investment, an
                    investor commits a substantial amount to a specific mutual
                    fund scheme all at once. Conversely, a Systematic Investment
                    Plan (SIP) involves making smaller, regular investments,
                    typically on a monthly basis.
                  </p>
                  <p>
                    Each investment approach in mutual funds has its advantages.
                    Lump sum investments are often favored by many investors due
                    to their simplicity and the potential for higher returns. To
                    estimate the returns on a lump sum mutual fund investment,
                    you can utilize an online mutual fund lump sum calculator.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <FAQIndex
          data={[
            {
              question: "How do lump sum and SIP investments differ?",
              answer:
                "A lump sum investment involves depositing a large amount into a mutual fund scheme at once, while a Systematic Investment Plan (SIP) entails making smaller, regular investments over time, often monthly.",
            },
            {
              question:
                "Which investment method is preferable, lump sum or SIP?",
              answer:
                "The choice between lump sum and SIP investments depends on your financial situation, investment goals, and risk tolerance. SIPs can be more suitable for those looking to invest gradually, whereas lump sum investments may be better for those with a significant amount of capital ready to invest.",
            },
            {
              question: "Where is it possible to invest in mutual funds?",
              answer:
                "Mutual fund investments can be made through various platforms, including banks, financial institutions, mutual fund companies, and online investment platforms.",
            },
            {
              question: "How accurate are mutual fund calculators?",
              answer:
                "Mutual fund calculators provide estimates based on the inputs provided and assumptions about market conditions. While they can offer useful guidance, actual returns can vary due to market fluctuations and other factors.",
            },
          ]}
        />
      </div>
    </>
  );
};

export default CalculatorIndex;
