import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Container, Button, Form, Accordion } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import CustomLazyLoad from "../../../Helper/CustomLazyLoad";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-multi-lang";
import { useDispatch, useSelector } from "react-redux";
import {
  npsCalculatorStart,
  npsCalculatorSuccess,
} from "../../../../store/slices/CommonSlice";
import { ButtonLoader } from "../../../Helper/Loader";
import PieChart from "../PieChart";
import FAQIndex from "../FAQIndex";
import { Link } from "react-router-dom";
import formatNumberWithCommas from "../../../FormatNumber";

const CalculatorIndex = (props) => {
  const t = useTranslation("tax_calculator");
  const investmentRef = useRef(null);
  const interestRef = useRef(null);
  const currentAgeRef = useRef(null);
  const dispatch = useDispatch();
  const formRef = useRef();
  const npsCalculator = useSelector((state) => state.common.npsCalculator);

  useEffect(() => {
    const onChange = (event, field) => {
      const customEvent = event;
      switch (field) {
        case "investment":
          formRef.current.setFieldValue(
            "monthly_investment",
            customEvent.detail.value
          );
          formRef.current.submitForm();
          break;
        case "interest":
          formRef.current.setFieldValue(
            "rate_of_interest",
            customEvent.detail.value
          );
          formRef.current.submitForm();
          break;
        case "currentAge":
          formRef.current.setFieldValue(
            "current_age",
            customEvent.detail.value
          );
          formRef.current.submitForm();
          break;
      }
      setTimeout(() => {
        formRef.current.submitForm();
      }, 1);
    };
    investmentRef.current?.addEventListener("change", (event) =>
      onChange(event, "investment")
    );
    interestRef.current?.addEventListener("change", (event) =>
      onChange(event, "interest")
    );
    currentAgeRef.current?.addEventListener("change", (event) =>
      onChange(event, "currentAge")
    );
    return () => {
      investmentRef.current?.removeEventListener("change", onChange);
      interestRef.current?.removeEventListener("change", onChange);
      currentAgeRef.current?.removeEventListener("change", onChange);
    };
  }, []);

  useEffect(() => {
    // dispatch(npsCalculatorStart(formRef.current.values));
    formRef.current.isValid && calculate(formRef.current.values);
  }, []);

  const onChangeInput = (event, field) => {
    const value = !isNaN(parseFloat(event.target.value))
      ? parseFloat(event.target.value)
      : "";
    switch (field) {
      case "investment":
        formRef.current.setFieldValue("monthly_investment", value);
        investmentRef.current.value = value;
        break;
      case "interest":
        formRef.current.setFieldValue("rate_of_interest", value);
        interestRef.current.value = value;
        break;
      case "currentAge":
        formRef.current.setFieldValue("current_age", value);
        currentAgeRef.current.value = value;
        break;
    }
    setTimeout(() => {
      formRef.current.submitForm();
    }, 1);
  };

  const handleSubmit = (values) => {
    // dispatch(npsCalculatorStart(values));
    formRef.current.isValid && calculate(values);
  };

  const sipSchema = Yup.object().shape({
    monthly_investment: Yup.string()
      .test("min", t("nps_calculator.invalid_min", { value: 500 }), (value) => {
        return value >= 500;
      })
      .test(
        "max",
        t("nps_calculator.invalid_max", {
          value: 150000,
          name: "Monthly investment",
        }),
        (value) => {
          return value <= 150000;
        }
      )
      .required(t("nps_calculator.required")),
    rate_of_interest: Yup.string()
      .test("min", t("nps_calculator.invalid_min", { value: 8 }), (value) => {
        return value >= 8;
      })
      .test(
        "max",
        t("nps_calculator.invalid_max", {
          value: 15,
          name: "Rate of interest",
        }),
        (value) => {
          return value <= 15;
        }
      )
      .required(t("nps_calculator.required")),
    current_age: Yup.string()
      .test("min", t("nps_calculator.invalid_min", { value: 18 }), (value) => {
        return value >= 18;
      })
      .test(
        "max",
        t("nps_calculator.invalid_max", { value: 60, name: "Current age" }),
        (value) => {
          return value <= 60;
        }
      )
      .required(t("nps_calculator.required")),
  });

  const calculate = (values) => {
    const {
      monthly_investment,
      rate_of_interest,
      current_age,
      compound_frequency,
    } = values;

    if (
      !monthly_investment ||
      !rate_of_interest ||
      !current_age ||
      !compound_frequency
    ) {
      return;
    }

    const maturity_age = 60;
    const yearly_investment = monthly_investment * 12;
    const time_period = maturity_age - current_age;

    let existing_investment = 0;

    for (let i = 0; i <= time_period; i++) {
      const total_corpus_for_year =
        (existing_investment + yearly_investment) *
        (1 + rate_of_interest / 100);
      existing_investment = total_corpus_for_year;
    }

    const total_investment = time_period * yearly_investment;
    const interest_earned = existing_investment - total_investment;
    const min_annuity_amount = existing_investment * 0.4;

    const maturity_amount = Math.round(existing_investment);
    const invested_amount = Math.round(total_investment);
    const estimated_return = Math.round(interest_earned);
    const rounded_min_annuity_amount = Math.round(min_annuity_amount);

    const result = {
      maturity_amount: maturity_amount,
      invested_amount: invested_amount,
      estimated_return: estimated_return,
      min_annuity_amount: rounded_min_annuity_amount,
    };

    dispatch(npsCalculatorSuccess(result));
  };

  return (
    <>
      <div className="single-tax-calculator">
        <div className="sm-padding single-tax-calculator-sec">
          <Container>
            <div className="single-tax-calculator-box">
              <div className="single-tax-calculator-header">
                <Row>
                  <Col md={6}>
                    <div className="d-flex other-calculator-btn-sec">
                      <Link
                        to="/tax-calculator"
                        className="other-calculator-btn"
                      >
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            enableBackground="new 0 0 512 512"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="#fff"
                              d="M10.957 12.354a.5.5 0 010-.708l4.586-4.585a1.5 1.5 0 00-2.121-2.122L8.836 9.525a3.505 3.505 0 000 4.95l4.586 4.586a1.5 1.5 0 002.121-2.122z"
                              data-original="#000000"
                            ></path>
                          </svg>
                        </span>
                        <span>Other Calculators</span>
                      </Link>
                    </div>
                    <div className="single-tax-calculator-info-sec">
                      <h1>{t("nps_calculator.heading")}</h1>
                      <p>{t("nps_calculator.content")}</p>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="single-tax-calculator-header-img-sec">
                      <CustomLazyLoad
                        className="single-tax-calculator-header-img"
                        src={
                          window.location.origin +
                          "/img/single-tax-calculator-header-img.png"
                        }
                        type="image/svg"
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="single-tax-calculator-form-box  line-stright">
              <Row className="align-items-center g-5">
                <Col md={6} lg={6}>
                  <Formik
                    initialValues={{
                      monthly_investment: 500,
                      rate_of_interest: 8,
                      current_age: 18,
                      compound_frequency: 2, // value send as static
                    }}
                    innerRef={formRef}
                    validationSchema={sipSchema}
                    onSubmit={handleSubmit}
                  >
                    {({
                      setFieldValue,
                      values,
                      resetForm,
                      submitForm,
                      isValid,
                    }) => (
                      <FORM className="single-tax-calculator-form">
                        <div className="single-tax-calculator-form-card">
                          <div className="single-tax-calculator-form-header-sec">
                            <h4>{t("nps_calculator.investment.text")}</h4>
                            <Form.Group
                              className="mb-0"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Field
                                className="form-control"
                                name="monthly_investment"
                                placeholder={t(
                                  "nps_calculator.investment.placeholder"
                                )}
                                type="number"
                                onKeyDown={(e) => {
                                  if (
                                    !/[0-9]|Backspace|ArrowLeft|ArrowRight|Delete|ArrowUp|ArrowDown|Tab/.test(
                                      e.key
                                    ) &&
                                    !(
                                      e.ctrlKey &&
                                      (e.key === "v" ||
                                        e.key === "c" ||
                                        e.key === "x" ||
                                        e.key === "a")
                                    )
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                                onInput={(e) => {
                                  if (e.target.value < 0) {
                                    e.target.value = 0;
                                  }
                                  onChangeInput(e, "investment");
                                  submitForm();
                                }}
                              />
                            </Form.Group>
                          </div>
                          <ErrorMessage
                            component={"div"}
                            name="monthly_investment"
                            className="errorMsg"
                          />
                          <div className="tax-calculator-input-slider-sec">
                            <tc-range-slider
                              ref={investmentRef}
                              value={investmentRef}
                              mousewheel-disabled="true"
                              min={500}
                              max={150000}
                              slider-width="100%"
                              slider-bg-fill="#2F5CFF"
                              generate-labels="true"
                              round="0"
                            ></tc-range-slider>
                          </div>
                        </div>
                        <div className="single-tax-calculator-form-card">
                          <div className="single-tax-calculator-form-header-sec">
                            <h4>{t("nps_calculator.return_rate.text")}</h4>
                            <Form.Group
                              className="mb-0"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Field
                                className="form-control"
                                name="rate_of_interest"
                                placeholder={t(
                                  "nps_calculator.return_rate.placeholder"
                                )}
                                type="number"
                                onKeyDown={(e) => {
                                  if (
                                    !/[0-9.]|Backspace|ArrowLeft|ArrowRight|Delete|ArrowUp|ArrowDown|Tab/.test(
                                      e.key
                                    ) ||
                                    (e.key === "-" &&
                                      e.target.selectionStart !== 0)
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                                onInput={(e) => {
                                  if (e.target.value < 0) {
                                    e.target.value = 0;
                                  }
                                  onChangeInput(e, "interest");
                                  submitForm();
                                }}
                              />
                            </Form.Group>
                          </div>
                          <ErrorMessage
                            component={"div"}
                            name="rate_of_interest"
                            className="errorMsg"
                          />
                          <div className="tax-calculator-input-slider-sec">
                            <tc-range-slider
                              ref={interestRef}
                              value={interestRef}
                              mousewheel-disabled="true"
                              min={8}
                              max={15}
                              slider-width="100%"
                              slider-bg-fill="#2F5CFF"
                              generate-labels="true"
                              round="1"
                            ></tc-range-slider>
                          </div>
                        </div>
                        <div className="single-tax-calculator-form-card">
                          <div className="single-tax-calculator-form-header-sec">
                            <h4>{t("nps_calculator.current_age.text")}</h4>
                            <Form.Group
                              className="mb-0"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Field
                                className="form-control"
                                name="current_age"
                                placeholder={t(
                                  "nps_calculator.current_age.placeholder"
                                )}
                                type="number"
                                onKeyDown={(e) => {
                                  if (
                                    !/[0-9]|Backspace|ArrowLeft|ArrowRight|Delete|ArrowUp|ArrowDown|Tab/.test(
                                      e.key
                                    ) &&
                                    !(
                                      e.ctrlKey &&
                                      (e.key === "v" ||
                                        e.key === "c" ||
                                        e.key === "x" ||
                                        e.key === "a")
                                    )
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                                onInput={(e) => {
                                  if (e.target.value < 0) {
                                    e.target.value = 0;
                                  }
                                  onChangeInput(e, "currentAge");
                                  submitForm();
                                }}
                              />
                            </Form.Group>
                          </div>
                          <ErrorMessage
                            component={"div"}
                            name="current_age"
                            className="errorMsg"
                          />
                          <div className="tax-calculator-input-slider-sec">
                            <tc-range-slider
                              ref={currentAgeRef}
                              value={currentAgeRef}
                              mousewheel-disabled="true"
                              min={18}
                              max={60}
                              slider-width="100%"
                              slider-bg-fill="#2F5CFF"
                              generate-labels="true"
                              round="0"
                            ></tc-range-slider>
                          </div>
                        </div>
                        {/* <div className="d-flex justify-content-end">
                          <Button
                            type="submit"
                            className="default-primary-btn"
                            disabled={npsCalculator.buttonDisable}
                          >
                            {npsCalculator.buttonDisable ? (
                              <ButtonLoader />
                            ) : (
                              t("nps_calculator.calculate")
                            )}
                          </Button>
                        </div> */}
                      </FORM>
                    )}
                  </Formik>
                </Col>
                <Col md={6} lg={6}>
                  {npsCalculator.loading ? (
                    <div className="single-calculator-info-frame">
                      <div className="pie-chart-skelton">
                        <div className="skeleton-wrapper">
                          <Skeleton
                            borderRadius={130}
                            width={230}
                            height={230}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                        </div>
                        <div className="mt-4">
                          <Skeleton
                            width={250}
                            height={15}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                        </div>
                      </div>

                      <div className="calculator-info-details-card">
                        <div className="calculator-details-frame">
                          <Skeleton
                            width={200}
                            height={20}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                          <Skeleton
                            width={130}
                            height={20}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                        </div>
                        <div className="calculator-details-frame">
                          <Skeleton
                            width={200}
                            height={20}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                          <Skeleton
                            width={130}
                            height={20}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                        </div>
                        <hr />
                        <div className="calculator-details-frame top-bottom-line">
                          <Skeleton
                            width={200}
                            height={20}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                          <Skeleton
                            width={130}
                            height={20}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                        </div>
                        <hr />
                      </div>
                    </div>
                  ) : (
                    Object.keys(npsCalculator.data).length > 0 && (
                      <div className="single-calculator-info-frame">
                        <div className="pie-chart-sec">
                          <PieChart
                            name={[
                              t("nps_calculator.invested_amount"),
                              t("nps_calculator.interest_earned"),
                            ]}
                            data={[
                              npsCalculator.data.invested_amount,
                              npsCalculator.data.estimated_return,
                            ]}
                          />
                        </div>
                        <div className="calculator-info-details-card">
                          <div className="calculator-details-frame">
                            <p>
                              {" "}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                x="0"
                                y="0"
                                enableBackground="new 0 0 29.107 29.107"
                                version="1.1"
                                viewBox="0 0 29.107 29.107"
                                xmlSpace="preserve"
                                width="20"
                                height="20"
                                fill="#2A334F"
                              >
                                <path d="M14.554 0C6.561 0 0 6.562 0 14.552c0 7.996 6.561 14.555 14.554 14.555 7.996 0 14.553-6.559 14.553-14.555C29.106 6.562 22.55 0 14.554 0z"></path>
                              </svg>
                              <span>{t("nps_calculator.invested_amount")}</span>
                            </p>
                            <h3>
                              {formatNumberWithCommas(
                                npsCalculator.data.invested_amount
                              )}
                            </h3>
                          </div>
                          <div className="calculator-details-frame">
                            <p>
                              {" "}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                x="0"
                                y="0"
                                enableBackground="new 0 0 29.107 29.107"
                                version="1.1"
                                viewBox="0 0 29.107 29.107"
                                xmlSpace="preserve"
                                width="20"
                                height="20"
                                fill="#2F5CFF"
                              >
                                <path d="M14.554 0C6.561 0 0 6.562 0 14.552c0 7.996 6.561 14.555 14.554 14.555 7.996 0 14.553-6.559 14.553-14.555C29.106 6.562 22.55 0 14.554 0z"></path>
                              </svg>
                              <span>{t("nps_calculator.interest_earned")}</span>
                            </p>
                            <h3>
                              {formatNumberWithCommas(
                                npsCalculator.data.estimated_return
                              )}
                            </h3>
                          </div>
                          <hr />
                          <div className="calculator-details-frame top-bottom-line">
                            <p className="d-inline">
                              {t("nps_calculator.maturity_amount")}
                            </p>
                            <h3>
                              {formatNumberWithCommas(
                                npsCalculator.data.maturity_amount
                              )}
                            </h3>
                          </div>
                          <hr />
                          <div className="calculator-details-frame top-bottom-line">
                            <p className="d-inline">
                              {t("nps_calculator.min_annuity")}
                            </p>
                            <h3>
                              {formatNumberWithCommas(
                                npsCalculator.data.min_annuity_amount
                              )}
                            </h3>
                          </div>
                        </div>
                      </div>
                    )
                  )}
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <Container>
          <Row>
            <Col lg={12}>
              <div className="static-page-card">
                <div className="pricacy-policy-info">
                  <h4>Description</h4>
                  <p>
                    The National Pension System (NPS) serves as a mechanism to
                    ensure financial security for Indian citizens
                    post-retirement, formerly known as the National Pension
                    Scheme. Individuals aged 60 and above can access the funds
                    accumulated in their pension corpus. An NPS calculator is a
                    useful tool for estimating the total value of this
                    accumulation.
                  </p>
                  <p>
                    Citizens of India aged between 18 and 60 years are eligible
                    to contribute to a pension corpus through NPS, creating a
                    valuable asset for their retirement years. Given the
                    prevalence of private sector employment with limited job
                    security in India, many individuals rely on a National
                    Pension Scheme calculator. Unlike market-linked investment
                    options, pension schemes in India offer stable returns.
                  </p>
                </div>
                <div className="pricacy-policy-info">
                  <h4>Benefits of Using an NPS Calculator</h4>
                  <p>The NPS pension calculator aids you in several ways:</p>
                  <ul>
                    <li>
                      IIt calculates the future amount you will receive,
                      treating the pension as an investment rather than a
                      burden. Utilizing an NPS calculator is crucial for
                      estimating the total pension corpus.
                    </li>
                    <li>
                      According to current laws, one cannot withdraw the entire
                      pension sum post-retirement. A minimum of 40% of the total
                      must be allocated towards annuities, and the remaining 60%
                      is taxable. An NPS calculator provides a detailed
                      breakdown of these amounts.
                    </li>
                    <li>
                      The accuracy of an NPS calculator ensures error-free
                      calculations, a relief for anyone who has attempted to
                      manually figure out pension sums. The NPS calculator
                      streamlines this complex process.
                    </li>
                    <li>
                      Additionally, the calculator allows you to understand the
                      tax implications on pension funds, estimate the monthly
                      pension you will receive, and access various other
                      critical details related to your pension planning.
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <FAQIndex
          data={[
            {
              question:
                "Do pension maturity values differ based on whether you're in a tier-1 or tier-2 city?",
              answer:
                "No, pension maturity values under NPS are not determined by the city classification such as tier-1 or tier-2. They are calculated based on the contributions and the returns generated by the chosen investment options.",
            },
            {
              question:
                "Can the calculator assist in figuring out the tax implications upon withdrawal?",
              answer:
                "Yes, the NPS calculator can provide an estimate of the taxable amount upon withdrawal, helping you understand the tax liabilities associated with your pension corpus.",
            },
            {
              question:
                "I've opted for the Auto choice - Lifecycle Fund within NPS. Is the NPS calculator still applicable?",
              answer:
                "Absolutely, the NPS calculator can be used regardless of the investment option chosen, including the Auto choice - Lifecycle Fund. It will take into account the investment pattern and provide estimates based on that.",
            },
          ]}
        />
      </div>
    </>
  );
};

export default CalculatorIndex;
