import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Container, Button, Form, Accordion } from "react-bootstrap";
import CustomLazyLoad from "../../../Helper/CustomLazyLoad";
import Skeleton from "react-loading-skeleton";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-multi-lang";
import { useDispatch, useSelector } from "react-redux";
import { inflationCalculatorStart, inflationCalculatorSuccess } from "../../../../store/slices/CommonSlice";
import { ButtonLoader } from "../../../Helper/Loader";
import PieChart from "../PieChart";
import FAQIndex from "../FAQIndex";
import { Link } from "react-router-dom";
import formatNumberWithCommas from "../../../FormatNumber";

const CalculatorIndex = (props) => {
  const t = useTranslation("tax_calculator.inflation_calculator");
  const currentCostRef = useRef(null);
  const interestRef = useRef(null);
  const timePeriodRef = useRef(null);
  const dispatch = useDispatch();
  const formRef = useRef();
  const inflationCalculator = useSelector(
    (state) => state.common.inflationCalculator
  );

  useEffect(() => {
    const onChange = (evt, field) => {
      const customEvent = evt;
      switch (field) {
        case "current_cost":
          formRef.current.setFieldValue(
            "current_cost",
            customEvent.detail.value
          );
          formRef.current.submitForm();
          break;
        case "interest":
          formRef.current.setFieldValue(
            "rate_of_inflation",
            customEvent.detail.value
          );
          formRef.current.submitForm();
          break;
        case "timePeriod":
          formRef.current.setFieldValue(
            "time_period",
            customEvent.detail.value
          );
          formRef.current.submitForm();
          break;
      }
    };
    currentCostRef.current?.addEventListener("change", (evt) =>
      onChange(evt, "current_cost")
    );
    interestRef.current?.addEventListener("change", (evt) =>
      onChange(evt, "interest")
    );
    timePeriodRef.current?.addEventListener("change", (evt) =>
      onChange(evt, "timePeriod")
    );
    return () => {
      currentCostRef.current?.removeEventListener("change", onChange);
      interestRef.current?.removeEventListener("change", onChange);
      timePeriodRef.current?.removeEventListener("change", onChange);
    };
  }, []);

  useEffect(() => {
    // dispatch(inflationCalculatorStart(formRef.current.values));
    calculate(formRef.current.values);
  }, []);

  const onChangeInput = (event, field) => {
    const value = !isNaN(parseFloat(event.target.value))
      ? parseFloat(event.target.value)
      : "";
    switch (field) {
      case "current_cost":
        formRef.current.setFieldValue("current_cost", value);
        currentCostRef.current.value = value;
        break;
      case "interest":
        formRef.current.setFieldValue("rate_of_inflation", value);
        interestRef.current.value = value;
        break;
      case "timePeriod":
        formRef.current.setFieldValue("time_period", value);
        timePeriodRef.current.value = value;
        break;
    }
  };

  const handleSubmit = (values) => {
    // dispatch(inflationCalculatorStart(values));
    formRef.current.isValid && calculate(values);
  };

  const inflationSchema = Yup.object().shape({
    current_cost: Yup.string()
      .test(
        "min",
        t("inflation_calculator.invalid_min", { value: 1000 }),
        (value) => {
          return value >= 1;
        }
      )
      .test(
        "max",
        t("inflation_calculator.invalid_max", {
          value: 1000000,
          name: "Current cost",
        }),
        (value) => {
          return value <= 1000000;
        }
      )
      .required(t("inflation_calculator.required")),
    rate_of_inflation: Yup.string()
      .test(
        "min",
        t("inflation_calculator.invalid_min", { value: 1 }),
        (value) => {
          return value >= 1;
        }
      )
      .test(
        "max",
        t("inflation_calculator.invalid_max", {
          value: 50,
          name: "Rate of Inflation",
        }),
        (value) => {
          return value <= 50;
        }
      )
      .required(t("inflation_calculator.required")),
    time_period: Yup.string()
      .test(
        "min",
        t("inflation_calculator.invalid_min", { value: 1000 }),
        (value) => {
          return value >= 1;
        }
      )
      .test(
        "max",
        t("inflation_calculator.invalid_max", {
          value: 40,
          name: "Time period",
        }),
        (value) => {
          return value <= 40;
        }
      )
      .required(t("inflation_calculator.required")),
  });

  const calculate = (values) => {
    const { current_cost, rate_of_inflation, time_period } = values;

    if (!current_cost || !rate_of_inflation || !time_period) {
      return;
    }

    const rate = rate_of_inflation / 100;
    const future_cost = current_cost * Math.pow((1 + rate), time_period);
    const cost_increase = future_cost - current_cost;

    const rounded_future_cost = Math.round(future_cost);
    const rounded_cost_increase = Math.round(cost_increase);

    const result = {
      future_cost: rounded_future_cost,
      cost_increase: rounded_cost_increase
    };

    dispatch(inflationCalculatorSuccess(result));
  };

  return (
    <>
      <div className="single-tax-calculator">
        <div className="sm-padding single-tax-calculator-sec">
          <Container>
            <div className="single-tax-calculator-box">
              <div className="single-tax-calculator-header">
                <Row>
                  <Col md={6}>
                    <div className="d-flex other-calculator-btn-sec">
                      <Link
                        to="/tax-calculator"
                        className="other-calculator-btn"
                      >
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            enableBackground="new 0 0 512 512"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="#fff"
                              d="M10.957 12.354a.5.5 0 010-.708l4.586-4.585a1.5 1.5 0 00-2.121-2.122L8.836 9.525a3.505 3.505 0 000 4.95l4.586 4.586a1.5 1.5 0 002.121-2.122z"
                              data-original="#000000"
                            ></path>
                          </svg>
                        </span>
                        <span>Other Calculators</span>
                      </Link>
                    </div>
                    <div className="single-tax-calculator-info-sec">
                      <h1>{t("heading")}</h1>
                      <p>{t("content")}</p>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="single-tax-calculator-header-img-sec">
                      <CustomLazyLoad
                        className="single-tax-calculator-header-img"
                        src={
                          window.location.origin +
                          "/img/single-tax-calculator-header-img.png"
                        }
                        type="image/svg"
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="single-tax-calculator-form-box  line-stright calculator-space-lg">
              <Row className="align-items-center g-5">
                <Col md={6} lg={6}>
                  <Formik
                    initialValues={{
                      current_cost: 1000,
                      rate_of_inflation: 2,
                      time_period: 2,
                    }}
                    innerRef={formRef}
                    validationSchema={inflationSchema}
                    onSubmit={handleSubmit}
                  >
                    {({ setFieldValue, values, resetForm, setFieldError, errors, isValid, submitForm }) => (
                      <FORM className="single-tax-calculator-form">
                        <div className="single-tax-calculator-form-card">
                          <div className="single-tax-calculator-form-header-sec">
                            <h4>{t("current_cost.text")}</h4>
                            <Form.Group
                              className="mb-0"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Field
                                className="form-control"
                                name="current_cost"
                                placeholder={t("current_cost.placeholder")}
                                type="number"
                                onKeyDown={(e) => {
                                  if (
                                    !/[0-9]|Backspace|ArrowLeft|ArrowRight|Delete|ArrowUp|ArrowDown|Tab/.test(
                                      e.key
                                    ) &&
                                    !(
                                      e.ctrlKey &&
                                      (e.key === "v" ||
                                        e.key === "c" ||
                                        e.key === "x" ||
                                        e.key === "a")
                                    )
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                                onInput={(e) => {
                                  if (e.target.value < 0) {
                                    e.target.value = 0;
                                  }
                                  onChangeInput(e, "current_cost");
                                  submitForm()
                                }}
                              />
                            </Form.Group>
                          </div>
                          <ErrorMessage
                            component={"div"}
                            name="current_cost"
                            className="errorMsg"
                          />
                          <div className="tax-calculator-input-slider-sec">
                            <tc-range-slider
                              ref={currentCostRef}
                              value={currentCostRef}
                              mousewheel-disabled="true"
                              min={1}
                              max={1000000}
                              slider-width="100%"
                              slider-bg-fill="#2F5CFF"
                              generate-labels="true"
                              round="0"
                            ></tc-range-slider>
                          </div>
                        </div>
                        <div className="single-tax-calculator-form-card">
                          <div className="single-tax-calculator-form-header-sec">
                            <h4>{t("rate_of_inflation.text")}</h4>
                            <Form.Group
                              className="mb-0"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Field
                                className="form-control"
                                name="rate_of_inflation"
                                placeholder={t("rate_of_inflation.placeholder")}
                                type="number"
                                onKeyDown={(e) => {
                                  if (
                                    !/[0-9.]|Backspace|ArrowLeft|ArrowRight|Delete|ArrowUp|ArrowDown|Tab/.test(
                                      e.key
                                    ) ||
                                    (e.key === "-" &&
                                      e.target.selectionStart !== 0)
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                                onInput={(e) => {
                                  if (e.target.value < 0) {
                                    e.target.value = 0;
                                  }
                                  onChangeInput(e, "interest");
                                  submitForm()
                                }}
                              />
                            </Form.Group>
                          </div>
                          <ErrorMessage
                            component={"div"}
                            name="rate_of_inflation"
                            className="errorMsg"
                          />
                          <div className="tax-calculator-input-slider-sec">
                            <tc-range-slider
                              ref={interestRef}
                              value={interestRef}
                              mousewheel-disabled="true"
                              min={1}
                              max={50}
                              slider-width="100%"
                              slider-bg-fill="#2F5CFF"
                              generate-labels="true"
                              round="1"
                            ></tc-range-slider>
                          </div>
                        </div>
                        <div className="single-tax-calculator-form-card">
                          <div className="single-tax-calculator-form-header-sec">
                            <h4>{t("time_period.text")}</h4>
                            <Form.Group
                              className="mb-0"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Field
                                className="form-control"
                                name="time_period"
                                placeholder={t("time_period.placeholder")}
                                type="number"
                                onKeyDown={(e) => {
                                  if (
                                    !/[0-9]|Backspace|ArrowLeft|ArrowRight|Delete|ArrowUp|ArrowDown|Tab/.test(
                                      e.key
                                    ) &&
                                    !(
                                      e.ctrlKey &&
                                      (e.key === "v" ||
                                        e.key === "c" ||
                                        e.key === "x" ||
                                        e.key === "a")
                                    )
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                                onInput={(e) => {
                                  if (e.target.value < 0) {
                                    e.target.value = 0;
                                  }
                                  onChangeInput(e, "timePeriod");
                                  submitForm()
                                }}
                              />
                            </Form.Group>
                          </div>
                          <ErrorMessage
                            component={"div"}
                            name="time_period"
                            className="errorMsg"
                          />
                          <div className="tax-calculator-input-slider-sec">
                            <tc-range-slider
                              ref={timePeriodRef}
                              value={timePeriodRef}
                              mousewheel-disabled="true"
                              min={1}
                              max={30}
                              slider-width="100%"
                              slider-bg-fill="#2F5CFF"
                              generate-labels="true"
                              round="0"
                            ></tc-range-slider>
                          </div>
                        </div>
                        {/* <div className="d-flex justify-content-end">
                          <Button
                            type="submit"
                            className="default-primary-btn"
                            disabled={inflationCalculator.buttonDisable}
                          >
                            {inflationCalculator.buttonDisable ? (
                              <ButtonLoader />
                            ) : (
                              t("calculate")
                            )}
                          </Button>
                        </div> */}
                      </FORM>
                    )}
                  </Formik>
                </Col>
                <Col md={6} lg={6}>
                  {inflationCalculator.loading ? (
                    <div className="single-calculator-info-frame">
                      <div className="pie-chart-skelton">
                        <div className="skeleton-wrapper">
                          <Skeleton
                            borderRadius={130}
                            width={230}
                            height={230}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                        </div>
                        <div className="mt-4">
                          <Skeleton
                            width={250}
                            height={15}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                        </div>
                      </div>

                      <div className="calculator-info-details-card">
                        <div className="calculator-details-frame">
                          <Skeleton
                            width={200}
                            height={20}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                          <Skeleton
                            width={130}
                            height={20}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                        </div>
                        <div className="calculator-details-frame">
                          <Skeleton
                            width={200}
                            height={20}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                          <Skeleton
                            width={130}
                            height={20}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                        </div>
                        <hr />
                        <div className="calculator-details-frame top-bottom-line">
                          <Skeleton
                            width={200}
                            height={20}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                          <Skeleton
                            width={130}
                            height={20}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                        </div>
                        <hr />
                      </div>
                    </div>
                  ) : (
                    Object.keys(inflationCalculator.data).length > 0 && (
                      <div className="single-calculator-info-frame">
                        <div className="pie-chart-sec">
                          <PieChart
                            name={[t("current_cost.text"), t("cost_increase")]}
                            data={[
                              formRef.current?.values.current_cost,
                              inflationCalculator.data.cost_increase,
                            ]}
                          />
                        </div>
                        <div className="calculator-info-details-card">
                          <div className="calculator-details-frame">
                            <p>
                              {" "}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                x="0"
                                y="0"
                                enableBackground="new 0 0 29.107 29.107"
                                version="1.1"
                                viewBox="0 0 29.107 29.107"
                                xmlSpace="preserve"
                                width="20"
                                height="20"
                                fill="#2A334F"
                              >
                                <path d="M14.554 0C6.561 0 0 6.562 0 14.552c0 7.996 6.561 14.555 14.554 14.555 7.996 0 14.553-6.559 14.553-14.555C29.106 6.562 22.55 0 14.554 0z"></path>
                              </svg>
                              <span>{t("current_cost.text")}</span>
                            </p>
                            <h3>

                              {formRef.current?.values.current_cost && formatNumberWithCommas(
                                formRef.current?.values.current_cost
                              )}
                            </h3>
                          </div>
                          <div className="calculator-details-frame">
                            <p>
                              {" "}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                x="0"
                                y="0"
                                enableBackground="new 0 0 29.107 29.107"
                                version="1.1"
                                viewBox="0 0 29.107 29.107"
                                xmlSpace="preserve"
                                width="20"
                                height="20"
                                fill="#2F5CFF"
                              >
                                <path d="M14.554 0C6.561 0 0 6.562 0 14.552c0 7.996 6.561 14.555 14.554 14.555 7.996 0 14.553-6.559 14.553-14.555C29.106 6.562 22.55 0 14.554 0z"></path>
                              </svg>
                              <span>{t("cost_increase")}</span>
                            </p>
                            <h3>
                              {inflationCalculator.data.cost_increase && formatNumberWithCommas(
                                inflationCalculator.data.cost_increase
                              )}
                            </h3>
                          </div>
                          <hr />
                          <div className="calculator-details-frame top-bottom-line">
                            <p className="d-inline">{t("future_cost")}</p>
                            <h3>
                              {inflationCalculator.data.future_cost && formatNumberWithCommas(
                                inflationCalculator.data.future_cost
                              )}
                            </h3>
                          </div>
                          <hr />
                        </div>
                      </div>
                    )
                  )}
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <Container>
          <Row>
            <Col lg={12}>
              <div className="static-page-card">
                <div className="pricacy-policy-info">
                  <h4>Description</h4>
                  <p>
                    Inflation reflects the rate at which prices for certain
                    goods and services increase over a given period, indicating
                    a decrease in purchasing power.
                  </p>
                  <h4>What is Inflation?</h4>
                  <p>
                    Inflation refers to the increase in the price levels of
                    everyday goods and services, essentially showing a decrease
                    in the purchasing power of money.
                  </p>
                  <p>
                    There are primarily two key metrics used to measure
                    inflation:
                  </p>

                  <ul>
                    <li>
                      <h4>Consumer Price Index (CPI) </h4>- This index measures
                      changes in retail prices experienced by consumers.
                    </li>
                    <li>
                      <h4>Wholesale Price Index (WPI)</h4>
                      This index tracks price changes at the wholesale level.
                    </li>
                  </ul>
                  <p>
                    The WPI focuses on the price movement of goods sold and
                    traded in bulk, while the CPI assesses the price changes of
                    goods and services bought by households.
                  </p>
                  <h4>How Does Inflation Impact Your Savings?</h4>
                  <p>
                    Investors save and invest with the primary goal of growing
                    their funds over time to meet future financial needs and
                    obligations.
                  </p>
                  <p>
                    Despite well-laid plans, external factors like inflation can
                    significantly affect savings. Inflation causes prices of
                    goods and services to rise, eroding the purchasing power of
                    money. While investors often park their money in bank
                    accounts to accrue interest, sometimes the interest earned
                    is insufficient to offset the effects of inflation.
                    Moreover, the impact of inflation largely depends on the
                    types of investments made. Returns on investments can
                    fluctuate based on the level of inflation.
                  </p>
                  <h4>Strategies to Mitigate the Effects of Inflation</h4>
                  <p>
                    Inflation is a constant in the economy, and while government
                    policies aim to control it, some impacts remain beyond
                    control. Individuals should, therefore, prepare in advance
                    to handle inflation.
                  </p>
                  <p>
                    Through strategic investments and sound financial planning
                    that targets inflation-beating returns, one can safeguard
                    their financial health. Investing in stocks and mutual
                    funds, for example, has historically provided returns that
                    exceed inflation rates. However, it's crucial to recognize
                    that these investments carry risks that could potentially
                    lead to losses.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <FAQIndex
          data={[
            {
              question: "What formula is used to calculate inflation?",
              answer:
                "Inflation is typically calculated using the Consumer Price Index (CPI). The formula is: [(CPI in the current year - CPI in the previous year) / CPI in the previous year] x 100. This formula gives the percentage increase in prices over a year, indicating the rate of inflation.",
            },
            {
              question: "What does price inflation mean?",
              answer:
                "Price inflation refers to the rate at which the general level of prices for goods and services rises over a period, leading to a decrease in the purchasing power of currency. It is a measure of the decline in the value of money as it relates to consumer purchasing power.",
            },
            {
              question: "What is Deflation?",
              answer:
                "Deflation is the opposite of inflation, characterized by a decrease in the prices of goods and services. It occurs when the inflation rate falls below 0%, leading to an increase in the value of money and enhancing purchasing power.",
            },
            {
              question: "What are the primary causes of inflation?",
              answer:
                "The main causes of inflation include demand-pull inflation, where demand for goods and services exceeds their supply; cost-push inflation, where the costs of production increase, pushing prices up; and monetary inflation, which occurs when there is an increase in the money supply.",
            },
            {
              question: "What are the types of inflation?",
              answer:
                "There are several types of inflation, including: 1.  Demand-pull inflation: Caused by an increase in demand across the economy that outstrips supply. 2. Cost-push inflation: Triggered by a rise in prices due to increases in the costs of production and inputs like labor and raw materials. 3.Built-in inflation: Stemming from adaptive expectations, where people expect current inflation rates to continue in the future, which influences wage negotiations and price settings.",
            },
          ]}
        />
      </div>
    </>
  );
};

export default CalculatorIndex;
