import React, { useState } from "react";
import { Row, Col, Container, Accordion } from "react-bootstrap";

const FAQIndex = (props) => {
  const [activeKey, setActiveKey] = useState(null);

  const handleAccordionToggle = (eventKey) => {
    setActiveKey(eventKey === activeKey ? null : eventKey);
  };

  return (
    <div className="faq-sec sm-padding">
      <Container>
        <Row className="justify-content-md-center">
          <Col md={8} lg={9} className="text-center">
            <div className="section-title">
              <h2>Frequently Asked Questions</h2>
            </div>
          </Col>
        </Row>
        <div className="faq-box">
          <Row className="justify-content-md-center">
            <Col md={12} xl={9}>
              <Accordion
                className="faq-accordion"
                activeKey={activeKey}
                onSelect={handleAccordionToggle}
              >
                {props.data && props.data.length > 0
                  ? props.data.map((item, i) => (
                      <Accordion.Item
                        key={i}
                        eventKey={i + 1}
                        onClick={() => handleAccordionToggle(i + 1)}
                      >
                        <Accordion.Header>{item.question}</Accordion.Header>
                        <Accordion.Body>{item.answer}</Accordion.Body>
                      </Accordion.Item>
                    ))
                  : null}
              </Accordion>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default FAQIndex;
