import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Container, Button, Form, Accordion } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-multi-lang";
import { useDispatch, useSelector } from "react-redux";
import {
  apyCalculatorStart,
  apyCalculatorSuccess,
} from "../../../../store/slices/CommonSlice";
import { ButtonLoader } from "../../../Helper/Loader";
import PieChart from "../PieChart";
import CustomLazyLoad from "../../../Helper/CustomLazyLoad";
import FAQIndex from "../FAQIndex";
import { Link } from "react-router-dom";
import formatNumberWithCommas from "../../../FormatNumber";
import Papa from "papaparse";
import { getErrorNotificationMessage } from "../../../../components/Helper/NotificationMessage";

const CalculatorIndex = (props) => {
  const t = useTranslation("tax_calculator");
  const joiningAgeRef = useRef(null);
  const desiredMonthlyPensionRef = useRef(null);
  const dispatch = useDispatch();
  const formRef = useRef();
  const apyCalculator = useSelector((state) => state.common.apyCalculator);
  const [data, setData] = useState([]);

  useEffect(() => {
    const onChange = (evt, field) => {
      const customEvent = evt;
      switch (field) {
        case "joiningAge":
          formRef.current.setFieldValue(
            "joining_age",
            customEvent.detail.value
          );
          break;
        case "desiredMonthlyPension":
          formRef.current.setFieldValue(
            "desired_monthly_pension",
            customEvent.detail.value
          );
          break;
      }
      setTimeout(() => {
        formRef.current.submitForm();
      }, 1);
    };
    joiningAgeRef.current?.addEventListener("change", (evt) =>
      onChange(evt, "joiningAge")
    );
    desiredMonthlyPensionRef.current?.addEventListener("change", (evt) =>
      onChange(evt, "desiredMonthlyPension")
    );
    return () => {
      joiningAgeRef.current?.removeEventListener("change", onChange);
      desiredMonthlyPensionRef.current?.removeEventListener("change", onChange);
    };
  }, []);

  useEffect(() => {
    if (data.length > 0) {
      calculate({ ...formRef.current.values, data: data });
      // dispatch(apyCalculatorStart(formRef.current.values));
    }
  }, [data]);

  const onChangeInput = (event, field) => {
    const value = !isNaN(parseInt(event.target.value))
      ? parseInt(event.target.value)
      : "";
    switch (field) {
      case "joiningAge":
        formRef.current.setFieldValue("joining_age", value);
        joiningAgeRef.current.value = value;
        break;
      case "desiredMonthlyPension":
        formRef.current.setFieldValue("desired_monthly_pension", value);
        desiredMonthlyPensionRef.current.value = value;
        break;
    }
    setTimeout(() => {
      formRef.current.submitForm();
    }, 1);
  };

  const handleSubmit = (values) => {
    formRef.current.isValid && calculate({ ...values, data: data });
    // dispatch(apyCalculatorStart({ ...values, data: data }));
  };

  const apySchema = Yup.object().shape({
    joining_age: Yup.string()
      .test("min", t("apy_calculator.invalid_min", { value: 18 }), (value) => {
        return value >= 18;
      })
      .test(
        "max",
        t("apy_calculator.invalid_max", {
          value: 39,
          name: "Joining age",
        }),
        (value) => {
          return value <= 39;
        }
      )
      .required(t("apy_calculator.required")),
    desired_monthly_pension: Yup.number()
      .oneOf([1000, 2000, 3000, 4000, 5000], t("apy_calculator.invalid"))
      .required(t("apy_calculator.required")),
  });

  useEffect(() => {
    fetch("/apy_calculator.csv")
      .then((response) => response.text())
      .then((csv) => {
        Papa.parse(csv, {
          header: true,
          complete: (results) => {
            setData(results.data);
          },
        });
      });
  }, []);

  const calculate = (values) => {
    const { joining_age, desired_monthly_pension, data } = values;
    const columnName = desired_monthly_pension + "_monthly";
    const row = data.find((row) => parseInt(row.age, 10) === joining_age);
    if (!row) {
      getErrorNotificationMessage(
        "Something Went Wrong please try again later"
      );
      return dispatch(
        apyCalculatorSuccess({
          monthly_contribution: 0,
          invested_amount: 0,
          investment_duration: 0,
        })
      );
    }
    const monthlyContribution = parseFloat(row[columnName]);
    const investedAmount = monthlyContribution * 12 * (60 - joining_age);
    const investmentDuration = 60 - joining_age;

    return dispatch(
      apyCalculatorSuccess({
        monthly_contribution: monthlyContribution,
        invested_amount: investedAmount,
        investment_duration: investmentDuration,
      })
    );
  };

  return (
    <>
      <div className="single-tax-calculator">
        <div className="sm-padding single-tax-calculator-sec">
          <Container>
            <div className="single-tax-calculator-box">
              <div className="single-tax-calculator-header">
                <Row>
                  <Col md={6}>
                    <div className="d-flex other-calculator-btn-sec">
                      <Link
                        to="/tax-calculator"
                        className="other-calculator-btn"
                      >
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            enableBackground="new 0 0 512 512"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="#fff"
                              d="M10.957 12.354a.5.5 0 010-.708l4.586-4.585a1.5 1.5 0 00-2.121-2.122L8.836 9.525a3.505 3.505 0 000 4.95l4.586 4.586a1.5 1.5 0 002.121-2.122z"
                              data-original="#000000"
                            ></path>
                          </svg>
                        </span>
                        <span>Other Calculators</span>
                      </Link>
                    </div>
                    <div className="single-tax-calculator-info-sec">
                      <h1>{t("apy_calculator.heading")}</h1>
                      <p>{t("apy_calculator.content")}</p>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="single-tax-calculator-header-img-sec">
                      <CustomLazyLoad
                        className="single-tax-calculator-header-img"
                        src={
                          window.location.origin +
                          "/img/single-tax-calculator-header-img.png"
                        }
                        type="image/svg"
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="single-tax-calculator-form-box  line-stright">
              <Row className="align-items-center g-5">
                <Col md={6} lg={6}>
                  <Formik
                    initialValues={{
                      joining_age: 25,
                      desired_monthly_pension: 1000,
                    }}
                    innerRef={formRef}
                    validationSchema={apySchema}
                    onSubmit={handleSubmit}
                  >
                    {({
                      setFieldValue,
                      values,
                      resetForm,
                      isValid,
                      submitForm,
                    }) => (
                      <FORM className="single-tax-calculator-form">
                        <div className="single-tax-calculator-form-card">
                          <div className="single-tax-calculator-form-header-sec">
                            <h4>{t("apy_calculator.your_joining_age.text")}</h4>
                            <Form.Group
                              className="mb-0"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Field
                                className="form-control"
                                name="joining_age"
                                placeholder={t(
                                  "apy_calculator.your_joining_age.placeholder"
                                )}
                                type="number"
                                onKeyDown={(e) => {
                                  if (
                                    !/[0-9]|Backspace|ArrowLeft|ArrowRight|Delete|ArrowUp|ArrowDown|Tab/.test(
                                      e.key
                                    ) &&
                                    !(
                                      e.ctrlKey &&
                                      (e.key === "v" ||
                                        e.key === "c" ||
                                        e.key === "x" ||
                                        e.key === "a")
                                    )
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                                onInput={(e) => {
                                  if (e.target.value < 0) {
                                    e.target.value = 0;
                                  }
                                  onChangeInput(e, "joiningAge");
                                }}
                              />
                            </Form.Group>
                          </div>
                          <ErrorMessage
                            component={"div"}
                            name="joining_age"
                            className="errorMsg"
                          />
                          <div className="tax-calculator-input-slider-sec">
                            <tc-range-slider
                              ref={joiningAgeRef}
                              value={joiningAgeRef}
                              mousewheel-disabled="true"
                              min={18}
                              max={39}
                              slider-width="100%"
                              slider-bg-fill="#2F5CFF"
                              generate-labels="true"
                              round="0"
                            ></tc-range-slider>
                          </div>
                        </div>
                        <div className="single-tax-calculator-form-card">
                          <div className="single-tax-calculator-form-header-sec">
                            <h4>
                              {t("apy_calculator.desired_monthly_pension.text")}
                            </h4>
                            <Form.Group
                              className="mb-0"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Field
                                className="form-control"
                                name="desired_monthly_pension"
                                placeholder={t(
                                  "apy_calculator.desired_monthly_pension.placeholder"
                                )}
                                type="number"
                                onChange={(e) =>
                                  onChangeInput(e, "desiredMonthlyPension")
                                }
                                onKeyDown={(e) => {
                                  if (
                                    !/[0-9]|Backspace|ArrowLeft|ArrowRight|Delete|ArrowUp|ArrowDown|Tab/.test(
                                      e.key
                                    ) &&
                                    !(
                                      e.ctrlKey &&
                                      (e.key === "v" ||
                                        e.key === "c" ||
                                        e.key === "x" ||
                                        e.key === "a")
                                    )
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                                onInput={(e) => {
                                  if (e.target.value < 0) {
                                    e.target.value = 0;
                                  }
                                }}
                              />
                            </Form.Group>
                          </div>
                          <ErrorMessage
                            component={"div"}
                            name="desired_monthly_pension"
                            className="errorMsg"
                          />
                          <div className="tax-calculator-input-slider-sec">
                            <tc-range-slider
                              ref={desiredMonthlyPensionRef}
                              value={desiredMonthlyPensionRef}
                              mousewheel-disabled="true"
                              step="1000"
                              min={1000}
                              max={5000}
                              slider-width="100%"
                              slider-bg-fill="#2F5CFF"
                              generate-labels="true"
                              round="0"
                            ></tc-range-slider>
                          </div>
                        </div>
                        {/* <div className="d-flex justify-content-end">
                          <Button
                            type="submit"
                            className="default-primary-btn"
                            disabled={apyCalculator.buttonDisable}
                          >
                            {apyCalculator.buttonDisable ? (
                              <ButtonLoader />
                            ) : (
                              t("apy_calculator.calculate")
                            )}
                          </Button>
                        </div> */}
                      </FORM>
                    )}
                  </Formik>
                </Col>
                <Col md={6} lg={6}>
                  {apyCalculator.loading ? (
                    <div className="single-calculator-info-frame">
                      <div className="pie-chart-skelton">
                        <div className="skeleton-wrapper">
                          <Skeleton
                            borderRadius={130}
                            width={230}
                            height={230}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                        </div>
                        <div className="mt-4">
                          <Skeleton
                            width={250}
                            height={15}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                        </div>
                      </div>

                      <div className="calculator-info-details-card">
                        <div className="calculator-details-frame">
                          <Skeleton
                            width={200}
                            height={20}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                          <Skeleton
                            width={130}
                            height={20}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                        </div>
                        <div className="calculator-details-frame">
                          <Skeleton
                            width={200}
                            height={20}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                          <Skeleton
                            width={130}
                            height={20}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                        </div>
                        <hr />
                        <div className="calculator-details-frame top-bottom-line">
                          <Skeleton
                            width={200}
                            height={20}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                          <Skeleton
                            width={130}
                            height={20}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                        </div>
                        <hr />
                      </div>
                    </div>
                  ) : (
                    Object.keys(apyCalculator.data).length > 0 && (
                      <div className="single-calculator-info-frame">
                        <div className="pie-chart-sec">
                          <PieChart
                            name={[
                              t("apy_calculator.monthly_investment"),
                              t("apy_calculator.investment_duration"),
                            ]}
                            data={[
                              apyCalculator.data.monthly_contribution,
                              apyCalculator.data.investment_duration,
                            ]}
                          />
                        </div>
                        <div className="calculator-info-details-card">
                          <div className="calculator-details-frame">
                            <p>
                              {" "}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                x="0"
                                y="0"
                                enableBackground="new 0 0 29.107 29.107"
                                version="1.1"
                                viewBox="0 0 29.107 29.107"
                                xmlSpace="preserve"
                                width="20"
                                height="20"
                                fill="#2A334F"
                              >
                                <path d="M14.554 0C6.561 0 0 6.562 0 14.552c0 7.996 6.561 14.555 14.554 14.555 7.996 0 14.553-6.559 14.553-14.555C29.106 6.562 22.55 0 14.554 0z"></path>
                              </svg>
                              <span>
                                {t("apy_calculator.monthly_investment")}
                              </span>
                            </p>
                            <h3>
                              {formatNumberWithCommas(
                                apyCalculator.data.monthly_contribution
                              )}
                            </h3>
                          </div>
                          <div className="calculator-details-frame">
                            <p>
                              {" "}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                x="0"
                                y="0"
                                enableBackground="new 0 0 29.107 29.107"
                                version="1.1"
                                viewBox="0 0 29.107 29.107"
                                xmlSpace="preserve"
                                width="20"
                                height="20"
                                fill="#2F5CFF"
                              >
                                <path d="M14.554 0C6.561 0 0 6.562 0 14.552c0 7.996 6.561 14.555 14.554 14.555 7.996 0 14.553-6.559 14.553-14.555C29.106 6.562 22.55 0 14.554 0z"></path>
                              </svg>
                              <span>
                                {t("apy_calculator.investment_duration")}
                              </span>
                            </p>
                            <h3>{apyCalculator.data.investment_duration} </h3>
                          </div>
                          <hr />
                          <div className="calculator-details-frame top-bottom-line">
                            <p className="d-inline">
                              {t("apy_calculator.total_amount")}
                            </p>
                            <h3>
                              {formatNumberWithCommas(
                                apyCalculator.data.invested_amount
                              )}
                            </h3>
                          </div>
                        </div>
                      </div>
                    )
                  )}
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <Container>
          <Row>
            <Col lg={12}>
              <div className="static-page-card">
                <div className="pricacy-policy-info">
                  <h4>Description</h4>
                  <p>
                    The Atal Pension Yojana (APY) was launched during the Union
                    Budget of 2015-2016, primarily targeting workers in the
                    unorganized sector, though it is also available to
                    individuals in the private sector who do not have access to
                    pension benefits.
                  </p>
                  <p>
                    Upon reaching the age of 60, an individual enrolled in APY
                    can opt for a monthly pension of Rs. 1,000, Rs. 2,000, Rs.
                    4,000, or Rs. 5,000. The amount of pension received depends
                    on their contributions to the scheme and the age at which
                    they began their subscription. Additionally, in the event of
                    the account holder's death, the pension can be transferred
                    to their spouse. The funds collected under the Atal scheme
                    are managed by the Pension Funds Regulatory Authority of
                    India.
                  </p>
                </div>
                <div className="pricacy-policy-info">
                  <h4>What is an APY Calculator, and How Can It Assist You?</h4>
                  <p>
                    The APY Calculator is a tool designed to help you calculate
                    the actual interest earned on investments in the Atal
                    Pension Yojana. This calculator can help you determine the
                    desired pension amount and the appropriate age to enroll in
                    the scheme. Additionally, it provides instant feedback on
                    the interest that would have been accrued on your pension
                    contributions, helping you to avoid common errors.
                    Therefore, this tool is extremely useful for both public and
                    private sector employees considering their pension options.
                  </p>
                  <h4>The APY Calculator offers several benefits:</h4>
                  <ul>
                    <li>
                      <h4>Instant Estimation:</h4>t quickly provides an estimate
                      of the final pension amount you can expect.
                    </li>
                    <li>
                      <h4>Investment Timing: </h4>
                      It helps determine the best time to start investing in the
                      scheme.
                    </li>
                    <li>
                      <h4>Financial Planning:</h4>
                      It aids in assessing whether the pension scheme fits your
                      financial objectives.
                    </li>
                  </ul>
                  <h4>
                    Advantages of Using the Atal Pension Yojana Calculator
                  </h4>
                  <p>
                    The Atal Pension Yojana (APY) is tailored for all Indian
                    citizens, particularly targeting those in the unorganized
                    sector without formal pension coverage, to encourage them to
                    save for retirement. Using the APY Calculator offers several
                    benefits:
                  </p>
                  <ul>
                    <li>
                      <h4>Goal Alignment: </h4>It helps you understand how the
                      plan can effectively meet your financial goals.
                    </li>
                    <li>
                      <h4>Instant Results: </h4>The calculator provides
                      immediate insights into the potential outcomes of your
                      investment.
                    </li>
                    <li>
                      <h4>Customization:</h4>You can input data according to
                      your specific preferences to get tailored results.
                    </li>
                    <li>
                      <h4>Self-Sufficiency:</h4>It allows you to obtain
                      estimates on your own, eliminating the need for external
                      assistance.
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <FAQIndex
          data={[
            {
              question: "Is there a fee to use the APY calculator?",
              answer:
                "No, the APY calculator is available for free. There is no charge for using this tool to estimate your pension benefits.",
            },
            {
              question: "How do I open an APY account?",
              answer:
                "To set up an APY account, visit your nearest bank branch where you hold a savings account. You'll need to fill out the APY registration form and submit the necessary documents. The account will be linked to your savings account for automatic monthly contributions.",
            },
            {
              question:
                "What are the consequences if my bank account does not have sufficient funds for the APY contribution?",
              answer:
                "If your bank account doesn’t maintain the minimum balance required for the APY contribution, the payment may fail, which could lead to penalties or even suspension of your pension account until the dues are cleared.",
            },
            {
              question:
                "Can I enroll in APY if I am already a participant in the National Pension Scheme (NPS)?",
              answer:
                "Yes, participation in the NPS does not restrict you from opening an APY account. You are eligible to subscribe to both pensions schemes simultaneously.",
            },
            {
              question:
                "What is the difference between APY and an interest rate?",
              answer:
                "APY (Annual Percentage Yield) refers to the total amount of interest you will earn in one year, taking into account the effect of compounding. In contrast, an interest rate typically refers to the nominal rate charged or paid on an investment or loan, not accounting for compounding within that year. APY provides a more accurate representation of the actual return on investment when compounding is involved.",
            },
          ]}
        />
      </div>
    </>
  );
};

export default CalculatorIndex;
