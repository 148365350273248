import React, { useState, useEffect, useRef } from "react";
import { Button, Form } from "react-bootstrap";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-multi-lang";
import { useDispatch, useSelector } from "react-redux";
import { brokerageCalculatorSuccess } from "../../../../store/slices/CommonSlice";
import { ButtonLoader } from "../../../Helper/Loader";

const BrokerageForm = (props) => {
  const t = useTranslation("tax_calculator");
  const buyRef = useRef(null);
  const sellRef = useRef(null);
  const formRef = useRef();
  const dispatch = useDispatch();
  const brokerageCalculator = useSelector(
    (state) => state.common.brokerageCalculator
  );
  formRef.current?.setFieldValue("trade_type", props.traderType);

  useEffect(() => {
    const onChange = (event, field) => {
      const customEvent = event;
      switch (field) {
        case "buy":
          formRef.current.setFieldValue("buy_price", customEvent.detail.value);
          break;
        case "sell":
          formRef.current.setFieldValue("sell_price", customEvent.detail.value);
          break;
      }
      setTimeout(() => { formRef.current.submitForm() }, 1)
    };
    buyRef.current?.addEventListener("change", (event) =>
      onChange(event, "buy")
    );
    sellRef.current?.addEventListener("change", (event) =>
      onChange(event, "sell")
    );
    return () => {
      buyRef.current?.removeEventListener("change", onChange);
      sellRef.current?.removeEventListener("change", onChange);
    };
  }, []);

  useEffect(() => {
    calculate({
      ...formRef.current.values,
      trade_type: props.traderType,
    })

  }, [props.tab]);

  const onChangeInput = (event, field) => {
    const value = !isNaN(parseInt(event.target.value))
      ? parseInt(event.target.value)
      : "";
    switch (field) {
      case "buy":
        formRef.current.setFieldValue("buy_price", value);
        buyRef.current.value = value;
        break;
      case "quantity":
        formRef.current.setFieldValue("quantity", value);
        break;
      case "sell":
        formRef.current.setFieldValue("sell_price", value);
        sellRef.current.value = value;
        break;
      case "broker":
        formRef.current.setFieldValue("broker_charges", value);
        break;
    }
    setTimeout(() => { formRef.current.submitForm() }, 1)
  };

  const handleSubmit = (values) => {
    switch (props.traderType) {
      case "delivery":
        props.setDeliveryBrokerage(formRef.current?.values.broker_charges);
        break;
      case "intraday":
        props.setIntradayBrokerage(formRef.current?.values.broker_charges);
        break;
      case "future":
        props.setFoBrokerage(formRef.current?.values.broker_charges);
        break;
      case "option":
        props.setFoBrokerage(formRef.current?.values.broker_charges);
        break;
    }
    //dispatch(brokerageCalculatorStart(values));
    formRef.current.isValid && calculate(values)
  };

  const ppfSchema = Yup.object().shape({
    buy_price: Yup.number()
      .max(
        100000,
        t("brokerage_calculator.invalid_max", {
          value: 100000,
          name: "Buy price",
        })
      )
      .required(t("brokerage_calculator.required")),
    sell_price: Yup.number()
      .max(
        100000,
        t("brokerage_calculator.invalid_max", {
          value: 100000,
          name: "Sell price",
        })
      )
      .required(t("brokerage_calculator.required")),
    quantity: Yup.number()
      .max(
        10000,
        t("brokerage_calculator.invalid_max", {
          value: 10000,
          name: "Quantity",
        })
      )
      .required(t("brokerage_calculator.required")),
    trade_type: Yup.string().required(t("brokerage_calculator.required")),
    broker_charges: Yup.number()
      .max(
        10000,
        t("brokerage_calculator.invalid_max", {
          value: 10000,
          name: "Brokerage charges",
        })
      )
      .required(t("brokerage_calculator.required")),
  });

  const calculate = (values) => {
    const { buy_price, sell_price, quantity, broker_charges } = values
    let turnover = buy_price * quantity + sell_price * quantity;
    let profitAndLoss = (sell_price - buy_price) * quantity;
    let brokerage = broker_charges;
    let stt = props.sttAmount * turnover;
    stt = Math.round(stt * 100) / 100;
    let exchangeTransactionCharges = 0.0000325 * (buy_price * quantity + sell_price * quantity);
    exchangeTransactionCharges = Math.round(exchangeTransactionCharges * 100) / 100;
    let sebiCharges = 0.000001 * (buy_price * quantity + sell_price * quantity);
    sebiCharges = Math.round(sebiCharges * 100) / 100;
    let gst = 0.18 * (brokerage + exchangeTransactionCharges + sebiCharges);
    gst = Math.round(gst * 100) / 100;
    let stampDuty = 0.00015 * buy_price * quantity;
    stampDuty = Math.round(stampDuty * 100) / 100;
    let totalCharges = brokerage + stt + exchangeTransactionCharges + gst + sebiCharges + stampDuty;
    totalCharges = Math.round(totalCharges * 100) / 100;
    let nonBrokerageCharges = stt + exchangeTransactionCharges + gst + sebiCharges + stampDuty;
    nonBrokerageCharges = Math.round(nonBrokerageCharges * 100) / 100;
    let totalProfitLoss = profitAndLoss - totalCharges;
    totalProfitLoss = Math.round(totalProfitLoss * 100) / 100;

    return dispatch(brokerageCalculatorSuccess({
      turnover: turnover,
      profit_and_loss: profitAndLoss,
      total_charges: totalCharges,
      non_brorkerage_charges: nonBrokerageCharges,
      total_profit_loss: totalProfitLoss,
      brokerage: brokerage,
      stt: stt,
      exchange_transaction_charges: exchangeTransactionCharges,
      gst: gst,
      sebi_charges: sebiCharges,
      stamp_duty: stampDuty
    }));
  }

  return (
    <>
      <Formik
        initialValues={{
          trade_type: null,
          buy_price: 1000,
          sell_price: 15,
          quantity: 15,
          broker_charges: 40,
        }}
        innerRef={formRef}
        validationSchema={ppfSchema}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue, values, resetForm, submitForm, isValid }) => (
          <FORM className="calculator-tabs-body">
            <div className="single-tax-calculator-form">
              <div className="single-tax-calculator-form-card">
                <div className="single-tax-calculator-form-header-sec">
                  <h4>{t("brokerage_calculator.buy.text")}</h4>
                  <Form.Group
                    className="mb-0"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Field
                      className="form-control"
                      name="buy_price"
                      type="number"
                      placeholder={t("brokerage_calculator.buy.text")}
                      onKeyDown={(e) => {
                        if (
                          !/[0-9]|Backspace|ArrowLeft|ArrowRight|Delete|ArrowUp|ArrowDown|Tab/.test(
                            e.key
                          ) ||
                          (e.key === "-" && e.target.selectionStart !== 0)
                        ) {
                          e.preventDefault();
                        }
                      }}
                      onInput={(e) => {
                        if (e.target.value < 0) {
                          e.target.value = 0;
                        }
                        onChangeInput(e, "buy");
                      }}
                    />
                  </Form.Group>
                </div>
                <ErrorMessage
                  component={"div"}
                  name="buy_price"
                  className="errorMsg"
                />
                <div className="tax-calculator-input-slider-sec">
                  <tc-range-slider
                    ref={buyRef}
                    value={buyRef}
                    mousewheel-disabled="true"
                    min={0}
                    max={100000}
                    slider-width="100%"
                    slider-bg-fill="#2F5CFF"
                    generate-labels="true"
                    round="0"
                  ></tc-range-slider>
                </div>
              </div>
              <div className="single-tax-calculator-form-card">
                <div className="single-tax-calculator-form-header-sec">
                  <h4>{t("brokerage_calculator.sell.text")}</h4>
                  <Form.Group
                    className="mb-0"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Field
                      className="form-control"
                      name="sell_price"
                      type="number"
                      placeholder={t("brokerage_calculator.sell.placeholder")}
                      onKeyDown={(e) => {
                        if (
                          !/[0-9]|Backspace|ArrowLeft|ArrowRight|Delete|ArrowUp|ArrowDown|Tab/.test(
                            e.key
                          ) ||
                          (e.key === "-" && e.target.selectionStart !== 0)
                        ) {
                          e.preventDefault();
                        }
                      }}
                      onInput={(e) => {
                        if (e.target.value < 0) {
                          e.target.value = 0;
                        }
                        onChangeInput(e, "sell");
                      }}
                    />
                  </Form.Group>
                </div>
                <ErrorMessage
                  component={"div"}
                  name="sell_price"
                  className="errorMsg"
                />
                <div className="tax-calculator-input-slider-sec">
                  <tc-range-slider
                    ref={sellRef}
                    value={sellRef}
                    mousewheel-disabled="true"
                    min={0}
                    max={100000}
                    slider-width="100%"
                    slider-bg-fill="#2F5CFF"
                    generate-labels="true"
                    round="0"
                  ></tc-range-slider>
                </div>
              </div>
              <div className="single-tax-calculator-form-card">
                <div className="single-tax-calculator-form-header-sec">
                  {props.tab === "fo" ? (
                    <>
                      <div className="yes-or-no-radio-btn-sec ">
                        <div class="pretty p-default p-round p-smooth">
                          <input
                            type="radio"
                            value="future"
                            checked={
                              formRef.current?.values.trade_type == "future"
                            }
                            onClick={(e) => {
                              props.setTraderType(e.target.value);
                              formRef.current.setFieldValue(
                                "trade_type",
                                e.target.value
                              );
                              formRef.current.submitForm()
                            }}
                          />
                          <div class="state p-primary-o">
                            <label>
                              {t("brokerage_calculator.qty_future")}
                            </label>
                          </div>
                        </div>
                        <div class="pretty p-default p-round p-smooth">
                          <input
                            type="radio"
                            value="option"
                            checked={
                              formRef.current?.values.trade_type == "option"
                            }
                            onClick={(e) => {
                              props.setTraderType(e.target.value);
                              formRef.current.setFieldValue(
                                "trade_type",
                                e.target.value
                              );
                              formRef.current.submitForm()
                            }}
                          />
                          <div class="state p-primary-o">
                            <label>
                              {t("brokerage_calculator.qty_option")}
                            </label>
                          </div>
                        </div>
                        <ErrorMessage
                          component={"div"}
                          name="trade_type"
                          className="errorMsg"
                        />
                      </div>
                    </>
                  ) : (
                    <h4>{t("brokerage_calculator.qty.text")}</h4>
                  )}

                  <Form.Group
                    className="mb-0"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Field
                      className="form-control"
                      name="quantity"
                      type="number"
                      placeholder={t("brokerage_calculator.qty.placeholder")}
                      onChange={(e) => onChangeInput(e, "quantity")}
                      onKeyDown={(e) => {
                        if (
                          !/[0-9]|Backspace|ArrowLeft|ArrowRight|Delete|ArrowUp|ArrowDown|Tab/.test(
                            e.key
                          ) ||
                          (e.key === "-" && e.target.selectionStart !== 0)
                        ) {
                          e.preventDefault();
                        }
                      }}
                      onInput={(e) => {
                        if (e.target.value < 0) {
                          e.target.value = 0;
                        }
                      }}
                    />
                  </Form.Group>
                </div>
                <ErrorMessage
                  component={"div"}
                  name="quantity"
                  className="errorMsg"
                />
              </div>
              <div className="single-tax-calculator-form-card mt-4">
                <div className="single-tax-calculator-form-header-sec">
                  <h4>{t("brokerage_calculator.brokerage_charges.text")}</h4>
                  <Form.Group
                    className="mb-0"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Field
                      className="form-control"
                      name="broker_charges"
                      type="number"
                      placeholder={t(
                        "brokerage_calculator.brokerage_charges.placeholder"
                      )}
                      onChange={(e) => onChangeInput(e, "broker")}
                      onKeyDown={(e) => {
                        if (
                          !/[0-9]|Backspace|ArrowLeft|ArrowRight|Delete|ArrowUp|ArrowDown|Tab/.test(
                            e.key
                          ) ||
                          (e.key === "-" && e.target.selectionStart !== 0)
                        ) {
                          e.preventDefault();
                        }
                      }}
                      onInput={(e) => {
                        if (e.target.value < 0) {
                          e.target.value = 0;
                        }
                      }}
                    />
                  </Form.Group>
                </div>
                <ErrorMessage
                  component={"div"}
                  name="broker_charges"
                  className="errorMsg"
                />
              </div>
              {/* <div className="d-flex justify-content-end mt-3">
                <Button
                  type="submit"
                  className="default-primary-btn"
                  disabled={brokerageCalculator.buttonDisable}
                >
                  {brokerageCalculator.buttonDisable ? (
                    <ButtonLoader />
                  ) : (
                    t("brokerage_calculator.calculate")
                  )}
                </Button>
              </div> */}
            </div>
          </FORM>
        )}
      </Formik>
    </>
  );
};

export default BrokerageForm;
