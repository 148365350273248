import { takeLatest, all } from "redux-saga/effects";
import { getCommonSaga, getSettingsSaga } from "../sagaUtils";
import { contactFormFailure, contactFormSuccess, settingsJsonFailure, settingsJsonSuccess } from "../slices/ContactSlice";

function* settingsJsonAPI(action) {
  yield getSettingsSaga({
    apiUrl: "get_settings_json",
    payload: action.payload,
    successNotify: false,
    success: settingsJsonSuccess,
    failure: settingsJsonFailure,
  });
}

function* contactFormAPI(action) {
    yield getCommonSaga({
      apiUrl: "contact",
      payload: action.payload,
      successCallback: false,
      successNotify: false,
      success: contactFormSuccess,
      failure: contactFormFailure,
    });
  }

export default function* ContactSaga() {
  yield all([yield takeLatest("contact/settingsJsonStart", settingsJsonAPI)]);
  yield all([yield takeLatest("contact/contactFormStart", contactFormAPI)]);
}
