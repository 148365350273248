import React, { useEffect, useState, useRef } from "react";
import { Container, Row, Col, Image, Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import "toolcool-range-slider/dist/plugins/tcrs-storage.min.js";
import "toolcool-range-slider";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import { useTranslation } from "react-multi-lang";

const IncomeDetails = (props) => {
  const t = useTranslation("tax_calculator");
  const sliderRef = useRef(null);

  useEffect(() => {
    const slider = sliderRef.current;
    const onChange = (evt) => {
      const customEvent = evt;
      props.onSliderChange(customEvent.detail.value);
    };
    slider?.addEventListener("change", onChange);
    return () => {
      slider?.removeEventListener("change", onChange);
    };
  }, []);

  const handleInputChange = (event) => {
    const value = event.target.value;
    props.onSliderChange(value);
    if (sliderRef.current) {
      sliderRef.current.value = value;
    }
  };

  return (
    <>
      <div className="tax-calculator-basic-details-sec">
        <div className="tax-calculator-basic-details-box">
          <div className="tax-calculator-basic-details-header-sec">
            <h4>
              {t("income_details.gross_salary_income")} <span>Annually</span>
            </h4>
            <Form.Group className="mb-0" controlId="exampleForm.ControlInput1">
              <Field
                className="form-control"
                name="basic_salary"
                placeholder="Enter amount"
                type="number"
                onKeyDown={(e) => {
                  if (
                    !/[0-9]|Backspace|ArrowLeft|ArrowRight|Delete|ArrowUp|ArrowDown|Tab/.test(
                      e.key
                    ) &&
                    !(
                      e.ctrlKey &&
                      (e.key === "v" ||
                        e.key === "c" ||
                        e.key === "x" ||
                        e.key === "a")
                    )
                  ) {
                    e.preventDefault();
                  }
                }}
                onInput={(e) => {
                  if (!e.target.value == "") {
                    e.target.value = e.target.value <= 0 ? 1 : e.target.value;
                  }
                  handleInputChange(e);
                }}
              />
            </Form.Group>
          </div>
          <ErrorMessage
            component={"div"}
            name="basic_salary"
            className="errorMsg"
          />
          <div className="tax-calculator-input-slider-sec">
            <tc-range-slider
              ref={sliderRef}
              value={props.sliderValue}
              mousewheel-disabled="true"
              min={1}
              max={10000000}
              slider-width="100%"
              slider-bg-fill="#2F5CFF"
              generate-labels="true"
              round="0"
            />
          </div>
          <div className="tax-calculator-basic-details-body-sec">
            <h4>{t("income_details.other_income_details")}</h4>
            <Row>
              <Col md={6}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>
                    {t("income_details.dearness_allowances")}
                  </Form.Label>
                  <Field
                    type="number"
                    className="form-control"
                    name="dearness_allownces"
                    placeholder="Enter dear allowance"
                    onKeyDown={(e) => {
                      if (
                        !/[0-9]|Backspace|ArrowLeft|ArrowRight|Delete|ArrowUp|ArrowDown|Tab/.test(
                          e.key
                        ) &&
                        !(
                          e.ctrlKey &&
                          (e.key === "v" ||
                            e.key === "c" ||
                            e.key === "x" ||
                            e.key === "a")
                        )
                      ) {
                        e.preventDefault();
                      }
                    }}
                    onInput={(e) => {
                      if (e.target.value < 0) {
                        e.target.value = 0;
                      }
                    }}
                  />
                  <ErrorMessage
                    component={"div"}
                    name="dearness_allownces"
                    className="errorMsg"
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>{t("income_details.hra")}</Form.Label>
                  <Field
                    type="number"
                    className="form-control"
                    name="hra"
                    placeholder="Enter HRA"
                    onKeyDown={(e) => {
                      if (
                        !/[0-9]|Backspace|ArrowLeft|ArrowRight|Delete|ArrowUp|ArrowDown|Tab/.test(
                          e.key
                        ) &&
                        !(
                          e.ctrlKey &&
                          (e.key === "v" ||
                            e.key === "c" ||
                            e.key === "x" ||
                            e.key === "a")
                        )
                      ) {
                        e.preventDefault();
                      }
                    }}
                    onInput={(e) => {
                      if (e.target.value <= 0 && e.target.value.length > 1) {
                        e.target.value = 0;
                      }
                    }}
                  />
                  <ErrorMessage
                    component={"div"}
                    name="hra"
                    className="errorMsg"
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>{t("income_details.actual_hra")}</Form.Label>
                  <Field
                    type="number"
                    className="form-control"
                    name="hra_actual"
                    placeholder="Enter actual HRA"
                    onKeyDown={(e) => {
                      if (
                        !/[0-9]|Backspace|ArrowLeft|ArrowRight|Delete|ArrowUp|ArrowDown|Tab/.test(
                          e.key
                        ) &&
                        !(
                          e.ctrlKey &&
                          (e.key === "v" ||
                            e.key === "c" ||
                            e.key === "x" ||
                            e.key === "a")
                        )
                      ) {
                        e.preventDefault();
                      }
                    }}
                    onInput={(e) => {
                      if (e.target.value <= 0 && e.target.value.length > 1) {
                        e.target.value = 0;
                      }
                    }}
                  />
                  <ErrorMessage
                    component={"div"}
                    name="hra_actual"
                    className="errorMsg"
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>
                    {t("income_details.special_allowance")}
                  </Form.Label>
                  <Field
                    type="number"
                    className="form-control"
                    name="special_allowance"
                    placeholder="Enter special allowance "
                    onKeyDown={(e) => {
                      if (
                        !/[0-9]|Backspace|ArrowLeft|ArrowRight|Delete|ArrowUp|ArrowDown|Tab/.test(
                          e.key
                        ) &&
                        !(
                          e.ctrlKey &&
                          (e.key === "v" ||
                            e.key === "c" ||
                            e.key === "x" ||
                            e.key === "a")
                        )
                      ) {
                        e.preventDefault();
                      }
                    }}
                    onInput={(e) => {
                      if (e.target.value <= 0 && e.target.value.length > 1) {
                        e.target.value = 0;
                      }
                    }}
                  />
                  <ErrorMessage
                    component={"div"}
                    name="special_allowance"
                    className="errorMsg"
                  />
                </Form.Group>
              </Col>
            </Row>
          </div>
          <div className="tax-calculator-basic-detail-next">
            <Button className="default-primary-btn" type="submit">
              {t("income_details.next")}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default IncomeDetails;
