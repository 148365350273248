import api from "../Environment";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../components/Helper/NotificationMessage";
import store from "./index";

export const getCommonSaga = async ({
  apiUrl,
  payload,
  success,
  failure,
  successCallback = false,
  failureCallback = false,
  successNotify = true,
  errorNotify = true,
}) => {
  try {
    const response = await api.postMethod(apiUrl, payload);
    if (response.data.success) {
      if (successCallback) success(response.data.data);
      else store.dispatch(success(response.data.data));
      if (successNotify) getSuccessNotificationMessage(response.data.message);
    } else {
      if (failureCallback) failure(response.data);
      else store.dispatch(failure(response.data));
      if (errorNotify) getErrorNotificationMessage(response.data.error);
    }
  } catch (error) {
    if (failureCallback) failure(error);
    else store.dispatch(failure(error));
    if (errorNotify) getErrorNotificationMessage(error.message);
  }
};

export const getSettingsSaga = async ({
  apiUrl,
  payload,
  success,
  failure,
  successCallback = false,
  failureCallback = false,
  successNotify = true,
  errorNotify = true,
}) => {
  try {
    const response = await api.getMethod(apiUrl, payload);
    if (response.data) {
      if (successCallback) success(response.data.data);
      else store.dispatch(success(response.data.data));
      if (successNotify) getSuccessNotificationMessage(response.data.message);
    } else {
      if (failureCallback) failure(response.data);
      else store.dispatch(failure(response.data));
      if (errorNotify) getErrorNotificationMessage(response.data.error);
    }
  } catch (error) {
    if (failureCallback) failure(error);
    else store.dispatch(failure(error));
    if (errorNotify) getErrorNotificationMessage(error.message);
  }
}

export const aiGetCommonSaga = async ({
  apiUrl,
  payload,
  success,
  failure,
  failureCallback = false,
  successCallback = false,
  errorNotify = true,
}) => {
  try {
    const response = await api.aiPostMethod(apiUrl, payload);
    if (response.status == 200) {
      if (successCallback) success(response.data);
      else store.dispatch(success(response.data));
    } else {
      if (failureCallback) failure(response.data);
      else store.dispatch(failure(response.data));
      if (errorNotify) getErrorNotificationMessage(response.data.error);
    }
  } catch (error) {
    if (failureCallback) failure(error);
    else store.dispatch(failure(error));
    if (errorNotify) getErrorNotificationMessage(error.message);
  }
};

export const calculatorGetCommonSaga = async ({
  apiUrl,
  payload,
  success,
  failure,
  failureCallback = false,
  successCallback = false,
  errorNotify = true,
}) => {
  try {
    const response = await api.calculatorPostMethod(apiUrl, payload);
    if (response.status == 200) {
      if (successCallback) success(response.data);
      else store.dispatch(success(response.data));
    } else {
      if (failureCallback) failure(response.data);
      else store.dispatch(failure(response.data));
      if (errorNotify) getErrorNotificationMessage(response.data.error);
    }
  } catch (error) {
    if (failureCallback) failure(error);
    else store.dispatch(failure(error));
    if (errorNotify) getErrorNotificationMessage(error.message);
  }
};
