import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Container, Button, Form, Accordion } from "react-bootstrap";
import CustomLazyLoad from "../../../Helper/CustomLazyLoad";
import Skeleton from "react-loading-skeleton";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-multi-lang";
import { useDispatch, useSelector } from "react-redux";
import { emiCalculatorSuccess, siCalculatorStart, siCalculatorSuccess } from "../../../../store/slices/CommonSlice";
import { ButtonLoader } from "../../../Helper/Loader";
import PieChart from "../PieChart";
import FAQIndex from "../FAQIndex";
import { Link } from "react-router-dom";
import formatNumberWithCommas from "../../../FormatNumber";

const CalculatorIndex = (props) => {
  const t = useTranslation("tax_calculator");
  const principleRef = useRef(null);
  const timePeriodRef = useRef(null);
  const interestPeriodRef = useRef(null);
  const formRef = useRef();
  const dispatch = useDispatch();
  const siCalculator = useSelector((state) => state.common.siCalculator);

  useEffect(() => {
    const onChange = (event, field) => {
      const customEvent = event;
      switch (field) {
        case "principal":
          formRef.current.setFieldValue("principal", customEvent.detail.value);
          break;
        case "timePeriod":
          formRef.current.setFieldValue(
            "time_period",
            customEvent.detail.value
          );
          break;
        case "interest":
          formRef.current.setFieldValue(
            "rate_of_interest",
            customEvent.detail.value
          );
          break;
      }
      setTimeout(() => { formRef.current.submitForm() }, 1)
    };
    principleRef.current?.addEventListener("change", (event) =>
      onChange(event, "principal")
    );
    timePeriodRef.current?.addEventListener("change", (event) =>
      onChange(event, "timePeriod")
    );
    interestPeriodRef.current?.addEventListener("change", (event) =>
      onChange(event, "interest")
    );
    return () => {
      principleRef.current?.removeEventListener("change", onChange);
      timePeriodRef.current?.removeEventListener("change", onChange);
      interestPeriodRef.current?.removeEventListener("change", onChange);
    };
  }, []);

  useEffect(() => {
    calculate(formRef.current.values);
  }, []);

  const onChangeInput = (event, field) => {
    const value =
      !isNaN(parseInt(event.target.value)) == true
        ? parseFloat(event.target.value)
        : "";
    switch (field) {
      case "principal":
        formRef.current.setFieldValue("principal", value);
        principleRef.current.value = value;
        break;
      case "timePeriod":
        formRef.current.setFieldValue("time_period", value);
        timePeriodRef.current.value = value;
        break;
      case "interest":
        formRef.current.setFieldValue("rate_of_interest", value);
        interestPeriodRef.current.value = value;
        break;
    }
    setTimeout(() => { formRef.current.submitForm() }, 1)
  };

  const handleSubmit = (values) => {
    formRef.current.isValid && calculate(values)
    // dispatch(siCalculatorStart(values));
  };

  const ppfSchema = Yup.object().shape({
    principal: Yup.string()
      .test("min", t("si_calculator.invalid_min", { value: 500 }), (value) => {
        return value >= 500;
      })
      .test(
        "max",
        t("si_calculator.invalid_max", {
          value: 10000000,
          name: "Monthly investment",
        }),
        (value) => {
          return value <= 10000000;
        }
      )
      .required(t("si_calculator.required")),
    rate_of_interest: Yup.string()
      .test("min", t("si_calculator.invalid_min", { value: 1 }), (value) => {
        return value >= 1;
      })
      .test(
        "max",
        t("si_calculator.invalid_max", {
          value: 50,
          name: "Rate of interest",
        }),
        (value) => {
          return value <= 50;
        }
      )
      .required(t("si_calculator.required")),
    time_period: Yup.string()
      .test("min", t("si_calculator.invalid_min", { value: 1 }), (value) => {
        return value >= 1;
      })
      .test(
        "max",
        t("si_calculator.invalid_max", { value: 30, name: "Time period" }),
        (value) => {
          return value <= 30;
        }
      )
      .required(t("si_calculator.required")),
  });

  const calculate = (values) => {
    const { rate_of_interest, time_period, principal } = values
    let simple_interest = (principal * rate_of_interest * time_period) / 100
    let total_amount = principal + simple_interest
    const newResult = {
      principal: Math.round(principal),
      simple_interest: Math.round(simple_interest),
      total_amount: Math.round(total_amount),
    };
    dispatch(siCalculatorSuccess(newResult))
  }

  return (
    <>
      <div className="single-tax-calculator">
        <div className="sm-padding single-tax-calculator-sec">
          <Container>
            <div className="single-tax-calculator-box">
              <div className="single-tax-calculator-header">
                <Row>
                  <Col md={6}>
                    <div className="single-tax-calculator-info-sec">
                      <div className="d-flex other-calculator-btn-sec">
                        <Link
                          to="/tax-calculator"
                          className="other-calculator-btn"
                        >
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              enableBackground="new 0 0 512 512"
                              viewBox="0 0 24 24"
                            >
                              <path
                                fill="#fff"
                                d="M10.957 12.354a.5.5 0 010-.708l4.586-4.585a1.5 1.5 0 00-2.121-2.122L8.836 9.525a3.505 3.505 0 000 4.95l4.586 4.586a1.5 1.5 0 002.121-2.122z"
                                data-original="#000000"
                              ></path>
                            </svg>
                          </span>
                          <span>Other Calculators</span>
                        </Link>
                      </div>
                      <h1>{t("si_calculator.heading")}</h1>
                      <p>{t("si_calculator.content")}</p>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="single-tax-calculator-header-img-sec">
                      <CustomLazyLoad
                        className="single-tax-calculator-header-img"
                        src={
                          window.location.origin +
                          "/img/single-tax-calculator-header-img.png"
                        }
                        type="image/svg"
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="single-tax-calculator-form-box  line-stright calculator-space-lg">
              <Row className="align-items-center g-5">
                <Col md={6} lg={6}>
                  <Formik
                    initialValues={{
                      principal: 1000,
                      rate_of_interest: 15,
                      time_period: 2,
                    }}
                    innerRef={formRef}
                    validationSchema={ppfSchema}
                    onSubmit={handleSubmit}
                  >
                    {({ setFieldValue, values, resetForm, submitForm, isValid }) => (
                      <FORM className="single-tax-calculator-form">
                        <div className="single-tax-calculator-form-card">
                          <div className="single-tax-calculator-form-header-sec">
                            <h4>{t("si_calculator.principal.text")}</h4>
                            <Form.Group
                              className="mb-0"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Field
                                className="form-control"
                                name="principal"
                                placeholder={t(
                                  "si_calculator.principal.placeholder"
                                )}
                                type="number"
                                onKeyDown={(e) => {
                                  if (
                                    !/[0-9]|Backspace|ArrowLeft|ArrowRight|Delete|ArrowUp|ArrowDown|Tab/.test(
                                      e.key
                                    ) &&
                                    !(
                                      e.ctrlKey &&
                                      (e.key === "v" ||
                                        e.key === "c" ||
                                        e.key === "x" ||
                                        e.key === "a")
                                    )
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                                onInput={(e) => {
                                  if (e.target.value < 0) {
                                    e.target.value = 0;
                                  }
                                  onChangeInput(e, "principal");
                                }}
                              />
                            </Form.Group>
                          </div>
                          <ErrorMessage
                            component={"div"}
                            name="principal"
                            className="errorMsg"
                          />
                          <div className="tax-calculator-input-slider-sec">
                            <tc-range-slider
                              ref={principleRef}
                              value={principleRef}
                              mousewheel-disabled="true"
                              min={1000}
                              max={10000000}
                              slider-width="100%"
                              slider-bg-fill="#2F5CFF"
                              generate-labels="true"
                              round="0"
                            ></tc-range-slider>
                          </div>
                        </div>
                        <div className="single-tax-calculator-form-card">
                          <div className="single-tax-calculator-form-header-sec">
                            <h4>{t("si_calculator.time_period.text")}</h4>
                            <Form.Group
                              className="mb-0"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Field
                                className="form-control"
                                name="time_period"
                                placeholder={t(
                                  "si_calculator.time_period.placeholder"
                                )}
                                type="number"
                                onKeyDown={(e) => {
                                  if (
                                    !/[0-9]|Backspace|ArrowLeft|ArrowRight|Delete|ArrowUp|ArrowDown|Tab/.test(
                                      e.key
                                    ) &&
                                    !(
                                      e.ctrlKey &&
                                      (e.key === "v" ||
                                        e.key === "c" ||
                                        e.key === "x" ||
                                        e.key === "a")
                                    )
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                                onInput={(e) => {
                                  if (e.target.value < 0) {
                                    e.target.value = 0;
                                  }
                                  onChangeInput(e, "timePeriod");
                                }}
                              />
                            </Form.Group>
                          </div>
                          <ErrorMessage
                            component={"div"}
                            name="time_period"
                            className="errorMsg"
                          />
                          <div className="tax-calculator-input-slider-sec">
                            <tc-range-slider
                              ref={timePeriodRef}
                              value={timePeriodRef}
                              mousewheel-disabled="true"
                              min={1}
                              max={30}
                              slider-width="100%"
                              slider-bg-fill="#2F5CFF"
                              generate-labels="true"
                              round="0"
                            ></tc-range-slider>
                          </div>
                        </div>
                        <div className="single-tax-calculator-form-card">
                          <div className="single-tax-calculator-form-header-sec">
                            <h4>{t("si_calculator.rate_of_interest.text")}</h4>
                            <Form.Group
                              className="mb-0"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Field
                                className="form-control"
                                name="rate_of_interest"
                                placeholder={t(
                                  "si_calculator.rate_of_interest.placeholder"
                                )}
                                type="number"
                                onKeyDown={(e) => {
                                  if (
                                    !/[0-9.]|Backspace|ArrowLeft|ArrowRight|Delete|ArrowUp|ArrowDown|Tab/.test(
                                      e.key
                                    ) ||
                                    (e.key === "-" &&
                                      e.target.selectionStart !== 0)
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                                onInput={(e) => {
                                  if (e.target.value < 0) {
                                    e.target.value = 0;
                                  }
                                  onChangeInput(e, "interest");
                                }}
                              />
                            </Form.Group>
                          </div>
                          <ErrorMessage
                            component={"div"}
                            name="rate_of_interest"
                            className="errorMsg"
                          />
                        </div>
                        <div className="tax-calculator-input-slider-sec">
                          <tc-range-slider
                            ref={interestPeriodRef}
                            value={interestPeriodRef}
                            mousewheel-disabled="true"
                            min={1}
                            max={50}
                            slider-width="100%"
                            slider-bg-fill="#2F5CFF"
                            generate-labels="true"
                            round="1"
                          ></tc-range-slider>
                        </div>
                        {/* <div className="d-flex justify-content-end mt-4">
                          <Button
                            type="submit"
                            className="default-primary-btn"
                            disabled={siCalculator.buttonDisable}
                          >
                            {siCalculator.buttonDisable ? (
                              <ButtonLoader />
                            ) : (
                              t("si_calculator.calculate")
                            )}
                          </Button>
                        </div> */}
                      </FORM>
                    )}
                  </Formik>
                </Col>
                <Col md={6} lg={6}>
                  {siCalculator.loading ? (
                    <div className="single-calculator-info-frame">
                      <div className="pie-chart-skelton">
                        <div className="skeleton-wrapper">
                          <Skeleton
                            borderRadius={130}
                            width={230}
                            height={230}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                        </div>
                        <div className="mt-4">
                          <Skeleton
                            width={250}
                            height={15}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                        </div>
                      </div>

                      <div className="calculator-info-details-card">
                        <div className="calculator-details-frame">
                          <Skeleton
                            width={200}
                            height={20}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                          <Skeleton
                            width={130}
                            height={20}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                        </div>
                        <div className="calculator-details-frame">
                          <Skeleton
                            width={200}
                            height={20}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                          <Skeleton
                            width={130}
                            height={20}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                        </div>
                        <hr />
                        <div className="calculator-details-frame top-bottom-line">
                          <Skeleton
                            width={200}
                            height={20}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                          <Skeleton
                            width={130}
                            height={20}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                        </div>
                        <hr />
                      </div>
                    </div>
                  ) : (
                    Object.keys(siCalculator.data).length > 0 && (
                      <div className="single-calculator-info-frame">
                        <div className="pie-chart-sec">
                          <PieChart
                            name={[
                              t("si_calculator.principal_amount"),
                              t("si_calculator.total_interest"),
                            ]}
                            data={[
                              siCalculator.data.principal,
                              siCalculator.data.simple_interest,
                            ]}
                          />
                        </div>
                        <div className="calculator-info-details-card">
                          <div className="calculator-details-frame">
                            <p>
                              {" "}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                x="0"
                                y="0"
                                enableBackground="new 0 0 29.107 29.107"
                                version="1.1"
                                viewBox="0 0 29.107 29.107"
                                xmlSpace="preserve"
                                width="20"
                                height="20"
                                fill="#2A334F"
                              >
                                <path d="M14.554 0C6.561 0 0 6.562 0 14.552c0 7.996 6.561 14.555 14.554 14.555 7.996 0 14.553-6.559 14.553-14.555C29.106 6.562 22.55 0 14.554 0z"></path>
                              </svg>
                              <span>{t("si_calculator.principal_amount")}</span>
                            </p>
                            <h3>
                              {formatNumberWithCommas(
                                siCalculator.data.principal
                              )}
                            </h3>
                          </div>
                          <div className="calculator-details-frame">
                            <p>
                              {" "}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                x="0"
                                y="0"
                                enableBackground="new 0 0 29.107 29.107"
                                version="1.1"
                                viewBox="0 0 29.107 29.107"
                                xmlSpace="preserve"
                                width="20"
                                height="20"
                                fill="#2F5CFF"
                              >
                                <path d="M14.554 0C6.561 0 0 6.562 0 14.552c0 7.996 6.561 14.555 14.554 14.555 7.996 0 14.553-6.559 14.553-14.555C29.106 6.562 22.55 0 14.554 0z"></path>
                              </svg>
                              <span>{t("si_calculator.total_interest")}</span>
                            </p>
                            <h3>
                              {formatNumberWithCommas(
                                siCalculator.data.simple_interest
                              )}
                            </h3>
                          </div>
                          <hr />
                          <div className="calculator-details-frame top-bottom-line">
                            <p className="d-inline">
                              {t("si_calculator.total_value")}
                            </p>
                            <h3>
                              {formatNumberWithCommas(
                                siCalculator.data.total_amount
                              )}
                            </h3>
                          </div>
                          <hr />
                        </div>
                      </div>
                    )
                  )}
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <Container>
          <Row>
            <Col lg={12}>
              <div className="static-page-card">
                <div className="pricacy-policy-info">
                  <h4>Description</h4>
                  <p>
                    Returns on an investment are typically calculated as the
                    gains accrued on the interest earned. These gains can be
                    calculated using either compound or simple interest methods,
                    where interest is applied to the principal amount.
                  </p>
                  <p>
                    Understanding the total gain you will receive at the end of
                    a specific period, based on the interest rate, is crucial.
                    If your investment accrues interest on a simple interest
                    basis, a simple interest calculator can be invaluable. Such
                    calculators make it easy to determine the total funds you
                    will generate by the time of maturity.
                  </p>
                </div>
                <div className="pricacy-policy-info">
                  <h4>How to Use a Simple Interest Calculator?</h4>
                  <p>
                    If you're unsure about the gains you'll accumulate by the
                    end of the investment period, a simple interest calculator
                    is an essential tool. Simply input the principal amount, the
                    interest rate, and the time duration, and the calculator
                    will compute the results in seconds.
                  </p>
                </div>
                <div className="pricacy-policy-info">
                  <h4>Advantages of Using Simple Interest Calculators</h4>
                  <p>
                    Relying on manual calculations for interest often leads to
                    errors, such as paying off accrued interest before the
                    principal. This can complicate the computation of simple
                    interest on a sum. Here are some benefits of switching from
                    manual calculations to using an online simple interest
                    calculator:
                  </p>
                  <ul>
                    <li>
                      The calculator works with any currency unit, whether it's
                      dollars, euros, or any other, ensuring effective and
                      accurate computations.
                    </li>
                    <li>
                      For those looking to save time calculating interest over
                      different periods, using an online calculator efficiently
                      manages this task.
                    </li>
                    <li>
                      It provides numerous variables that help assess your total
                      investment, allowing you to understand the overall return
                      and maturity time, which aids in making informed financial
                      decisions.
                    </li>
                    <li>
                      The calculator is also useful in determining the present
                      value of money, helping you understand the current worth
                      of future returns.
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <FAQIndex
          data={[
            {
              question:
                "What information can I obtain using a Simple Interest (SI) calculator?",
              answer:
                "A Simple Interest calculator helps you determine the total interest you will earn on a principal amount over a specific period. It calculates the total amount you will receive upon the investment's maturity, helping you plan your finances accordingly",
            },
            {
              question:
                "Is it possible to adjust the currency unit in the SI calculator?",
              answer:
                "Yes, most Simple Interest calculators allow you to enter and calculate amounts in different currencies, making it versatile for international use.",
            },
            {
              question:
                "Does the interest rate on the principal amount vary over time with Simple Interest?",
              answer:
                "No, in Simple Interest calculations, the interest rate applied to the principal amount remains constant throughout the investment period, regardless of the time span involved.",
            },
          ]}
        />
      </div>
    </>
  );
};

export default CalculatorIndex;
