import React, { useEffect, useState } from "react";
import CanvasJSReact from "@canvasjs/react-charts";
import { useSelector } from "react-redux";

const PieChart = (props) => {
  var CanvasJSChart = CanvasJSReact.CanvasJSChart;
  const indexLabelFormatter = (e) => {
    const maxLength = 50; // Adjust the max length as needed
    let label = e.dataPoint.name;
    if (label.length > maxLength) {
      label = label.slice(0, maxLength) + "\n" + label.slice(maxLength);
    }
    return `${label}: ${e.dataPoint.y}`;
  };

  const options = {
    animationEnabled: true,
    backgroundColor: "transparent",
    data: [
      {
        type: "doughnut",
        showInLegend: true,
        indexLabel: "{name}: {y}",
        indexLabelFormatter: indexLabelFormatter,
        indexLabelMaxWidth: 100,
        yValueFormatString: "₹,#,###.",
        dataPoints: [
          { name: props.name[0], y: props.data[0], color: "#2A334F" },
          { name: props.name[1], y: props.data[1], color: "#2F5CFF" },
        ],
        indexLabelFontColor: "#fff",
      },
    ],
    legend: {
      fontColor: "white",
    },
    height: 300,
  };

  return (
    <>
      <div className="pie-chart-sec">
        <CanvasJSChart
          options={options}
          /* onRef={ref => this.chart = ref} */
        />
        {/*You can get reference to the chart instance as shown above using onRef. This allows you to access all chart properties and methods*/}
      </div>
    </>
  );
};

export default PieChart;
