import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Container, Accordion, Form, Button } from "react-bootstrap";
import CustomLazyLoad from "../../../Helper/CustomLazyLoad";
import Skeleton from "react-loading-skeleton";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-multi-lang";
import { useDispatch, useSelector } from "react-redux";
import { hraCalculatorStart, hraCalculatorSuccess } from "../../../../store/slices/CommonSlice";
import { ButtonLoader } from "../../../Helper/Loader";
import FAQIndex from "../FAQIndex";
import { Link } from "react-router-dom";
import formatNumberWithCommas from "../../../FormatNumber";

const CalculatorIndex = (props) => {
  const t = useTranslation("tax_calculator");
  const basicSalaryRef = useRef(null);
  const dearnessAllowncesRef = useRef(null);
  const hraRef = useRef(null);
  const actualHraRef = useRef(null);
  const dispatch = useDispatch();
  const formRef = useRef();
  const hraCalculator = useSelector((state) => state.common.hraCalculator);

  useEffect(() => {
    const onChange = (event, field) => {
      const customEvent = event;
      switch (field) {
        case "basicSalary":
          formRef.current.setFieldValue(
            "basic_salary",
            customEvent.detail.value
          );
          break;
        case "dearnessAllownces":
          formRef.current.setFieldValue(
            "dearness_allownces",
            customEvent.detail.value
          );
          break;
        case "hra":
          formRef.current.setFieldValue("hra", customEvent.detail.value);
          break;
        case "actualHra":
          formRef.current.setFieldValue("hra_actual", customEvent.detail.value);
          break;
      }
      setTimeout(() => { formRef.current.submitForm() }, 1)
    };
    basicSalaryRef.current?.addEventListener("change", (event) =>
      onChange(event, "basicSalary")
    );
    dearnessAllowncesRef.current?.addEventListener("change", (event) =>
      onChange(event, "dearnessAllownces")
    );
    hraRef.current?.addEventListener("change", (event) =>
      onChange(event, "hra")
    );
    actualHraRef.current?.addEventListener("change", (event) =>
      onChange(event, "actualHra")
    );
    return () => {
      basicSalaryRef.current?.removeEventListener("change", onChange);
      dearnessAllowncesRef.current?.removeEventListener("change", onChange);
      hraRef.current?.removeEventListener("change", onChange);
      actualHraRef.current?.removeEventListener("change", onChange);
    };
  }, []);

  useEffect(() => {
    calculate(formRef.current.values);
  }, []);

  const onChangeInput = (event, field) => {
    const value =
      !isNaN(parseInt(event.target.value)) == true
        ? parseInt(event.target.value)
        : "";
    switch (field) {
      case "basicSalary":
        formRef.current.setFieldValue("basic_salary", value);
        basicSalaryRef.current.value = value;
        break;
      case "dearnessAllownces":
        formRef.current.setFieldValue("dearness_allownces", value);
        dearnessAllowncesRef.current.value = value;
        break;
      case "hra":
        formRef.current.setFieldValue("hra", value);
        hraRef.current.value = value;
        break;
      case "actualHra":
        formRef.current.setFieldValue("hra_actual", value);
        actualHraRef.current.value = value;
        break;
    }
    setTimeout(() => { formRef.current.submitForm() }, 1)
  };

  const handleSubmit = (values) => {
    formRef.current.isValid && calculate(values)
    //dispatch(hraCalculatorStart(values));
  };

  const hraSchema = Yup.object().shape({
    basic_salary: Yup.string()
      .test(
        "min",
        t("hra_calculator.invalid_min", { value: 1000 }),
        (value) => {
          return value >= 1000;
        }
      )
      .test(
        "max",
        t("hra_calculator.invalid_max", {
          value: 10000000,
          name: "Basic salary",
        }),
        (value) => {
          return value <= 10000000;
        }
      )
      .required(t("hra_calculator.required")),
    dearness_allownces: Yup.string()
      .test(
        "max",
        t("hra_calculator.invalid_max", {
          value: 10000000,
          name: "Dearness allownces",
        }),
        (value) => {
          return value <= 10000000;
        }
      )
      .required(t("hra_calculator.required")),
    hra: Yup.string()
      .test(
        "min",
        t("hra_calculator.invalid_min", { value: 1000 }),
        (value) => {
          return value >= 1000;
        }
      )
      .test(
        "max",
        t("hra_calculator.invalid_max", { value: 10000000, name: "HRA" }),
        (value) => {
          return value <= 10000000;
        }
      )
      .required(t("hra_calculator.required")),
    hra_actual: Yup.string()
      .test(
        "min",
        t("hra_calculator.invalid_min", { value: 1000 }),
        (value) => {
          return value >= 1000;
        }
      )
      .test(
        "max",
        t("hra_calculator.invalid_max", {
          value: 10000000,
          name: "Total rent paid",
        }),
        (value) => {
          return value <= 10000000;
        }
      )
      .required(t("hra_calculator.required")),
  });

  const calculate = (values) => {

    const { basic_salary,
      location,
      dearness_allownces,
      hra,
      hra_actual } = values
    let hra_exemption;
    if (location === "Metro") {
      hra_exemption = Math.max(
        0,
        Math.ceil(
          Math.min(
            0.5 * (basic_salary + dearness_allownces),
            hra,
            hra_actual - 0.1 * (basic_salary + dearness_allownces)
          )
        )
      );
    } else {
      hra_exemption = Math.max(
        0,
        Math.ceil(
          Math.min(
            0.4 * (basic_salary + dearness_allownces),
            hra,
            hra_actual - 0.1 * (basic_salary + dearness_allownces)
          )
        )
      );
    }
    let taxable_hra = hra - hra_exemption

    return dispatch(hraCalculatorSuccess({ hra_exemption: hra_exemption, taxable_hra: taxable_hra }));
  }


  return (
    <>
      <div className="single-tax-calculator">
        <div className="sm-padding single-tax-calculator-sec">
          <Container>
            <div className="single-tax-calculator-box">
              <div className="single-tax-calculator-header">
                <Row>
                  <Col md={6}>
                    <div className="d-flex other-calculator-btn-sec">
                      <Link
                        to="/tax-calculator"
                        className="other-calculator-btn"
                      >
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            enableBackground="new 0 0 512 512"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="#fff"
                              d="M10.957 12.354a.5.5 0 010-.708l4.586-4.585a1.5 1.5 0 00-2.121-2.122L8.836 9.525a3.505 3.505 0 000 4.95l4.586 4.586a1.5 1.5 0 002.121-2.122z"
                              data-original="#000000"
                            ></path>
                          </svg>
                        </span>
                        <span>Other Calculators</span>
                      </Link>
                    </div>
                    <div className="single-tax-calculator-info-sec">
                      <h1>{t("hra_calculator.heading")}</h1>
                      <p>{t("hra_calculator.content")}</p>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="single-tax-calculator-header-img-sec">
                      <CustomLazyLoad
                        className="single-tax-calculator-header-img"
                        src={
                          window.location.origin +
                          "/img/single-tax-calculator-header-img.png"
                        }
                        type="image/svg"
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="single-tax-calculator-form-box line-stright line-bit-left">
              <Row className="align-items-center">
                <Col
                  md={12}
                  xl={6}
                  lg={12}
                  className="resp-margin-btm-small-lap"
                >
                  <Formik
                    initialValues={{
                      location: "Metro",
                      basic_salary: 1000,
                      dearness_allownces: 2,
                      hra: 1500,
                      hra_actual: 20000,
                    }}
                    innerRef={formRef}
                    validationSchema={hraSchema}
                    onSubmit={handleSubmit}
                  >
                    {({ setFieldValue, values, resetForm, submitForm, isValid }) => (
                      <FORM className="single-tax-calculator-form line-stright-right ">
                        <div className="single-tax-calculator-form-card">
                          <div className="single-tax-calculator-form-header-sec">
                            <h4>{t("hra_calculator.basic_salary.text")}</h4>
                            <Form.Group
                              className="mb-0"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Field
                                className="form-control"
                                name="basic_salary"
                                placeholder={t(
                                  "hra_calculator.basic_salary.placeholder"
                                )}
                                type="number"
                                onKeyDown={(e) => {
                                  if (
                                    !/[0-9]|Backspace|ArrowLeft|ArrowRight|Delete|ArrowUp|ArrowDown|Tab/.test(
                                      e.key
                                    ) &&
                                    !(
                                      e.ctrlKey &&
                                      (e.key === "v" ||
                                        e.key === "c" ||
                                        e.key === "x" ||
                                        e.key === "a")
                                    )
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                                onInput={(e) => {
                                  if (e.target.value < 0) {
                                    e.target.value = 0;
                                  }
                                  onChangeInput(e, "basicSalary");
                                }}
                              />
                            </Form.Group>
                          </div>
                          <ErrorMessage
                            component={"div"}
                            name="basic_salary"
                            className="errorMsg"
                          />
                          <div className="tax-calculator-input-slider-sec">
                            <tc-range-slider
                              ref={basicSalaryRef}
                              value={basicSalaryRef}
                              mousewheel-disabled="true"
                              min={1000}
                              max={10000000}
                              slider-width="100%"
                              slider-bg-fill="#2F5CFF"
                              generate-labels="true"
                              round="0"
                            ></tc-range-slider>
                          </div>
                        </div>
                        <div className="single-tax-calculator-form-card">
                          <div className="single-tax-calculator-form-header-sec">
                            <h4>
                              {t("hra_calculator.dearness_allowance.text")}
                            </h4>
                            <Form.Group
                              className="mb-0"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Field
                                className="form-control"
                                name="dearness_allownces"
                                placeholder={t(
                                  "hra_calculator.dearness_allowance.placeholder"
                                )}
                                type="number"
                                onKeyDown={(e) => {
                                  if (
                                    !/[0-9]|Backspace|ArrowLeft|ArrowRight|Delete|ArrowUp|ArrowDown|Tab/.test(
                                      e.key
                                    ) &&
                                    !(
                                      e.ctrlKey &&
                                      (e.key === "v" ||
                                        e.key === "c" ||
                                        e.key === "x" ||
                                        e.key === "a")
                                    )
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                                onInput={(e) => {
                                  if (e.target.value < 0) {
                                    e.target.value = 0;
                                  }
                                  onChangeInput(e, "dearnessAllownces");
                                }}
                              />
                            </Form.Group>
                          </div>
                          <ErrorMessage
                            component={"div"}
                            name="dearness_allownces"
                            className="errorMsg"
                          />
                          <div className="tax-calculator-input-slider-sec">
                            <tc-range-slider
                              ref={dearnessAllowncesRef}
                              value={dearnessAllowncesRef}
                              mousewheel-disabled="true"
                              min={0}
                              max={10000000}
                              slider-width="100%"
                              slider-bg-fill="#2F5CFF"
                              generate-labels="true"
                              round="0"
                            ></tc-range-slider>
                          </div>
                        </div>
                        <div className="single-tax-calculator-form-card">
                          <div className="single-tax-calculator-form-header-sec">
                            <h4>{t("hra_calculator.hra_received.text")}</h4>
                            <Form.Group
                              className="mb-0"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Field
                                className="form-control"
                                name="hra"
                                placeholder={t(
                                  "hra_calculator.hra_received.placeholder"
                                )}
                                type="number"
                                onKeyDown={(e) => {
                                  if (
                                    !/[0-9]|Backspace|ArrowLeft|ArrowRight|Delete|ArrowUp|ArrowDown|Tab/.test(
                                      e.key
                                    ) &&
                                    !(
                                      e.ctrlKey &&
                                      (e.key === "v" ||
                                        e.key === "c" ||
                                        e.key === "x" ||
                                        e.key === "a")
                                    )
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                                onInput={(e) => {
                                  if (e.target.value < 0) {
                                    e.target.value = 0;
                                  }
                                  onChangeInput(e, "hra");
                                }}
                              />
                            </Form.Group>
                          </div>
                          <ErrorMessage
                            component={"div"}
                            name="hra"
                            className="errorMsg"
                          />
                          <div className="tax-calculator-input-slider-sec">
                            <tc-range-slider
                              ref={hraRef}
                              value={hraRef}
                              mousewheel-disabled="true"
                              min={1000}
                              max={10000000}
                              slider-width="100%"
                              slider-bg-fill="#2F5CFF"
                              generate-labels="true"
                              round="0"
                            ></tc-range-slider>
                          </div>
                        </div>
                        <div className="single-tax-calculator-form-card">
                          <div className="single-tax-calculator-form-header-sec">
                            <h4>{t("hra_calculator.total_rent.text")}</h4>
                            <Form.Group
                              className="mb-0"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Field
                                className="form-control"
                                name="hra_actual"
                                placeholder={t(
                                  "hra_calculator.total_rent.placeholder"
                                )}
                                type="number"
                                onKeyDown={(e) => {
                                  if (
                                    !/[0-9]|Backspace|ArrowLeft|ArrowRight|Delete|ArrowUp|ArrowDown|Tab/.test(
                                      e.key
                                    ) &&
                                    !(
                                      e.ctrlKey &&
                                      (e.key === "v" ||
                                        e.key === "c" ||
                                        e.key === "x" ||
                                        e.key === "a")
                                    )
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                                onInput={(e) => {
                                  if (e.target.value < 0) {
                                    e.target.value = 0;
                                  }
                                  onChangeInput(e, "actualHra");
                                }}
                              />
                            </Form.Group>
                          </div>
                          <ErrorMessage
                            component={"div"}
                            name="hra_actual"
                            className="errorMsg"
                          />
                          <div className="tax-calculator-input-slider-sec">
                            <tc-range-slider
                              ref={actualHraRef}
                              value={actualHraRef}
                              mousewheel-disabled="true"
                              min={1000}
                              max={10000000}
                              slider-width="100%"
                              slider-bg-fill="#2F5CFF"
                              generate-labels="true"
                              round="0"
                            ></tc-range-slider>
                          </div>
                        </div>
                        <div className="single-tax-calculator-form-card">
                          <div className="single-tax-calculator-form-header-sec single-tax-calculator-form-header-yes-or-no-sec">
                            <h4>{t("hra_calculator.working_in_metro")}</h4>
                            <div className="yes-or-no-radio-btn-sec">
                              <div class="pretty p-default p-round p-smooth">
                                <input
                                  type="radio"
                                  value="Metro"
                                  checked={
                                    formRef.current?.values.location === "Metro"
                                  }
                                  onClick={(e) => {
                                    formRef.current.setFieldValue(
                                      "location",
                                      e.target.value
                                    );
                                    submitForm()
                                  }}
                                />
                                <div class="state p-primary-o">
                                  <label>{t("hra_calculator.yes")}</label>
                                </div>
                              </div>
                              <div class="pretty p-default p-round p-smooth">
                                <input
                                  type="radio"
                                  value="Non-Metro"
                                  checked={
                                    formRef.current?.values.location ===
                                    "Non-Metro"
                                  }
                                  onClick={(e) => {
                                    formRef.current.setFieldValue(
                                      "location",
                                      e.target.value
                                    );
                                    submitForm()
                                  }
                                  }
                                />
                                <div class="state p-primary-o">
                                  <label>{t("hra_calculator.no")}</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <div className="d-flex justify-content-end mt-3">
                          <Button
                            type="submit"
                            className="default-primary-btn"
                            disabled={hraCalculator.buttonDisable}
                          >
                            {hraCalculator.buttonDisable ? (
                              <ButtonLoader />
                            ) : (
                              t("hra_calculator.calculate")
                            )}
                          </Button>
                        </div> */}
                      </FORM>
                    )}
                  </Formik>
                </Col>
                <Col md={12} xl={6} lg={12}>
                  {hraCalculator.loading ? (
                    <>
                      <div className="single-tax-calculator-form-value-details-sec ">
                        <Skeleton
                          width={130}
                          height={20}
                          baseColor="#202020"
                          highlightColor="#444"
                        />
                        <Skeleton
                          width={140}
                          height={55}
                          baseColor="#202020"
                          highlightColor="#444"
                        />
                      </div>
                      <div className="single-tax-calculator-form-value-details-sec margin-top-sm">
                        <Skeleton
                          width={130}
                          height={20}
                          baseColor="#202020"
                          highlightColor="#444"
                        />
                        <Skeleton
                          width={140}
                          height={55}
                          baseColor="#202020"
                          highlightColor="#444"
                        />
                      </div>
                    </>
                  ) : (
                    Object.keys(hraCalculator.data).length > 0 && (
                      <>
                        <div className="single-tax-calculator-form-value-details-sec ">
                          <p>{t("hra_calculator.exempted_hra")}</p>
                          <div className="single-tax-calculator-form-value-amount">
                            {formatNumberWithCommas(
                              hraCalculator.data.hra_exemption
                            )}
                          </div>
                        </div>
                        <div className="single-tax-calculator-form-value-details-sec margin-top-sm">
                          <p>{t("hra_calculator.taxable_hra")}</p>
                          <div className="single-tax-calculator-form-value-amount">
                            {formatNumberWithCommas(
                              hraCalculator.data.taxable_hra
                            )}
                          </div>
                        </div>
                      </>
                    )
                  )}
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <Container>
          <Row>
            <Col lg={12}>
              <div className="static-page-card">
                <div className="pricacy-policy-info">
                  <h4>Description</h4>
                  <p>
                    House Rent Allowance (HRA) is a key component of your salary
                    package, designed to cover rental expenses. Many employees
                    don't realize that HRA can also offer tax benefits. The
                    amount of HRA you're eligible for depends on various
                    factors, including your salary structure, actual income, and
                    the city where you reside. If you're a salaried individual
                    living in rented accommodation, you can use our HRA
                    calculator to figure out your potential tax savings.
                  </p>
                </div>
                <div className="pricacy-policy-info">
                  <h4>What exactly is an HRA Calculator?</h4>
                  <p>
                    With the cost of living in many Indian cities on the rise
                    due to factors like higher disposable incomes and inflation,
                    many companies provide their employees with a House Rent
                    Allowance (HRA) to help with rent. Our HRA calculator is
                    designed to assist you in figuring out the allowance you're
                    entitled to.
                  </p>
                  <p>
                    Following the 7th Pay Commission's recommendations, HRA
                    categories have been significantly revised, dividing cities
                    into three main slabs. Slab X includes the most urbanized
                    cities, where calculating your HRA exemption is crucial,
                    while Slab Y encompasses cities with slightly lower living
                    costs.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <FAQIndex
          data={[
            {
              question: "What portion of my HRA is exempt from taxation?",
              answer:
                "The tax-exempt portion of your HRA is the minimum of the following three amounts: the actual HRA received, 40% of your salary (50% if you reside in a metro city), or the excess of rent paid over 10% of your salary. This calculation ensures that a part of your HRA can be exempted from taxes, aiding in reducing your total tax liability.",
            },
            {
              question:
                "Is it possible to get an HRA exemption if my landlord lives abroad?",
              answer:
                "Yes, you can still claim an HRA exemption even if your landlord is a Non-Resident Indian (NRI). However, you must ensure that you deduct a 30% tax at source (TDS) on the rent paid to the landlord and provide the landlord's PAN.",
            },
            {
              question: "What evidence do I need to show that I've paid rent?",
              answer:
                "To prove rent payments for claiming HRA exemption, you should keep rent receipts, which typically include details like the rented property's address, rented period, and landlord's signature. In cases where the annual rent exceeds a certain threshold, providing the landlord's PAN is also necessary.",
            },
            {
              question:
                "Can I claim both HRA exemption and home loan deductions?",
              answer:
                "Yes, if you are paying rent for accommodation while also servicing a home loan for a different property, you can claim both HRA exemption and deductions under Section 24 for your home loan interest payments.",
            },
            {
              question:
                "Does Dearness Allowance (DA) factor into HRA calculations?",
              answer:
                "Yes, if your salary includes Dearness Allowance (DA) and it forms a part of the retirement benefits, then DA is considered while calculating HRA for tax exemption purposes.",
            },
          ]}
        />
      </div>
    </>
  );
};

export default CalculatorIndex;
