import React, { useState } from "react";
import { Container, Row, Col, Image, Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import "toolcool-range-slider/dist/plugins/tcrs-storage.min.js";
import "toolcool-range-slider";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import { useTranslation } from "react-multi-lang";

const Deductions = (props) => {
  const t = useTranslation("tax_calculator");

  return (
    <>
      <div className="tax-calculator-basic-details-sec">
        <div className="tax-calculator-basic-details-box">
          {/* <div className="tax-calculator-basic-details-header-sec">
                        <h4>Gross salary income <span>Annually</span></h4>
                        <Form.Group className="mb-0" controlId="exampleForm.ControlInput1">
                            <Form.Control type="email" placeholder="₹7,00,000" />
                        </Form.Group>
                    </div> */}
          {/* <div className="tax-calculator-input-slider-sec">
                        <tc-range-slider
                            slider-width="100%"
                        >
                        </tc-range-slider>
                    </div> */}
          <div className="tax-calculator-basic-details-body-sec">
            <h4>{t("deductions.other_income_details")}</h4>
            <Row>
              <Col md={6}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>{t("deductions.pf")}</Form.Label>
                  <Field
                    type="number"
                    className="form-control"
                    name="pf_deduction"
                    placeholder="Enter PF deduction "
                    onKeyDown={(e) => {
                      if (
                        !/[0-9]|Backspace|ArrowLeft|ArrowRight|Delete|ArrowUp|ArrowDown|Tab/.test(
                          e.key
                        ) &&
                        !(
                          e.ctrlKey &&
                          (e.key === "v" ||
                            e.key === "c" ||
                            e.key === "x" ||
                            e.key === "a")
                        )
                      ) {
                        e.preventDefault();
                      }
                    }}
                    onInput={(e) => {
                      if (e.target.value <= 0 && e.target.value.length > 1) {
                        e.target.value = 0;
                      }
                    }}
                  />
                  <ErrorMessage
                    component={"div"}
                    name="pf_deduction"
                    className="errorMsg"
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>{t("deductions.deduction_80c")}</Form.Label>
                  <Field
                    type="number"
                    className="form-control"
                    name="deduction_80c"
                    placeholder="Enter deduction 80c"
                    onKeyDown={(e) => {
                      if (
                        !/[0-9]|Backspace|ArrowLeft|ArrowRight|Delete|ArrowUp|ArrowDown|Tab/.test(
                          e.key
                        ) &&
                        !(
                          e.ctrlKey &&
                          (e.key === "v" ||
                            e.key === "c" ||
                            e.key === "x" ||
                            e.key === "a")
                        )
                      ) {
                        e.preventDefault();
                      }
                    }}
                    onInput={(e) => {
                      if (e.target.value <= 0 && e.target.value.length > 1) {
                        e.target.value = 0;
                      }
                    }}
                  />
                  <ErrorMessage
                    component={"div"}
                    name="deduction_80c"
                    className="errorMsg"
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>{t("deductions.professinal_tax")}</Form.Label>
                  <Field
                    type="number"
                    className="form-control"
                    name="pt_deduction"
                    placeholder="Enter PT deduction "
                    onKeyDown={(e) => {
                      if (
                        !/[0-9]|Backspace|ArrowLeft|ArrowRight|Delete|ArrowUp|ArrowDown|Tab/.test(
                          e.key
                        ) &&
                        !(
                          e.ctrlKey &&
                          (e.key === "v" ||
                            e.key === "c" ||
                            e.key === "x" ||
                            e.key === "a")
                        )
                      ) {
                        e.preventDefault();
                      }
                    }}
                    onInput={(e) => {
                      if (e.target.value <= 0 && e.target.value.length > 1) {
                        e.target.value = 0;
                      }
                    }}
                  />
                  <ErrorMessage
                    component={"div"}
                    name="pt_deduction"
                    className="errorMsg"
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label> {t("deductions.deduction_80d")}</Form.Label>
                  <Field
                    type="number"
                    className="form-control"
                    name="deduction_80d"
                    placeholder="Enter deduction 80d"
                    onKeyDown={(e) => {
                      if (
                        !/[0-9]|Backspace|ArrowLeft|ArrowRight|Delete|ArrowUp|ArrowDown|Tab/.test(
                          e.key
                        ) &&
                        !(
                          e.ctrlKey &&
                          (e.key === "v" ||
                            e.key === "c" ||
                            e.key === "x" ||
                            e.key === "a")
                        )
                      ) {
                        e.preventDefault();
                      }
                    }}
                    onInput={(e) => {
                      if (e.target.value <= 0 && e.target.value.length > 1) {
                        e.target.value = 0;
                      }
                    }}
                  />
                  <ErrorMessage
                    component={"div"}
                    name="deduction_80d"
                    className="errorMsg"
                  />
                </Form.Group>
              </Col>
            </Row>
          </div>
          <div className="tax-calculator-basic-detail-footer-sec">
            <Button
              type="submit"
              onClick={() => props.setStep(1)}
              className="default-grey-btn"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                fill="none"
                viewBox="0 0 12 11"
              >
                <path
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M11.006 5.492l-9.722.015M4.889 1.613L1.006 5.508 4.901 9.39"
                ></path>
              </svg>
              {t("deductions.back")}
            </Button>
            <Button className="default-primary-btn" type="submit">
              {t("deductions.next")}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Deductions;
