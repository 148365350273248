import { call, select, put, takeLatest, all } from "redux-saga/effects";
import { aiGetCommonSaga, calculatorGetCommonSaga } from "../sagaUtils";
import {
  chatMessagesSuccess,
  chatMessagesFailure,
  chatDetailsStart,
  taxCalculatorSuccess,
  taxCalculatorFailure,
  sipCalculatorSuccess,
  sipCalculatorFailure,
  lumpSumCalculatorSuccess,
  lumpSumCalculatorFailure,
  mfCalculatorSuccess,
  mfCalculatorFailure,
  fdCalculatorSuccess,
  fdCalculatorFailure,
  rdCalculatorSuccess,
  rdCalculatorFailure,
  npsCalculatorSuccess,
  npsCalculatorFailure,
  hraCalculatorSuccess,
  hraCalculatorFailure,
  retirementPlanCalculatorSuccess,
  retirementPlanCalculatorFailure,
  ppfCalculatorSuccess,
  ppfCalculatorFailure,
  swpCalculatorSuccess,
  swpCalculatorFailure,
  ssyCalculatorSuccess,
  ssyCalculatorFailure,
  epfCalculatorSuccess,
  epfCalculatorFailure,
  emiCalculatorSuccess,
  emiCalculatorFailure,
  siCalculatorSuccess,
  siCalculatorFailure,
  ciCalculatorSuccess,
  ciCalculatorFailure,
  nscCalculatorSuccess,
  nscCalculatorFailure,
  stepUpSipCalculatorSuccess,
  stepUpSipCalculatorFailure,
  gratuityCalculatorSuccess,
  gratuityCalculatorFailure,
  apyCalculatorSuccess,
  apyCalculatorFailure,
  cagrCalculatorSuccess,
  cagrCalculatorFailure,
  gstCalculatorSuccess,
  gstCalculatorFailure,
  flatVsReducingRateCalculatorSuccess,
  flatVsReducingRateCalculatorFailure,
  inflationCalculatorSuccess,
  inflationCalculatorFailure,
  winningAmountTaxCalculatorSuccess,
  winningAmountTaxCalculatorFailure,
  bitCoinCalculatorSuccess,
  bitCoinCalculatorFailure,
  takeHomeSalaryCalculatorSuccess,
  takeHomeSalaryCalculatorFailure,
  tdsCalculatorSuccess,
  tdsCalculatorFailure,
  brokerageCalculatorSuccess,
  brokerageCalculatorFailure,
} from "../slices/CommonSlice";
import store from "../index";
import { getErrorNotificationMessage } from "../../components/Helper/NotificationMessage";

function* chatMessagesAPI(action) {
  const { time, text, type, ...payload } = action.payload;
  const { uuid, question, ...chatQuestion } = action.payload;

  yield put(chatDetailsStart(chatQuestion));
  yield aiGetCommonSaga({
    apiUrl: "query",
    payload: payload,
    successCallback: true,
    success: (data) => {
      if (data.response) {
        store.dispatch(
          chatMessagesSuccess({
            text: data.response,
            type: "message",
            time: new Date().toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            }),
          })
        );
      } else {
        store.dispatch(chatMessagesFailure());
        getErrorNotificationMessage("Oops!!, something went wrong");
      }
    },
    failure: chatMessagesFailure,
  });
}

function* taxCalculatorAPI(action) {
  yield aiGetCommonSaga({
    apiUrl: "calculate-tax",
    payload: action.payload,
    successCallback: false,
    success: taxCalculatorSuccess,
    failure: taxCalculatorFailure,
  });
}

function* sipCalculatorAPI(action) {
  yield calculatorGetCommonSaga({
    apiUrl: "sip-calculator",
    payload: action.payload,
    successCallback: false,
    success: sipCalculatorSuccess,
    failure: sipCalculatorFailure,
  });
}

function* lumpSumCalculatorAPI(action) {
  yield calculatorGetCommonSaga({
    apiUrl: "lumpsum-calculator",
    payload: action.payload,
    successCallback: false,
    success: lumpSumCalculatorSuccess,
    failure: lumpSumCalculatorFailure,
  });
}

function* mfCalculatorAPI(action) {
  yield calculatorGetCommonSaga({
    apiUrl: "mf_calculator",
    payload: action.payload,
    successCallback: false,
    success: mfCalculatorSuccess,
    failure: mfCalculatorFailure,
  });
}

function* fdCalculatorAPI(action) {
  yield calculatorGetCommonSaga({
    apiUrl: "fd_calculator",
    payload: action.payload,
    successCallback: false,
    success: fdCalculatorSuccess,
    failure: fdCalculatorFailure,
  });
}

function* rdCalculatorAPI(action) {
  yield calculatorGetCommonSaga({
    apiUrl: "rd_calculator",
    payload: action.payload,
    successCallback: false,
    success: rdCalculatorSuccess,
    failure: rdCalculatorFailure,
  });
}

function* npsCalculatorAPI(action) {
  yield calculatorGetCommonSaga({
    apiUrl: "nps_calculator",
    payload: action.payload,
    successCallback: false,
    success: npsCalculatorSuccess,
    failure: npsCalculatorFailure,
  });
}

function* hraCalculatorAPI(action) {
  yield calculatorGetCommonSaga({
    apiUrl: "calculate_hra",
    payload: action.payload,
    successCallback: false,
    success: hraCalculatorSuccess,
    failure: hraCalculatorFailure,
  });
}

function* retirementPlanCalculatorAPI(action) {
  yield calculatorGetCommonSaga({
    apiUrl: "calculate_retirement_plan",
    payload: action.payload,
    successCallback: false,
    success: retirementPlanCalculatorSuccess,
    failure: retirementPlanCalculatorFailure,
  });
}

function* ppfCalculatorAPI(action) {
  yield calculatorGetCommonSaga({
    apiUrl: "ppf_calculator",
    payload: action.payload,
    successCallback: false,
    success: ppfCalculatorSuccess,
    failure: ppfCalculatorFailure,
  });
}

function* swpCalculatorAPI(action) {
  yield calculatorGetCommonSaga({
    apiUrl: "swp-calculator",
    payload: action.payload,
    successCallback: false,
    success: swpCalculatorSuccess,
    failure: swpCalculatorFailure,
  });
}

function* ssyCalculatorAPI(action) {
  yield calculatorGetCommonSaga({
    apiUrl: "ssy_calculator",
    payload: action.payload,
    successCallback: false,
    success: ssyCalculatorSuccess,
    failure: ssyCalculatorFailure,
  });
}

function* epfCalculatorAPI(action) {
  yield calculatorGetCommonSaga({
    apiUrl: "epf_calculator",
    payload: action.payload,
    successCallback: false,
    success: epfCalculatorSuccess,
    failure: epfCalculatorFailure,
  });
}

function* emiCalculatorAPI(action) {
  yield calculatorGetCommonSaga({
    apiUrl: "calculate_emi",
    payload: action.payload,
    successCallback: false,
    success: emiCalculatorSuccess,
    failure: emiCalculatorFailure,
  });
}

function* siCalculatorAPI(action) {
  yield calculatorGetCommonSaga({
    apiUrl: "calculate_simple_interest",
    payload: action.payload,
    successCallback: false,
    success: siCalculatorSuccess,
    failure: siCalculatorFailure,
  });
}

function* ciCalculatorAPI(action) {
  yield calculatorGetCommonSaga({
    apiUrl: "calculate_compound_interest",
    payload: action.payload,
    successCallback: false,
    success: ciCalculatorSuccess,
    failure: ciCalculatorFailure,
  });
}

function* nscCalculatorAPI(action) {
  yield calculatorGetCommonSaga({
    apiUrl: "calculate_nsc",
    payload: action.payload,
    successCallback: false,
    success: nscCalculatorSuccess,
    failure: nscCalculatorFailure,
  });
}

function* stepUpSipCalculatorAPI(action) {
  yield calculatorGetCommonSaga({
    apiUrl: "calculate_stepup_sip",
    payload: action.payload,
    successCallback: false,
    success: stepUpSipCalculatorSuccess,
    failure: stepUpSipCalculatorFailure,
  });
}

function* gratuityCalculatorAPI(action) {
  yield calculatorGetCommonSaga({
    apiUrl: "calculate_gratuity",
    payload: action.payload,
    successCallback: false,
    success: gratuityCalculatorSuccess,
    failure: gratuityCalculatorFailure,
  });
}

function* apyCalculatorAPI(action) {
  yield calculatorGetCommonSaga({
    apiUrl: "calculate_apy",
    payload: action.payload,
    successCallback: false,
    success: apyCalculatorSuccess,
    failure: apyCalculatorFailure,
  });
}

function* cagrCalculatorAPI(action) {
  yield calculatorGetCommonSaga({
    apiUrl: "calculate_cagr",
    payload: action.payload,
    successCallback: false,
    success: cagrCalculatorSuccess,
    failure: cagrCalculatorFailure,
  });
}

function* gstCalculatorAPI(action) {
  yield calculatorGetCommonSaga({
    apiUrl: "calculate_gst",
    payload: action.payload,
    successCallback: false,
    success: gstCalculatorSuccess,
    failure: gstCalculatorFailure,
  });
}

function* flatVsReducingRateCalculatorAPI(action) {
  yield calculatorGetCommonSaga({
    apiUrl: "calculate_flat_vs_reducing",
    payload: action.payload,
    successCallback: false,
    success: flatVsReducingRateCalculatorSuccess,
    failure: flatVsReducingRateCalculatorFailure,
  });
}

function* inflationCalculatorAPI(action) {
  yield calculatorGetCommonSaga({
    apiUrl: "calculate_inflation",
    payload: action.payload,
    successCallback: false,
    success: inflationCalculatorSuccess,
    failure: inflationCalculatorFailure,
  });
}

function* winningAmountTaxCalculatorAPI(action) {
  yield calculatorGetCommonSaga({
    apiUrl: "calculate_winning_amount",
    payload: action.payload,
    successCallback: false,
    success: winningAmountTaxCalculatorSuccess,
    failure: winningAmountTaxCalculatorFailure,
  });
}

function* bitCoinCalculatorAPI(action) {
  yield calculatorGetCommonSaga({
    apiUrl: "calculate_bitcoin",
    payload: action.payload,
    successCallback: false,
    success: bitCoinCalculatorSuccess,
    failure: bitCoinCalculatorFailure,
  });
}

function* takeHomeSalaryCalculatorAPI(action) {
  yield calculatorGetCommonSaga({
    apiUrl: "calculate_take_home_salary",
    payload: action.payload,
    successCallback: false,
    success: takeHomeSalaryCalculatorSuccess,
    failure: takeHomeSalaryCalculatorFailure,
  });
}

function* tdsCalculatorAPI(action) {
  yield calculatorGetCommonSaga({
    apiUrl: "calculate_tds",
    payload: action.payload,
    successCallback: false,
    success: tdsCalculatorSuccess,
    failure: tdsCalculatorFailure,
  });
}

function* brokerageCalculatorAPI(action) {
  yield calculatorGetCommonSaga({
    apiUrl: "brokerage_calculator",
    payload: action.payload,
    successCallback: false,
    success: brokerageCalculatorSuccess,
    failure: brokerageCalculatorFailure,
  });
}

export default function* CommonSaga() {
  yield all([
    yield takeLatest("common/chatMessagesStart", chatMessagesAPI),
    yield takeLatest("common/taxCalculatorStart", taxCalculatorAPI),
    yield takeLatest("common/sipCalculatorStart", sipCalculatorAPI),
    yield takeLatest("common/lumpSumCalculatorStart", lumpSumCalculatorAPI),
    yield takeLatest("common/mfCalculatorStart", mfCalculatorAPI),
    yield takeLatest("common/fdCalculatorStart", fdCalculatorAPI),
    yield takeLatest("common/rdCalculatorStart", rdCalculatorAPI),
    yield takeLatest("common/npsCalculatorStart", npsCalculatorAPI),
    yield takeLatest("common/hraCalculatorStart", hraCalculatorAPI),
    yield takeLatest(
      "common/retirementPlanCalculatorStart",
      retirementPlanCalculatorAPI
    ),
    yield takeLatest("common/ppfCalculatorStart", ppfCalculatorAPI),
    yield takeLatest("common/swpCalculatorStart", swpCalculatorAPI),
    yield takeLatest("common/ssyCalculatorStart", ssyCalculatorAPI),
    yield takeLatest("common/epfCalculatorStart", epfCalculatorAPI),
    yield takeLatest("common/emiCalculatorStart", emiCalculatorAPI),
    yield takeLatest("common/siCalculatorStart", siCalculatorAPI),
    yield takeLatest("common/ciCalculatorStart", ciCalculatorAPI),
    yield takeLatest("common/nscCalculatorStart", nscCalculatorAPI),
    yield takeLatest("common/stepUpSipCalculatorStart", stepUpSipCalculatorAPI),
    yield takeLatest("common/gratuityCalculatorStart", gratuityCalculatorAPI),
    yield takeLatest("common/apyCalculatorStart", apyCalculatorAPI),
    yield takeLatest("common/cagrCalculatorStart", cagrCalculatorAPI),
    yield takeLatest("common/gstCalculatorStart", gstCalculatorAPI),
    yield takeLatest(
      "common/flatVsReducingRateCalculatorStart",
      flatVsReducingRateCalculatorAPI
    ),
    yield takeLatest("common/inflationCalculatorStart", inflationCalculatorAPI),
    yield takeLatest(
      "common/winningAmountTaxCalculatorStart",
      winningAmountTaxCalculatorAPI
    ),
    yield takeLatest("common/bitCoinCalculatorStart", bitCoinCalculatorAPI),
    yield takeLatest(
      "common/takeHomeSalaryCalculatorStart",
      takeHomeSalaryCalculatorAPI
    ),
    yield takeLatest("common/tdsCalculatorStart", tdsCalculatorAPI),
    yield takeLatest("common/brokerageCalculatorStart", brokerageCalculatorAPI),
  ]);
}
