import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Container, Button, Form, Accordion } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import CustomLazyLoad from "../../../Helper/CustomLazyLoad";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-multi-lang";
import { useDispatch, useSelector } from "react-redux";
import {
  ciCalculatorStart,
  ciCalculatorSuccess,
} from "../../../../store/slices/CommonSlice";
import { ButtonLoader } from "../../../Helper/Loader";
import PieChart from "../PieChart";
import FAQIndex from "../FAQIndex";
import { Link } from "react-router-dom";
import formatNumberWithCommas from "../../../FormatNumber";

const CalculatorIndex = () => {
  const t = useTranslation("tax_calculator");
  const principleRef = useRef(null);
  const timePeriodRef = useRef(null);
  const interestPeriodRef = useRef(null);
  const formRef = useRef();
  const dispatch = useDispatch();
  const ciCalculator = useSelector((state) => state.common.ciCalculator);

  useEffect(() => {
    const onChange = (event, field) => {
      const customEvent = event;
      switch (field) {
        case "principal":
          formRef.current.setFieldValue("principal", customEvent.detail.value);
          break;
        case "timePeriod":
          formRef.current.setFieldValue(
            "time_period",
            customEvent.detail.value
          );
          break;
        case "interest":
          formRef.current.setFieldValue(
            "rate_of_interest",
            customEvent.detail.value
          );
          break;
      }
      setTimeout(() => {
        formRef.current.submitForm();
      }, 1);
    };
    principleRef.current?.addEventListener("change", (event) =>
      onChange(event, "principal")
    );
    timePeriodRef.current?.addEventListener("change", (event) =>
      onChange(event, "timePeriod")
    );
    interestPeriodRef.current?.addEventListener("change", (event) =>
      onChange(event, "interest")
    );
    return () => {
      principleRef.current?.removeEventListener("change", onChange);
      timePeriodRef.current?.removeEventListener("change", onChange);
      interestPeriodRef.current?.removeEventListener("change", onChange);
    };
  }, []);

  useEffect(() => {
    dispatch(ciCalculatorStart(formRef.current.values));
    // calculate(formRef.current.values);
  }, []);

  const onChangeInput = (event, field) => {
    const value =
      !isNaN(parseFloat(event.target.value)) == true
        ? parseFloat(event.target.value)
        : "";
    switch (field) {
      case "principal":
        formRef.current.setFieldValue("principal", value);
        principleRef.current.value = value;
        break;
      case "timePeriod":
        formRef.current.setFieldValue("time_period", value);
        timePeriodRef.current.value = value;
        break;
      case "interest":
        formRef.current.setFieldValue("rate_of_interest", value);
        interestPeriodRef.current.value = value;
        break;
    }
    setTimeout(() => {
      formRef.current.submitForm();
    }, 100);
  };

  const handleSubmit = (values) => {
    formRef.current.isValid && calculate(values);
    // dispatch(ciCalculatorStart(values));
  };

  const ppfSchema = Yup.object().shape({
    principal: Yup.string()
      .test("min", t("ci_calculator.invalid_min", { value: 500 }), (value) => {
        return value >= 500;
      })
      .test(
        "max",
        t("ci_calculator.invalid_max", {
          value: 10000000,
          name: "Monthly investment",
        }),
        (value) => {
          return value <= 10000000;
        }
      )
      .required(t("ci_calculator.required")),
    rate_of_interest: Yup.string()
      .test("min", t("ci_calculator.invalid_min", { value: 1 }), (value) => {
        return value >= 1;
      })
      .test(
        "max",
        t("ci_calculator.invalid_max", {
          value: 50,
          name: "Rate of interest",
        }),
        (value) => {
          return value <= 50;
        }
      )
      .required(t("ci_calculator.required")),
    time_period: Yup.string()
      .test("min", t("ci_calculator.invalid_min", { value: 1 }), (value) => {
        return value >= 1;
      })
      .test(
        "max",
        t("ci_calculator.invalid_max", { value: 30, name: "Time period" }),
        (value) => {
          return value <= 30;
        }
      )
      .required(t("ci_calculator.required")),
  });

  const calculate = (values) => {
    const { principal, rate_of_interest, time_period, compounding_frequency } =
      values;
    let rateOfInterest = rate_of_interest / 100;
    let totalAmount =
      principal *
      Math.pow(
        1 + rateOfInterest / compounding_frequency,
        time_period * compounding_frequency
      );
    let totalInterest = totalAmount - principal;

    const newResult = {
      compound_interest: Math.round(totalInterest),
      total_amount: Math.round(totalAmount),
      principal: Math.round(principal),
    };
    dispatch(ciCalculatorSuccess(newResult));
  };

  return (
    <>
      <div className="single-tax-calculator">
        <div className="sm-padding single-tax-calculator-sec">
          <Container>
            <div className="single-tax-calculator-box">
              <div className="single-tax-calculator-header">
                <Row>
                  <Col md={6}>
                    <div className="d-flex other-calculator-btn-sec">
                      <Link
                        to="/tax-calculator"
                        className="other-calculator-btn"
                      >
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            enableBackground="new 0 0 512 512"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="#fff"
                              d="M10.957 12.354a.5.5 0 010-.708l4.586-4.585a1.5 1.5 0 00-2.121-2.122L8.836 9.525a3.505 3.505 0 000 4.95l4.586 4.586a1.5 1.5 0 002.121-2.122z"
                              data-original="#000000"
                            ></path>
                          </svg>
                        </span>
                        <span>Other Calculators</span>
                      </Link>
                    </div>
                    <div className="single-tax-calculator-info-sec">
                      <h1>{t("ci_calculator.heading")}</h1>
                      <p>{t("ci_calculator.content")}</p>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="single-tax-calculator-header-img-sec">
                      <CustomLazyLoad
                        className="single-tax-calculator-header-img"
                        src={
                          window.location.origin +
                          "/img/single-tax-calculator-header-img.png"
                        }
                        type="image/svg"
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="single-tax-calculator-form-box  line-stright calculator-space-lg">
              <Row className="align-items-center g-5">
                <Col md={6} lg={6}>
                  <Formik
                    initialValues={{
                      principal: 1000,
                      rate_of_interest: 15,
                      time_period: 2,
                      compounding_frequency: 1,
                    }}
                    innerRef={formRef}
                    validationSchema={ppfSchema}
                    onSubmit={handleSubmit}
                  >
                    {({
                      setFieldValue,
                      values,
                      resetForm,
                      isValid,
                      submitForm,
                    }) => (
                      <FORM className="single-tax-calculator-form">
                        <div className="single-tax-calculator-form-card">
                          <div className="single-tax-calculator-form-header-sec">
                            <h4>{t("ci_calculator.principal.text")}</h4>
                            <Form.Group
                              className="mb-0"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Field
                                className="form-control"
                                name="principal"
                                placeholder={t(
                                  "ci_calculator.principal.placeholder"
                                )}
                                type="number"
                                onKeyDown={(e) => {
                                  if (
                                    !/[0-9]|Backspace|ArrowLeft|ArrowRight|Delete|ArrowUp|ArrowDown|Tab/.test(
                                      e.key
                                    ) &&
                                    !(
                                      e.ctrlKey &&
                                      (e.key === "v" ||
                                        e.key === "c" ||
                                        e.key === "x" ||
                                        e.key === "a")
                                    )
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                                onInput={(e) => {
                                  if (e.target.value < 0) {
                                    e.target.value = 0;
                                  }
                                  onChangeInput(e, "principal");
                                }}
                              />
                            </Form.Group>
                          </div>
                          <ErrorMessage
                            component={"div"}
                            name="principal"
                            className="errorMsg"
                          />
                          <div className="tax-calculator-input-slider-sec">
                            <tc-range-slider
                              ref={principleRef}
                              value={principleRef}
                              mousewheel-disabled="true"
                              min={1000}
                              max={10000000}
                              slider-width="100%"
                              slider-bg-fill="#2F5CFF"
                              generate-labels="true"
                              round="0"
                            ></tc-range-slider>
                          </div>
                        </div>
                        <div className="single-tax-calculator-form-card">
                          <div className="single-tax-calculator-form-header-sec">
                            <h4>{t("ci_calculator.time_period.text")}</h4>
                            <Form.Group
                              className="mb-0"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Field
                                className="form-control"
                                name="time_period"
                                placeholder={t(
                                  "ci_calculator.time_period.placeholder"
                                )}
                                type="number"
                                onKeyDown={(e) => {
                                  if (
                                    !/[0-9]|Backspace|ArrowLeft|ArrowRight|Delete|ArrowUp|ArrowDown|Tab/.test(
                                      e.key
                                    ) &&
                                    !(
                                      e.ctrlKey &&
                                      (e.key === "v" ||
                                        e.key === "c" ||
                                        e.key === "x" ||
                                        e.key === "a")
                                    )
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                                onInput={(e) => {
                                  if (e.target.value < 0) {
                                    e.target.value = 0;
                                  }
                                  onChangeInput(e, "timePeriod");
                                }}
                              />
                            </Form.Group>
                          </div>
                          <ErrorMessage
                            component={"div"}
                            name="time_period"
                            className="errorMsg"
                          />
                          <div className="tax-calculator-input-slider-sec">
                            <tc-range-slider
                              ref={timePeriodRef}
                              value={timePeriodRef}
                              mousewheel-disabled="true"
                              min={1}
                              max={30}
                              slider-width="100%"
                              slider-bg-fill="#2F5CFF"
                              generate-labels="true"
                              round="0"
                            ></tc-range-slider>
                          </div>
                        </div>
                        <div className="single-tax-calculator-form-card">
                          <div className="single-tax-calculator-form-header-sec">
                            <h4>{t("ci_calculator.rate_of_interest.text")}</h4>
                            <Form.Group
                              className="mb-0"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Field
                                className="form-control"
                                name="rate_of_interest"
                                placeholder={t(
                                  "ci_calculator.rate_of_interest.placeholder"
                                )}
                                type="number"
                                onKeyDown={(e) => {
                                  if (
                                    !/[0-9.]|Backspace|ArrowLeft|ArrowRight|Delete|ArrowUp|ArrowDown|Tab/.test(
                                      e.key
                                    ) ||
                                    (e.key === "-" &&
                                      e.target.selectionStart !== 0)
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                                onInput={(e) => {
                                  if (e.target.value < 0) {
                                    e.target.value = 0;
                                  }
                                  onChangeInput(e, "interest");
                                }}
                              />
                            </Form.Group>
                          </div>
                          <ErrorMessage
                            component={"div"}
                            name="rate_of_interest"
                            className="errorMsg"
                          />
                        </div>
                        <div className="tax-calculator-input-slider-sec">
                          <tc-range-slider
                            ref={interestPeriodRef}
                            value={interestPeriodRef}
                            mousewheel-disabled="true"
                            min={1}
                            max={50}
                            slider-width="100%"
                            slider-bg-fill="#2F5CFF"
                            generate-labels="true"
                            round="1"
                          ></tc-range-slider>
                        </div>
                        <div className="single-tax-calculator-form-card">
                          <div className="single-tax-calculator-form-header-sec">
                            <h4>{t("ci_calculator.compound_frequency")}</h4>
                          </div>
                          <div className="yes-or-no-radio-btn-sec mt-4">
                            <div class="pretty p-default p-round p-smooth">
                              <input
                                type="radio"
                                value={1}
                                onClick={(e) => {
                                  formRef.current.setFieldValue(
                                    "compounding_frequency",
                                    parseInt(e.target.value)
                                  );
                                  submitForm();
                                }}
                                checked={
                                  formRef.current?.values
                                    .compounding_frequency == 1
                                }
                              />
                              <div class="state p-primary-o">
                                <label>{t("ci_calculator.yearly")}</label>
                              </div>
                            </div>
                            <div class="pretty p-default p-round p-smooth">
                              <input
                                type="radio"
                                value={2}
                                onClick={(e) => {
                                  formRef.current.setFieldValue(
                                    "compounding_frequency",
                                    parseInt(e.target.value)
                                  );
                                  submitForm();
                                }}
                                checked={
                                  formRef.current?.values
                                    .compounding_frequency == 2
                                }
                              />
                              <div class="state p-primary-o">
                                <label>{t("ci_calculator.half_yearly")}</label>
                              </div>
                            </div>
                            <div class="pretty p-default p-round p-smooth">
                              <input
                                type="radio"
                                value={4}
                                checked={
                                  formRef.current?.values
                                    .compounding_frequency == 4
                                }
                                onClick={(e) => {
                                  formRef.current.setFieldValue(
                                    "compounding_frequency",
                                    parseInt(e.target.value)
                                  );
                                  submitForm();
                                }}
                              />
                              <div class="state p-primary-o">
                                <label>{t("ci_calculator.quarterly")}</label>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <div className="d-flex justify-content-end mt-4">
                          <Button
                            type="submit"
                            className="default-primary-btn"
                            disabled={ciCalculator.buttonDisable}
                          >
                            {ciCalculator.buttonDisable ? (
                              <ButtonLoader />
                            ) : (
                              t("ci_calculator.calculate")
                            )}
                          </Button>
                        </div> */}
                      </FORM>
                    )}
                  </Formik>
                </Col>
                <Col md={6} lg={6}>
                  {ciCalculator.loading ? (
                    <div className="single-calculator-info-frame">
                      <div className="pie-chart-skelton">
                        <div className="skeleton-wrapper">
                          <Skeleton
                            borderRadius={130}
                            width={230}
                            height={230}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                        </div>
                        <div className="mt-4">
                          <Skeleton
                            width={250}
                            height={15}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                        </div>
                      </div>

                      <div className="calculator-info-details-card">
                        <div className="calculator-details-frame">
                          <Skeleton
                            width={200}
                            height={20}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                          <Skeleton
                            width={130}
                            height={20}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                        </div>
                        <div className="calculator-details-frame">
                          <Skeleton
                            width={200}
                            height={20}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                          <Skeleton
                            width={130}
                            height={20}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                        </div>
                        <hr />
                        <div className="calculator-details-frame top-bottom-line">
                          <Skeleton
                            width={200}
                            height={20}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                          <Skeleton
                            width={130}
                            height={20}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                        </div>
                        <hr />
                      </div>
                    </div>
                  ) : (
                    Object.keys(ciCalculator.data).length > 0 && (
                      <div className="single-calculator-info-frame">
                        <div className="pie-chart-sec">
                          <PieChart
                            name={[
                              t("ci_calculator.principal_amount"),
                              t("ci_calculator.total_interest"),
                            ]}
                            data={[
                              ciCalculator.data.principal,
                              ciCalculator.data.compound_interest,
                            ]}
                          />
                        </div>
                        <div className="calculator-info-details-card">
                          <div className="calculator-details-frame">
                            <p>
                              {" "}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                x="0"
                                y="0"
                                enableBackground="new 0 0 29.107 29.107"
                                version="1.1"
                                viewBox="0 0 29.107 29.107"
                                xmlSpace="preserve"
                                width="20"
                                height="20"
                                fill="#2A334F"
                              >
                                <path d="M14.554 0C6.561 0 0 6.562 0 14.552c0 7.996 6.561 14.555 14.554 14.555 7.996 0 14.553-6.559 14.553-14.555C29.106 6.562 22.55 0 14.554 0z"></path>
                              </svg>
                              <span>{t("ci_calculator.principal_amount")}</span>
                            </p>
                            <h3>
                              {formatNumberWithCommas(
                                ciCalculator.data.principal
                              )}
                            </h3>
                          </div>
                          <div className="calculator-details-frame">
                            <p>
                              {" "}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                x="0"
                                y="0"
                                enableBackground="new 0 0 29.107 29.107"
                                version="1.1"
                                viewBox="0 0 29.107 29.107"
                                xmlSpace="preserve"
                                width="20"
                                height="20"
                                fill="#2F5CFF"
                              >
                                <path d="M14.554 0C6.561 0 0 6.562 0 14.552c0 7.996 6.561 14.555 14.554 14.555 7.996 0 14.553-6.559 14.553-14.555C29.106 6.562 22.55 0 14.554 0z"></path>
                              </svg>
                              <span>{t("ci_calculator.total_interest")}</span>
                            </p>
                            <h3>
                              {formatNumberWithCommas(
                                ciCalculator.data.compound_interest
                              )}
                            </h3>
                          </div>
                          <hr />
                          <div className="calculator-details-frame top-bottom-line">
                            <p className="d-inline">
                              {t("ci_calculator.total_value")}
                            </p>
                            <h3>
                              {formatNumberWithCommas(
                                ciCalculator.data.total_amount
                              )}
                            </h3>
                          </div>
                          <hr />
                        </div>
                      </div>
                    )
                  )}
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <Container>
          <Row>
            <Col lg={12}>
              <div className="static-page-card">
                <div className="pricacy-policy-info">
                  <h4>Description</h4>
                  <p>
                    Compounding interest, unlike simple interest, allows your
                    wealth to grow exponentially as you earn interest not only
                    on your principal amount but also on the accumulated
                    interest. This method can greatly enhance the growth
                    potential of your investments. It's a fundamental concept
                    that many modern businesses rely on. To accurately determine
                    your return on investment (ROI) using this method, a
                    reliable compound interest calculator is essential.
                  </p>
                </div>
                <div className="pricacy-policy-info">
                  <h4>
                    How to Operate TaxTrax’s Compound Interest Calculator?
                  </h4>
                  <p>
                    Operating TaxTrax’s calculator is straightforward. Just
                    follow these simple steps:
                  </p>
                  <ul>
                    <li>
                      Input the principal amount, interest rate, and tenure for
                      which you want to calculate compound interest.
                    </li>
                    <li>
                      The calculator will quickly compute and display the
                      results in just a few seconds.
                    </li>
                  </ul>
                </div>
                <div className="pricacy-policy-info">
                  <h4>
                    Benefits of Using TaxTrax’s Compound Interest Calculator
                  </h4>
                  <p>
                    TaxTrax offers a precise and reliable compound interest
                    calculator that you can use as often as needed. Its key
                    advantages include:
                  </p>
                  <ul>
                    <li>
                      Ease of Use: The calculator is user-friendly, making it
                      simple to operate.
                    </li>
                    <li>
                      Reliability and Accuracy: It provides dependable and
                      precise calculations every time.
                    </li>
                    <li>
                      Data Security: Your information remains secure while using
                      the calculator.
                    </li>
                    <p>
                      In addition to the compound interest calculator, TaxTrax
                      also features a variety of other financial calculators.
                      Each is designed to meet industry standards and is
                      perfectly suited for regular use.
                    </p>
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <FAQIndex
          data={[
            {
              question:
                "Is it possible to calculate returns on National Savings Certificates using this calculator?",
              answer:
                "While the compound interest calculator is designed primarily for calculating compound interest, for specific products like National Savings Certificates, which may have different interest compounding rules, it's best to use a dedicated calculator designed for that purpose to ensure accuracy.",
            },
            {
              question: "How often can I use the calculator?",
              answer:
                "You can use the calculator an unlimited number of times. It is available for continuous use, allowing you to make multiple calculations whenever you need",
            },
            {
              question: "Is my personal data safe when using this website?",
              answer:
                "Yes, your data security is a priority. This website uses advanced security measures to ensure that all your personal information is kept secure and confidential.",
            },
          ]}
        />
      </div>
    </>
  );
};

export default CalculatorIndex;
