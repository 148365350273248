import React, { useState } from "react";
import { Container, Row, Col, Accordion } from "react-bootstrap";
import { Link } from "react-router-dom";

const FAQIndex = (props) => {
  return (
    <>
      <div className="faq-sec sm-padding">
        <Container>
          <Row className="justify-content-md-center">
            <Col md={8} lg={9} className="text-center">
              <div className="section-title">
                <h2>Frequently Asked Questions</h2>
                {/* <p>Still you have any questions? Contact our Team via support@yourbank.com.</p> */}
              </div>
            </Col>
          </Row>
          <div className="faq-box">
            <Row className="justify-content-md-center">
              <Col md={12} xl={9}>
                <Accordion className="faq-accordion" defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      {" "}
                      How does TaxTrax ensure the security of my financial
                      information?
                    </Accordion.Header>
                    <Accordion.Body>
                      TaxTrax employs bank-level encryption and adheres to
                      stringent data protection regulations to secure your
                      financial information. We prioritize your privacy with
                      advanced security measures.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      Can TaxTrax handle tax filings for both state and federal
                      taxes?
                    </Accordion.Header>
                    <Accordion.Body>
                      Absolutely, TaxTrax is equipped to assist with both
                      federal and state tax filings. Our AI system is updated
                      with tax laws from all jurisdictions to provide
                      comprehensive filing support.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      How does TaxTrax keep up with the latest tax laws and
                      regulations?
                    </Accordion.Header>
                    <Accordion.Body>
                      Our AI system is continuously updated with the latest tax
                      laws and regulations to ensure that you're receiving the
                      most current tax guidance possible, enabling you to make
                      informed decisions.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      What makes TaxTrax different from other tax software?
                    </Accordion.Header>
                    <Accordion.Body>
                      TaxTrax stands out with its AI-driven platform that not
                      only provides answers but also offers tailored tax-saving
                      strategies. Plus, our 24/7 availability means you can get
                      tax help on your schedule.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      Is TaxTrax suitable for self-employed individuals and
                      freelancers?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes, TaxTrax is designed to cater to the unique tax
                      situations of self-employed individuals and freelancers,
                      providing them with personalized tax advice and
                      strategies.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      Does TaxTrax offer support for filing amended tax returns?
                    </Accordion.Header>
                    <Accordion.Body>
                      Indeed, if you need to correct your tax filing, TaxTrax
                      offers guidance on preparing and filing amended returns to
                      ensure everything is accurate and up-to-date.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default FAQIndex;
