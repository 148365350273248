import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import CustomLazyLoad from "../Helper/CustomLazyLoad";
import "./staticPage.css";

const AboutUs = () => {
  return (
    <>
      <div className="landing-page-sec">
        <div className="about-us-sec sm-padding">
          <Container>
            <Row className="justify-content-center">
              <Col lg={8} md={8} className="text-center">
                <div className="section-title">
                  <h2>About Us</h2>
                </div>
              </Col>
            </Row>
            <div className="about-us-banner-sec">
              <Row className="align-items-center">
                <Col md={5} className="order-change">
                  <div className="about-us-banner-img">
                    <CustomLazyLoad
                      className="how-its-work-img"
                      src={window.location.origin + "/img/about-us.png"}
                    />
                  </div>
                </Col>
                <Col md={7} className="resp-margin-btm-xs">
                  <div className="about-us-banner-info">
                    <h3>Why Do We Do - What We Do?</h3>
                    <p>
                      At TaxTrax, we're driven by the vision of making tax
                      management intuitive and user-friendly. We recognize the
                      challenges and hurdles that taxes can present, and our
                      goal is to eliminate these barriers with cutting-edge
                      technology and expert guidance. Our commitment lies in
                      providing clarity and simplification in an often complex
                      tax environment, enabling our users to approach their tax
                      obligations with confidence and peace of mind.
                    </p>
                    <p>
                      Our foundation is built on the principle that effective
                      tax solutions should be accessible to all, regardless of
                      financial literacy or background. We're here to bridge the
                      gap between complex tax regulations and the everyday
                      taxpayer, offering tools and resources that are not only
                      reliable but also easy to use. By fostering a supportive
                      tax community, we empower our users to make informed
                      decisions and optimize their financial health.
                    </p>
                    <p>
                      The essence of our work at TaxTrax stems from a
                      deep-rooted belief in empowerment through education and
                      technology. We're not just about solving immediate tax
                      issues; we're about building a future where tax management
                      is no longer a daunting task but a seamless part of
                      personal and business finance. Our dedication to
                      innovation and service excellence is our pledge to
                      transform the tax experience, making it a positive and
                      rewarding aspect of financial management.
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="our-vision">
              <Row className="justify-content-center">
                <Col lg={8} md={8} className="text-center">
                  <div className="section-title">
                    <h2>Our Vision</h2>
                    <p>
                      Discover unparalleled tax guidance with TaxTrax, where
                      cutting-edge AI meets personalized, secure, and
                      ever-accessible tax assistance.
                    </p>
                  </div>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col
                  md={6}
                  sm={12}
                  xl={4}
                  lg={6}
                  className="resp-mrg-btm-md resp-mrg-btm-xl"
                >
                  <div className="why-choose-us-card">
                    <div className="why-choose-us-icon-sec">
                      <CustomLazyLoad
                        className="why-choose-us-icon"
                        src={
                          window.location.origin +
                          "/img/why-choose-us/smart-guidance.png"
                        }
                        type="image/svg"
                      />
                    </div>
                    <div className="why-choose-us-info-sec">
                      <h4>Secures Business Operations</h4>
                      <p>
                        TaxTrax employs advanced algorithms to uncover
                        deductions and credits you might miss. Our AI digs deep,
                        ensuring no stone is left unturned in maximizing your
                        returns.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col
                  md={6}
                  sm={12}
                  xl={4}
                  lg={6}
                  className="resp-mrg-btm-md resp-mrg-btm-xl"
                >
                  <div className="why-choose-us-card">
                    <div className="why-choose-us-icon-sec">
                      <CustomLazyLoad
                        className="why-choose-us-icon"
                        src={
                          window.location.origin +
                          "/img/why-choose-us/personalized-recommendations.png"
                        }
                        type="image/svg"
                      />
                    </div>
                    <div className="why-choose-us-info-sec">
                      <h4>Safeguards Revenue Turnover</h4>
                      <p>
                        Stay ahead of the curve. TaxTrax is continuously updated
                        with the latest tax regulations, providing you with
                        up-to-the-minute advice for informed decision-making.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col
                  md={6}
                  sm={12}
                  xl={4}
                  lg={6}
                  className="resp-mrg-btm-md resp-mrg-btm-xl"
                >
                  <div className="why-choose-us-card">
                    <div className="why-choose-us-icon-sec">
                      <CustomLazyLoad
                        className="why-choose-us-icon"
                        src={
                          window.location.origin +
                          "/img/why-choose-us/quick-efficient.png"
                        }
                        type="image/svg"
                      />
                    </div>
                    <div className="why-choose-us-info-sec">
                      <h4>Upholds Brand Trust</h4>
                      <p>
                        Simplicity is at the core of TaxTrax. Our intuitive
                        platform guides you through every step, making tax
                        preparation accessible, regardless of your financial
                        expertise.
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
