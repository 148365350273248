import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Container, Button, Form, Accordion } from "react-bootstrap";
import CustomLazyLoad from "../../../Helper/CustomLazyLoad";
import Skeleton from "react-loading-skeleton";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-multi-lang";
import { useDispatch, useSelector } from "react-redux";
import { retirementPlanCalculatorStart, retirementPlanCalculatorSuccess } from "../../../../store/slices/CommonSlice";
import { ButtonLoader } from "../../../Helper/Loader";
import FAQIndex from "../FAQIndex";
import { Link } from "react-router-dom";
import formatNumberWithCommas from "../../../FormatNumber";
//import { Finance } from "financejs";

const CalculatorIndex = (props) => {
  const t = useTranslation("tax_calculator");
  const currentAgeRef = useRef(null);
  const monthlyExpenseRef = useRef(null);
  const dispatch = useDispatch();
  const formRef = useRef();
  const retirementPlanCalculator = useSelector(
    (state) => state.common.retirementPlanCalculator
  );

  useEffect(() => {
    const onChange = (evt, field) => {
      const customEvent = evt;
      switch (field) {
        case "currentAge":
          formRef.current.setFieldValue(
            "current_age",
            customEvent.detail.value
          );
          // formRef.current.submitForm();
          break;
        case "monthlyExpense":
          formRef.current.setFieldValue(
            "monthly_expense",
            customEvent.detail.value
          );
          // formRef.current.submitForm();
          break;
      }
    };
    currentAgeRef.current?.addEventListener("change", (evt) =>
      onChange(evt, "currentAge")
    );
    monthlyExpenseRef.current?.addEventListener("change", (evt) =>
      onChange(evt, "monthlyExpense")
    );
    return () => {
      currentAgeRef.current?.removeEventListener("change", onChange);
      monthlyExpenseRef.current?.removeEventListener("change", onChange);
    };
  }, []);

  useEffect(() => {
    dispatch(retirementPlanCalculatorStart(formRef.current.values));
    // calculate(formRef.current.values);
  }, []);

  const onChangeInput = (event, field) => {
    const value = !isNaN(parseInt(event.target.value))
      ? parseInt(event.target.value)
      : "";
    switch (field) {
      case "currentAge":
        formRef.current.setFieldValue("current_age", value);
        currentAgeRef.current.value = value;
        break;
      case "monthlyExpense":
        formRef.current.setFieldValue("monthly_expense", value);
        monthlyExpenseRef.current.value = value;
        break;
    }
  };

  const handleSubmit = (values) => {
    dispatch(retirementPlanCalculatorStart(values));
    //formRef.current.isValid && calculate(values);
  };

  const retirementSchema = Yup.object().shape({
    current_age: Yup.string()
      .test(
        "min",
        t("retirement_calculator.invalid_min", { value: 18 }),
        (value) => {
          return value >= 18;
        }
      )
      .test(
        "max",
        t("retirement_calculator.invalid_max", {
          value: 65,
          name: "Current Age",
        }),
        (value) => {
          return value <= 65;
        }
      )
      .required(t("retirement_calculator.required")),
    monthly_expense: Yup.string()
      .test(
        "min",
        t("retirement_calculator.invalid_min", { value: 1000 }),
        (value) => {
          return value >= 1000;
        }
      )
      .test(
        "max",
        t("retirement_calculator.invalid_max", {
          value: 1000000,
          name: "Monthly Expense",
        }),
        (value) => {
          return value <= 1000000;
        }
      )
      .required(t("retirement_calculator.required")),
  });

  //const finance = new Finance();

  // const calculateAnnualIncomeRequirement = (monthly_expense, inflation_rate, retirement_age, current_age) => {
  //   const future_expanses = monthly_expense * Math.pow((1 + inflation_rate), (retirement_age - current_age));
  //   return Math.round(future_expanses * 12);
  // };

  // const calculate = (values) => {
  //   const { monthly_expense, rate_of_return, current_age, retirement_age, retirement_type } = values;

  //   if (!monthly_expense || !rate_of_return || !current_age || !retirement_age || !retirement_type) {
  //     return;
  //   }

  //   const inflation_rate = 0.06;
  //   const rateOfReturn = rate_of_return / 100;
  //   const MONTHS_IN_YEAR = 12;
  //   const retirement_years = 20;

  //   const annual_income_requirement = calculateAnnualIncomeRequirement(monthly_expense, inflation_rate, retirement_age, current_age);
  //   // console.log(`The yearly requirement is: ${annual_income_requirement} INR`);

  //   const inflation_adjusted_return = (1 + rateOfReturn) / (1 + inflation_rate) - 1;
  //   const monthly_inflation_adjusted_return = inflation_adjusted_return / MONTHS_IN_YEAR;

  //   const retirement_period_months = retirement_years * MONTHS_IN_YEAR;
  //   let monthly_income_requirement = annual_income_requirement / MONTHS_IN_YEAR;

  //   if (retirement_type === "elevated") {
  //     monthly_income_requirement *= 1.25;
  //   } else if (retirement_type === "lower") {
  //     monthly_income_requirement *= 0.75;
  //   }

  //   const retirement_corpus = finance.PV(monthly_inflation_adjusted_return, retirement_period_months, -monthly_income_requirement, 0, 1);

  //   const years_until_retirement = retirement_age - current_age;
  //   const monthly_savings_required = finance.PMT(rateOfReturn / MONTHS_IN_YEAR, years_until_retirement * MONTHS_IN_YEAR, 0, -retirement_corpus, 1);

  //   const rounded_retirement_corpus = Math.round(retirement_corpus);
  //   const rounded_monthly_savings_required = Math.round(monthly_savings_required);

  //   const result = {
  //     retirement_corpous: rounded_retirement_corpus,
  //     saving_per_month: rounded_monthly_savings_required
  //   };

  //   console.log(result)

  //   dispatch(retirementPlanCalculatorSuccess(result));
  // };

  return (
    <>
      <div className="single-tax-calculator">
        <div className="sm-padding single-tax-calculator-sec">
          <Container>
            <div className="single-tax-calculator-box">
              <div className="single-tax-calculator-header">
                <Row>
                  <Col md={6}>
                    <div className="d-flex other-calculator-btn-sec">
                      <Link
                        to="/tax-calculator"
                        className="other-calculator-btn"
                      >
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            enableBackground="new 0 0 512 512"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="#fff"
                              d="M10.957 12.354a.5.5 0 010-.708l4.586-4.585a1.5 1.5 0 00-2.121-2.122L8.836 9.525a3.505 3.505 0 000 4.95l4.586 4.586a1.5 1.5 0 002.121-2.122z"
                              data-original="#000000"
                            ></path>
                          </svg>
                        </span>
                        <span>Other Calculators</span>
                      </Link>
                    </div>
                    <div className="single-tax-calculator-info-sec">
                      <h1>{t("retirement_calculator.heading")}</h1>
                      <p>{t("retirement_calculator.content")}</p>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="single-tax-calculator-header-img-sec">
                      <CustomLazyLoad
                        className="single-tax-calculator-header-img"
                        src={
                          window.location.origin +
                          "/img/single-tax-calculator-header-img.png"
                        }
                        type="image/svg"
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="single-tax-calculator-form-box  line-stright line-bit-left">
              <Row className="align-items-center">
                <Col
                  md={12}
                  xl={6}
                  lg={12}
                  className="resp-margin-btm-small-lap"
                >
                  <Formik
                    initialValues={{
                      current_age: 25,
                      monthly_expense: 25000,
                      retirement_type: "elevated",
                      rate_of_return: 7,
                      retirement_age: 60,
                      current_savings: 0,
                    }}
                    innerRef={formRef}
                    validationSchema={retirementSchema}
                    onSubmit={handleSubmit}
                  >
                    {({ setFieldValue, values, resetForm, submitForm }) => (
                      <FORM className="single-tax-calculator-form ">
                        <div className="single-tax-calculator-form-card">
                          <div className="single-tax-calculator-form-header-sec">
                            <h4>{t("retirement_calculator.age.text")}?</h4>
                            <Form.Group
                              className="mb-0"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Field
                                className="form-control"
                                name="current_age"
                                placeholder={t(
                                  "retirement_calculator.age.placeholder"
                                )}
                                type="number"
                                onKeyDown={(e) => {
                                  if (
                                    !/[0-9]|Backspace|ArrowLeft|ArrowRight|Delete|ArrowUp|ArrowDown|Tab/.test(
                                      e.key
                                    ) &&
                                    !(
                                      e.ctrlKey &&
                                      (e.key === "v" ||
                                        e.key === "c" ||
                                        e.key === "x" ||
                                        e.key === "a")
                                    )
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                                onInput={(e) => {
                                  if (e.target.value < 0) {
                                    e.target.value = 0;
                                  }
                                  onChangeInput(e, "currentAge");
                                  //  submitForm();
                                }}
                              />
                            </Form.Group>
                          </div>
                          <ErrorMessage
                            component={"div"}
                            name="current_age"
                            className="errorMsg"
                          />
                          <div className="tax-calculator-input-slider-sec">
                            <tc-range-slider
                              ref={currentAgeRef}
                              value={currentAgeRef}
                              mousewheel-disabled="true"
                              min={18}
                              max={65}
                              slider-width="100%"
                              slider-bg-fill="#2F5CFF"
                              generate-labels="true"
                              round="0"
                            ></tc-range-slider>
                          </div>
                        </div>
                        <div className="single-tax-calculator-form-card">
                          <div className="single-tax-calculator-form-header-sec">
                            <h4>{t("retirement_calculator.expense.text")}?</h4>
                            <Form.Group
                              className="mb-0"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Field
                                className="form-control"
                                name="monthly_expense"
                                placeholder={t(
                                  "retirement_calculator.expense.placeholder"
                                )}
                                type="number"
                                onKeyDown={(e) => {
                                  if (
                                    !/[0-9]|Backspace|ArrowLeft|ArrowRight|Delete|ArrowUp|ArrowDown|Tab/.test(
                                      e.key
                                    ) &&
                                    !(
                                      e.ctrlKey &&
                                      (e.key === "v" ||
                                        e.key === "c" ||
                                        e.key === "x" ||
                                        e.key === "a")
                                    )
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                                onInput={(e) => {
                                  if (e.target.value < 0) {
                                    e.target.value = 0;
                                  }
                                  onChangeInput(e, "monthlyExpense");
                                  //submitForm();
                                }}
                              />
                            </Form.Group>
                          </div>
                          <ErrorMessage
                            component={"div"}
                            name="monthly_expense"
                            className="errorMsg"
                          />
                          <div className="tax-calculator-input-slider-sec">
                            <tc-range-slider
                              ref={monthlyExpenseRef}
                              value={monthlyExpenseRef}
                              mousewheel-disabled="true"
                              min={1000}
                              max={1000000}
                              slider-width="100%"
                              slider-bg-fill="#2F5CFF"
                              generate-labels="true"
                              round="0"
                            ></tc-range-slider>
                          </div>
                        </div>
                        <div className="single-tax-calculator-form-card">
                          <div className="single-tax-calculator-form-header-sec d-inline">
                            <h4>
                              {t(
                                "retirement_calculator.what_kind_of_retirement_you_want"
                              )}
                              ?
                            </h4>
                            <div className="question-type-checkbox">
                              <div class="pretty p-default p-round p-smooth">
                                <Field
                                  type="radio"
                                  name="retirement_type"
                                  value="elevated"
                                  className="form-control"
                                  checked={values.retirement_type == "elevated"}
                                  onChange={(e) => {
                                    setFieldValue("retirement_type", "elevated")
                                    //submitForm();
                                  }}
                                />
                                <div class="state p-primary-o">
                                  <label>
                                    {t("retirement_calculator.like_a_king")}
                                  </label>
                                </div>
                              </div>
                              <div class="pretty p-default p-round p-smooth">
                                <Field
                                  type="radio"
                                  name="retirement_type"
                                  value="normal"
                                  className="form-control"
                                  checked={values.retirement_type == "normal"}
                                  onChange={(e) => {
                                    setFieldValue("retirement_type", "normal")
                                    //  submitForm();
                                  }}
                                />
                                <div class="state p-primary-o">
                                  <label>
                                    {t(
                                      "retirement_calculator.i_am_happy_the_way_i_am"
                                    )}
                                  </label>
                                </div>
                              </div>
                              <div class="pretty p-default p-round p-smooth">
                                <Field
                                  type="radio"
                                  name="retirement_type"
                                  value="lower"
                                  className="form-control"
                                  checked={values.retirement_type == "lower"}
                                  onChange={(e) => {
                                    setFieldValue("retirement_type", "lower")
                                    // submitForm();
                                  }}
                                />
                                <div class="state p-primary-o">
                                  <label>
                                    {t("retirement_calculator.like_a_monk")}
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="single-tax-calculator-form-card">
                          <div className="single-tax-calculator-form-header-sec d-inline">
                            <h4>
                              {t(
                                "retirement_calculator.where_are_you_saving_for_your_retirement"
                              )}
                              ?
                            </h4>
                            <div className="question-type-checkbox">
                              <div class="pretty p-default p-round p-smooth">
                                <Field
                                  type="radio"
                                  name="rate_of_return"
                                  value={7}
                                  className="form-control"
                                  checked={values.rate_of_return == 7}
                                  onChange={(e) => {
                                    setFieldValue("rate_of_return", 7)
                                    //  submitForm();
                                  }}
                                />
                                <div class="state p-primary-o">
                                  <label>
                                    {t("retirement_calculator.safe")}(
                                    {t("retirement_calculator.pf_fd_etc")})
                                  </label>
                                </div>
                              </div>
                              <div class="pretty p-default p-round p-smooth">
                                <Field
                                  type="radio"
                                  name="rate_of_return"
                                  value={12}
                                  className="form-control"
                                  checked={values.rate_of_return == 12}
                                  onChange={(e) => {
                                    setFieldValue("rate_of_return", 12)
                                    //  submitForm();
                                  }}
                                />
                                <div class="state p-primary-o">
                                  <label>
                                    {t("retirement_calculator.aggressive")} (
                                    {t(
                                      "retirement_calculator.mutual_fund_equity_etc"
                                    )}
                                    ){" "}
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex justify-content-end mt-3">
                          <Button
                            type="submit"
                            className="default-primary-btn"
                            disabled={retirementPlanCalculator.buttonDisable}
                          >
                            {retirementPlanCalculator.buttonDisable ? (
                              <ButtonLoader />
                            ) : (
                              t("retirement_calculator.calculate")
                            )}
                          </Button>
                        </div>
                      </FORM>
                    )}
                  </Formik>
                </Col>
                <Col md={12} xl={6} lg={12}>
                  {retirementPlanCalculator.loading ? (
                    <>
                      <div className="single-tax-calculator-form-value-details-sec ">
                        <Skeleton
                          width={250}
                          height={15}
                          baseColor="#202020"
                          highlightColor="#444"
                        />
                        <Skeleton
                          width={190}
                          height={55}
                          borderRadius={10}
                          baseColor="#202020"
                          highlightColor="#444"
                        />
                      </div>
                      <div className="single-tax-calculator-form-value-details-sec margin-top-sm">
                        <Skeleton
                          width={360}
                          height={15}
                          baseColor="#202020"
                          highlightColor="#444"
                        />
                        <Skeleton
                          width={140}
                          height={55}
                          borderRadius={10}
                          baseColor="#202020"
                          highlightColor="#444"
                        />
                      </div>
                    </>
                  ) : (
                    Object.keys(retirementPlanCalculator.data).length > 0 && (
                      <>
                        <div className="single-tax-calculator-form-value-details-sec ">
                          <p>
                            {t(
                              "retirement_calculator.amount_required_for_retirement"
                            )}
                          </p>
                          <div className="single-tax-calculator-form-value-amount">
                            {formatNumberWithCommas(
                              retirementPlanCalculator.data.retirement_corpous
                            )}
                          </div>
                        </div>
                        <div className="single-tax-calculator-form-value-details-sec  mt-3">
                          <p>
                            {t(
                              "retirement_calculator.how_much_do_you_need_to_save_per_month_to_retire"
                            )}
                            ?
                          </p>
                          <div className="single-tax-calculator-form-value-amount">
                            {formatNumberWithCommas(
                              retirementPlanCalculator.data.saving_per_month
                            )}
                          </div>
                        </div>
                      </>
                    )
                  )}
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <Container>
          <Row>
            <Col lg={12}>
              <div className="static-page-card">
                <div className="pricacy-policy-info">
                  <h4>Description</h4>
                  <p>
                    Achieving a comfortable retirement is entirely feasible with
                    smart savings and investment strategies. Utilizing a
                    retirement calculator can help you understand the potential
                    returns on your investments, highlighting the importance of
                    combining financial discipline and savvy investing when
                    preparing for the future.
                  </p>
                  <p>
                    It's critical for everyone, including those who are salaried
                    and self-employed, to engage in retirement planning.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <FAQIndex
          data={[
            {
              question:
                "Do I need a private retirement plan if I'm employed by a private company?",
              answer:
                "Absolutely. Regardless of your employer's size or industry, having a private retirement plan is a wise decision to ensure your financial security in retirement.",
            },
            {
              question: "How much should I aim to save for retirement?",
              answer:
                "The amount you need	 to save for retirement varies based on your lifestyle, expenses, and retirement goals. A general guideline is to aim for enough savings to maintain your current standard of living in retirement.",
            },
            {
              question:
                "What investment options are highly recommended for retirement savings?",
              answer:
                "Several investment options are favored for retirement savings, including mutual funds, stocks, bonds, and real estate. The best choice depends on your risk tolerance, investment horizon, and financial goals.",
            },
          ]}
        />
      </div>
    </>
  );
};

export default CalculatorIndex;
