import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Image,
  Container,
  Button,
  Form,
  Accordion,
} from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-multi-lang";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import {
  tdsCalculatorStart,
  tdsCalculatorSuccess,
} from "../../../../store/slices/CommonSlice";
import { ButtonLoader } from "../../../Helper/Loader";
import FAQIndex from "../FAQIndex";
import CustomLazyLoad from "../../../Helper/CustomLazyLoad";
import { Link } from "react-router-dom";
import formatNumberWithCommas from "../../../FormatNumber";

const CalculatorIndex = (props) => {
  const t = useTranslation("tax_calculator");
  const dispatch = useDispatch();
  const formRef = useRef();
  const amountRef = useRef(null);
  const tdsCalculator = useSelector((state) => state.common.tdsCalculator);

  useEffect(() => {
    const onChange = (event, field) => {
      const customEvent = event;
      switch (field) {
        case "amount":
          formRef.current.setFieldValue("amount", customEvent.detail.value);
          break;
      }
      setTimeout(() => {
        formRef.current.submitForm();
      }, 1);
    };
    amountRef.current?.addEventListener("change", (event) =>
      onChange(event, "amount")
    );
    return () => {
      amountRef.current?.removeEventListener("change", onChange);
    };
  }, []);

  useEffect(() => {
    calculate(formRef.current.values);
    // dispatch(tdsCalculatorStart(formRef.current.values));
  }, []);

  const onChangeInput = (event, field) => {
    const value =
      !isNaN(parseInt(event.target.value)) == true
        ? parseFloat(event.target.value)
        : "";
    switch (field) {
      case "amount":
        formRef.current.setFieldValue("amount", value);
        amountRef.current.value = value;
        break;
    }
    setTimeout(() => {
      formRef.current.submitForm();
    }, 1);
  };

  const handleSubmit = (values) => {
    // formRef.current.isValid && calculate(values);
    formRef.current.isValid && dispatch(tdsCalculatorStart(values));
  };

  const tdsSchema = Yup.object().shape({
    amount: Yup.string()
      .test(
        "min",
        t("tds_calculator.invalid_min", { value: 1000 }),
        (value) => {
          return value >= 1000;
        }
      )
      .test(
        "max",
        t("tds_calculator.invalid_max", {
          value: 10000000,
          name: "Amount",
        }),
        (value) => {
          return value <= 10000000;
        }
      )
      .required(t("tds_calculator.required")),
    section: Yup.string().required(t("tds_calculator.required")),
  });

  const customStyles = {
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "8px",
      overflow: "hidden",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      minWidth: 250,
      fontSize: "0.85em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "3px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "auto" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "#3E3D45!important",
      border: "1px solid #1A203D99!important",
      borderRadius: "8px!important",
      boxShadow: "none!important",
      height: "45px",
      minWidth: "150px",
      cursor: "pointer",
      fontSize: "0.9em",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#fff",
      fontSize: "1.1em",
      fontWeight: "600",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#fff",
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
      fontSize: "0.9em",
      fontWeight: "600",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      color: "#fff!important",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      fill: "#fff!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#fff",
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isSelected ? "#f7f7f7" : "#fff",
        color: "#000",
      };
    },
  };

  const options = [
    { value: "192A", label: t("tds_calculator.premature_withdrawal_from_epf") },
    { value: "193", label: t("tds_calculator.interest_on_securities") },
    { value: "194", label: t("tds_calculator.dividends_income") },
    { value: "194A", label: t("tds_calculator.interest_on_other_banks") },
    {
      value: "194B",
      label: t("tds_calculator.winnings_from_lotteries_crossword_puzzles_etc"),
    },
    { value: "194BB", label: t("tds_calculator.winnings_from_horse_race") },
    {
      value: "194C",
      label: t("tds_calculator.payment_to_contractors_subcontractors"),
    },
    { value: "194D", label: t("tds_calculator.insurance_commission") },
    { value: "194H", label: t("tds_calculator.commission_or_brokerage") },
    { value: "194I_A", label: t("tds_calculator.rent_of_plant_and_machinery") },
    { value: "194I_B", label: t("tds_calculator.rent_of_land_para") },
    {
      value: "194IA",
      label: t("tds_calculator.transfer_of_immovable_property"),
    },
    { value: "194IB", label: t("tds_calculator.rent_by_individual_or_huf") },
    {
      value: "194IC",
      label: t("tds_calculator.payment_under_specified_agreement"),
    },
    {
      value: "194J_professional",
      label: t("tds_calculator.professional_fees"),
    },
    { value: "194J_technical", label: t("tds_calculator.other_fees") },
    { value: "194K", label: t("tds_calculator.income_in_respect_of_units") },
    { value: "194LA", label: t("tds_calculator.payment_of_compensation_para") },
    { value: "194LBA", label: t("tds_calculator.business_trust") },
    {
      value: "194LBB",
      label: t(
        "tds_calculator.investment_fund_paying_an_income_to_a_unit_holder"
      ),
    },
    {
      value: "194LBC",
      label: t("tds_calculator.income_in_respect_of_investment_para"),
    },
    { value: "194M", label: t("tds_calculator.commission_brokerage_etc") },
    { value: "194N", label: t("tds_calculator.cash_withdrawal") },
  ];

  const taxCalcOldRegime = (income) => {
    if (income <= 500000) {
      return 0;
    } else if (income <= 1000000) {
      return (income - 500000) * 0.2 + 12500;
    } else if (income <= 5000000) {
      return (income - 1000000) * 0.3 + 112500;
    } else if (income <= 10000000) {
      return ((income - 1000000) * 0.3 + 112500) * 1.1;
    } else if (income <= 20000000) {
      return ((income - 1000000) * 0.3 + 112500) * 1.15;
    } else if (income <= 50000000) {
      return ((income - 1000000) * 0.3 + 112500) * 1.25;
    } else {
      return ((income - 1000000) * 0.3 + 112500) * 1.37;
    }
  };

  const taxCalcNewRegime = (income) => {
    let tax;
    if (income <= 300000) {
      tax = 0;
    } else if (income <= 600000) {
      tax = (income - 300000) * 0.05;
    } else if (income <= 900000) {
      tax = (income - 600000) * 0.1 + 15000;
    } else if (income <= 1200000) {
      tax = (income - 900000) * 0.15 + 45000;
    } else if (income <= 1500000) {
      tax = (income - 1200000) * 0.2 + 90000;
    } else {
      tax = (income - 1500000) * 0.3 + 150000;
    }

    if (income <= 700000) {
      tax = 0;
    }

    let surchargeRate;
    if (income <= 5000000) {
      surchargeRate = 0;
    } else if (income <= 10000000) {
      surchargeRate = 0.1;
    } else if (income <= 20000000) {
      surchargeRate = 0.15;
    } else {
      surchargeRate = 0.25;
    }

    let surcharge = tax * surchargeRate;
    let totalTax = tax + surcharge;
    return totalTax;
  };

  const calculate = (values) => {
    const { recipient_type, section, amount, income, tax_regime } = values;
    const tdsRates = {
      "192A": 10,
      193: 10,
      194: 10,
      "194A": 10,
      "194B": 30,
      "194BB": 30,
      "194C": { individuals: 1, others: 2 },
      "194D": 5,
      "194H": 5,
      "194I_A": 2,
      "194I_B": 10,
      "194IA": 1,
      "194IB": 5,
      "194IC": 10,
      "194J_professional": 2,
      "194J_technical": 2,
      "194K": 10,
      "194LA": 10,
      "194LBA": 10,
      "194LBB": 10,
      "194LBC": 25,
      "194M": 5,
      "194N": { exceeds_20lakhs: 2, exceeds_1crore: 5 },
    };

    if (section === "192") {
      if (tax_regime === "old") {
        return dispatch(
          tdsCalculatorSuccess({ tds: Math.round(taxCalcOldRegime(income)) })
        );
      } else if (tax_regime === "new") {
        return dispatch(
          tdsCalculatorSuccess({ tds: Math.round(taxCalcNewRegime(income)) })
        );
      } else {
        return dispatch(tdsCalculatorSuccess({ tds: 0 }));
      }
    } else if (tdsRates.hasOwnProperty(section)) {
      let rate = tdsRates[section];

      if (typeof rate === "object") {
        if (section === "194N" && income > 0) {
          if (amount > 10000000) {
            rate = rate["exceeds_1crore"];
          } else if (amount > 2000000) {
            rate = rate["exceeds_20lakhs"];
          } else {
            return dispatch(tdsCalculatorSuccess({ tds: 0 }));
          }
        } else if (section === "194C") {
          rate =
            recipient_type === "others" ? rate["others"] : rate["individuals"];
        }
      }

      const tdsAmount = (amount * rate) / 100;
      return dispatch(tdsCalculatorSuccess({ tds: Math.round(tdsAmount) }));
    } else {
      return dispatch(tdsCalculatorSuccess({ tds: 0 }));
    }
  };

  return (
    <>
      <div className="single-tax-calculator">
        <div className="sm-padding single-tax-calculator-sec">
          <Container>
            <div className="single-tax-calculator-box">
              <div className="single-tax-calculator-header">
                <Row>
                  <Col md={6}>
                    <div className="d-flex other-calculator-btn-sec">
                      <Link
                        to="/tax-calculator"
                        className="other-calculator-btn"
                      >
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            enableBackground="new 0 0 512 512"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="#fff"
                              d="M10.957 12.354a.5.5 0 010-.708l4.586-4.585a1.5 1.5 0 00-2.121-2.122L8.836 9.525a3.505 3.505 0 000 4.95l4.586 4.586a1.5 1.5 0 002.121-2.122z"
                              data-original="#000000"
                            ></path>
                          </svg>
                        </span>
                        <span>Other Calculators</span>
                      </Link>
                    </div>
                    <div className="single-tax-calculator-info-sec">
                      <h1>{t("tds_calculator.heading")}</h1>
                      <p>{t("tds_calculator.content")}</p>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="single-tax-calculator-header-img-sec">
                      <CustomLazyLoad
                        className="single-tax-calculator-header-img"
                        src={
                          window.location.origin +
                          "/img/single-tax-calculator-header-img.png"
                        }
                        type="image/svg"
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="single-tax-calculator-form-box  line-stright line-bit-left">
              <Row className="align-items-center">
                <Col
                  md={12}
                  xl={6}
                  lg={12}
                  className="resp-margin-btm-small-lap"
                >
                  <Formik
                    initialValues={{
                      recipient_type: "individual",
                      section: "192A",
                      amount: 1300000,
                      income: 0,
                      tax_regime: "new",
                    }}
                    innerRef={formRef}
                    validationSchema={tdsSchema}
                    onSubmit={handleSubmit}
                  >
                    {({
                      setFieldValue,
                      values,
                      resetForm,
                      submitForm,
                      isValid,
                    }) => (
                      <FORM className="single-tax-calculator-form">
                        <div className="single-tax-calculator-form-card mb-3">
                          <div className="single-tax-calculator-form-header-sec d-inline">
                            <h4>{t("tds_calculator.recipient.text")}</h4>
                            <div className="question-type-checkbox">
                              <div class="pretty p-default p-round p-smooth">
                                <Field
                                  type="radio"
                                  name="recipient_type"
                                  value="individual"
                                  className="form-control"
                                  checked={
                                    values.recipient_type == "individual"
                                  }
                                  onChange={(e) => {
                                    setFieldValue(
                                      "recipient_type",
                                      e.target.value
                                    );
                                    setTimeout(() => {
                                      submitForm();
                                    }, 1);
                                  }}
                                />
                                <div class="state p-primary-o">
                                  <label>
                                    {t(
                                      "tds_calculator.recipient.individual_huf_para"
                                    )}
                                  </label>
                                </div>
                              </div>
                              <div class="pretty p-default p-round p-smooth">
                                <Field
                                  type="radio"
                                  name="recipient_type"
                                  value="others"
                                  className="form-control"
                                  checked={values.recipient_type == "others"}
                                  onChange={(e) => {
                                    setFieldValue(
                                      "recipient_type",
                                      e.target.value
                                    );
                                    setTimeout(() => {
                                      submitForm();
                                    }, 1);
                                  }}
                                />
                                <div class="state p-primary-o">
                                  <label>
                                    {t("tds_calculator.recipient.others")}
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="single-tax-calculator-form-card mb-3">
                          <div className="single-tax-calculator-form-header-sec d-inline">
                            <h4>{t("tds_calculator.tax_regime.text")}</h4>
                            <div className="question-type-checkbox">
                              <div class="pretty p-default p-round p-smooth">
                                <Field
                                  type="radio"
                                  name="tax_regime"
                                  value="new"
                                  className="form-control"
                                  checked={values.tax_regime == "new"}
                                  onChange={(e) => {
                                    setFieldValue("tax_regime", e.target.value);
                                    setTimeout(() => {
                                      submitForm();
                                    }, 1);
                                  }}
                                />
                                <div class="state p-primary-o">
                                  <label>
                                    {t("tds_calculator.tax_regime.new_regime")}
                                  </label>
                                </div>
                              </div>
                              <div class="pretty p-default p-round p-smooth">
                                <Field
                                  type="radio"
                                  name="tax_regime"
                                  value="old"
                                  className="form-control"
                                  checked={values.tax_regime == "old"}
                                  onChange={(e) => {
                                    setFieldValue("tax_regime", e.target.value);
                                    setTimeout(() => {
                                      submitForm();
                                    }, 1);
                                  }}
                                />
                                <div class="state p-primary-o">
                                  <label>
                                    {t("tds_calculator.tax_regime.old_regime")}
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="single-tax-calculator-form-card mb-3">
                          <div className="single-tax-calculator-form-header-sec d-inline">
                            <h4 className="mb-3">
                              {t("tds_calculator.nature_of_payment.text")}
                            </h4>
                            <Select
                              options={options}
                              styles={customStyles}
                              isSearchable={false}
                              name="section"
                              defaultValue={options.find(
                                (option) => option.value === "192A"
                              )}
                              onChange={(selectedOption) => {
                                setFieldValue(
                                  "section",
                                  selectedOption == null
                                    ? ""
                                    : selectedOption.value
                                );
                                setTimeout(() => {
                                  submitForm();
                                }, 1);
                              }}
                              placeholder={
                                <div className="placeholder-flex">
                                  {t("tds_calculator.nature_of_payment.select")}
                                </div>
                              }
                              isClearable
                            />
                          </div>
                          <ErrorMessage
                            component={"div"}
                            name="section"
                            className="errorMsg"
                          />
                        </div>
                        <div className="single-tax-calculator-form-card">
                          <div className="single-tax-calculator-form-header-sec">
                            <h4>
                              {t("tds_calculator.amount_of_payment.text")}
                            </h4>
                            <Form.Group
                              className="mb-0"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Field
                                className="form-control"
                                name="amount"
                                placeholder={t(
                                  "tds_calculator.amount_of_payment.placeholder"
                                )}
                                type="number"
                                onKeyDown={(e) => {
                                  if (
                                    !/[0-9.]|Backspace|ArrowLeft|ArrowRight|Delete|ArrowUp|ArrowDown|Tab/.test(
                                      e.key
                                    ) ||
                                    (e.key === "-" &&
                                      e.target.selectionStart !== 0)
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                                onInput={(e) => {
                                  if (e.target.value < 0) {
                                    e.target.value = 0;
                                  }
                                  setTimeout(() => {
                                    submitForm();
                                  }, 1);
                                }}
                              />
                            </Form.Group>
                          </div>
                          <ErrorMessage
                            component={"div"}
                            name="amount"
                            className="errorMsg"
                          />
                          <div className="tax-calculator-input-slider-sec">
                            <tc-range-slider
                              ref={amountRef}
                              value={amountRef}
                              mousewheel-disabled="true"
                              min={1000}
                              max={10000000}
                              slider-width="100%"
                              slider-bg-fill="#2F5CFF"
                              generate-labels="true"
                              round="0"
                            ></tc-range-slider>
                          </div>
                        </div>
                        {/* <div className="d-flex justify-content-end mt-4">
                          <Button
                            type="submit"
                            className="default-primary-btn"
                            disabled={tdsCalculator.buttonDisable}
                          >
                            {tdsCalculator.buttonDisable ? (
                              <ButtonLoader />
                            ) : (
                              t("nsc_calculator.calculate")
                            )}
                          </Button>
                        </div> */}
                      </FORM>
                    )}
                  </Formik>
                </Col>
                <Col md={12} xl={6} lg={12}>
                  {tdsCalculator.loading ? (
                    <div className="single-tax-calculator-form-value-details-sec ">
                      <Skeleton
                        width={80}
                        baseColor="#202020"
                        highlightColor="#444"
                        height={20}
                      />
                      <Skeleton
                        width={130}
                        baseColor="#202020"
                        highlightColor="#444"
                        borderRadius={10}
                        height={60}
                      />
                    </div>
                  ) : (
                    Object.keys(tdsCalculator.data).length > 0 && (
                      <div className="single-tax-calculator-form-value-details-sec ">
                        <p>{t("tds_calculator.total_tds")}</p>
                        <div className="single-tax-calculator-form-value-amount">
                          {formatNumberWithCommas(tdsCalculator.data.tds)}
                        </div>
                      </div>
                    )
                  )}
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <Container>
          <Row>
            <Col lg={12}>
              <div className="static-page-card">
                <div className="pricacy-policy-info">
                  <h4>Description</h4>
                  <p>
                    Tax Deducted at Source (TDS) is a specific amount withheld
                    when payments such as salary, rent, commission, interest,
                    and others are made. The entity making the payment deducts
                    this amount, while the recipient of the payment has the
                    obligation to pay the tax. TDS helps reduce tax evasion by
                    collecting taxes at the source, or the time the payment is
                    made.
                  </p>
                  <p>
                    You can determine the amount of your TDS or the interest on
                    your TDS using a TDS or TDS interest calculator. This tool
                    will assist you in estimating the amount of TDS that should
                    be deducted from your payments.
                  </p>
                </div>
                <div className="pricacy-policy-info">
                  <h4>What is a TDS Calculator?</h4>
                  <p>
                    The TDS calculator is an accessible online tool that helps
                    you determine whether TDS needs to be deducted from a
                    payment. If TDS deduction is required, the calculator will
                    show you the correct amount that should be deducted. This
                    tool also allows you to verify if the correct amount of TDS
                    was deducted from past transactions and offers basic
                    guidance on the rules related to TDS.
                  </p>
                  <p>
                    Using the TDS online calculator is straightforward, and it
                    is available for use whenever needed, without any usage
                    limits.
                  </p>
                  <h4>How the TDS Calculator Benefits You</h4>
                  <p>
                    The TDS calculator informs users about the amount of TDS
                    deducted from their salary, enabling them to determine their
                    net take-home pay. This helps in better financial planning
                    and budgeting. The calculator is straightforward to use; it
                    requires no signup or login—simply input some basic
                    information, and it provides the results instantly.
                  </p>
                  <p>
                    Operating on a fast and efficient algorithm, the calculator
                    delivers results in seconds, avoiding the lengthy and
                    cumbersome process of manual calculations. Additionally, it
                    helps eliminate potential errors that can occur with manual
                    calculations, ensuring accuracy in determining your
                    financial obligations.
                  </p>
                  <h4>How to Use the TDS Amount Calculator?</h4>
                  <p>
                    Operating the TDS calculator is straightforward. You simply
                    need to provide the following details:
                  </p>
                  <ul>
                    <li>
                      <h4>Recipient Type: </h4>
                      Identify what type of recipient you are, such as an
                      individual, a sole proprietor, a HUF (Hindu Undivided
                      Family), or another entity.
                    </li>
                    <li>
                      <h4>Payment Amount: </h4>
                      Input the amount of the payment you are making or
                      receiving.
                    </li>
                    <li>
                      <h4>Nature of Payment:</h4>Specify the nature of the
                      payment, for example, whether it is a salary, interest
                      from a fixed deposit (FD), or another type of payment.
                    </li>
                  </ul>
                  <p>
                    Once you've entered these details, the calculator will
                    quickly process the information and display the results. It
                    will indicate whether TDS is applicable to your transaction.
                    If TDS is deductible, the calculator will also show the
                    amount of TDS that should be deducted.
                  </p>
                </div>
                <div className="pricacy-policy-info">
                  <h4>Benefits of Using an Online TDS Calculator</h4>
                  <p>
                    The online TDS calculator offers several advantages,
                    including:
                  </p>
                  <ul>
                    <li>
                      <h4>Accurate Estimations:</h4>
                      Helps you accurately estimate the TDS amount that needs to
                      be deducted for specific transactions.
                    </li>
                    <li>
                      <h4>User-Friendliness: </h4>
                      The calculator is easy to use, making it a convenient tool
                      for quickly calculating TDS amounts.
                    </li>
                    <li>
                      <h4>Informative:</h4>
                      Provides detailed information about the amount and the
                      fundamental principles associated with the specific
                      transaction.
                    </li>
                    <li>
                      <h4>Verification: </h4>
                      Assists in verifying whether the TDS deducted is correct.
                    </li>
                    <li>
                      <h4>Efficiency: </h4>
                      Enables quick and easy TDS checks with just a few clicks.
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <FAQIndex
          data={[
            {
              question: "What formula is used to calculate TDS?",
              answer:
                "The formula for calculating TDS depends on the nature of the payment and the applicable tax slabs. Generally, TDS is calculated by applying the relevant percentage rate to the payment amount as specified by tax laws.",
            },
            {
              question: "How does the TDS calculator function?",
              answer:
                "The TDS calculator simplifies the process of determining the TDS to be deducted from a payment. By inputting specifics like the payment amount, nature of the payment, and the recipient’s details, the calculator quickly computes the TDS based on current tax regulations.",
            },
            {
              question:
                "What details are required to calculate my TDS using the calculator?",
              answer:
                "To calculate TDS, you typically need to provide the amount of the transaction, the nature of the payment (such as salary, commission, rent, etc.), and the type of recipient (individual, company, etc.).",
            },
            {
              question: "When should I deposit the TDS?",
              answer:
                "TDS must be deposited with the government by the 7th of the following month in which the TDS was deducted. For TDS deducted in March, the deadline is extended to April 30th.",
            },
            {
              question:
                "Does the TDS calculator indicate the percentage of tax to be deducted at the source?",
              answer:
                "Yes, the TDS calculator not only calculates the amount to be deducted but also indicates the applicable percentage rate based on the type of payment and other relevant factors as per the tax laws.",
            },
          ]}
        />
      </div>
    </>
  );
};

export default CalculatorIndex;
