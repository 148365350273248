import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Container, Accordion, Nav, Tab } from "react-bootstrap";
import CustomLazyLoad from "../../../Helper/CustomLazyLoad";
import Skeleton from "react-loading-skeleton";
import { useTranslation } from "react-multi-lang";
import BrokerageForm from "./BrokerageForm";
import { useSelector } from "react-redux";
import FAQIndex from "../FAQIndex";
import { Link } from "react-router-dom";

const CalculatorIndex = (props) => {
  const t = useTranslation("tax_calculator");
  const [tab, setTab] = useState("delivery");
  const [traderType, setTraderType] = useState("delivery");
  const [deliveryBrokerage, setDeliveryBrokerage] = useState(40);
  const [intradayBrokerage, setIntradayBrokerage] = useState(40);
  const [FoBrokerage, setFoBrokerage] = useState(40);
  const brokerageCalculator = useSelector(
    (state) => state.common.brokerageCalculator
  );

  return (
    <>
      <div className="single-tax-calculator">
        <div className="sm-padding single-tax-calculator-sec">
          <Container>
            <div className="single-tax-calculator-box">
              <div className="single-tax-calculator-header">
                <Row>
                  <Col md={6}>
                    <div className="d-flex other-calculator-btn-sec">
                      <Link
                        to="/tax-calculator"
                        className="other-calculator-btn"
                      >
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            enableBackground="new 0 0 512 512"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="#fff"
                              d="M10.957 12.354a.5.5 0 010-.708l4.586-4.585a1.5 1.5 0 00-2.121-2.122L8.836 9.525a3.505 3.505 0 000 4.95l4.586 4.586a1.5 1.5 0 002.121-2.122z"
                              data-original="#000000"
                            ></path>
                          </svg>
                        </span>
                        <span>Other Calculators</span>
                      </Link>
                    </div>
                    <div className="single-tax-calculator-info-sec">
                      <h1>{t("brokerage_calculator.heading")}</h1>
                      <p>{t("brokerage_calculator.content")}</p>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="single-tax-calculator-header-img-sec">
                      <CustomLazyLoad
                        className="single-tax-calculator-header-img"
                        src={
                          window.location.origin +
                          "/img/single-tax-calculator-header-img.png"
                        }
                        type="image/svg"
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="single-tax-calculator-form-box line-stright line-bit-left">
              <Row className="align-items-center">
                <Col
                  md={12}
                  xl={6}
                  lg={12}
                  className="resp-margin-btm-small-lap"
                >
                  <div className="single-tax-calculator-form line-stright-right ">
                    <div className="calculator-info-tabs-area">
                      <Tab.Container
                        id="left-tabs-example"
                        defaultActiveKey="equity"
                      >
                        <Nav variant="pills">
                          <Nav.Item>
                            <Nav.Link
                              eventKey="equity"
                              onClick={() => {
                                setTraderType("delivery");
                                setTab("delivery");
                              }}
                            >
                              {t("brokerage_calculator.equity_delivery")}
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link
                              eventKey="reducing"
                              onClick={() => {
                                setTraderType("intraday");
                                setTab("intraday");
                              }}
                            >
                              {t("brokerage_calculator.equity_intraday")}
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link
                              eventKey="fo"
                              onClick={() => {
                                setTraderType("option");
                                setTab("fo");
                              }}
                            >
                              {t("brokerage_calculator.equity_fo")}
                            </Nav.Link>
                          </Nav.Item>
                        </Nav>
                        <Tab.Content>
                          <Tab.Pane eventKey="equity">
                            {tab == "delivery" &&
                              <BrokerageForm
                                tab={tab}
                                traderType={traderType}
                                setDeliveryBrokerage={setDeliveryBrokerage}
                                setTraderType={setTraderType}
                                sttAmount={0.001}
                              />

                            }

                          </Tab.Pane>
                          <Tab.Pane eventKey="reducing">
                            {tab == "intraday" &&
                              <BrokerageForm
                                tab={tab}
                                traderType={traderType}
                                setIntradayBrokerage={setIntradayBrokerage}
                                setTraderType={setTraderType}
                                sttAmount={0.00025}
                              />
                            }
                          </Tab.Pane>
                          <Tab.Pane eventKey="fo">
                            {tab == "fo" &&
                              <BrokerageForm
                                tab={tab}
                                traderType={traderType}
                                setTab={setTab}
                                setFoBrokerage={setFoBrokerage}
                                setTraderType={setTraderType}
                                sttAmount={traderType == "future" ? 0.000125 : 0.000625}
                              />
                            }
                          </Tab.Pane>
                        </Tab.Content>
                      </Tab.Container>
                    </div>
                  </div>
                </Col>
                <Col md={12} xl={6} lg={12}>
                  {brokerageCalculator.loading ? (
                    <div className="single-calculator-info-frame ms-4 d-space">
                      <div className="calculator-grid-even">
                        <Skeleton
                          width={75}
                          height={15}
                          baseColor="#202020"
                          highlightColor="#444"
                        />
                        <h3 className="text-end">
                          <Skeleton
                            width={100}
                            height={15}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                        </h3>
                      </div>
                      <div className="calculator-grid-even">
                        <Skeleton
                          width={60}
                          height={15}
                          baseColor="#202020"
                          highlightColor="#444"
                        />
                        <h3 className="text-end">
                          <Skeleton
                            width={100}
                            height={15}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                        </h3>
                      </div>
                      <div className="calculator-grid-even">
                        <Skeleton
                          width={70}
                          height={15}
                          baseColor="#202020"
                          highlightColor="#444"
                        />
                        <h3 className="text-end">
                          <Skeleton
                            width={70}
                            height={15}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                        </h3>
                      </div>
                      <hr />
                      <div className="calculator-grid-even">
                        <Skeleton
                          width={150}
                          height={15}
                          baseColor="#202020"
                          highlightColor="#444"
                        />
                        <h3 className="text-end">
                          <Skeleton
                            width={35}
                            height={15}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                        </h3>
                      </div>
                      <div className="calculator-grid-even">
                        <Skeleton
                          width={170}
                          height={15}
                          baseColor="#202020"
                          highlightColor="#444"
                        />
                        <h3 className="text-end">
                          <Skeleton
                            width={35}
                            height={15}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                        </h3>
                      </div>
                      <div className="calculator-grid-even">
                        <Skeleton
                          width={185}
                          height={15}
                          baseColor="#202020"
                          highlightColor="#444"
                        />
                        <h3 className="text-end">
                          <Skeleton
                            width={35}
                            height={15}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                        </h3>
                      </div>
                      <div className="calculator-grid-even">
                        <Skeleton
                          width={140}
                          height={15}
                          baseColor="#202020"
                          highlightColor="#444"
                        />
                        <h3 className="text-end">
                          <Skeleton
                            width={55}
                            height={15}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                        </h3>
                      </div>
                      <div className="calculator-grid-even">
                        <Skeleton
                          width={35}
                          height={15}
                          baseColor="#202020"
                          highlightColor="#444"
                        />
                        <h3 className="text-end">
                          <Skeleton
                            width={45}
                            height={15}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                        </h3>
                      </div>
                      <div className="calculator-grid-even">
                        <Skeleton
                          width={100}
                          height={15}
                          baseColor="#202020"
                          highlightColor="#444"
                        />
                        <h3 className="text-end">
                          <Skeleton
                            width={35}
                            height={15}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                        </h3>
                      </div>
                      <hr />
                      <div className="calculator-grid-even">
                        <Skeleton
                          width={125}
                          height={15}
                          baseColor="#202020"
                          highlightColor="#444"
                        />
                        <h3 className="text-end">
                          <Skeleton
                            width={75}
                            height={15}
                            baseColor="#202020"
                            highlightColor="#444"
                          />
                        </h3>
                      </div>
                    </div>
                  ) : (
                    Object.keys(brokerageCalculator.data).length > 0 && (
                      <div className="single-calculator-info-frame ms-4 d-space">
                        <div className="calculator-grid-even">
                          <p>{t("brokerage_calculator.turn_over")}</p>
                          <h3 className="text-end">
                            ₹{brokerageCalculator.data.turnover}
                          </h3>
                        </div>
                        <div className="calculator-grid-even">
                          <p>{t("brokerage_calculator.profit_loss")}</p>
                          <h3 className="text-end">
                            ₹{brokerageCalculator.data.profit_and_loss}
                          </h3>
                        </div>
                        <div className="calculator-grid-even">
                          <p>{t("brokerage_calculator.charges")}</p>
                          <h3 className="text-end">
                            ₹{brokerageCalculator.data.total_charges}
                          </h3>
                        </div>
                        <hr />
                        <div className="calculator-grid-even">
                          <p>{t("brokerage_calculator.platform_charges")}</p>
                          <h3 className="text-end">
                            ₹
                            {traderType == "delivery"
                              ? deliveryBrokerage
                              : traderType == "intraday"
                                ? intradayBrokerage
                                : FoBrokerage}
                          </h3>
                        </div>
                        <div className="calculator-grid-even">
                          <p>{t("brokerage_calculator.other_charges")}</p>
                          <h3 className="text-end">
                            ₹{brokerageCalculator.data.non_brorkerage_charges}
                          </h3>
                        </div>
                        <div className="calculator-grid-even">
                          <p>{t("brokerage_calculator.stt")}</p>
                          <h3 className="text-end">
                            ₹{brokerageCalculator.data.stt}
                          </h3>
                        </div>
                        <div className="calculator-grid-even">
                          <p>{t("brokerage_calculator.exchange_charges")}</p>
                          <h3 className="text-end">
                            ₹
                            {
                              brokerageCalculator.data
                                .exchange_transaction_charges
                            }
                          </h3>
                        </div>
                        <div className="calculator-grid-even">
                          <p>{t("brokerage_calculator.sebi_fees")}</p>
                          <h3 className="text-end">
                            ₹{brokerageCalculator.data.sebi_charges}
                          </h3>
                        </div>
                        <div className="calculator-grid-even">
                          <p>{t("brokerage_calculator.gst")}</p>
                          <h3 className="text-end">
                            ₹{brokerageCalculator.data.gst}
                          </h3>
                        </div>
                        <div className="calculator-grid-even">
                          <p>{t("brokerage_calculator.stamp_duty")}</p>
                          <h3 className="text-end">
                            ₹{brokerageCalculator.data.stamp_duty}
                          </h3>
                        </div>
                        <hr />
                        <div className="calculator-grid-even">
                          <h3>{t("brokerage_calculator.net_p&l")}</h3>
                          <h3 className="text-end">
                            ₹
                            {brokerageCalculator.data.profit_and_loss -
                              brokerageCalculator.data.total_charges}
                          </h3>
                        </div>
                      </div>
                    )
                  )}
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <Container>
          <Row>
            <Col lg={12}>
              <div className="static-page-card">
                <div className="pricacy-policy-info">
                  <h4>Description</h4>
                  <p>
                    Trading in securities entails various charges beyond the
                    initial purchase price, with one of the most significant
                    being the brokerage fee. This fee is paid to a broker for
                    facilitating trades and typically represents a percentage of
                    the total trade value. Brokers charge this fee in addition
                    to the original trade value and deduct it directly from the
                    trader's portfolio.
                  </p>
                  <p>
                    Due to the potentially substantial cost, especially in
                    larger trades, many investors utilize a brokerage
                    calculator. This tool helps quickly analyze and understand
                    the total costs associated with trading, including brokerage
                    fees.
                  </p>
                </div>
                <div className="pricacy-policy-info">
                  <h4>Factors Influencing Brokerage Calculation</h4>
                  <p>
                    Brokerage calculations are influenced by several factors:
                  </p>
                  <ol>
                    <li>
                      <h4>Buy/Sale Price</h4>
                      <p>
                        The buy or sale price of a single security unit
                        significantly impacts the brokerage commission. The
                        brokerage fee is typically proportional to the
                        transaction price.
                      </p>
                    </li>
                    <li>
                      <h4>Transaction Volume</h4>
                      <p>
                        The total volume of the transaction also plays a crucial
                        role in determining the brokerage fee. Generally, the
                        greater the volume of the trade, the higher the
                        brokerage fee. However, some brokers offer reduced
                        commission rates for larger transaction volumes to
                        encourage high-volume trading.
                      </p>
                    </li>
                    <li>
                      <h4>Type of Broker</h4>
                      <p>
                        Brokerage fees can also vary depending on the type of
                        broker involved:
                      </p>
                      <ul>
                        <li>
                          <h4>Full-Service Brokers: </h4>
                          These brokers offer a comprehensive range of services,
                          including research, sales management, and investment
                          advice, which typically makes their fees higher.
                        </li>
                        <li>
                          <h4>Discount Brokers:</h4>
                          These brokers primarily provide a platform for trading
                          and usually charge lower brokerage fees. Discount
                          brokers often apply a flat rate for their services,
                          regardless of the trade value.
                        </li>
                      </ul>
                    </li>
                  </ol>
                  <h4>Benefits of Using a Brokerage Calculator</h4>
                  <p>
                    Utilizing an online tool like the TaxTrax brokerage
                    calculator offers significant advantages for traders:
                  </p>
                  <p>
                    Cost Transparency: A brokerage calculator provides clear
                    insights into all the fees associated with a transaction,
                    helping traders understand the full cost of their trades
                    upfront.
                  </p>
                  <p>
                    Time Efficiency: By automating the calculation of various
                    charges, including brokerage fees, traders can save
                    considerable time that would otherwise be spent on manual
                    calculations.
                  </p>
                  <p>
                    Improved Decision Making: With accurate cost details at
                    their disposal, traders can make more informed decisions
                    about their transactions, optimizing their trading
                    strategies based on cost-effectiveness.
                  </p>
                  <p>
                    Ease of Use: Online brokerage calculators are designed to be
                    user-friendly, making it easy for traders of all skill
                    levels to quickly assess trading costs.
                  </p>
                  <p>
                    These benefits make brokerage calculators an essential tool
                    for enhancing the trading process, allowing traders to
                    manage their expenses more effectively and streamline their
                    operations.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        {/* <FAQIndex
          data={[
            {
              question: "",
              answer: "",
            },
          ]}
        /> */}
      </div>
    </>
  );
};

export default CalculatorIndex;
