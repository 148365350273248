import { createSlice } from "@reduxjs/toolkit";
import {
  getCommonInitialState,
  getCommonStart,
  getCommonSuccess,
  getCommonFailure,
} from "../utils";

const initialState = {
  settingsJson: getCommonInitialState(),
  contactForm: getCommonInitialState(),
};

const ContactSlice = createSlice({
  name: "contact",
  initialState,
  reducers: {

    settingsJsonStart: (state) => { state.settingsJson = getCommonStart() },
    settingsJsonSuccess: (state, action) => { state.settingsJson = getCommonSuccess(action.payload) },
    settingsJsonFailure: (state, action) => { state.settingsJson = getCommonFailure(action.payload) },

    contactFormStart: (state) => { state.contactForm = getCommonStart() },
    contactFormSuccess: (state, action) => { state.contactForm = getCommonSuccess(action.payload) },
    contactFormFailure: (state, action) => { state.contactForm = getCommonFailure(action.payload) },

  }
});



export const {
  settingsJsonFailure,
  settingsJsonStart,
  settingsJsonSuccess,
  contactFormFailure,
  contactFormStart,
  contactFormSuccess
} = ContactSlice.actions;

export default ContactSlice.reducer;