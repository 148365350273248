import React, { useState, useEffect } from "react";
import HomeBannerIndex from "./HomeBannerIndex";
import ChatWidgetIndex from "./ChatWidgetIndex";
import Background from './banner-bg-img.png';
import WhyChooseUsIndex from "./WhyChooseUsIndex";
import BotExperienceIndex from "./BotExperienceIndex";
import TaxSolutionIndex from "./TAXSolutionIndex";
import HowItsWorkIndex from "./HowItsWorkIndex";
import CTAIndex from "./CTAIndex";
import FAQIndex from "./FAQIndex";
import CTAFirstIndex from "./CTAFirstIndex";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import ContactIndex from "./ContactIndex";

const LandingPageIndex = (props) => {

  useEffect(() => {
    getDeviceUniqueID();
  }, []);

  const getDeviceUniqueID = async () => {
    const fpPromise = FingerprintJS.load();
    const fp = await fpPromise;
    const result = await fp.get();
    localStorage.setItem("uuid", result.visitorId)
  }

  return (
    <>
      <div className="landing-page-sec">
        <div className="landing-page-product-widget-sec" style={{
          background: `url(${Background})`,
        }}
        >
          <HomeBannerIndex />
          <ChatWidgetIndex />
        </div>
        <WhyChooseUsIndex />
        <BotExperienceIndex />
        <TaxSolutionIndex />
        <HowItsWorkIndex />
        <CTAIndex />
        <FAQIndex />
        <ContactIndex/>
        <CTAFirstIndex />
      </div>
    </>
  );
};

export default LandingPageIndex;
