import axios from "axios";
export const aiApiUrl = "https://taxtrax-ai.botfingers.com:5004/api/v1/"; // Production Mode
const LOGIN_BY = "manual";
const DEVICE_TYPE = "web";
const DEVICE_TOKEN = "123456";
const apiUrl = "https://cms.tax-trax.com/api/user/";
const calculatorApiUrl = "https://taxtrax-ai.botfingers.com:5005/api/v1/";

const Environment = {
  postMethod: async (action, object) => {
    let userId =
      localStorage.getItem("userId") !== "" &&
      localStorage.getItem("userId") !== null &&
      localStorage.getItem("userId") !== undefined
        ? localStorage.getItem("userId")
        : "";

    let accessToken =
      localStorage.getItem("accessToken") !== "" &&
      localStorage.getItem("accessToken") !== null &&
      localStorage.getItem("accessToken") !== undefined
        ? localStorage.getItem("accessToken")
        : "";

    const url = apiUrl + action;

    let formData = new FormData();

    // By Default Id and token

    formData.append("id", userId);
    formData.append("token", accessToken);

    var socialLoginUser = 0;

    // append your data
    for (var key in object) {
      formData.append(key, object[key]);

      if (key === "social_unique_id") {
        socialLoginUser = 1;
      }
    }

    // By Default added device type and login type in future use
    if (!socialLoginUser) {
      formData.append("login_by", LOGIN_BY);
    }

    formData.append("device_type", DEVICE_TYPE);
    formData.append("device_token", DEVICE_TOKEN);
    return await axios.post(url, formData);
  },

  getMethod: async (action, object) => {
    let userId =
      localStorage.getItem("userId") !== "" &&
      localStorage.getItem("userId") !== null &&
      localStorage.getItem("userId") !== undefined
        ? localStorage.getItem("userId")
        : "";
    let accessToken =
      localStorage.getItem("accessToken") !== "" &&
      localStorage.getItem("accessToken") !== null &&
      localStorage.getItem("accessToken") !== undefined
        ? localStorage.getItem("accessToken")
        : "";

    const url = apiUrl + action;

    let formData = new FormData();

    // By Default Id and token

    formData.append("id", userId);
    formData.append("token", accessToken);

    // append your data
    for (var key in object) {
      formData.append(key, object[key]);
    }

    // By Default added device type and login type in future use

    formData.append("login_by", LOGIN_BY);
    formData.append("device_type", DEVICE_TYPE);
    formData.append("device_token", DEVICE_TOKEN);

    return await axios.get(url, formData);
  },

  aiPostMethod: async (action, object) => {
    const url = aiApiUrl + action;
    return await axios.post(url, object);
  },

  calculatorPostMethod: async (action, object) => {
    const url = calculatorApiUrl + action;
    return await axios.post(url, object);
  },
};

export default Environment;
